import appStrings from '@/app/utility/string.utility';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import VueCkeditor from 'vue-ckeditor5';
import description from './description';
export default {
  name: 'terms',
  props: [
    'editMode',
    'tenantLeaseApprovalStatus',
    'leaseMasterAgreementHdrId',
    'selectedLeaseDetails'
  ],
  components: {
    'vue-ckeditor': VueCkeditor.component,
    description
  },
  data() {
    return {
      editors: {
        classic: ClassicEditor
      },
      editorConfig: {
        removePlugins: [
          'CKFinderUploadAdapter',
          'CKFinder',
          'EasyImage',
          'Image',
          'ImageCaption',
          'ImageStyle',
          'ImageToolbar',
          'ImageUpload',
          'MediaEmbed'
        ]
      },
      showValueSetModal: false,
      parent_value_set_id: null,
      valueSetDtlId: null,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      gridData: [
        {
          lease_tenant_agreement_hdr_id: 0,
          lease_tenant_term_con_id: 0,
          active: true,
          term_con_vset: null,
          term_con_vset_meaning: null,
          term_con_type_vset: null,
          term_con_type_vset_meaning: null,
          description: null,
          value_set_dtl_id: null
        }
      ],
      gridFields: [
        {
          key: 'active',
          stickyColumn: true
        },
        {
          key: 'term_con_type_vset_meaning',
          label: 'Terms and Condition Type'
        },
        {
          key: 'term_con_vset_meaning',
          label: 'Terms and Condition'
        },
        {
          key: 'description'
        },
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        }
      ],
      showDescriptionModal: false,
      descriptionValue: null,
      selectedIndex: null
    };
  },
  mounted() {
    this.eventBus.$on('terms', selectedLeaseDetails => {
      if (selectedLeaseDetails.leaseMasterAgreementHdrId) {
        this.leaseMasterId = selectedLeaseDetails.leaseMasterAgreementHdrId;
        this.getTermsConditionMasterDetails(this.leaseMasterId);
      } else {
        this.getTermsConditionDetails(selectedLeaseDetails.leaseTenantHdrId);
      }
    });
  },
  methods: {
    getTermsConditionMasterDetails(leaseMasterAgreementHdrId) {
      const payload = {
        leaseMasterAgreementHdrId: leaseMasterAgreementHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('leases/getTermsConditionDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200 && resp.data.data.length) {
            this.gridData = resp.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getTermsConditionDetails(leaseTenantAgreementHdrId) {
      const payload = {
        leaseTenantAgreementHdrId: leaseTenantAgreementHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('leases/getTermsConditionTenantLeaseDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200 && resp.data.data.length) {
            this.gridData = resp.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.specIndex = index;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.TERM_CON_VSET) {
        this.parent_value_set_id = this.gridData[
          this.specIndex
        ].value_set_dtl_id;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.TERM_CON_TYPE_VSET) {
        this.gridData[this.specIndex].term_con_type_vset = item.value_code;
        this.gridData[this.specIndex].term_con_type_vset_meaning =
          item.value_meaning;
        this.gridData[this.specIndex].value_set_dtl_id = item.value_set_dtl_id;
        this.gridData[this.specIndex].term_con_vset = null;
        this.gridData[this.specIndex].term_con_vset_meaning = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.TERM_CON_VSET) {
        this.gridData[this.specIndex].term_con_vset = item.value_code;
        this.gridData[this.specIndex].term_con_vset_meaning =
          item.value_meaning;
      }
      this.showValueSetModal = false;
    },
    addNewRow() {
      if (this.editMode) {
        this.gridData.push({
          lease_tenant_agreement_hdr_id: 0,
          lease_tenant_term_con_id: 0,
          active: true,
          term_con_vset: null,
          term_con_vset_meaning: null,
          term_con_type_vset: null,
          term_con_type_vset_meaning: null,
          description: null
        });
      }
    },
    removeRow(index) {
      if (this.editMode) {
        this.gridData.splice(index, 1);
      }
    },
    clearVsetValues(vsetCode, index) {
      switch (vsetCode) {
        case 'TERM_CON_TYPE_VSET':
          this.gridData[index].term_con_type_vset_meaning = null;
          break;
        case 'TERM_CON_VSET':
          this.gridData[index].term_con_vset_meaning = null;
          break;
      }
    },
    showHideDescriptionModal(flag, item, index) {
      this.showDescriptionModal = flag;
      if (flag) {
        this.selectedIndex = index;
        this.descriptionValue = item.description;
      }
    },
    updatedValue(description) {
      this.gridData[this.selectedIndex].description = description;
      this.showDescriptionModal = false;
    }
  },
  beforeDestroy() {
    this.eventBus.$off('terms');
  }
};
