import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'addEditTerminationLease',
  components: {
    DatePicker
  },
  props: ['selectedLeaseDetails'],
  data() {
    return {
      loader: false,
      payload: null,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      terminationDate: null,
      periodTo: null,
      disabledFlag: false
    };
  },
  validations: {
    terminationDate: {
      required
    }
  },
  mounted() {
    this.getLeaseTerminationDate(
      this.selectedLeaseDetails.lease_tenant_agreement_hdr_id
    );
  },
  methods: {
    leaseTermination() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const promt = confirm('Are you sure, you want to delete?');
      if (promt) {
        const payload = {
          lease_tenant_agreement_hdr_id: this.selectedLeaseDetails.lease_tenant_agreement_hdr_id,
          termination_date: commonHelper.formattedDate(this.terminationDate)
        };
        this.loader = true;
        this.$store
          .dispatch('leases/leaseTermination', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.disabledFlag = true;
              this.showAlert = true;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
            } else {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      }
    }
    },
    getLeaseTerminationDate(leaseTenantAgreementHdrId) {
      this.$store
        .dispatch('leases/getLeaseTerminationDate', leaseTenantAgreementHdrId)
        .then(response => {
          if (response.status === 200) {
            if(response.data.data){
            this.periodTo = response.data.data.period_to;}
            else {
                this.periodTo= this.selectedLeaseDetails.from_date;
            }
          }
        });
    },    
    disabledDates(date) {
      const restrictedDate = new Date(this.periodTo);
      restrictedDate.setHours(0, 0, 0, 0);
      return date < restrictedDate;
    }
  }
};