import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility';
import codeCombination from '@/app/components/shared/codeCombination';
export default {
  name: 'SecurityDeposit',
  components: {
    DatePicker,
    codeCombination
  },
  props: ['editMode', 'leaseForm'],
  data() {
    return {
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      vsetCode: null,
      uomClassObj: null,
      specIndex: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      areaType: null,
      setTimeVsetCode: null,
      gridData: [
        {
          active: false,
          lease_investor_agreement_hdr_id: null,
          lease_investor_agreement_sec_dpt_id: 0,
          lease_security_deposit_uom_vset_meaning: null,
          period_from: null,
          period_to: null,
          period: null,
          lease_security_deposit_uom_vset: null,
          lease_security_deposit: null,
          request_num: null,
          security_deposit_type_meaning: null,
          security_deposit_type_code: null,
          security_deposit_status: null,
          create_security_deposit: false,
          charge_ac_ccid: null,
          charge_ac_ccid_dtl: null,
          charge_ac_ccid_meaning: null,
        }
      ],
      gridFields: [
        {
          key: 'create_security_deposit'
        },
        {
          key: 'lease_security_deposit_uom_vset_meaning',
          label: 'UOM'
        },
        {
          key: 'security_deposit_type_meaning',
          label: 'Deposit Type'
        },
        {
          key: 'period'
        },
        {
          key: 'lease_security_deposit',
          label: 'Amount'
        },
        {
          key: 'charge_ac_ccid_meaning',
          label: 'Charge Account'
        },
        {
          key: 'security_deposit_status_meaning',
          label: 'Status'
        },
        {
          key: 'request_num'
        },
        {
          key: 'error_msg'
        }
      ],
      charge_ac_ccid: null,
      charge_ac_ccid_dtl: null,
      charge_ac_ccid_meaning: null,
      showCodeCombinationModal: false,
      combinationDetails: {
        le_id: null,
        project_code: null
      },
      codeCombDtls: {
        le_id: null,
        lease_prj_code: null
      },
    };
  },
  mounted() {
    this.eventBus.$on('sd', tabData => {
      this.codeCombDtls.le_id = tabData.leaseForm.le_id;
      this.codeCombDtls.lease_prj_code = tabData.leaseForm.lease_prj_code;
      if (tabData.leaseInvestorHdrId) {
        this.getDetails(tabData.leaseInvestorHdrId);
      }
      if (tabData.leaseMasterAgreementHdrId) {
        this.getMasterData(tabData.leaseMasterAgreementHdrId);
      }
    });
  },
  methods: {
    getDetails(leaseInvestorHdrId) {
      const payload = {
        tabName: 'security-deposit',
        leaseInvestorHdrId: leaseInvestorHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('leases/getLeaseInvestorDataById', payload)
        .then(resp => {
          this.loader = false;
          if (
            resp.status === 200 &&
            resp.data.data.length &&
            this.gridData[0].lease_investor_agreement_sec_dpt_id === 0
          ) {
            this.gridData = resp.data.data;
            const createSecurityDepositIndex = resp.data.data.findIndex(
              data =>
                data.security_deposit_status === 'U' ||
                data.security_deposit_status === 'E'
            );
            if (createSecurityDepositIndex !== -1)
              this.gridData[
                createSecurityDepositIndex
              ].create_security_deposit = true;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getMasterData(leaseMasterAgreementHdrId) {
      const payload = {
        tabName: 'security-deposit',
        leaseMasterAgreementHdrId: leaseMasterAgreementHdrId
      };
      this.$store
        .dispatch('leases/getLeaseMasterAgreementDataById', payload)
        .then(resp => {
          if (resp.status === 200 && resp.data.data.length > 0) {
            const filterData = resp.data.data.filter(
              item => item.active === true
            );
            this.gridData = filterData.map(item => {
              return {
                active: item.active,
                lease_investor_agreement_hdr_id: null,
                lease_investor_agreement_sec_dpt_id: 0,
                lease_security_deposit_uom_vset_meaning:
                  item.lease_security_deposit_uom_vset_meaning,
                period_from: item.period_from,
                period_to: item.period_to,
                period: item.period,
                lease_security_deposit_uom_vset:
                  item.lease_security_deposit_uom_vset,
                lease_security_deposit: item.lease_security_deposit,
                request_num: null,
                security_deposit_type_code: item.security_deposit_type,
                security_deposit_type_meaning:
                  item.security_deposit_type_meaning,
                security_deposit_status: item.security_deposit_status,
                create_security_deposit: false,
                charge_ac_ccid: this.charge_ac_ccid,
                charge_ac_ccid_dtl: this.charge_ac_ccid_dtl,
                charge_ac_ccid_meaning: this.charge_ac_ccid_meaning,
              };
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addNewRow() {
      this.gridData.push({
        active: false,
        lease_investor_agreement_hdr_id: null,
        lease_investor_agreement_sec_dpt_id: 0,
        lease_security_deposit_uom_vset_meaning: null,
        period_from: null,
        period_to: null,
        period: null,
        lease_security_deposit_uom_vset: null,
        lease_security_deposit: null,
        request_num: null,
        security_deposit_type_meaning: null,
        security_deposit_type_code: null,
        security_deposit_status: null,
        create_security_deposit: false,
        charge_ac_ccid: null,
        charge_ac_ccid_dtl: null,
        charge_ac_ccid_meaning: null,
      });
    },
    removeRow(index) {
      this.gridData.splice(index, 1);
    },
    opendDependentUom(vsetCode, index) {
      const payload = {
        valueSetName: vsetCode,
        dependentVariable: {
          user_id: this.$store.state.auth.userId,
          res_id: this.$store.state.shared.responsibilityId
        }
      };
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          this.loader = false;
          this.uomClassObj = null;
          if (response.status === 200) {
            const findTimeObj = response.data.data.page.find(
              vsetData => vsetData.value_code === 'TIME'
            );
            if (findTimeObj) {
              this.uomClassObj = findTimeObj;
              this.openValueSetModal('UOM', index);
            }
          }
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'UOM') {
        this.gridData[this.specIndex].lease_security_deposit_uom_vset =
          item.value_code;
        this.gridData[this.specIndex].lease_security_deposit_uom_vset_meaning =
          item.value_meaning;
      } else if (this.vsetCode === 'SECURITY_DEPOSIT_TYPE') {
        this.gridData[this.specIndex].security_deposit_type_code =
          item.value_code;
        this.gridData[this.specIndex].security_deposit_type_meaning =
          item.value_meaning;
      }
      this.showValueSetModal = false;
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.specIndex = index;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.uomClassObj && vsetCode !== 'SECURITY_DEPOSIT_TYPE') {
        this.parent_value_set_id = this.uomClassObj.value_set_dtl_id;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    calcAmount(period, index) {
      this.gridData[index].lease_security_deposit =
        period * this.leaseForm.rate * this.leaseForm.super_area;
    },
    checkUncheckRadio(specIndex) {
      this.gridData.forEach((element, index) => {
        return (element.active = index === specIndex ? true : false);
      });
    },
    validateCheckBox(row) {
      // validation of checkbox
      if (row.request_num) {
        if (!/e/i.test(row.security_deposit_status)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    createSecDeposit(index) {
      const payload = {
        lease_inv_sch: [
          {
            entity_id: this.gridData[index].lease_investor_agreement_sec_dpt_id,
            entity_type: 'SDEP',
            lease_id: this.gridData[index].lease_investor_agreement_hdr_id
          }
        ]
      };
      this.loader = true;
      this.$store
        .dispatch('leases/createRentalForInvestor', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 201) {
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.getDetails(
              this.gridData[index].lease_investor_agreement_hdr_id
            );
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsgmessage;
        });
    },
    showHideCodeCombinationModal(flag, index) {
      this.segmentIndex = index;
      if (flag) {
        if (!this.codeCombDtls.le_id) {
          return alert(appStrings.DEPENDENTVSETMSG.LEMSG);
        }
        if (!this.codeCombDtls.lease_prj_code) {
          return alert(appStrings.DEPENDENTVSETMSG.FMSPRJMSG);
        }
        this.combinationDetails.le_id = this.codeCombDtls.le_id;
        this.combinationDetails.project_code = this.codeCombDtls.lease_prj_code;
      }
      this.showCodeCombinationModal = flag;
    },
    selectedSegment(item) {
      this.gridData[this.segmentIndex].charge_ac_ccid = item.ccid;
      this.gridData[this.segmentIndex].charge_ac_ccid_dtl = item.segment_code;
      this.gridData[this.segmentIndex].charge_ac_ccid_meaning =
        item.segment_meaning;
      this.gridData = JSON.parse(JSON.stringify(this.gridData));
      this.showCodeCombinationModal = false;
    }
  },
  beforeDestroy() {
    this.eventBus.$off('sd');
  }
};
