import DatePicker from 'vue2-datepicker';
export default {
  name: 'rent',
  props: [
    'editMode',
    'tenantLeaseApprovalStatus',
    'leaseMasterAgreementHdrId',
    'selectedLeaseDetails'
  ],
  components: { DatePicker },
  data() {
    return {
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      gridData: [
        {
          lease_tenant_rent_period_id: 0,
          active: true,
          period_from: null,
          period_to: null
        }
      ],
      gridFields: [
        {
          key: 'active',
          stickyColumn: true
        },
        {
          key: 'period_from'
        },
        {
          key: 'period_to'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ]
    };
  },
  mounted() {
    this.eventBus.$on('rent', selectedLeaseDetails => {
      if (selectedLeaseDetails.leaseMasterAgreementHdrId) {
        this.leaseMasterId = selectedLeaseDetails.leaseMasterAgreementHdrId;
        this.getRentFreeMasterDetails(this.leaseMasterId);
      } else {
        this.getRentFreeTenantLeaseDetails(
          selectedLeaseDetails.leaseTenantHdrId
        );
      }
    });
  },
  methods: {
    getRentFreeMasterDetails(leaseMasterAgreementHdrId) {
      const payload = {
        leaseMasterAgreementHdrId: leaseMasterAgreementHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('leases/getRentFreeDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200 && resp.data.data.length) {
            this.gridData = resp.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getRentFreeTenantLeaseDetails(leaseTenantAgreementHdrId) {
      const payload = {
        leaseTenantAgreementHdrId: leaseTenantAgreementHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('leases/getRentFreeTenantLeaseDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200 && resp.data.data.length) {
            this.gridData = resp.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    periodFromIndex(index) {
      this.specIndex = index;
    },
    disabledDates(date) {
      const restrictedDate = new Date(
        this.gridData[this.specIndex].period_from
      );
      restrictedDate.setHours(0, 0, 0, 0);
      return date < restrictedDate;
    },
    addNewRow() {
      if (this.editMode) {
        this.gridData.push({
          lease_tenant_rent_period_id: 0,
          active: true,
          period_from: null,
          period_to: null
        });
      }
    },
    removeRow(index) {
      if (this.editMode) {
        this.gridData.splice(index, 1);
      }
    }
  },
  beforeDestroy() {
    this.eventBus.$off('rent');
  }
};
