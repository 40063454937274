import appStrings from '@/app/utility/string.utility';
export default {
  name: 'location',
  props: { leId: String, editMode: Boolean },
  data() {
    return {
      unsubscribe: null,
      loader: false,
      localtionIndex: null,
      setTimeVsetCode: null,
      parent_value_set_id: null,
      showValueSetModal: false,
      locationList: [
        {
          location_name: null,
          location_id: null
        }
      ],
      locationFeilds: [
        {
          key: 'location_name',
          label: 'Location'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ]
    };
  },
  mounted() {
    this.eventBus.$on('location', wfHeaderId => {
      if (wfHeaderId) {
        this.getLocationList(wfHeaderId);
      }
    });
  },
  methods: {
    getLocationList(wfHeaderId) {
      this.loader = true;
      const payload = {
        tabType: 'locations',
        wfHeaderId: wfHeaderId
      };
      this.$store
        .dispatch('intWorkflow/getWfTabsDetails', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200 && response.data.data.length > 0) {
            this.locationList = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addNewRow() {
      if (this.editMode) {
        this.locationList.push({
          location_name: null,
          location_id: null
        });
      }
    },
    openValueSetModal(vsetCode, index) {
      if (!this.leId) {
        alert(appStrings.DEPENDENTVSETMSG.LEMSG);
        return;
      }
      this.localtionIndex = index;
      this.parent_value_set_id = this.leId.toString();
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      this.locationList[this.localtionIndex].location_name = item.loc_name;
      this.locationList[this.localtionIndex].location_id = item.loc_id;
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    removeRow(index) {
      this.locationList.splice(index, 1);
    },
    rowSelected() {},
    clearVsetValues(vsetCode,localtionIndex){
      this.locationList[localtionIndex].location_name = null;
      this.locationList[localtionIndex].location_id = null;
    }
  },
  beforeDestroy() {
    this.eventBus.$off('location');
    clearTimeout(this.setTimeVsetCode);
  }
};
