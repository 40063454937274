var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loader)?_c('div',{staticClass:"overlay"},[_c('b-spinner',{staticClass:"loader",attrs:{"variant":"primary","label":"Spinning"}})],1):_vm._e(),_c('b-row',[_c('b-col',{staticClass:"mt-0 text-centre pl-3",attrs:{"md":"12"}},[_c('div',{},[_c('b-table',{staticClass:"bg-white",attrs:{"striped":"","hover":"","outlined":"","items":_vm.documentsData,"fields":_vm.documentsFields,"show-empty":"","sticky-header":""},scopedSlots:_vm._u([{key:"cell(original_file_name)",fn:function(data){return [_c('span',{staticStyle:{"cursor":"pointer","color":"#007bff"},on:{"click":function($event){return _vm.getOpenKmAuthToken(
                  data.item.document_det_id,
                  data.item.original_file_name
                )}}},[_vm._v(_vm._s(data.item.original_file_name))])]}},{key:"cell(file_name)",fn:function(data){return [_c('span',{staticStyle:{"color":"#007BFF","cursor":"pointer"},on:{"click":function($event){return _vm.openPreviewDoc(
                  true,
                  data.item.document_det_id,
                  data.item.original_file_name,
                  data.index
                )}}},[(data.item.document_det_id)?_c('b-icon',{attrs:{"icon":"eye-fill","font-scale":"1.5"}}):_vm._e()],1)]}}])})],1)])],1),_c('b-modal',{attrs:{"no-close-on-esc":"","no-close-on-backdrop":"","hide-footer":"","size":"xl","title":"Preview"},on:{"hidden":function($event){return _vm.showHideOpenModal(false)}},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" Preview "),_c('access-right-button',{attrs:{"accessButtons":_vm.accessButtons}})]},proxy:true}]),model:{value:(_vm.showOpenModal),callback:function ($$v) {_vm.showOpenModal=$$v},expression:"showOpenModal"}},[_c('previewDoc',{attrs:{"authToken":_vm.authToken,"docDtlId":_vm.docDtlId,"uploadedFileName":_vm.uploadedFileName,"data":_vm.documentsData,"currentPageIndex":_vm.currentPageIndex}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }