import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'tax',
  components: {
    DatePicker
  },
  props: ['editMode', 'leaseMasterAgreementHdrId', 'tenantLeaseApprovalStatus'],
  data() {
    return {
      vsetCode: null,
      uomClassObj: null,
      specIndex: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      areaType: null,
      setTimeVsetCode: null,
      taxNature: null,
      gridData: [
        {
          active: true,
          category_name: null,
          lease_tenant_agreement_hdr_id: null,
          tax_group: null,
          tax_group_id: null,
          tax_cat_id: null,
          period_from: null,
          period_to: null,
          lease_tenant_agreement_tax_id: 0,
          investor_intra_tax_cat_name: null,
          investor_intra_tax_cat: null,
          investor_inter_tax_cat_name: null,
          investor_inter_tax_cat: null
        }
      ],
      gridFields: [
        {
          key: 'active',
          stickyColumn: true
        },
        {
          key: 'tax_group'
        },
        {
          key: 'category_name'
        },
        {
          key: 'investor_intra_tax_cat_name',
          label: 'Investor Intra Tax Cat'
        },
        {
          key: 'investor_inter_tax_cat_name',
          label: 'Investor Inter Tax Cat'
        },
        {
          key: 'period_from_to',
          label: 'Period (From - To)'
        },
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        }
      ]
    };
  },
  mounted() {
    this.setDefaultTaxGroup();
    this.eventBus.$on('tax', tabData => {
      if (tabData.leaseTenantHdrId) {
        this.getDetails(tabData.leaseTenantHdrId);
      }
      if (tabData.leaseMasterAgreementHdrId && !tabData.leaseTenantHdrId) {
        this.getMasterLease(tabData.leaseMasterAgreementHdrId);
      }
    });
  },
  methods: {
    getDetails(leaseTenantHdrId) {
      const payload = {
        tabName: 'tax',
        leaseTenantHdrId: leaseTenantHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('leases/getLeaseTenantDataById', payload)
        .then(resp => {
          this.loader = false;
          if (
            resp.status === 200 &&
            resp.data.data.length &&
            this.gridData[0].lease_tenant_agreement_tax_id === 0
          ) {
            this.gridData = resp.data.data.map(data => {
              data.period_from_to = [
                new Date(data.period_from),
                new Date(data.period_to)
              ];
              return data;
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addNewRow(index) {
      if (this.editMode) {
        this.gridData.push({
          active: true,
          lease_tenant_agreement_hdr_id: null,
          tax_group: this.gridData[index].tax_group,
          tax_group_id: this.gridData[index].tax_group_id,
          tax_cat_id: this.gridData[index].tax_cat_id,
          category_name: this.gridData[index].category_name,
          period_from: null,
          period_to: null,
          lease_tenant_agreement_tax_id: 0,
          investor_intra_tax_cat_name: this.gridData[index]
            .investor_intra_tax_cat_name,
          investor_intra_tax_cat: this.gridData[index].investor_intra_tax_cat,
          investor_inter_tax_cat_name: this.gridData[index]
            .investor_inter_tax_cat_name,
          investor_inter_tax_cat: this.gridData[index].investor_inter_tax_cat
        });
      }
    },
    getMasterLease(leaseMasterAgreementHdrId) {
      const payload = {
        tabName: 'tax',
        leaseMasterAgreementHdrId: leaseMasterAgreementHdrId
      };
      this.$store
        .dispatch('leases/getLeaseMasterAgreementDataById', payload)
        .then(resp => {
          if (resp.status === 200 && resp.data.data.length) {
            // const filterData = resp.data.data.filter(
            //   item => item.active === true
            // );
            this.gridData = resp.data.data.map(item => {
              return {
                active: item.active,
                category_name: item.category_name,
                lease_tenant_agreement_hdr_id: 0,
                tax_group: item.tax_group,
                tax_group_id: item.tax_group_id,
                tax_cat_id: item.tax_cat_id,
                period_from_to: [
                  new Date(item.period_from),
                  new Date(item.period_to)
                ],
                lease_tenant_agreement_tax_id: 0,
                investor_intra_tax_cat_name: item.investor_intra_tax_cat_name,
                investor_intra_tax_cat: item.investor_intra_tax_cat,
                investor_inter_tax_cat_name: item.investor_inter_tax_cat_name,
                investor_inter_tax_cat: item.investor_inter_tax_cat
              };
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    removeRow(index) {
      if (this.editMode) {
        this.gridData.splice(index, 1);
      }
    },
    opendDependentUom(vsetCode, index) {
      const payload = {
        valueSetName: vsetCode,
        dependentVariable: {
          user_id: this.$store.state.auth.userId,
          res_id: this.$store.state.shared.responsibilityId
        }
      };
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          this.loader = false;
          this.uomClassObj = null;
          if (response.status === 200) {
            const findTimeObj = response.data.data.page.find(
              vsetData => vsetData.value_code === 'TIME'
            );
            if (findTimeObj) {
              this.uomClassObj = findTimeObj;
              this.openValueSetModal('UOM', index);
            }
          }
        });
    },
    selectBoxChecked(flag, index) {
      if (flag) {
        const findActivatedRecords = this.gridData.filter(data => {
          return (
            data.active &&
            this.gridData[index].tax_group_id == data.tax_group_id
          );
        });
        if (findActivatedRecords.length > 1) {
          alert('Two groups can not be active');
          this.gridData[index].active = false;
        }
      }
    },
    validateDoubleGroup(item) {
      const findActivatedRecords = this.gridData.find(data => {
        return (
          data.active === this.gridData[this.specIndex].active &&
          item.tax_group_id == data.tax_group_id
        );
      });
      return findActivatedRecords ? true : false;
    },
    selectedvalueSet(item) {
      if (
        this.validateDoubleGroup(item) &&
        this.vsetCode === 'TAX_GROUP_NAME_VSET'
      ) {
        alert('You are not allowed to add two active group');
        return;
      }
      if (this.vsetCode === 'TAX_GROUP_NAME_VSET') {
        this.gridData[this.specIndex].tax_group_id = item.tax_group_id;
        this.gridData[this.specIndex].tax_group = item.tax_group;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.TAX_CATEGORY_NAME_VSET
      ) {
        if (this.taxNature === 'investor_intra') {
          this.gridData[this.specIndex].investor_intra_tax_cat_name =
            item.category_name;
          this.gridData[this.specIndex].investor_intra_tax_cat =
            item.tax_cat_hdr_id;
        } else if (this.taxNature === 'investor_inter') {
          this.gridData[this.specIndex].investor_inter_tax_cat_name =
            item.category_name;
          this.gridData[this.specIndex].investor_inter_tax_cat =
            item.tax_cat_hdr_id;
        } else {
          this.gridData[this.specIndex].category_name = item.category_name;
          this.gridData[this.specIndex].tax_cat_id = item.tax_cat_hdr_id;
        }
      }
      this.showValueSetModal = false;
    },
    openValueSetModal(vsetCode, index, taxNature) {
      this.vsetCode = vsetCode;
      this.specIndex = index;
      this.taxNature = taxNature;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.gridData[this.specIndex].tax_group_id) {
        this.parent_value_set_id = this.gridData[this.specIndex].tax_group_id;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    setDate(dateRange, index) {
      this.gridData[index].period_from_to = commonHelper.setFutureDate(
        dateRange
      );
    },
    setDefaultTaxGroup() {
      const groupType = commonHelper.getValuesetData(
        appStrings.VALUESETTYPE.TAX_GROUP_NAME_VSET
      );
      groupType.then(key => {
        this.gridData[0].tax_group_id = key[0].tax_group_id;
        this.gridData[0].tax_group = key[0].tax_group;
        this.parent_value_set_id = this.gridData[0].tax_group_id;
      });
      // const groupCat = commonHelper.getValuesetData(
      //   appStrings.VALUESETTYPE.TAX_CATEGORY_NAME_VSET
      // );
      // groupCat.then(key => {
      //   this.gridData[0].category_name = key[2].category_name;
      //   this.gridData[0].tax_cat_id = key[2].tax_cat_hdr_id;
      // });
    },
    clearVsetValues(vsetCode, index) {
      switch (vsetCode) {
        case 'TAX_GROUP_NAME_VSET':
          this.gridData[index].tax_group = null;
          break;
        case 'TAX_CATEGORY_NAME_VSET':
          this.gridData[index].category_name = null;
          break;
        case 'investor_intra':
          this.gridData[index].investor_intra_tax_cat_name = null;
          break;
        case 'investor_inter':
          this.gridData[index].investor_inter_tax_cat_name = null;
          break;
      }
    },
    setDefaultTaxCategory() {}
  },
  beforeDestroy() {
    this.eventBus.$off('tax');
  }
};
