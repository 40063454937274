import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'tax',
  components: {
    DatePicker
  },
  props: ['editMode', 'masterAgreementApprovalStatus'],
  data() {
    return {
      vsetCode: null,
      uomClassObj: null,
      specIndex: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      areaType: null,
      setTimeVsetCode: null,
      taxNature: null,
      gridData: [
        {
          category_name: null,
          laese_master_agreement_hdr_id: null,
          lease_master_agreement_tax_id: 0,
          period_from_to: [],
          period_from: null,
          period_to: null,
          tax_cat_id: null,
          tax_group: null,
          tax_group_id: null,
          investor_intra_tax_cat_name: null,
          investor_intra_tax_cat: null,
          investor_inter_tax_cat_name: null,
          investor_inter_tax_cat: null,
          active: true
        }
      ],
      gridFields: [
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        },
        {
          key: 'active',
          stickyColumn: true
        },
        {
          key: 'tax_group'
        },
        {
          key: 'category_name'
        },
        {
          key: 'investor_intra_tax_cat_name',
          label: 'Investor Intra Tax Cat'
        },
        {
          key: 'investor_inter_tax_cat_name',
          label: 'Investor Inter Tax Cat'
        },
        {
          key: 'period_from'
        },
        {
          key: 'period_to'
        }
      ]
    };
  },
  mounted() {
    this.eventBus.$on('tax', leaseMasterAgreementHdrId => {
      if (leaseMasterAgreementHdrId) {
        this.getDetails(leaseMasterAgreementHdrId);
      } else {
        this.setDefaultValueForTax();
      }
    });
  },
  methods: {
    setDefaultValueForTax() {
      this.gridData[0].tax_group_id = 1;
      this.gridData[0].tax_group = 'GST GROUP';
      this.gridData[0].tax_cat_id = 4;
      this.gridData[0].category_name = 'LEASE CGST 9% + SGST 9%';
      this.gridData[0].investor_intra_tax_cat = 4;
      this.gridData[0].investor_intra_tax_cat_name = 'LEASE CGST 9% + SGST 9%';
      this.gridData[0].investor_inter_tax_cat = 5;
      this.gridData[0].investor_inter_tax_cat_name = 'LEASE IGST 18%';
    },
    setDefaultTaxGroup() {
      const taxGroup = commonHelper.getValuesetData(
        appStrings.VALUESETTYPE.TAX_GROUP_NAME_VSET,
        'GST'
      );
      taxGroup.then(key => {
        this.gridData[0].tax_group = key[0].value_code;
        this.gridData[0].tax_group_id = key[0].value_set_dtl_id;
      });
    },
    setDefaultTaxCategory() {
      const taxCategory = commonHelper.getValuesetData(
        appStrings.VALUESETTYPE.TAX_CATEGORY_NAME_VSET,
        'LEASE'
      );
      taxCategory.then(key => {
        this.gridData[0].tax_group = key[0].value_code;
        this.gridData[0].tax_group_id = key[0].value_set_dtl_id;
      });
    },
    getDetails(leaseMasterAgreementHdrId) {
      const payload = {
        tabName: 'tax',
        leaseMasterAgreementHdrId: leaseMasterAgreementHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('leases/getLeaseMasterAgreementDataById', payload)
        .then(resp => {
          this.loader = false;
          if (
            resp.status === 200 &&
            resp.data.data.length &&
            this.gridData[0].lease_master_agreement_tax_id === 0
          ) {
            this.gridData = resp.data.data;
            // this.gridData = resp.data.data.map(data => {
            //   data.period_from_to = [
            //     new Date(data.period_from),
            //     new Date(data.period_to)
            //   ];
            //   return data;
            // });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addNewRow(index) {
      if (this.editMode) {
        this.gridData.push({
          category_name: this.gridData[index].category_name,
          laese_master_agreement_hdr_id: null,
          lease_master_agreement_tax_id: 0,
          period_from: null,
          period_to: null,
          period_from_to: [],
          tax_cat_id: this.gridData[index].tax_cat_id,
          tax_group: this.gridData[index].tax_group,
          tax_group_id: this.gridData[index].tax_group_id,
          investor_intra_tax_cat_name: this.gridData[index]
            .investor_intra_tax_cat_name,
          investor_intra_tax_cat: this.gridData[index].investor_intra_tax_cat,
          investor_inter_tax_cat_name: this.gridData[index]
            .investor_inter_tax_cat_name,
          investor_inter_tax_cat: this.gridData[index].investor_inter_tax_cat,
          active: true
        });
      }
    },
    removeRow(index) {
      if (this.editMode) {
        this.gridData.splice(index, 1);
      }
    },
    opendDependentUom(vsetCode, index) {
      const payload = {
        valueSetName: vsetCode,
        dependentVariable: {
          user_id: this.$store.state.auth.userId,
          res_id: this.$store.state.shared.responsibilityId
        }
      };
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          this.loader = false;
          this.uomClassObj = null;
          if (response.status === 200) {
            const findTimeObj = response.data.data.page.find(
              vsetData => vsetData.value_code === 'TIME'
            );
            if (findTimeObj) {
              this.uomClassObj = findTimeObj;
              this.openValueSetModal('UOM', index);
            }
          }
        });
    },
    selectBoxChecked(flag, index) {
      if (flag) {
        const findActivatedRecords = this.gridData.filter(data => {
          return (
            data.active &&
            this.gridData[index].tax_group_id == data.tax_group_id
          );
        });
        if (findActivatedRecords.length > 1) {
          alert('Two groups can not be active');
          this.gridData[index].active = false;
        }
      }
    },
    validateDoubleGroup(item) {
      const findActivatedRecords = this.gridData.find(data => {
        return (
          data.active === this.gridData[this.specIndex].active &&
          item.tax_group_id == data.tax_group_id
        );
      });
      return findActivatedRecords ? true : false;
    },
    selectedvalueSet(item) {
      if (
        this.validateDoubleGroup(item) &&
        this.vsetCode === 'TAX_GROUP_NAME_VSET'
      ) {
        alert('You are not allowed to add two active group');
        return;
      }
      if (this.vsetCode === 'TAX_GROUP_NAME_VSET') {
        this.gridData[this.specIndex].tax_group_id = item.tax_group_id;
        this.gridData[this.specIndex].tax_group = item.tax_group;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.TAX_CATEGORY_NAME_VSET
      ) {
        if (this.taxNature === 'investor_intra') {
          this.gridData[this.specIndex].investor_intra_tax_cat_name =
            item.category_name;
          this.gridData[this.specIndex].investor_intra_tax_cat =
            item.tax_cat_hdr_id;
        } else if (this.taxNature === 'investor_inter') {
          this.gridData[this.specIndex].investor_inter_tax_cat_name =
            item.category_name;
          this.gridData[this.specIndex].investor_inter_tax_cat =
            item.tax_cat_hdr_id;
        } else {
          this.gridData[this.specIndex].category_name = item.category_name;
          this.gridData[this.specIndex].tax_cat_id = item.tax_cat_hdr_id;
        }
      }
      this.showValueSetModal = false;
    },
    openValueSetModal(vsetCode, index, taxNature) {
      this.vsetCode = vsetCode;
      this.specIndex = index;
      this.taxNature = taxNature;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.gridData[this.specIndex].tax_group_id) {
        this.parent_value_set_id = this.gridData[this.specIndex].tax_group_id;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    setDate(dateRange, index) {
      this.gridData[index].period_from_to = commonHelper.setFutureDate(
        dateRange
      );
    },
    startDateIndex(index) {
      this.specIndex = index;
      this.gridData[index].period_to = null;
    },
    endDateIndex(index) {
      this.specIndex = index;
    },
    disabledDates(date) {
      return commonHelper.disabledDates(
        date,
        this.gridData[this.specIndex].period_from
      );
    }
  },
  beforeDestroy() {
    this.eventBus.$off('tax');
  }
};
