import DatePicker from 'vue2-datepicker';
export default {
  name: 'SecurityDeposit',
  components: {
    DatePicker
  },
  props: ['editMode', 'specCompRef', 'masterAgreementApprovalStatus'],
  data() {
    return {
      vsetCode: null,
      uomClassObj: null,
      specIndex: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      areaType: null,
      setTimeVsetCode: null,
      gridData: [
        {
          lease_master_agreement_hdr_id: null,
          lease_master_agreement_sec_dpt_id: 0,
          lease_security_deposit: null,
          lease_security_deposit_uom_vset: null,
          lease_security_deposit_uom_vset_meaning: null,
          period: null,
          period_from: null,
          period_to: null,
          active: true,
          security_deposit_type_meaning: null,
          security_deposit_type_code: null
        }
      ],
      gridFields: [
        {
          key: 'active',
          stickyColumn: true
        },
        {
          key: 'security_deposit_type_meaning',
          label: 'Deposit Type'
        },
        {
          key: 'lease_security_deposit_uom_vset_meaning',
          label: 'UOM'
        },
        {
          key: 'period'
        },
        {
          key: 'lease_security_deposit',
          label: 'Amount'
        }
      ]
    };
  },
  mounted() {
    this.eventBus.$on('sd', leaseMasterAgreementHdrId => {
      if (leaseMasterAgreementHdrId) {
        this.getDetails(leaseMasterAgreementHdrId);
      }
    });
    this.setSdAmount();
  },
  methods: {
    getDetails(leaseMasterAgreementHdrId) {
      const payload = {
        tabName: 'security-deposit',
        leaseMasterAgreementHdrId: leaseMasterAgreementHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('leases/getLeaseMasterAgreementDataById', payload)
        .then(resp => {
          this.loader = false;
          if (
            resp.status === 200 &&
            resp.data.data.length &&
            this.gridData[0].lease_master_agreement_sec_dpt_id === 0
          ) {
            this.gridData = resp.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    setSdAmount() {},
    addNewRow() {
      this.gridData.push({
        lease_master_agreement_hdr_id: null,
        lease_master_agreement_sec_dpt_id: 0,
        lease_security_deposit: null,
        lease_security_deposit_uom_vset: null,
        lease_security_deposit_uom_vset_meaning: null,
        period: null,
        period_from: null,
        period_to: null,
        active: true,
        security_deposit_type_meaning: null,
        security_deposit_type_code: null
      });
    },
    removeRow(index) {
      this.gridData.splice(index, 1);
    },
    opendDependentUom(vsetCode, index) {
      const payload = {
        valueSetName: vsetCode,
        dependentVariable: {
          user_id: this.$store.state.auth.userId,
          res_id: this.$store.state.shared.responsibilityId
        }
      };
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          this.loader = false;
          this.uomClassObj = null;
          if (response.status === 200) {
            const findTimeObj = response.data.data.page.find(
              vsetData => vsetData.value_code === 'TIME'
            );
            if (findTimeObj) {
              this.uomClassObj = findTimeObj;
              this.openValueSetModal('UOM', index);
            }
          }
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'UOM') {
        this.gridData[this.specIndex].lease_security_deposit_uom_vset =
          item.value_code;
        this.gridData[this.specIndex].lease_security_deposit_uom_vset_meaning =
          item.value_meaning;
      } else if (this.vsetCode === 'SECURITY_DEPOSIT_TYPE') {
        this.gridData[this.specIndex].security_deposit_type_code =
          item.value_code;
        this.gridData[this.specIndex].security_deposit_type_meaning =
          item.value_meaning;
      }
      this.showValueSetModal = false;
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.specIndex = index;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.uomClassObj && vsetCode !== 'SECURITY_DEPOSIT_TYPE') {
        this.parent_value_set_id = this.uomClassObj.value_set_dtl_id;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    calcAmount(period, index) {
      this.gridData[index].lease_security_deposit =
        period *
        this.specCompRef[index].rate *
        this.specCompRef[index].lease_super_area;
    },
    checkUncheckRadio(specIndex) {
      this.gridData.forEach((element, index) => {
        return (element.active = index === specIndex ? true : false);
      });
    },
    clearVsetValues(vsetCode, index) {
      if (vsetCode === 'UOM_CLASS') {
        this.gridData[index].lease_security_deposit_uom_vset = null;
        this.gridData[index].lease_security_deposit_uom_vset_meaning = null;
      } else if (vsetCode === 'SECURITY_DEPOSIT_TYPE') {
        this.gridData[index].security_deposit_type_code = null;
        this.gridData[index].security_deposit_type_meaning = null;
      }
    }
  },
  beforeDestroy() {
    this.eventBus.$off('sd');
  }
};
