import appStrings from '@/app/utility/string.utility';
export default {
  name: 'Specification',
  props: {
    parentProjectUnitData: Object,
    leasePrjUnitId: String
  },
  data() {
    return {
      unsubscribe: null,
      showModalComponent: false,
      showValueSetModal: false,
      parent_value_set_id: null,
      editMode: false,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      unitId: null,
      widthType: null,
      carParkOpenExc: null,
      carParkOpenNonExc: null,
      carParkCoverExc: null,
      carParkCoverNonExc: null,
      carParkComments: null,
      unitCategory: {
        value: null,
        text: null,
        id: null
      },
      unitSubcategory: {
        value: null,
        text: null
      }
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode == true) {
          this.addEditProjectUnitSpec();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
    if (this.$route.params.summaryData) {
      this.urlSummaryData = JSON.parse(
        Buffer.from(this.$route.params.summaryData, 'base64').toString('ascii')
      );
      this.unitId = this.urlSummaryData.lease_prj_unit_id;
    }  else if (this.parentProjectUnitData) {
      this.unitId = this.parentProjectUnitData.lease_prj_unit_id;
    } else {
      this.unitId = this.leasePrjUnitId;
    }
    this.getProjectUnitSpec();
  },
  methods: {
    getProjectUnitSpec() {
      const payload = {
        prjUnitId: this.unitId
      };
      this.loader = true;
      this.$store
        .dispatch('leaseUnit/getLeaseUnitSpecification', payload)
        .then(response => {
          this.loader = false;
          const results = response.data.data;
          this.unitId = results.lease_prj_unit_id;
          this.projectUnitSpecId = results.lease_unit_spec_id;
          this.carParkOpenExc = results.car_park_open_exc;
          this.carParkOpenNonExc = results.car_park_open_nonexc;
          this.carParkCoverExc = results.car_park_cover_exc;
          this.unitCategory = {
            value: results.unit_cat_vset,
            text: results.unit_cat_meaning
          };
          this.carParkCoverNonExc = results.car_park_cover_nonexc;
          this.carParkComments = results.car_parking_comments;
          this.unitSubcategory = {
            value: results.unit_sub_cat_vset,
            text: results.unit_sub_cat_meaning
          };
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditProjectUnitSpec() {
      const payload = {
        car_park_open_exc: this.carParkOpenExc,
        unit_sub_cat_vset: this.unitSubcategory.value,
        car_park_open_non_exc: this.carParkOpenNonExc,
        unit_cat_vset: this.unitCategory.value,
        lease_prj_unit_id: this.unitId,
        car_park_cover_non_exc: this.carParkCoverNonExc,
        car_park_cover_exc: this.carParkCoverExc,
        car_parking_comments: this.carParkComments,
        lease_unit_spec_id: this.projectUnitSpecId
      };
      this.loader = true;
      this.$store
        .dispatch('leaseUnit/addEditLeaseUnitSpecification', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            const results = response.data.data;
            this.projectUnitSpecId = results.lease_unit_spec_id;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'UNIT_CAT') {
        this.unitCategory = {
          value: item.value_code,
          text: item.value_meaning,
          id: item.value_set_dtl_id
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.UNIT_SUB_CAT) {
        this.unitSubcategory = {
          value: item.value_code,
          text: item.value_meaning
        };
      }
    },
    openValueSetModal(vsetCode, widthType) {
      this.vsetCode = vsetCode;
      if (this.vsetCode === appStrings.VALUESETTYPE.UNIT_SUB_CAT) {
        this.parent_value_set_id = this.unitCategory.id;
      } else {
        this.parent_value_set_id = null;
      }
      this.widthType = widthType;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
