import DatePicker from 'vue2-datepicker';
import party from '@/app/components/generalLedger/party';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'rentalDetails',
  components: {
    DatePicker,
    party
  },
  props: ['editMode', 'tenantLeaseApprovalStatus'],
  data() {
    return {
      vsetCode: null,
      uomClassObj: null,
      specIndex: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      areaType: null,
      setTimeVsetCode: null,
      gridIndex: null,
      gridData: [
        {
          customer_site: null,
          renatal_status: null,
          rental_status_meaning: null,
          lease_tenant_agreement_hdr_id: null,
          sac: null,
          revenue_account: null,
          customer_name: null,
          active: true,
          lease_rental_end_date: null,
          lease_customer_id: null,
          lease_customer_site_id: null,
          lease_rental_payment_term_id: null,
          lease_rental_start_date: null,
          payment_term_name: null,
          request_num: null,
          lease_tenant_agreement_rental_id: 0,
          bill_rate_uom_vset: null,
          bill_rate_uom_uset_meaning: null,
          lease_tenant_agreement_excalation_id: null
        }
      ],
      gridFields: [
        {
          key: 'customer_name'
        },
        {
          key: 'customer_site'
        },
        {
          key: 'sac',
          label: 'SAC Code'
        },
        {
          key: 'revenue_account_meaning',
          label: 'Revenue Account'
        },
        {
          key: 'bill_rate'
        },
        {
          key: 'period_from_to',
          label: 'Period (From - To)'
        },
        // {
        //   key: 'lease_rental_start_date',
        //   label: 'Lease Start From'
        // },
        // {
        //   key: 'lease_rental_end_date',
        //   label: 'Lease End From'
        // },
        {
          key: 'payment_term_name'
        },
        {
          key: 'request_num'
        },
        {
          key: 'rental_status_meaning',
          label: 'Rental Status'
        }
      ],
      showCustomerSiteModal: false,
      gridDataSite: [],
      gridFieldsSite: [
        {
          key: 'site_name'
        },
        {
          key: 'site_type'
        },
        {
          key: 'bill_to_address',
          label: 'Site Address'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'gstn',
          label: 'GSTN'
        }
      ],
      showPartyModal: false
    };
  },
  mounted() {
    this.eventBus.$on('rd', tabData => {
      if (tabData.leaseTenantHdrId) {
        this.getDetails(tabData.leaseTenantHdrId);
      }
    });
  },
  methods: {
    getDetails(leaseTenantHdrId) {
      const payload = {
        tabName: 'rental-details',
        leaseTenantHdrId: leaseTenantHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('leases/getLeaseTenantDataById', payload)
        .then(resp => {
          this.loader = false;
          if (
            resp.status === 200 &&
            resp.data.data.length &&
            this.gridData[0].lease_tenant_agreement_rental_id === 0
          ) {
            this.gridData = resp.data.data.map(data => {
              data.period_from_to = [
                new Date(data.lease_rental_start_date),
                new Date(data.lease_rental_end_date)
              ];
              return data;
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addNewRow() {
      this.gridData.push({
        customer_site: null,
        renatal_status: null,
        rental_status_meaning: null,
        lease_tenant_agreement_hdr_id: null,
        sac: null,
        customer_name: null,
        active: true,
        lease_rental_end_date: null,
        lease_customer_id: null,
        lease_customer_site_id: null,
        lease_rental_payment_term_id: null,
        lease_rental_start_date: null,
        payment_term_name: null,
        request_num: null,
        revenue_account: null,
        lease_tenant_agreement_rental_id: 0
      });
    },
    removeRow(index) {
      this.gridData.splice(index, 1);
    },
    showHideCustomerSiteModal(flag) {
      this.showCustomerSiteModal = flag;
    },
    getCustomerSiteByPartyId(index) {
      this.gridIndex = index;
      const payload = {
        customerId: this.gridData[index].lease_customer_id,
        lease: {
          site_type_vset: appStrings.siteTypeVset
        }
      };
      this.$store
        .dispatch('party/getCustomerSiteById', payload)
        .then(response => {
          this.gridDataSite = response.data.data.customer_site_lists;
          this.showCustomerSiteModal = true;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedSite(item) {
      this.gridData[this.gridIndex].customer_site = item.site_name;
      this.gridData[this.gridIndex].lease_customer_site_id =
        item.customer_site_id;
      this.showCustomerSiteModal = false;
    },
    showHidePartyModal(flag, index) {
      this.showPartyModal = flag;
      this.gridIndex = index;
    },
    getSelectedPartyCustomer(item) {
      this.gridData[this.gridIndex].customer_name = item.party_name;
      this.showPartyModal = false;
      this.getCustomerByPartyId(item.party_id);
    },
    getCustomerByPartyId(partyId) {
      this.$store
        .dispatch('leases/getCustomerByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            this.gridData[this.gridIndex].lease_customer_id =
              response.data.data.customer_id;
          }
        });
    },
    opendDependentUom(vsetCode, index) {
      const payload = {
        valueSetName: vsetCode,
        dependentVariable: {
          user_id: this.$store.state.auth.userId,
          res_id: this.$store.state.shared.responsibilityId
        }
      };
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          this.loader = false;
          this.uomClassObj = null;
          if (response.status === 200) {
            const findTimeObj = response.data.data.page.find(
              vsetData => vsetData.value_code === 'TIME'
            );
            if (findTimeObj) {
              this.uomClassObj = findTimeObj;
              this.openValueSetModal('UOM', index);
            }
          }
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'UOM') {
        this.gridData[this.specIndex].lease_rental_period_uom_vset =
          item.value_code;
        this.gridData[this.specIndex].lease_rental_period_uom_vset_meaning =
          item.value_meaning;
      } else if (this.vsetCode === 'FMS_PAYMENT_TERM') {
        this.gridData[this.specIndex].lease_rental_payment_term_id =
          item.payment_term_id;
        this.gridData[this.specIndex].payment_term_name =
          item.payment_term_name;
      }
      this.showValueSetModal = false;
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.specIndex = index;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.uomClassObj && vsetCode !== 'FMS_PAYMENT_TERM') {
        this.parent_value_set_id = this.uomClassObj.value_set_dtl_id;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    setDate(dateRange, index) {
      this.gridData[index].period_from_to = commonHelper.setFutureDate(
        dateRange
      );
    },
    clearVsetValues(vsetCode, index) {
      if (vsetCode === 'FMS_PAYMENT_TERM') {
        this.gridData[index].payment_term_name = null;
      }
    }
  },
  beforeDestroy() {
    this.eventBus.$off('rd');
  }
};
