import { BasicSelect, ModelSelect } from 'vue-search-select';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import AddLeaseProject from './addLeaseProject';
export default {
  name: 'LeaseProject',
  watch: {
    currentPage: function() {
      this.getLeaseProjectsList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getLeaseProjectsList();
    }
  },
  components: {
    BasicSelect,
    ModelSelect,
    AddLeaseProject
  },
  props: ['showProjectModal'],
  data() {
    return {
      unsubscribe: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      loader: false,
      showLeasePrjDetailModal: false,
      showValueSetModal: false,
      vsetCode: null,
      selectedLe: {
        text: null,
        value: null
      },
      selectedOu: {
        text: null,
        value: null
      },
      inventoryOrg: {
        text: null,
        value: null
      },
      selectedLoc: {
        text: null,
        value: null
      },
      selectedLeasePrjType: {
        text: null,
        value: null
      },
      leasePrjTypeList: [],
      leasePrjCode: '',
      leasePrjName: '',
      active: true,
      leasePrjRowDetail: null,
      parent_value_set_id: null,
      leasePrjData: [],
      leaseProjectFields: [
        {
          key: 'lease_project_id',
          class: 'd-none'
        },
        {
          key: 'lease_project_code',
          label: 'Project Code'
        },
        {
          key: 'lease_project_name',
          label: 'Project Name'
        },
        {
          key: 'le_id',
          class: 'd-none'
        },
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'ou_id',
          class: 'd-none'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'inv_org_id',
          class: 'd-none'
        },
        {
          key: 'inv_org_name'
        },
        {
          key: 'location_id',
          class: 'd-none'
        },
        {
          key: 'location_name'
        },
        {
          key: 'address_id',
          class: 'd-none'
        },
        {
          key: 'address_name'
        },
        {
          key: 'master_prj_id',
          class: 'd-none'
        },
        {
          key: 'master_prj_name',
          label: 'Master Project Name'
        },

        {
          key: 'sector_id',
          class: 'd-none'
        },
        {
          key: 'sector_name'
        },
        {
          key: 'pocket_id',
          class: 'd-none'
        },
        {
          key: 'pocket_name'
        },
        {
          key: 'liability_ccid_code'
        },
        {
          key: 'debtor_code'
        },
        {
          key: 'lease_project_type_vset',
          class: 'd-none'
        },
        {
          key: 'lease_project_type'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        },
        {
          key: 'active'
        },
        {
          key: 'version'
        }
      ]
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showHideLeaseProjectDetailModal(true);
          this.leasePrjRowDetail = null;
        }
        if (actionName === 'download' && !this.showLeasePrjDetailModal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'leaseProject/getLeaseProjectsList',
            'lease-project',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getLeaseProjectsList() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        lease_project_name: this.leasePrjName,
        lease_project_code: this.leasePrjCode,
        le_id: this.selectedLe.value,
        ou_id: this.selectedOu.value,
        location_id: this.selectedLoc.value,
        lease_project_type: this.selectedLeasePrjType.value,
        active: this.active
      };
      this.loader = true;
      this.$store
        .dispatch('leaseProject/getLeaseProjectsList', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.leasePrjData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected(item) {
      this.leasePrjRowDetail = item;
      if (this.showProjectModal) {
        this.$emit('getProject', item);
      } else {
        this.showHideLeaseProjectDetailModal(true);
      }
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'PRJ_TYPE') {
        this.selectedLeasePrjType = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.selectedLe = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.selectedOu = {
          value: null,
          text: null
        };
        this.inventoryOrg = {
          value: null,
          text: null
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.selectedOu = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.INVENTORY_ORG_LIST) {
        this.inventoryOrg = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LOCATION) {
        this.selectedLoc = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      }
    },
    openValueSetModal(vsetCode) {
      if (
        vsetCode === 'PRJ_TYPE' ||
        vsetCode === appStrings.VALUESETTYPE.LOCATION
      ) {
        this.parent_value_set_id = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.selectedLe.value;
      } else if (vsetCode === appStrings.VALUESETTYPE.INVENTORY_ORG_LIST) {
        this.parent_value_set_id = this.selectedOu.value;
      }
      // else if (vsetCode === appStrings.VALUESETTYPE.LOCATION) {
      //   this.parent_value_set_id = this.inventoryOrg.value;
      // }
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearSearchFilters() {
      this.selectedLe = {
        text: null,
        value: null
      };
      this.selectedOu = {
        text: null,
        value: null
      };
      this.selectedLoc = {
        text: null,
        value: null
      };
      this.inventoryOrg = {
        text: null,
        value: null
      };
      this.selectedLeasePrjType = {
        text: null,
        value: null
      };
      this.leasePrjCode = '';
      this.leasePrjName = '';
      this.active = true;
      this.leasePrjData = [];
      this.totalRows = null;
      this.currentPage = 1;
    },
    showHideLeaseProjectDetailModal(flag) {
      this.showLeasePrjDetailModal = flag;
    },
    clearVsetValues(vsetCode) {
      switch (vsetCode) {
        case 'LEGAL_ENTITY_LIST':
          this.selectedLe = {
            text: null,
            value: null
          };
          break;
        case 'OU_LIST':
          this.selectedOu = {
            text: null,
            value: null
          };
          break;
        case 'INVENTORY ORG LIST':
          this.inventoryOrg = {
            text: null,
            value: null
          };
          break;
        case 'LOCATION':
          this.selectedLoc = {
            text: null,
            value: null
          };
          break;
        case 'PRJ_TYPE':
          this.selectedLeasePrjType = {
            text: null,
            value: null
          };
          break;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
