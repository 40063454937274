import DatePicker from 'vue2-datepicker';
import party from '@/app/components/generalLedger/party';
import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
import codeCombination from '@/app/components/shared/codeCombination';
export default {
  name: 'escalation',
  components: {
    DatePicker,
    party,
    codeCombination
  },
  watch: {
    custName() {
      this.gridData.map(key => {
        key.lease_customer_site_name = null;
        key.lease_customer_id = null;
        key.lease_customer_name = this.custName;
        key.lease_customer_site_id = null;
        return key;
      });
    }
  },
  props: ['editMode', 'tenantLeaseApprovalStatus', 'selectedLeaseDetails'],
  data() {
    return {
      revenue_account: null,
      revenue_account_ccid: null,
      revenue_account_meaning: null,
      setCustCode: null,
      vsetCode: null,
      uomClassObj: null,
      specIndex: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      areaType: null,
      concatedIndex: null,
      setTimeVsetCode: null,
      customerName: null,
      escalationCustomerId: null,
      customerSiteName: null,
      customerSiteId: null,
      le_name: null,
      le_id: null,
      lease_prj_name: null,
      lease_prj_id: null,
      gridData: [
        {
          lease_customer_site_name: null, // newly added
          lease_investor_agreement_hdr_id: null,
          lease_customer_id: null, // newly added
          lease_customer_name: null, // newly added
          lease_customer_site_id: null, // newly added
          sac: null, //newly added
          lease_escaltion_num: 0,
          active: false,
          period: null,
          revenue_account: null,
          revenue_account_ccid: null,
          revenue_account_meaning: null,
          lease_tenant_agreement_hdr_id: null,
          lease_tenant_agreement_escalation_id: 0,
          lease_escalation_uom_vset_meaning: null,
          period_from: null,
          period_to: null,
          lease_escalation: null,
          lease_escalation_uom_vset: null,
          request_num: null,
          create_rental: false,
          create_investor: false,
          escalation_type_vset: null,
          escalation_type_vset_meaning: null,
          // exp_ac_ccid: null,
          exp_ac_ccid_dtl: null,
          exp_ac_ccid_dtl_meaning: null,
          charge_account: null
        }
      ],
      showConcatSegmentModal: false,
      segmentFields: [
        {
          key: 'segment_meaning'
        },
        {
          key: 'segment_code'
        }
      ],
      segmentData: [],
      gridFields: [
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        },
        {
          key: 'create_rental'
        },
        {
          key: 'exp_ac_ccid_dtl'
        },
        {
          key: 'lease_customer_name',
          label: 'Customer Name'
        },
        {
          key: 'lease_customer_site_name',
          label: 'Customer Site'
        },
        {
          key: 'sac',
          label: 'SAC'
        },
        {
          key: 'lease_escaltion_num'
        },
        {
          key: 'lease_escalation_uom_vset_meaning',
          label: 'UOM'
        },
        {
          key: 'period'
        },
        {
          key: 'escalation_type_vset_meaning',
          label: 'Escalation Type'
        },
        {
          key: 'lease_escalation',
          label: 'Escalation'
        },
        {
          key: 'revenue_account_meaning',
          label: 'Revenue Account'
        },
        {
          key: 'escalation_status_meaning',
          label: 'Escalation Status'
        },
        // {
        //   key: 'period_from_to',
        //   label: 'Period (From - To)'
        // },
        {
          key: 'period_from'
        },
        {
          key: 'period_to'
        },
        {
          key: 'request_num'
        },
        {
          key: 'error_msg'
        }
      ],
      gridIndex: null,
      showCustomerSiteModal: false,
      showPartyModal: false,
      gridDataSite: [],
      gridFieldsSite: [
        {
          key: 'site_name'
        },
        {
          key: 'site_type'
        },
        {
          key: 'bill_to_address',
          label: 'Site Address'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'gstn',
          label: 'GSTN'
        }
      ],
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: [],
      periodTo: null,
      StartDate: null,
      EndDate: null,
      showCodeCombinationModal: false,
      accountType: null,
      combinationDetails: {
        le_id: null,
        project_code: null
      },
      ind:null
    };
  },
  mounted() {
    this.setDefaultRevenueAccount();
    this.eventBus.$on('periodFromTo', data => {
      if (this.gridData[0].escalation_status_meaning !== 'PROCESSED') {
        // this.gridData[0].period_from = commonHelper.formattedDate(
        //   data.periodFrom
        // );
        this.StartDate = commonHelper.formattedDate(data.rentPeriodFrom);
        this.EndDate = commonHelper.formattedDate(data.periodTo);
        this.gridData[0].period_from = commonHelper.formattedDate(
          data.rentPeriodFrom
        );
        this.gridData[0].period_to = commonHelper.formattedDate(data.periodTo);
      }
      this.EndDate = commonHelper.formattedDate(data.periodTo);
      this.gridData = JSON.parse(JSON.stringify(this.gridData));
    });
    this.eventBus.$on('rentPeriodFrom', data => {
      if (this.gridData[0].escalation_status_meaning !== 'PROCESSED') {
        this.gridData[0].period_from = commonHelper.formattedDate(data);
      }
    });
    this.eventBus.$on('escalation', tabData => {
      this.EndDate = tabData.leaseForm.to_date;
      this.le_id = tabData.leaseForm.le_id;
      if (tabData.leaseTenantHdrId) {
        setTimeout(() => {
          this.getDetails(tabData.leaseTenantHdrId);
        }, 1000);
      }
      if (tabData.leaseMasterAgreementHdrId && !tabData.leaseTenantHdrId) {
        this.getMasterData(tabData.leaseMasterAgreementHdrId);
      }
    });
    this.eventBus.$on('tenantHdrCustSiteData', leaseForm => {
      this.gridData = this.gridData.map(key => {
        key.lease_customer_id = leaseForm.customer_id;
        key.lease_customer_name = leaseForm.customer_name;
        key.lease_customer_site_id = leaseForm.customer_site_id;
        key.lease_customer_site_name = leaseForm.customer_site_name;
        return key;
      });
      this.le_id = leaseForm.le_id;
      this.le_name = leaseForm.le_name;
      this.lease_prj_id = leaseForm.lease_prj_id;
      this.lease_prj_name = leaseForm.lease_prj_name;
    });
  },
  methods: {
    getDetails(leaseTenantHdrId) {
      const payload = {
        tabName: 'escalation',
        leaseTenantHdrId: leaseTenantHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('leases/getLeaseTenantDataById', payload)
        .then(resp => {
          this.loader = false;
          if (
            resp.status === 200 &&
            resp.data.data.length > 0
            // &&`
            // this.gridData[0].lease_tenant_agreement_escalation_id === 0`
          ) {
            this.gridData = resp.data.data.map(data => {
              data.period_from_to = [
                new Date(data.period_from),
                new Date(data.period_to)
              ];
              return data;
            });
            const createRenetalIndex = resp.data.data.findIndex(
              data =>
                data.escalation_status === 'U' || data.escalation_status === 'E'
            );
            if (createRenetalIndex !== -1)
              this.gridData[createRenetalIndex].create_rental = true;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getMasterData(leaseMasterAgreementHdrId) {
      // this.setDefaultRevenueAccount();
      const payload = {
        tabName: 'escalation',
        leaseMasterAgreementHdrId: leaseMasterAgreementHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('leases/getLeaseMasterAgreementDataById', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200 && resp.data.data.length > 0) {
            // const filterData = resp.data.data.filter(
            //   item => item.active === true
            // );
            this.gridData = resp.data.data.map((item, index) => {
              return {
                lease_escaltion_num: index,
                lease_customer_site_name: item.lease_customer_site_name, // newly added
                lease_investor_agreement_hdr_id:
                  item.lease_investor_agreement_hdr_id,
                lease_customer_id: item.lease_customer_id, // newly added
                lease_customer_name: item.lease_customer_name, // newly added
                lease_customer_site_id: item.lease_customer_site_id, // newly added
                sac: 997212, //newly added
                period: item.period,
                period_from: item.period_from,
                period_to: item.period_to,
                revenue_account: this.revenue_account,
                revenue_account_ccid: this.revenue_account_ccid,
                revenue_account_meaning: this.revenue_account_meaning,
                active: item.active,
                escalation_type_vset: item.escalation_type_vset,
                escalation_type_vset_meaning: item.escalation_type_vset_meaning,
                lease_tenant_agreement_hdr_id: null,
                lease_tenant_agreement_escalation_id: 0,
                lease_escalation_uom_vset_meaning:
                item.lease_escalation_uom_vset_meaning,
                lease_escalation: item.lease_escalation,
                lease_escalation_uom_vset: item.lease_escalation_uom_vset,
                request_num: null,
                create_rental: false,
                create_investor: false,
                period_from_to: [
                  new Date(item.period_from),
                  new Date(item.period_to)
                ]
              };
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    periodFromIndex(index) {
      this.specIndex = index;
    },
    periodToIndex(index) {
      this.specIndex = index;
    },
    disabledDates(date) {
      const restrictedDate = new Date(
        this.gridData[this.specIndex].period_from
      );
      restrictedDate.setHours(0, 0, 0, 0);
      return date < restrictedDate;
    },
    findNexDate(date) {
      let nextFromDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() + 1
      );
      return nextFromDate;
    },
    addNewRow(index) {
      //to add new row
      if (this.editMode) {
        if (
          new Date(this.EndDate) <= new Date(this.gridData[index].period_to)
        ) {
          alert('Lease Period Exhaust');
        } else {
          this.gridData.push({
            active: false,
            lease_tenant_agreement_hdr_id: null,
            lease_tenant_agreement_escalation_id: 0,
            lease_escalation_uom_vset_meaning: null,
            period_from: commonHelper.formattedDate(
              this.findNexDate(new Date(this.gridData[index].period_to))
            ),
            period_to: this.EndDate,
            lease_escalation: null,
            lease_escalation_uom_vset: null,
            request_num: null,
            lease_customer_id: this.gridData[index].lease_customer_id,
            lease_customer_name: this.gridData[index].lease_customer_name,
            lease_customer_site_id: this.gridData[index].lease_customer_site_id,
            lease_customer_site_name: this.gridData[index]
              .lease_customer_site_name,
            sac: this.gridData[index].sac,
            revenue_account: this.gridData[index].revenue_account,
            revenue_account_ccid: this.gridData[index].revenue_account_ccid,
            revenue_account_meaning: this.gridData[index]
              .revenue_account_meaning,
            period: null,
            create_rental: false,
            create_investor: false,
            escalation_type_vset: null,
            escalation_type_vset_meaning: null,
            lease_escaltion_num: index + 1,
            // exp_ac_ccid: this.gridData[index].exp_ac_ccid,
            exp_ac_ccid_dtl: this.gridData[index].exp_ac_ccid_dtl,
            exp_ac_ccid_dtl_meaning: this.gridData[index]
              .exp_ac_ccid_dtl_meaning,
            charge_account: this.gridData[index].charge_account
          });
        }
      }
    },
    removeRow(index) {
      // to delete row
      if (this.editMode) {
        this.gridData.splice(index, 1);
      }
    },
    opendDependentUom(vsetCode, index) {
      const payload = {
        valueSetName: vsetCode,
        dependentVariable: {
          user_id: this.$store.state.auth.userId,
          res_id: this.$store.state.shared.responsibilityId
        }
      };
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          this.loader = false;
          this.uomClassObj = null;
          if (response.status === 200) {
            const findTimeObj = response.data.data.page.find(
              vsetData => vsetData.value_code === 'TIME'
            );
            if (findTimeObj) {
              this.uomClassObj = findTimeObj;
              this.openValueSetModal('UOM', index);
            }
          }
        });
    },
    showHideCodeCombinationModal(flag, index) {
      this.segmentIndex = index;
      if (flag) {
        if (!this.selectedLeaseDetails.le_id) {
          return alert(appStrings.DEPENDENTVSETMSG.LEMSG);
        }
        if (!this.selectedLeaseDetails.lease_prj_code) {
          return alert(appStrings.DEPENDENTVSETMSG.FMSPRJMSG);
        }
        this.combinationDetails.le_id = this.selectedLeaseDetails.le_id;
        this.combinationDetails.project_code = this.selectedLeaseDetails.lease_prj_code;
      }
      this.showCodeCombinationModal = flag;
    },
    selectedvalueSet(item) {
      // select data of valuset
      if (this.vsetCode === 'UOM') {
        this.gridData[this.specIndex].lease_escalation_uom_vset =
          item.value_code;
        this.gridData[this.specIndex].lease_escalation_uom_vset_meaning =
          item.value_meaning;
        this.uomClassObj = null;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.ESCALATION_TYPE_VSET
      ) {
        this.gridData[this.specIndex].escalation_type_vset = item.value_code;
        this.gridData[this.specIndex].escalation_type_vset_meaning =
          item.value_meaning;
      } else if (this.vsetCode === this.revenueVset) {
        this.gridData[this.specIndex].revenue_account = item.value_code;
        this.gridData[this.specIndex].revenue_account_ccid =
          item.value_set_dtl_id;
        this.gridData[this.specIndex].revenue_account_meaning =
          item.value_meaning;
        this.gridData[this.specIndex].revenue_account_meaning =
          this.gridData[this.specIndex].revenue_account +
          ' - ' +
          this.gridData[this.specIndex].revenue_account_meaning;
      }
      this.showValueSetModal = false;
    },
    openValueSetModal(vsetCode, index) {
      // open valuset modal
      this.vsetCode = vsetCode;
      this.specIndex = index;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.uomClassObj) {
        this.parent_value_set_id = this.uomClassObj.value_set_dtl_id;
      }
      if (this.vsetCode === appStrings.VALUESETTYPE.ESCALATION_TYPE_VSET) {
        this.parent_value_set_id = null;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    showHideCustomerSiteModal(flag) {
      this.showCustomerSiteModal = flag;
    },
    getCustomerSiteByPartyId(index) {
      //open customer site modal
      this.gridIndex = index;
      const payload = {
        customerId: this.gridData[index].lease_customer_id,
        lease: {
          site_type_vset: appStrings.siteTypeVset
        }
      };
      this.$store
        .dispatch('party/getCustomerSiteById', payload)
        .then(response => {
          this.gridDataSite = response.data.data.customer_site_lists;
          this.showCustomerSiteModal = true;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedSite(item) {
      // select site data
      this.gridData[this.gridIndex].lease_customer_site_name = item.site_name;
      this.gridData[this.gridIndex].lease_customer_site_id =
        item.customer_site_id;
      this.showCustomerSiteModal = false;
    },
    showHidePartyModal(flag, index) {
      // open party modal
      this.showPartyModal = flag;
      this.gridIndex = index;
      this.setCustCode = setTimeout(() => {
        this.eventBus.$emit('userType', 'CUST');
      }, 0);
    },
    getSelectedPartyCustomer(item) {
      // select for customer
      this.gridData[this.gridIndex].lease_customer_name = item.party_name;
      this.showPartyModal = false;
      this.getCustomerByPartyId(item.party_id);
      this.gridData[this.gridIndex].lease_customer_site_name = null;
      this.gridData[this.gridIndex].lease_customer_site_id = null;
    },
    getCustomerByPartyId(partyId) {
      this.$store
        .dispatch('leases/getCustomerByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            this.gridData[this.gridIndex].lease_customer_id =
              response.data.data.customer_id;
          }
        });
    },
    getIndex(index){
        this.ind=index
    },
    disabledFromDates(date){
      if(this.ind>0){
        return commonHelper.disabledDates(date, this.gridData[this.ind-1].period_to);
      }
      
      },
    createRental(index) {
      const payload = {
        lease_schd_template: [
          {
            entity_id: this.gridData[index]
              .lease_tenant_agreement_escalation_id,
            entity_type: 'SRENTAL',
            lease_id: this.gridData[index].lease_tenant_agreement_hdr_id
          }
        ]
      };
      this.loader = true;
      this.$store
        .dispatch('leases/createRentaldetails', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 201) {
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.getDetails(this.gridData[index].lease_tenant_agreement_hdr_id);
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsgmessage;
        });
    },
    validateCheckBox(row) {
      // validation of checkbox
      if (row.request_num) {
        if (!/e/i.test(row.escalation_status)) {
          return true;
        } else {
          return false;
        }
      } else if (!row.sac) {
        return true;
      } else {
        return false;
      }
    },
    showHideConcatSegmentModal(flag, index) {
      //
      this.concatedIndex = index;
      if (!this.le_id) {
        return alert(appStrings.DEPENDENTVSETMSG.LEMSG);
      }
      if (!this.lease_prj_name) {
        return alert(appStrings.DEPENDENTVSETMSG.FMSPROJECTMSG);
      }
      if (flag) {
        this.getConcatSegmentList();
      } else {
        this.segmentData = [];
      }
      this.showConcatSegmentModal = flag;
    },
    getConcatSegmentList() {
      // for concat segment list
      const payload = {
        le_id: this.le_id,
        segment_code: '',
        segment_meaning: this.lease_prj_name
      };
      this.$store
        .dispatch('leaseRecovery/getFlexFieldAccounting', payload)
        .then(response => {
          if (response.status === 200) {
            this.segmentData = response.data.data;
          }
        });
    },
    selectedSegment(item) {
      this.gridData[this.segmentIndex].charge_account = item.ccid;
      // this.gridData[this.segmentIndex].exp_ac_ccid = item.ccid;
      this.gridData[this.segmentIndex].exp_ac_ccid_dtl = item.segment_code;
      this.gridData[this.segmentIndex].exp_ac_ccid_dtl_meaning =
        item.segment_meaning;
      this.gridData = JSON.parse(JSON.stringify(this.gridData));
      this.showCodeCombinationModal = false;
    },
    // selectedSegment(item) {
    //   // for select concat segment data
    //   this.gridData[this.concatedIndex].revenue_account_ccid = item.ccid;
    //   this.gridData[this.concatedIndex].revenue_account = item.segment_code;
    //   this.gridData[this.concatedIndex].revenue_account_meaning =
    //     item.segment_meaning;
    //   this.showHideConcatSegmentModal(false);
    // },
    getValueSetNameForRevenueAcc(index) {
      this.$store
        .dispatch('leases/getLeBasedNaturalAccountVset', this.le_id)
        .then(resp => {
          if (resp.status === 200) {
            this.revenueVset = resp.data.data;
            if (!this.revenueVset) {
              return alert(
                'Natural Account Setup is Pending for this Legal Entity'
              );
            }
            this.openValueSetModal(resp.data.data, index);
          }
        })
        .catch(err => {
          alert(err);
        });
    },
    setDate(dateRange, index) {
      this.gridData[index].period_from_to = commonHelper.setFutureDate(
        dateRange
      );
    },
    setDefaultRevenueAccount() {
      const revenueAccount = commonHelper.getValuesetData(
        appStrings.VALUESETTYPE.VATIKA_GL_Accounts,
        31310001
      );
      revenueAccount.then(key => {
        // this.gridData[0].revenue_account = key[0].value_code;
        // this.gridData[0].revenue_account_ccid = key[0].value_set_dtl_id;
        // this.gridData[0].revenue_account_meaning =
        //   key[0].value_code + ' - ' + key[0].value_meaning;

        this.revenue_account = key[0].value_code;
        this.revenue_account_ccid = key[0].value_set_dtl_id;
        this.revenue_account_meaning =
          key[0].value_code + ' - ' + key[0].value_meaning;
      });
    },
    clearVsetValues(vsetCode, index) {
      if (vsetCode === 'UOM_CLASS') {
        this.gridData[index].lease_escalation_uom_vset = null;
        this.gridData[index].lease_escalation_uom_vset_meaning = null;
        this.uomClassObj = null;
      } else if (vsetCode === 'ESCALATION_TYPE_VSET') {
        this.gridData[index].escalation_type_vset = null;
        this.gridData[index].escalation_type_vset_meaning = null;
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.setCustCode);
    this.eventBus.$off('escalation');
    this.eventBus.$off('tenantHdrCustSiteData');
    this.eventBus.$off('periodFromTo');
    this.eventBus.$off('rentPeriodFrom');
  }
};
