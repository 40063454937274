import Specification from './specification';
import AddLeasetUnit from '../addLeaseUnit';
import UnitArea from './unitArea';
import OtherSpec from './otherSpec';
import store from '../../../../../../../store';
import openKmAuthentication from '../openKmAuthentication';
import downlaodFiles from '../assignSubUnitDetails/downloadFiles';
import Consult from '../../../../../onlineApproval/internalWorkFlow/addInternalWorkflow/consult';
export default {
  name: 'ProjectUnitDetails',
  props: {
    parentProjectUnitData: Object,
    eventId: Number,
    wf_inprocess_dtl_id: String,
    leasePrjUnitId: String,
    unit_disp_name: String,
    unit_name: String,
    project_name: String,
    floor_name: String,
    approval_status: String,
    ver: String
  },
  components: {
    Specification,
    AddLeasetUnit,
    UnitArea,
    OtherSpec,
    openKmAuthentication,
    downlaodFiles,
    Consult
  },
  data() {
    return {
      actionName: null,
      loader: false,
      responseMsg: [],
      activeTab: 'UnitDetails',
      urlSummaryData: null,
      notificationSummary: null,
      notificationComments: null,
      showConsultModal: false,
      unitDisplayName: null,
      unitName: null,
      projectName: null,
      floorName: null,
      approvalStatus: null,
      version: null,
      wfDtlId: null,
      showAlert: false,
      isSuccess: false
    };
  },
  mounted() {
    if (this.$route.params.summaryData) {
      this.urlSummaryData = JSON.parse(
        Buffer.from(this.$route.params.summaryData, 'base64').toString('ascii')
      );
      this.eventBus.$emit('urlData', this.urlSummaryData);
    }
    if (this.urlSummaryData) {
      this.wfDtlId = this.urlSummaryData.wf_inprocess_dtl_id;
      this.unitDisplayName = this.urlSummaryData.unit_display_name;
      this.unitName = this.urlSummaryData.unit_name;
      this.projectName = this.urlSummaryData.project_name;
      this.floorName = this.urlSummaryData.floor_name;
      this.approvalStatus = this.urlSummaryData.approval_status;
      this.version = this.urlSummaryData.version;
    } else if (this.parentProjectUnitData) {
      this.wfDtlId = this.wf_inprocess_dtl_id;
      this.unitDisplayName = this.parentProjectUnitData.unit_display_name;
      this.unitName = this.parentProjectUnitData.unit_name;
      this.projectName = this.parentProjectUnitData.project_name;
      this.floorName = this.parentProjectUnitData.floor_name;
      this.approvalStatus = this.parentProjectUnitData.approval_status;
      this.version = this.parentProjectUnitData.version;
    } else {
      this.wfDtlId = this.wf_inprocess_dtl_id;
      this.unitDisplayName = this.unit_disp_name;
      this.unitName = this.unit_name;
      this.projectName = this.project_name;
      this.floorName = this.floor_name;
      this.approvalStatus = this.approval_status;
      this.version = this.ver;
    }
    if (
      this.$route.name === 'notificationSummary' ||
      this.$route.name === 'dashboard' ||
      this.$route.name === 'workFlowSummary'
    ) {
      this.notificationSummary = true;
    }
  },
  methods: {
    selectedTab(tabValue) {
      this.activeTab = tabValue;
    },
    closeTabs() {
      this.$emit('closeProjectUnitDetail');
    },
    buttonAccessPermissions(actionName) {
      this.actionName = actionName;
    },
    changeStatus(status) {
      this.loader = true;
      const payload = [
        {
          event_id: this.urlSummaryData
            ? this.urlSummaryData.wf_event_id
            : this.eventId,
          notification_comments: this.notificationComments,
          notification_status: status,
          wf_inprocess_dtl_id: this.wfDtlId,
          temp_approved_amount: 0
        }
      ];
      this.$store
        .dispatch('intWorkflow/approvalReject', { approval_requests: payload })
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            // this.getApprovalHistory(this.resHdrId);
            const result = response.data.data;
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = result[0].message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getEventId() {
      this.$store
        .dispatch('intWorkflow/getEventId', store.state.shared.menuId)
        .then(response => {
          if (response.status === 200) {
            this.eventId = response.data.data;
          }
        });
    },
    showHideConsult(flag) {
      this.showConsultModal = flag;
    },
    checkOpenKmPasswordSaved() {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/checkSavedPassword')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data) {
              this.getOpenKmAuthToken();
            } else {
              this.showHideOpenKmModal(true);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getOpenKmAuthToken() {
      const payload = {
        password: null,
        saved_password_flag: true,
        user_name: null
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmAuthenticate', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openkmAuthToken = response.data.data.token;
            this.downloadFiles(true);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideOpenKmModal(flag) {
      this.showOpenKmModal = flag;
    },
    setOpenKmToken(token) {
      this.showHideOpenKmModal(false);
      this.openkmAuthToken = token;
      this.downloadFiles(true);
    },
    downloadFiles(flag) {
      this.showDownloadModal = flag;
      this.getLeaseUnitMappingDetailsById(
        this.urlSummaryData.lease_prj_unit_id
      );
    }
  }
};
