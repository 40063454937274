import commonHelper from '@/app/utility/common.helper.utility';
import addEditTenantLease from './addEditTenantLease';
import HistoryRecord from '@/app/components/onlineApproval/internalWorkFlow/history/historyRecord';
import ApprovalHistory from '@/app/components/onlineApproval/internalWorkFlow/history/approvalHistory';
import UploadDocument from '@/app/components/onlineApproval/internalWorkFlow/history/uploadDocument';
import sendForApproval from '@/app/components/onlineApproval/internalWorkFlow/history/sendForApproval';
import openKmAuthentication from '@/app/components/onlineApproval/internalWorkFlow/openKmAuthentication';
import masterAgreement from '../../leases/masterAgreement';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'tenantLease',
  watch: {
    currentPage: function() {
      this.getLeaseTenantList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getLeaseTenantList();
    }
  },
  props: ['showTenantLeaseModal'],
  components: {
    commonHelper,
    addEditTenantLease,
    HistoryRecord,
    ApprovalHistory,
    UploadDocument,
    sendForApproval,
    openKmAuthentication,
    masterAgreement
  },
  data() {
    return {
      leaseTwr: {
        value: null,
        text: null
      },
      leaseFloor: {
        value: null,
        text: null
      },
      partyName: '',
      leasePrj: {
        value: null,
        text: null
      },
      leaseMasterAgreement: {
        value: null,
        text: null
      },
      appStatus: {
        value: null,
        text: null
      },
      leaseAgreementNo: {
        value: null,
        text: null
      },
      modalType: null,
      showCommonModal: false,
      unsubscribe: null,
      showAddLeaseUnitModal: false,
      showDetails: false,
      showValueSetModal: false,
      parent_value_set_id: null,
      unitName: null,
      loader: false,
      unitDisplay: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      showAddLeaseModal: false,
      selectedLeaseDetails: null,
      leaseTenantNo: null,
      tenantLeasehdrId: null,
      createCopyWorkflow: false,
      gridData: [],
      gridFields: [
        {
          key: 'select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'lease_agreement_no',
          label: 'MLA No'
        },
        {
          key: 'lease_tenant_agreement_no',
          label: 'Lease Tenant No'
        },
        {
          key: 'lease_prj_name',
          label: 'Project Name'
        },
        {
          key: 'tower_name'
        },
        {
          key: 'floor_name'
        },
        {
          key: 'lease_prj_unit_name',
          label: 'Lease Office'
        },
        {
          key: 'billable_area'
        },
        {
          key: 'rate',
          stickyColumn: true
        },
        {
          key: 'customer_id'
        },
        {
          key: 'customer_name'
        },
        {
          key: 'customer_site_id'
        },
        {
          key: 'customer_site_name',
          label: 'Customer Site'
        },
        {
          key: 'customer_site_address',
          label: 'Site Address'
        },
        {
          key: 'lease_description'
        },
        {
          key: 'approval_status'
        },
        {
          key: 'mark_for_termination'
        },
        {
          key: 'status',
          stickyColumn: true
        },
        {
          key: 'lease_status_meaning',
          label: 'Lease Status'
        }
      ],
      showHistoryBtn: false,
      showHistory: false,
      historyType: null,
      showOpenKmModal: false,
      openkmAuthToken: null,
      selectedGridData: null,
      terminationMarked: false,
      schStatus: {
        value: null,
        text: null
      },
      defaultValue: {
        value: null,
        text: null
      }
    };
  },
  mounted() {
    if (this.showTenantLeaseModal) {
      this.getLeaseTenantList();
    }
    // this.setDefaultStatus();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showAddLeaseModal = true;
          this.selectedLeaseDetails = null;
          this.showHistoryBtn = false;
          this.tenantLeasehdrId = null;
          this.createCopyWorkflow= false;
        } if (actionName === 'delete' && !this.showAddLeaseUnitModal  && !this.showDetails && !this.showAddLeaseModal) {
          this.deleteTenantLease();
        }
        if (
          actionName === 'download' &&
          !this.showAddLeaseUnitModal &&
          !this.showDetails
        ) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'leases/getLeaseTenantList',
            'tenant-lease',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getLeaseTenantList() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        lease_tenant_no: this.leaseTenantNo,
        approval_status: this.appStatus.value,
        lease_master_agreement_id: this.leaseAgreementNo.value,
        party_name: this.partyName,
        lease_prj_id: this.leasePrj.value,
        lease_tower_id: this.leaseTwr.value,
        lease_floor_id: this.leaseFloor.value,
        lease_status: this.schStatus.value,
        mark_for_termination: this.terminationMarked
      };
      this.loader = true;
      this.$store
        .dispatch('leases/getLeaseTenantList', this.payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.gridData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideCommonLeaseModal(flag, modalType) {
      this.showCommonModal = flag;
      this.modalType = modalType;
      if (this.modalType === 'PARTY') {
        this.setCustCode = setTimeout(() => {
          this.eventBus.$emit('userType', 'CUST');
        }, 0);
      }
    },
    selectedLeaseMaster(item) {
      this.leaseAgreementNo.value = item.lease_master_agreement_hdr_id;
      this.leaseAgreementNo.text = item.lease_agreement_no;
      this.showCommonModal = false;
    },
    clearSearchFilters() {
      this.leaseTenantNo = null;
      this.appStatus = this.defaultValue;
      this.partyName = '';
      this.leaseAgreementNo = this.defaultValue;
      this.leasePrj = this.defaultValue;
      this.leaseTwr = this.defaultValue;
      this.leaseFloor = this.defaultValue;
      this.schStatus = this.defaultValue;
      this.gridData = [];
      this.parent_value_set_id = null;
      this.totalRows = null;
      this.currentPage = 1;
      this.terminationMarked = false;
      this.status = false;
      this.showHistoryBtn = false;
    },
    hideAddLeaseModal() {
      this.showAddLeaseModal = false;
      this.gridData = [];
      this.getLeaseTenantList();
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.leasePrj = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.APPROVAL_STATUS) {
        this.appStatus = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_TOWER_LIST) {
        this.leaseTwr = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_FLOOR_LIST) {
        this.leaseFloor = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.SCHEDULE_STATUS_VSET
      ) {
        this.schStatus = {
          value: item.value_code,
          text: item.value_meaning
        };
      }
    },
    openValueSetModal(vsetCode) {
      if (vsetCode === appStrings.VALUESETTYPE.LEASE_TOWER_LIST) {
        this.parent_value_set_id = this.leasePrj.value;
      } else if (vsetCode === appStrings.VALUESETTYPE.LEASE_FLOOR_LIST) {
        this.parent_value_set_id = this.leaseTwr.value;
      }
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    rowSelected(item) {
      item.period_from_to = [new Date(item.from_date), new Date(item.to_date)];
      if (this.showTenantLeaseModal) {
        this.$emit('selectedLeaseTenant', item);
      } else {
        this.selectedLeaseDetails = Object.assign({}, item);
        this.showAddLeaseModal = true;
        this.tenantLeasehdrId = item.lease_tenant_agreement_hdr_id;
        this.createCopyWorkflow= false;
      }
    },
    selectedLease(item) {
      this.leaseTenantAgreementHdrId = item.lease_tenant_agreement_hdr_id;
      this.selectedLeaseDetails = Object.assign({}, item);
      this.showHistoryBtn = true;
      this.tenantLeasehdrId = item.lease_tenant_agreement_hdr_id;
    },
    showHideHistory(flag, historyType) {
      if (this.checkTenantLeaseHdrId()) {
        this.showHistory = flag;
        this.historyType = historyType;
      }
    },
    async getTenantDetails() {
      if (this.checkTenantLeaseHdrId()) {
        const escalationPromise = await commonHelper.getTenantAgreementDetails(
          this.tenantLeasehdrId,
          'escalation'
        );
        const taxPromise = await commonHelper.getTenantAgreementDetails(
          this.tenantLeasehdrId,
          'tax'
        );
        Promise.all([escalationPromise, taxPromise])
          .then(values => {
            if (values[0].status === 200 && values[1].status === 200) {
              const escalationData = values[0].data.data;
              const taxData = values[1].data.data;
              if (escalationData && taxData) {
                if (escalationData.length > 0 && taxData.length > 0) {
                  this.isDocumentUploadedFunc();
                  return true;
                } else {
                  alert(
                    'Please enter data in Escalation & Tax before send for approval!'
                  );
                }
              }
            } else if (values[0].status !== 200 && values[1].status !== 200) {
              alert('Unable to fetch Escalation & Tax Data');
            } else {
              if (values[0].status !== 200) {
                alert('Unable to fetch Escalation Data');
              }
              if (values[1].status !== 200) {
                alert('Unable to fetch Tax Data');
              }
            }
            return false;
          })
          .catch(err => {
            alert(err);
          });
      }
    },
    checkOpenKmPasswordSaved() {
      if (this.checkTenantLeaseHdrId()) {
        this.loader = true;
        this.$store
          .dispatch('intWorkflow/checkSavedPassword')
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              if (response.data.data) {
                this.getOpenKmAuthToken();
              } else {
                this.showHideOpenKmModal(true);
              }
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    getOpenKmAuthToken() {
      const payload = {
        password: null,
        saved_password_flag: true,
        user_name: null
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmAuthenticate', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openkmAuthToken = response.data.data.token;
            this.selectedGridData = {
              ...this.selectedLeaseDetails,
              nfa_cat_vset: null,
              nfa_cat: null,
              nfa_sub_cat_vset: null,
              nfa_sub_cat: null
            };
            this.showHideHistory(true, 'uploadDocument');
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideOpenKmModal(flag) {
      this.showOpenKmModal = flag;
    },
    setOpenKmToken(token) {
      this.showHideOpenKmModal(false);
      this.openkmAuthToken = token;
      this.selectedGridData = {
        ...this.selectedLeaseDetails,
        nfa_cat_vset: null,
        nfa_cat: null,
        nfa_sub_cat_vset: null,
        nfa_sub_cat: null
      };
      this.showHideHistory(true, 'uploadDocument');
    },
    checkTenantLeaseHdrId() {
      if (!this.tenantLeasehdrId) {
        alert('Please save the record!');
        return false;
      }
      return true;
    },
    async isDocumentUploadedFunc() {
      const uploadedDocList = await commonHelper.getUploadedDocList(
        this.$store.state.shared.menuId,
        this.tenantLeasehdrId
      );
      const isDocumentUploaded = uploadedDocList.length > 0;
      isDocumentUploaded
        ? this.showHideHistory(true, 'sendForApproval')
        : alert('Please upload document!');
    },
    deleteTenantLease() {
      const promt = confirm('Are you sure, you want to delete this Tenant Lease?');
      if (promt) {
        const payload = {
          lease_tenant_agreement_hdr_id: this.leaseTenantAgreementHdrId
        };
        this.loader = true;
        this.$store
          .dispatch('leases/deleteTenantLease', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.showAlert = true;
              this.isSuccess = true;
              alert('Record Deleted Successfully');
              this.getLeaseTenantList();
            } else {
              this.showAlert = true;
              this.isSuccess = false;
              alert(response.response.data.message)
            }
          })
      }
    },
    // setDefaultStatus() {
    //   const statusValue = commonHelper.getValuesetData(
    //     appStrings.VALUESETTYPE.STATUS_VSET
    //   );
    //   statusValue.then(key => {
    //     this.status = {
    //       value: key[0].value_code,
    //       text: key[0].value_meaning
    //     };
    //   });
    // },
    clearVsetValues(vsetCode) {
      switch (vsetCode) {
        case 'LEASE_PROJECT_LIST':
          this.leasePrj = this.defaultValue;
          break;
        case 'LEASE_TOWER_LIST':
          this.leaseTwr = this.defaultValue;
          break;
        case 'LEASE_FLOOR_LIST':
          this.leaseFloor = this.defaultValue;
          break;
        case 'SCHEDULE_STATUS_VSET':
          this.schStatus = this.defaultValue;
          break;
        case 'APPROVAL_STATUS':
          this.appStatus = this.defaultValue;
          break;
      }
    },
    copyWorkflow() {
      const promt = confirm('Are you sure, you want to create copy?');
      if (promt) {
        this.createCopyWorkflow = true;
        this.showAddLeaseModal = true;
       }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
