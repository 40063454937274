import appStrings from '@/app/utility/string.utility';
export default {
  name: 'location',
  props: {
    editMode: Boolean,
    projectType: String,
    wfHeaderId: Number
  },
  data() {
    return {
      unsubscribe: null,
      showWaiverModal: false,
      sectorInput: false,
      pocketInput: false,
      projectInput: false,
      towerInput: false,
      floorInput: false,
      unitInput: false,
      loader: false,
      projectIndex: 0,
      setTimeVsetCode: null,
      vsetCode: null,
      parent_value_set_id: null,
      showValueSetModal: false,
      projectList: [
        {
          entity_type: null,
          entity_type_vset: null,
          sector_name: null,
          sector_id: null,
          pocket_name: null,
          pocket_id: null,
          prj_name: null,
          prj_id: null,
          tower_name: null,
          tower_id: null,
          floor_name: null,
          floor_id: null,
          unit_name: null,
          unit_id: null,
          waiver: 0,
          waiverList: [
            {
              nfa_prj_id: null,
              nfa_waiver_id: null,
              waiver_vset: null,
              waiver: null,
              waiver_amount: null,
              fms_comp_group: null,
              fms_comp_group_id: null,
            }
          ]
        }
      ],
      projectFeilds: [
        {
          key: 'entity_type',
          label: 'Entity Name'
        },
        {
          key: 'sector_name',
          label: 'Sector'
        },
        {
          key: 'pocket_name',
          label: 'Pocket'
        },
        {
          key: 'prj_name',
          label: 'Project'
        },
        {
          key: 'tower_name',
          label: 'Tower'
        },
        {
          key: 'floor_name',
          label: 'Floor'
        },
        {
          key: 'unit_name',
          label: 'Unit'
        },
        {
          key: 'waiver',
          class: 'd-none'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ],
      waiverIndex: null,
      waiverList: [],
      waiverFeilds: [
        {
          key: 'fms_comp_group',
        },
        {
          key: 'waiver',
          label: 'Head'
        },
        {
          key: 'waiver_amount',
          label: 'Amount'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ]
    };
  },
  mounted() {
    if (this.wfHeaderId) {
      this.getProjectList(this.wfHeaderId);
    }
    this.eventBus.$on('enbaleWaiver', enbaleWaiver => {
      if (enbaleWaiver) {
        this.projectFeilds[7].class = '';
      } else {
        this.projectFeilds[7].class = 'd-none';
      }
    });
    this.eventBus.$on('project', wfHeaderId => {
      if (wfHeaderId) {
        // this.wfHeaderId = wfHeaderId;
        this.getProjectList(wfHeaderId);
      }
    });
  },
  methods: {
    getProjectTypeBasedEntity(entity, index) {
      if (this.projectList[index].entity_type) {
        if (this.projectType === 'Project') {
          if (entity === 'Sector') {
            this.openValueSetModal('NFA_PRJ_SECTOR', index);
          } else if (entity === 'Pocket') {
            this.projectList[index].sector_name !== null
              ? this.openValueSetModal('NFA_PRJ_POCKET', index)
              : alert('Please select Sector');
          } else if (entity === 'Project') {
            this.projectList[index].pocket_name !== null
              ? this.openValueSetModal('NFA_PRJ_PROJECT', index)
              : alert('Please select Pocket');
          } else if (entity === 'Tower') {
            this.projectList[index].prj_name !== null
              ? this.openValueSetModal('NFA_PRJ_TOWER', index)
              : alert('Please select Project');
          } else if (entity === 'Floor') {
            this.projectList[index].tower_name !== null
              ? this.openValueSetModal('NFA_PRJ_FLOOR', index)
              : alert('Please select Tower');
          } else if (entity === 'Unit') {
            this.projectList[index].floor_name !== null
              ? this.openValueSetModal('NFA_PRJ_UNIT', index)
              : alert('Please select Floor');
          }
        } else if (this.projectType === 'FMS') {
          if (entity === 'Sector') {
            this.openValueSetModal('NFA_FMS_SECTOR', index);
          } else if (entity === 'Pocket') {
            this.projectList[index].sector_name !== null
              ? this.openValueSetModal('NFA_FMS_POCKET', index)
              : alert('Please select Sector');
          } else if (entity === 'Project') {
            this.projectList[index].pocket_name !== null
              ? this.openValueSetModal('NFA_FMS_PROJECT', index)
              : alert('Please select Pocket');
          } else if (entity === 'Tower') {
            this.projectList[index].prj_name !== null
              ? this.openValueSetModal(appStrings.VALUESETTYPE.NFA_FMS_TOWER, index)
              : alert('Please select Project');
          } else if (entity === 'Floor') {
            this.projectList[index].tower_name !== null
              ? this.openValueSetModal(appStrings.VALUESETTYPE.NFA_FMS_FLOOR, index)
              : alert('Please select Tower');
          } else if (entity === 'Unit') {
            this.projectList[index].floor_name !== null
              ? this.openValueSetModal(appStrings.VALUESETTYPE.NFA_FMS_UNIT, index)
              : alert('Please select Floor');
          }
        } else if (this.projectType === 'CRM') {
          if (entity === 'Sector') {
            this.openValueSetModal('NFA_CRM_SECTOR', index);
          } else if (entity === 'Pocket') {
            this.projectList[index].sector_name !== null
              ? this.openValueSetModal('NFA_CRM_POCKET', index)
              : alert('Please select Sector');
          } else if (entity === 'Project') {
            this.projectList[index].pocket_name !== null
              ? this.openValueSetModal('NFA_CRM_PROJECT', index)
              : alert('Please select Pocket');
          } else if (entity === 'Tower') {
            this.projectList[index].prj_name !== null
              ? this.openValueSetModal('NFA_CRM_TOWER', index)
              : alert('Please select Project');
          } else if (entity === 'Floor') {
            this.projectList[index].tower_name !== null
              ? this.openValueSetModal('NFA_CRM_FLOOR', index)
              : alert('Please select Tower');
          } else if (entity === 'Unit') {
            this.projectList[index].floor_name !== null
              ? this.openValueSetModal('NFA_CRM_UNIT', index)
              : alert('Please select Floor');
          }
        } else {
          alert('Please select Project Type');
        }
      } else {
        alert('Please select Entity');
      }
    },
    getEntityInput(index) {
      switch (this.projectList[index].entity_type) {
        case 'Pocket':
          this.projectList[index].sectorInput = true;
          this.projectList[index].pocketInput = true;
          this.projectList[index].projectInput = false;
          this.projectList[index].towerInput = false;
          this.projectList[index].floorInput = false;
          this.projectList[index].unitInput = false;
          break;
        case 'Project':
          this.projectList[index].sectorInput = true;
          this.projectList[index].pocketInput = true;
          this.projectList[index].projectInput = true;
          this.projectList[index].towerInput = false;
          this.projectList[index].floorInput = false;
          this.projectList[index].unitInput = false;
          break;
        case 'Tower':
          this.projectList[index].sectorInput = true;
          this.projectList[index].pocketInput = true;
          this.projectList[index].projectInput = true;
          this.projectList[index].towerInput = true;
          this.projectList[index].floorInput = false;
          this.projectList[index].unitInput = false;
          break;
        case 'Floor':
          this.projectList[index].sectorInput = true;
          this.projectList[index].pocketInput = true;
          this.projectList[index].projectInput = true;
          this.projectList[index].towerInput = true;
          this.projectList[index].floorInput = true;
          this.projectList[index].unitInput = false;
          break;
        case 'Unit':
          this.projectList[index].sectorInput = true;
          this.projectList[index].pocketInput = true;
          this.projectList[index].projectInput = true;
          this.projectList[index].towerInput = true;
          this.projectList[index].floorInput = true;
          this.projectList[index].unitInput = true;
          break;
        case 'Sector':
          this.projectList[index].sectorInput = true;
          this.projectList[index].pocketInput = false;
          this.projectList[index].projectInput = false;
          this.projectList[index].towerInput = false;
          this.projectList[index].floorInput = false;
          this.projectList[index].unitInput = false;
          break;
      }
    },
    getProjectList(wfHeaderId) {
      this.loader = true;
      const payload = {
        tabType: 'projects',
        wfHeaderId: wfHeaderId
      };
      this.$store
        .dispatch('intWorkflow/getWfTabsDetails', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200 && response.data.data.length > 0) {
            const addWaiverAmount = response.data.data.map(data => {
              data.waiver = data.waiver_amount;
              data.waiverList = data.waivers;
              return {
                entity_type: data.entity_type,
                entity_type_vset: data.entity_type_vset,
                sector_name: data.sector_name,
                sector_id: data.sector_id,
                sectorInput: false,
                pocket_name: data.pocket_name,
                pocket_id: data.pocket_id,
                pocketInput: false,
                prj_name: data.prj_name,
                prj_id: data.prj_id,
                projectInput: false,
                tower_name: data.tower_name,
                tower_id: data.tower_id,
                towerInput: false,
                floor_name: data.floor_name,
                floor_id: data.floor_id,
                floorInput: false,
                unit_name: data.unit_name,
                unit_id: data.unit_id,
                unitInput: false,
                waiver: parseFloat(
                  data.waiver_amount.replace(/,/g, '')
                ).toFixed(2),
                waiverList: data.waivers.map(wav => {
                  wav.waiver_amount = parseFloat(
                    wav.waiver_amount.replace(/,/g, '')
                  ).toFixed(2);
                  return wav;
                })
              };
            });
            this.projectList = addWaiverAmount;
            this.projectList.forEach((item, index) => {
              this.getEntityInput(index);
            });
            this.$emit('projectList', this.projectList);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addNewRow() {
      if (this.editMode) {
        this.projectList.push({
          entity_type: null,
          entity_type_vset: null,
          sector_name: null,
          sector_id: null,
          pocket_name: null,
          pocket_id: null,
          prj_name: null,
          prj_id: null,
          tower_name: null,
          tower_id: null,
          floor_name: null,
          floor_id: null,
          unit_name: null,
          unit_id: null,
          waiver: 0,
          waiverList: [
            {
              nfa_prj_id: null,
              nfa_waiver_id: null,
              waiver_vset: null,
              waiver: null,
              waiver_amount: null
            }
          ]
        });
      }
    },
    openValueSetModal(vsetCode, index) {
      this.projectIndex = index;
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: this.vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'NFA_ENTITY_TYPE') {
        this.projectList[this.projectIndex].entity_type_vset = item.value_code;
        this.projectList[this.projectIndex].entity_type = item.value_meaning;
        this.getEntityInput(this.projectIndex);
      } else if (
        this.vsetCode === 'NFA_FMS_SECTOR' ||
        this.vsetCode === 'NFA_CRM_SECTOR' ||
        this.vsetCode === 'NFA_PRJ_SECTOR'
      ) {
        this.projectList[this.projectIndex].sector_name = item.sector_name;
        this.projectList[this.projectIndex].sector_id = item.sector_id;
      } else if (
        this.vsetCode === 'NFA_FMS_POCKET' ||
        this.vsetCode === 'NFA_CRM_POCKET' ||
        this.vsetCode === 'NFA_PRJ_POCKET'
      ) {
        this.projectList[this.projectIndex].pocket_name = item.pocket_name;
        this.projectList[this.projectIndex].pocket_id = item.pocket_id;
      } else if (
        this.vsetCode === 'NFA_FMS_PROJECT' ||
        this.vsetCode === 'NFA_CRM_PROJECT' ||
        this.vsetCode === 'NFA_PRJ_PROJECT'
      ) {
        this.projectList[this.projectIndex].prj_name = item.project;
        this.projectList[this.projectIndex].prj_id = item.prj_id;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_TOWER ||
        this.vsetCode === 'NFA_CRM_TOWER' ||
        this.vsetCode === 'NFA_PRJ_TOWER'
      ) {
        this.projectList[this.projectIndex].tower_name = item.tower_name;
        this.projectList[this.projectIndex].tower_id = item.tower_id;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_FLOOR ||
        this.vsetCode === 'NFA_CRM_FLOOR' ||
        this.vsetCode === 'NFA_PRJ_FLOOR'
      ) {
        this.projectList[this.projectIndex].floor_name = item.floor_name;
        this.projectList[this.projectIndex].floor_id = item.floor_id;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_UNIT ||
        this.vsetCode === 'NFA_CRM_UNIT' ||
        this.vsetCode === 'NFA_PRJ_UNIT'
      ) {
        this.projectList[this.projectIndex].unit_name = item.unit_name;
        this.projectList[this.projectIndex].unit_id = item.unit_id;
      } else if (this.vsetCode === 'FMS_COMP_GROUP_TABLE') {
        this.waiverList[this.waiverIndex].fms_comp_group = item.fms_comp_group_display_name;
        this.waiverList[this.waiverIndex].fms_comp_group_id = item.fms_comp_group_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_COMP_TABLE) {
        this.waiverList[this.waiverIndex].waiver_vset = item.fms_comp_id;
        this.waiverList[this.waiverIndex].waiver = item.fms_comp_display_name;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    showAlert(message) {
      alert(message);
      // const entityType = message;
      // alert('Please select Entity', entityType);
    },
    removeRow(index) {
      this.projectList.splice(index, 1);
      const totalWaiverAmount = this.projectList.reduce(
        (accum, currentValue) => {
          return accum + parseFloat(currentValue.waiver);
        },
        0
      );
      this.$emit('totalWaiver', totalWaiverAmount);
    },
    addWaiver(index) {
      this.projectIndex = index;
      if (this.editMode) {
        this.showWaiverModal = true;
        this.waiverList = this.projectList[this.projectIndex].waiverList;
        this.waiverList = this.projectList[this.projectIndex].waiverList;
      }
    },
    setWaiverData(item) {
      this.projectList[this.projectIndex].waiverList = item;
    },
    hideWaiverModal() {
      this.showWaiverModal = false;
      const filterWaiverList = this.waiverList.filter(
        data => data.waiver_amount !== null
      );
      const waiverSum = filterWaiverList.reduce(function(
        accumulator,
        currentValue
      ) {
        return accumulator + parseFloat(currentValue.waiver_amount);
      },
      0);
      this.projectList[this.projectIndex].waiver = !isNaN(waiverSum)
        ? waiverSum.toFixed(2)
        : 0.0;
      this.projectList[this.projectIndex].waiverList = this.waiverList;
      const totalWaiverAmount = this.projectList.reduce(
        (accum, currentValue) => {
          return accum + parseFloat(currentValue.waiver);
        },
        0
      );
      this.$emit('totalWaiver', totalWaiverAmount.toFixed(2));
    },
    openWaiverValueSetModal(vsetCode, index) {
      this.waiverIndex = index;
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.FMS_COMP_TABLE) {
        this.parent_value_set_id = this.waiverList[this.waiverIndex].fms_comp_group_id;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    addNewWaiverRow() {
      this.waiverList.push({
        nfa_prj_id: null,
        nfa_waiver_id: null,
        waiver_vset: null,
        waiver: null,
        fms_comp_group: null,
        fms_comp_group_id: null,
        waiver_amount: null
      });
    },
    removeWaiverRow(index) {
      this.waiverList.splice(index, 1);
    },
    rowSelected() {}
  },
  beforeDestroy() {
    this.eventBus.$off('enbaleWaiver');
    this.eventBus.$off('project');
    clearTimeout(this.setTimeVsetCode);
  },
  filters: {
    formatNumber: function(value) {
      if (!value) return 0.0;
      return parseFloat(value).toFixed(2);
    }
  }
};
