import DatePicker from 'vue2-datepicker';
import party from '@/app/components/generalLedger/party';
import appStrings from '@/app/utility/string.utility.js';
import commonHelper from '@/app/utility/common.helper.utility';
import store from '../../../../../../../../store';
import codeCombination from '@/app/components/shared/codeCombination';
export default {
  name: 'escalation',
  components: {
    DatePicker,
    party,
    codeCombination
  },
  props: ['editMode', 'investorLeaseApprovalStatus', 'tenantAgreementHdrId'],
  data() {
    return {
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: null,
      setCustCode: null,
      vsetCode: null,
      uomClassObj: null,
      specIndex: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      areaType: null,
      setTimeVsetCode: null,
      showTrxTypeModal: false,
      segmentIndex: 0,
      showCodeCombinationModal: false,
      accountType: null,
      combinationDetails: {
        le_id: null,
        project_code: null
      },
      trxTypeList: [],
      trxTypeData: [],
      gridData: [
        {
          active: false,
          lease_vendor_id: null,
          trx_type: null,
          trx_type_dtl_id: null,
          period: null,
          lease_vendor_name: null,
          lease_vendor_site_id: null,
          lease_vendor_site_name: null,
          lease_investor_agreement_hdr_id: null,
          lease_investor_agreement_escalation_id: 0,
          lease_escalation_uom_vset_meaning: null,
          period_from: null,
          period_to: null,
          lease_escalation: null,
          lease_escalation_uom_vset: null,
          request_num: null,
          create_rental: false,
          charge_ac_ccid: null,
          charge_ac_ccid_dtl: null,
          charge_ac_ccid_meaning: null,
          escalation_type_vset: null,
          escalation_type_vset_meaning: null,
          sac: null,
          lease_escalation_num: null,
          lease_tenant_agreement_escalation_id: null,
          lease_tenant_agreement_escalation_num: null
        }
      ],
      gridFields: [
        {
          key: 'create_rental'
        },
        {
          key: 'lease_tenant_agreement_escalation_num',
          label: 'Tenant Lease Escalation'
        },
        {
          key: 'lease_vendor_name',
          label: 'Vendor Name'
        },
        {
          key: 'lease_vendor_site_name',
          label: 'Vendor Site'
        },
        {
          key: 'sac',
          label: 'SAC'
        },
        {
          key: 'lease_escalation_num',
          label: 'Lease Esc Num'
        },
        {
          key: 'lease_escalation_uom_vset_meaning',
          label: 'UOM'
        },
        {
          key: 'period'
        },
        {
          key: 'escalation_type_vset_meaning',
          label: 'Escalation Type'
        },
        {
          key: 'lease_escalation',
          label: 'Escalation'
        },
        {
          key: 'trx_type'
        },
        {
          key: 'escalation_status_meaning',
          label: 'Escalation Status'
        },
        {
          key: 'charge_ac_ccid_dtl',
          label: 'Charge Account'
        },
        {
          key: 'period_from_to',
          label: 'Period (From - To)'
        },
        {
          key: 'request_num'
        },
        {
          key: 'error_msg'
        },
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        }
      ],
      showCustomerSiteModal: false,
      gridIndex: null,
      showPartyModal: false,
      leaseForm: null,
      gridDataSite: [],
      gridFieldsSite: [
        {
          key: 'site_name'
        },
        {
          key: 'site_type'
        },
        {
          key: 'bill_to_address',
          label: 'Site Address'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'gstn',
          label: 'GSTN'
        }
      ],
      showConcatSegmentModal: false,
      segmentFields: [
        {
          key: 'segment_meaning'
        },
        {
          key: 'segment_code'
        }
      ],
      segmentData: [],
      segmentMeaning: '',
      filterSegmentCode: null,
      isBusy: true
    };
  },
  mounted() {
    this.getTxnTypeList();
    this.eventBus.$on('escalation', tabData => {
      this.leaseForm = tabData.leaseForm;
      if (tabData.leaseInvestorHdrId) {
        this.getDetails(tabData.leaseInvestorHdrId);
      }
      if (tabData.leaseMasterAgreementHdrId) {
        this.getMasterData(tabData.leaseMasterAgreementHdrId);
      }
    });
  },
  methods: {
    getDetails(leaseInvestorHdrId) {
      const payload = {
        tabName: 'escalation',
        leaseInvestorHdrId: leaseInvestorHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('leases/getLeaseInvestorDataById', payload)
        .then(resp => {
          this.loader = false;
          if (
            resp.status === 200 &&
            resp.data.data.length > 0
            // &&
            // this.gridData[0].lease_investor_agreement_escalation_id === 0
          ) {
            this.gridData = resp.data.data.map(data => {
              data.period_from_to = [
                new Date(data.period_from),
                new Date(data.period_to)
              ];
              return data;
            });
            const createRenetalIndex = resp.data.data.findIndex(
              data =>
                data.escalation_status === 'U' || data.escalation_status === 'E'
            );
            if (createRenetalIndex !== -1)
              this.gridData[createRenetalIndex].create_rental = true;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getMasterData(leaseMasterAgreementHdrId) {
      const payload = {
        tabName: 'escalation',
        leaseMasterAgreementHdrId: leaseMasterAgreementHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('leases/getLeaseMasterAgreementDataById', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200 && resp.data.data.length > 0) {
            const filterData = resp.data.data.filter(
              item => item.active === true
            );
            this.gridData = filterData.map(item => {
              return {
                active: item.active,
                lease_investor_agreement_hdr_id: null,
                lease_investor_agreement_escalation_id: 0,
                lease_escalation_uom_vset_meaning:
                  item.lease_escalation_uom_vset_meaning,
                period_from: item.period_from,
                period_to: item.period_to,
                period: item.period,
                trx_type: item.trx_type,
                lease_escalation: item.lease_escalation,
                lease_escalation_uom_vset: item.lease_escalation_uom_vset,
                request_num: null,
                create_rental: false
              };
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getTxnTypeList() {
      const moduleId = store.state.shared.moduleId;
      this.$store.dispatch('leases/getTxnTypeList', moduleId).then(response => {
        if (response.status === 200) {
          const results = response.data.data;
          const typeList = results.map(type => {
            return {
              value: type.trx_type_dtl_id,
              text: type.trx_desc
            };
          });
          this.trxTypeList = typeList;
        }
      });
    },
    trxTypeModal(flag, index) {
      this.trxTypeIndex = index;
      this.showTrxTypeModal = flag;
    },
    selectedTrxType(item) {
      this.gridData[this.trxTypeIndex].trx_type = item.text;

      this.gridData[this.trxTypeIndex].trx_type_dtl_id = item.value;
      this.showTrxTypeModal = false;
    },
    addNewRow(index) {
      if (this.editMode) {
        this.gridData.push({
          active: false,
          lease_vendor_id: this.gridData[index].lease_vendor_id,
          lease_vendor_name: this.gridData[index].lease_vendor_name,
          lease_vendor_site_id: this.gridData[index].lease_vendor_site_id,
          lease_vendor_site_name: this.gridData[index].lease_vendor_site_name,
          lease_investor_agreement_hdr_id: null,
          lease_investor_agreement_escalation_id: 0,
          lease_escalation_uom_vset_meaning: this.gridData[index]
            .lease_escalation_uom_vset_meaning,
          sac: this.gridData[index].sac,
          period_from: null,
          period: this.gridData[index].period,
          trx_type: this.gridData[index].trx_type,
          trx_type_dtl_id: this.gridData[index].trx_type_dtl_id,
          period_to: null,
          lease_escalation: this.gridData[index].lease_escalation,
          lease_escalation_uom_vset: this.gridData[index]
            .lease_escalation_uom_vset,
          create_rental: false,
          lease_escalation_num: this.gridData[index].lease_escalation_num,
          escalation_type_vset: this.gridData[index].escalation_type_vset,
          escalation_type_vset_meaning: this.gridData[index]
            .escalation_type_vset_meaning,
          charge_ac_ccid: this.gridData[index].charge_ac_ccid,
          charge_ac_ccid_dtl: this.gridData[index].charge_ac_ccid_dtl,
          charge_ac_ccid_meaning: this.gridData[index].charge_ac_ccid_meaning,
          lease_tenant_agreement_escalation_id: null,
          lease_tenant_agreement_escalation_num: null
        });
      }
    },
    removeRow(index) {
      if (this.editMode) {
        this.gridData.splice(index, 1);
      }
    },
    opendDependentUom(vsetCode, index) {
      const payload = {
        valueSetName: vsetCode,
        dependentVariable: {
          user_id: this.$store.state.auth.userId,
          res_id: this.$store.state.shared.responsibilityId
        }
      };
      this.loader = true;
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          this.loader = false;
          this.uomClassObj = null;
          if (response.status === 200) {
            const findTimeObj = response.data.data.page.find(
              vsetData => vsetData.value_code === 'TIME'
            );
            if (findTimeObj) {
              this.uomClassObj = findTimeObj;
              this.openValueSetModal('UOM', index);
            }
          }
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'UOM') {
        this.gridData[this.specIndex].lease_escalation_uom_vset =
          item.value_code;
        this.gridData[this.specIndex].lease_escalation_uom_vset_meaning =
          item.value_meaning;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.ESCALATION_TYPE_VSET
      ) {
        this.gridData[this.specIndex].escalation_type_vset = item.value_code;
        this.gridData[this.specIndex].escalation_type_vset_meaning =
          item.value_meaning;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.TENANT_LEASE_ESC_NUM
      ) {
        this.gridData[this.specIndex].lease_tenant_agreement_escalation_id =
          item.value_set_dtl_id;
        this.gridData[this.specIndex].lease_tenant_agreement_escalation_num =
          item.value_code;
      }
      // this.gridData = JSON.parse(JSON.stringify(this.gridData));
      this.showValueSetModal = false;
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.specIndex = index;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.uomClassObj) {
        this.parent_value_set_id = this.uomClassObj.value_set_dtl_id;
      }
      if (this.vsetCode === appStrings.VALUESETTYPE.TENANT_LEASE_ESC_NUM) {
        this.parent_value_set_id = this.leaseForm.lease_tenant_agreement_hdr_id;
      }
      if (this.vsetCode === appStrings.VALUESETTYPE.ESCALATION_TYPE_VSET) {
        this.parent_value_set_id = null;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    showHideCustomerSiteModal(flag) {
      this.showCustomerSiteModal = flag;
    },
    getVendorSiteByVendorId(index) {
      this.gridIndex = index;
      this.$store
        .dispatch(
          'leaseSchedule/getVendorSiteByVendorId',
          this.gridData[index].lease_vendor_id
        )
        .then(response => {
          this.gridDataSite = response.data.data.vendor_sites;
          this.showCustomerSiteModal = true;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedSite(item) {
      this.gridData[this.gridIndex].lease_vendor_site_name = item.site_name;
      this.gridData[this.gridIndex].lease_vendor_site_id = item.vendor_site_id;
      this.showCustomerSiteModal = false;
    },
    showHidePartyModal(flag, index) {
      this.showPartyModal = flag;
      this.gridIndex = index;
      this.setCustCode = setTimeout(() => {
        this.eventBus.$emit('userType', 'VEND');
      }, 0);
    },
    getSelectedPartyCustomer(item) {
      this.gridData[this.gridIndex].lease_vendor_name = item.party_name;
      this.showPartyModal = false;
      this.getVendorIdByPartyId(item.party_id);
    },
    getVendorIdByPartyId(partyId) {
      this.$store
        .dispatch('leaseSchedule/getVendorIdByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            this.gridData[this.gridIndex].lease_vendor_id = response.data.data;
          }
        });
    },
    createRental(index) {
      const payload = {
        lease_inv_sch: [
          {
            entity_id: this.gridData[index]
              .lease_investor_agreement_escalation_id,
            entity_type: 'SRENTAL',
            lease_id: this.gridData[index].lease_investor_agreement_hdr_id
          }
        ]
      };
      this.loader = true;
      this.$store
        .dispatch('leases/createRentalForInvestor', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 201) {
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.getDetails(
              this.gridData[index].lease_investor_agreement_hdr_id
            );
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsgmessage;
        });
    },
    validateCheckBox(row) {
      if (row.request_num) {
        if (!/e/i.test(row.escalation_status)) {
          return true;
        } else {
          return false;
        }
      } else if (!row.sac) {
        return true;
      } else {
        return false;
      }
    },
    setDate(dateRange, index) {
      this.gridData[index].period_from_to = commonHelper.setFutureDate(
        dateRange
      );
    },
    getTenantRental(index) {
      const payload = {
        tabName: 'rental-details',
        leaseTenantHdrId: this.leaseForm.lease_tenant_agreement_hdr_id,
        lease_rental_start_date: commonHelper.formattedDate(
          this.gridData[index].period_from_to[0]
        ),
        lease_rental_end_date: commonHelper.formattedDate(
          this.gridData[index].period_from_to[1]
        )
      };
      this.loader = true;
      this.$store
        .dispatch('leases/getTenantRentalForInvestor', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            if (resp.data.data.length) {
              this.createRental(index);
            } else {
              alert('Generate tenant rental before create investor rental!');
            }
          } else {
            alert('Failed to load tenant rental data!');
          }
        })
        .catch(() => {
          this.loader = false;
          alert('Failed to load tenant rental data!');
        });
    },
    showHideCodeCombinationModal(flag, name, index) {
      this.segmentIndex = index;
      this.accountType = name;
      if (flag) {
        if (!this.leaseForm.le_id) {
          return alert(appStrings.DEPENDENTVSETMSG.LEMSG);
        }
        if (!this.leaseForm.lease_prj_id) {
          return alert(appStrings.DEPENDENTVSETMSG.PROJECTMSG);
        }
        this.combinationDetails.le_id = this.leaseForm.le_id;
        this.combinationDetails.project_code = this.leaseForm.lease_prj_id;
      }
      this.showCodeCombinationModal = flag;
    },
    
    selectedSegment(item) {
      if (this.accountType === 'charge') {
      this.gridData[this.segmentIndex].charge_ac_ccid = item.ccid;
      this.gridData[this.segmentIndex].charge_ac_ccid_dtl = item.segment_code;
      this.gridData[this.segmentIndex].charge_ac_ccid_meaning = item.segment_meaning;
      }
      this.showHideCodeCombinationModal(false);
    },
   
    clearVsetValues(segment, index) {
      if (segment === 'ESCALATION_TYPE_VSET') {
        this.gridData[index].escalation_type_vset = null;
        this.gridData[index].escalation_type_vset_meaning = null;
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.setCustCode);
    this.eventBus.$off('escalation');
  }
};
