import DatePicker from 'vue2-datepicker';
import party from '@/app/components/generalLedger/party';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import Currency from '../../../../../../admin/currency';
import Employee from '../../../../../../admin/employee';

export default {
  name: 'brokerInfo',
  watch: {
    currentPage: function() {
      this.getBrokerHdrDetails();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getBrokerHdrDetails();
    }
  },
  props: ['editMode', 'masterAgreementApprovalStatus', 'brokerageData'],
  components: {
    DatePicker,
    party,
    Currency,
    Employee
  },
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      gridData: [
        {
          brokerage_hdr_id: 0,
          brokerage_amount: null,
          recoverable_amount: null,
          currency_id: null,
          currency: null,
          exchange_rate: 1,
          lease_master_agreement_hdr_id: null,
          comments: null,
          broker_details: [
            {
              request_id: null,
              exchange_rate: 1,
              status: null,
              status_meaning: 'UNPROCESSED',
              vendor_site: null,
              vendor_site_id: null,
              currency_id: null,
              currency: null,
              vendor: null,
              vendor_id: null,
              brokerage_hdr_id: null,
              brokerage_dtl_id: 0,
              brokerage_amount: null,
              parent_request_id: null,
              assigned_by: null,
              assigned_id: null
            }
          ]
        }
      ],
      gridFields: [
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        },
        {
          key: 'details',
          stickyColumn: true
        },
        {
          key: 'brokerage_amount'
        },
        {
          key: 'recoverable_amount'
        },
        {
          key: 'currency'
        },
        {
          key: 'exchange_rate'
        },
        {
          key: 'comments'
        }
      ],
      broker_details_fields: [
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        },
        {
          key: 'vendor',
          label: 'Vendor/Broker'
        },
        {
          key: 'vendor_site',
          label: 'Vendor/Broker Site'
        },
        {
          key: 'brokerage_amount'
        },
        {
          key: 'currency'
        },
        {
          key: 'exchange_rate'
        },
        {
          key: 'status_meaning',
          label: 'Status'
        },
        {
          key: 'assigned_by'
        }
      ],
      brokerageHdrId: null,
      openCurrencyModal: false,
      showPartyModal: false,
      showCustomerSiteModal: false,
      gridDataSite: [],
      gridFieldsSite: [
        {
          key: 'site_name'
        },
        {
          key: 'site_type'
        },
        {
          key: 'bill_to_address',
          label: 'Site Address'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'gstn',
          label: 'GSTN'
        }
      ],
      leaseMstrHdrId: null,
      showEmployeeModal: false
    };
  },
  mounted() {
    this.eventBus.$on('brokerInfo', leaseMasterAgreementHdrId => {
      this.leaseMstrHdrId = leaseMasterAgreementHdrId;
      if (leaseMasterAgreementHdrId) {
        this.getBrokerHdrDetails();
      }
    });
    this.eventBus.$on('getCurrency', getCurrency => {
      if (this.value === 'hdr') {
        this.gridData[this.hdrIndex].currency = getCurrency.currency_code;
        this.gridData[this.hdrIndex].currency_id = getCurrency.currency_id;
      } else if (this.value === 'dtl') {
        this.gridData[this.hdrIndex].broker_details[this.dtlIndex].currency =
          getCurrency.currency_code;
        this.gridData[this.hdrIndex].broker_details[this.dtlIndex].currency_id =
          getCurrency.currency_id;
      }
      this.openCurrencyModal = false;
    });
  },
  methods: {
    addEditBrokerDtlDetails(item, index) {
      const findBrokerHdrId = this.gridData[index].brokerage_hdr_id;
      if (findBrokerHdrId > 0) {
        const filterData = this.gridData[index].broker_details.filter(data => {
          if (data.vendor_id !== null) {
            return data;
          } else {
            alert('please select vendor');
          }
        });
        const brokerDtlDataArray = filterData.map(data => {
          return {
            brokerage_dtl_id: data.brokerage_dtl_id,
            vendor_id: data.vendor_id,
            vendor_site_id: data.vendor_site_id,
            brokerage_amount: parseFloat(
              data.brokerage_amount.replaceAll(',', '')
            ),
            currency_id: data.currency_id,
            exchange_rate: data.exchange_rate,
            status: data.status,
            request_id: data.request_id,
            parent_request_id: data.parent_request_id,
            assigned_id: data.assigned_id
          };
        });
        if (brokerDtlDataArray.length) {
          const payload = {
            brokerage_hdr_id: item.brokerage_hdr_id,
            brokerage_dtl: brokerDtlDataArray ? brokerDtlDataArray : []
          };
          this.$store
            .dispatch('leases/addEditBrokerDtlDetails', payload)
            .then(response => {
              if (response.status === 200) {
                this.getBrokerHdrDetails();
                this.activeTabList = [];
                this.editMode = false;
                this.showAlert = true;
                this.isSuccess = true;
                this.responseMsg = response.data.message;
              } else {
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg = response.response.data.message;
              }
            })
            .catch(() => {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = appStrings.autoFailedMsg;
            });
        }
      } else {
        alert('Please Fill Header First');
      }
    },
    showHidePartyModal(flag, hdrIndex, dtlIndex) {
      this.hdrIndex = hdrIndex;
      this.dtlIndex = dtlIndex;
      this.showPartyModal = flag;
      setTimeout(() => {
        this.eventBus.$emit('userType', 'VEND');
      }, 0);
    },
    getSelectedPartyCustomer(item) {
      this.gridData[this.hdrIndex].broker_details[this.dtlIndex].vendor =
        item.party_name;
      this.showPartyModal = false;
      this.getVendorIdByPartyId(item.party_id, this.hdrIndex, this.dtlIndex);
    },
    getVendorIdByPartyId(partyId, hdrIndex, dtlIndex) {
      this.$store
        .dispatch('leaseSchedule/getVendorIdByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            this.vendorId = response.data.data;
            this.gridData[hdrIndex].broker_details[dtlIndex].vendor_id =
              response.data.data;
            this.getVendorSites(hdrIndex, dtlIndex);
          }
        });
    },
    getVendorSites(hdrIndex, dtlIndex) {
      this.$store
        .dispatch('leaseSchedule/getVendorSiteByVendorId', this.vendorId)
        .then(response => {
          this.gridDataSite = response.data.data.vendor_sites;
          this.hdrIndex = hdrIndex;
          this.dtlIndex = dtlIndex;
        });
    },
    showHideCustomerSiteModal(flag, hdrIndex, dtlIndex) {
      if (flag) {
        if (this.gridData[hdrIndex].broker_details[dtlIndex].vendor) {
          this.showCustomerSiteModal = flag;
        } else {
          alert('Please Select Vendor First');
        }
      }
    },
    selectedSite(item) {
      this.gridData[this.hdrIndex].broker_details[this.dtlIndex].vendor_site =
        item.site_name;
      this.gridData[this.hdrIndex].broker_details[
        this.dtlIndex
      ].vendor_site_id = item.vendor_site_id;
      this.showCustomerSiteModal = false;
    },
    openCloseCurrency(flag, hdrIndex, value, dtlIndex) {
      this.value = value;
      this.hdrIndex = hdrIndex;
      this.dtlIndex = dtlIndex;
      this.$store.dispatch('party/setUnsetPartyId', 1);
      this.openCurrencyModal = flag;
    },
    showBrokerDetails(index, item) {
      this.existedDetailIndex = index;
      // this.brokerageHdrId = item.item.brokerage_hdr_id;
      item.toggleDetails();
      // if (!item.detailsShowing) {
      //   this.getBrokerDtlDetails(this.brokerageHdrId);
      // }
    },
    getBrokerHdrDetails() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        lease_master_agreement_hdr_id: this.leaseMstrHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('leases/getBrokerHdrDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.gridData = result;
            if (result.length > 0) {
              this.gridData.forEach(key => {
                this.getBrokerDtlDetails(key.brokerage_hdr_id, key);
              });
            } else {
              this.gridData = [
                {
                  brokerage_hdr_id: 0,
                  brokerage_amount: null,
                  recoverable_amount: null,
                  currency_id: null,
                  currency: null,
                  exchange_rate: 1,
                  lease_master_agreement_hdr_id: null,
                  comments: null
                }
              ];
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getBrokerDtlDetails(brokerageHdrId, item) {
      const payload = {
        brokerage_hdr_id: brokerageHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('leases/getBrokerDtlDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            if (result.length > 0) {
              item.broker_details = result;
            } else {
              item.broker_details = [
                {
                  request_id: null,
                  exchange_rate: 1,
                  status: 'U',
                  status_meaning: 'UNPROCESSED',
                  vendor_site: null,
                  vendor_site_id: null,
                  currency_id: null,
                  currency: null,
                  vendor: null,
                  vendor_id: null,
                  brokerage_hdr_id: null,
                  brokerage_dtl_id: 0,
                  brokerage_amount: null,
                  parent_request_id: null,
                  assigned_by: null,
                  assigned_id: null
                }
              ];
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addBrokerRow() {
      if (this.editMode) {
        this.gridData.push({
          brokerage_hdr_id: 0,
          brokerage_amount: null,
          recoverable_amount: null,
          currency_id: null,
          currency: null,
          exchange_rate: 1,
          lease_master_agreement_hdr_id: null,
          comments: null
        });
      }
    },
    removeBrokerRow(index) {
      if (this.editMode) {
        this.gridData.splice(index, 1);
      }
    },
    addBrokerDtlRow(hdrIndex) {
      this.gridData[hdrIndex].broker_details.push({
        request_id: null,
        exchange_rate: 1,
        status: 'U',
        status_meaning: 'UNPROCESSED',
        currency_id: null,
        vendor_site_id: null,
        currency: null,
        vendor_site: null,
        vendor: null,
        vendor_id: null,
        brokerage_hdr_id: null,
        brokerage_dtl_id: 0,
        brokerage_amount: null,
        parent_request_id: null,
        assigned_by: null,
        assigned_id: null
      });
      this.gridData = JSON.parse(JSON.stringify(this.gridData));
    },
    removeBrokerDtlRow(dtlIndex, hdrIndex) {
      this.gridData[hdrIndex].broker_details.splice(dtlIndex, 1);
    },
    showHideEmpModal(flag, hdrIndex, dtlIndex) {
      this.showEmployeeModal = flag;
      this.hdrIndex = hdrIndex;
      this.dtlIndex = dtlIndex;
    },
    selectedEmployee(item) {
      this.gridData[this.hdrIndex].broker_details[this.dtlIndex].assigned_by =
        item.name;
      this.gridData[this.hdrIndex].broker_details[this.dtlIndex].assigned_id =
        item.employee_id;
      this.showEmployeeModal = false;
    }
  },
  beforeDestroy() {
    this.eventBus.$off('brokerInfo');
    this.eventBus.$off('getCurrency');
    clearTimeout(this.setTimeout);
  }
};
