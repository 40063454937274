import DatePicker from 'vue2-datepicker';
import { ModelSelect } from 'vue-search-select';
import { format } from 'date-fns';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import party from '@/app/components/generalLedger/party';
import currency from '@/app/components/admin/currency';
import securityDeposit from './securityDeposit';
import escalation from './escalation';
import specification from './specification';
import terminationNotice from './ternimationNotice';
import rentalDetails from './rentalDetails';
import VueCkeditor from 'vue-ckeditor5';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import buildingProject from '../../../masterLease/leaseProject';
import tax from './tax';
import store from '../../../../../../../store';
import bankDetails from './receivingBankDetails';
import downlaodFiles from '@/app/components/onlineApproval/nfaApproval/addNfaApproval/downloadFiles';
import URL_UTILITY from '@/app/utility/url.utility';
import openKmAuthentication from '../../../../../onlineApproval/internalWorkFlow/openKmAuthentication';
import brokerInfo from './brokerInfo';
import terms from './termsCondition';
import rent from './rentFreePeriod';
export default {
  name: 'addEditMasterAgreement',
  props: [
    'leaseMasterAgreementHdrDetails',
    'eventId',
    'wf_inprocess_dtl_id',
    'createCopyWorkflow'
  ],
  components: {
    DatePicker,
    party,
    currency,
    securityDeposit,
    escalation,
    specification,
    terminationNotice,
    rentalDetails,
    'vue-ckeditor': VueCkeditor.component,
    buildingProject,
    tax,
    ModelSelect,
    bankDetails,
    downlaodFiles,
    openKmAuthentication,
    brokerInfo,
    terms,
    rent
  },
  data() {
    return {
      specCompRef: null,
      editors: {
        classic: ClassicEditor
      },
      editorConfig: {
        removePlugins: [
          'CKFinderUploadAdapter',
          'CKFinder',
          'EasyImage',
          'Image',
          'ImageCaption',
          'ImageStyle',
          'ImageToolbar',
          'ImageUpload',
          'MediaEmbed'
        ]
      },
      partyAddressList: [],
      selectedAddress: {
        value: null,
        text: null
      },
      notificationSummary: false,
      scopeOfWork: null,
      copyWorkFlow: null,
      showProjectModal: false,
      showCurrencyModal: false,
      showPartyModal: false,
      unsubscribe: null,
      showModalComponent: false,
      showValueSetModal: false,
      periodType: null,
      editMode: true,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: [],
      parent_value_set_id: null,
      tab: null,
      tempParentData: null,
      version: 0,
      agreementProject: {
        value: null,
        text: null
      },
      unitDisplayName: null,
      tempMasterAgreement_hdr_id: null,
      unitName: null,
      unitId: 0,
      startDate: null,
      endDate: null,
      active: true,
      subunitMapping: true,
      leaseForm: {
        address: null,
        approval_status: 'DRAFT',
        version: 0,
        lease_prj_name: null,
        currency: 'Indian Rupee',
        lease_prj_id: 1,
        party_name: null,
        lease_master_agreement_hdr_id: 0,
        lease_party_address_id: null,
        lease_master_agreement_date: format(new Date(), 'dd-MMM-yyyy'),
        lease_agreement_no: null,
        lease_party_id: null,
        transaction_currency_id: 68,
        notice_period: 0,
        notice_period_uom_vset: null,
        notice_period_uom_vset_meaning: null,
        locking_period: 0,
        locking_period_uom_vset: null,
        locking_period_uom_vset_meaning: null,
        lease_description: null,
        logic_note: null,
        fitout_scope: null,
        receipt_methods_name: null,
        ar_proj_receipt_method_mpng_id: null,
        menu_id: null,
        car_park_open_exc: null,
        car_park_open_nonexc: null,
        car_park_cover_exc: null,
        car_park_cover_nonexc: null,
        car_park_open_exc_price: null,
        car_park_open_nonexc_price: null,
        car_park_cover_exc_price: null,
        car_park_cover_nonexc_price: null,
        car_parking_comments: null
      },
      activeTab: 'specification',
      tabTimeOut: null,
      notificationComments: null,
      partyTypeTimeout: null,
      activeTabList: [],
      approvalHistoryList: [],
      approvalHistoryFields: [
        {
          key: 'approver_name',
          label: 'Name'
        },
        {
          key: 'approval_status',
          label: 'Status'
        },
        {
          key: 'comments',
          label: 'Comments'
        },
        {
          key: 'action_date'
        }
      ],
      isApprovedByCurrrentUser: false,
      isConsultInitiated: false,
      urlSummaryData: null,
      wfDtlId: null,
      fitoutScope: null,
      logicNotes: null,
      showReceivingBankForm: false,
      showBrokerForm: false,
      showBankDetail: false,
      showDownloadModal: false,

      downloadItems: [],
      downloadFields: [
        {
          key: 'original_file_name',
          label: 'File Name'
        },
        {
          key: 'doc_version',
          label: 'Version'
        },
        {
          key: 'file_size',
          label: 'Size'
        },
        {
          key: 'comments'
        },
        {
          key: 'created_by'
        },
        {
          key: 'creation_date'
        }
      ],
      downloadDocUrl: URL_UTILITY.getOpenKmDocDeleteUrl,
      totalRows: null,
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      showOpenKmModal: false,
      valueSetDtlId: null,
      termGridData: [
        {
          lease_master_agreement_hdr_id: 0,
          lease_master_term_con_id: 0,
          active: true,
          term_con_vset: null,
          term_con_vset_meaning: null,
          term_con_type_vset: null,
          term_con_type_vset_meaning: null,
          description: null
        }
      ],
      termGridFields: [
        {
          key: 'active',
          stickyColumn: true
        },
        {
          key: 'term_con_type_vset_meaning',
          label: 'Condition Type'
        },
        {
          key: 'term_con_vset_meaning',
          label: 'Terms Condition'
        },
        {
          key: 'description'
        },
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        }
      ]
    };
  },
  mounted() {
    if (this.leaseMasterAgreementHdrDetails) {
      this.copyWorkFlow = this.createCopyWorkflow;

      this.setLeaseFormData(this.leaseMasterAgreementHdrDetails);
    }
    if (this.$route.params.summaryData) {
      this.urlSummaryData = JSON.parse(
        Buffer.from(this.$route.params.summaryData, 'base64').toString('ascii')
      );
    }
    if (this.urlSummaryData) {
      this.wfDtlId = this.urlSummaryData.wf_inprocess_dtl_id;
    } else {
      this.wfDtlId = this.wf_inprocess_dtl_id;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.responseMsg = [];
          if (
            this.leaseForm.lease_master_agreement_hdr_id === 0 ||
            this.leaseForm.approval_status !== 'INPROCESS'
          ) {
            this.addEditMasterLease();
          } else {
            this.addEditSpecification();
            this.addEditRentalDetails();
            this.addEditEscalation();
            // this.addEditTermNotice();
            this.addEditSecurityDeposit();
            this.addEditTax();
            this.addEditBrokerage();
            this.addEditTermsCondition();
            this.addEditRentFreePeriod();
          }
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', { id: this.unitId });
        }
      }
    });

    if (this.urlSummaryData) {
      this.getLeaseMasterAgrDetails(
        this.urlSummaryData.LEASE_MASTER_AGREEMENT_HDR_ID
      );
      this.wfDtlId = this.urlSummaryData.wf_inprocess_dtl_id;
    }
  },
  methods: {
    addEditMasterLease() {
      const payload = {
        approval_status: this.leaseForm.approval_status, //required
        fitout_scope: this.fitoutScope,
        lease_approval_notes: this.leaseForm.lease_approval_notes,
        lease_description: this.leaseForm.lease_description,
        lease_master_agreement_date: this.leaseForm.lease_master_agreement_date
          ? format(
              new Date(this.leaseForm.lease_master_agreement_date),
              'dd-MMM-yyyy'
            )
          : null, //required
        lease_master_agreement_hdr_id: this.createCopyWorkflow
          ? 0
          : this.leaseForm.lease_master_agreement_hdr_id, //not null 0 for new record
        lease_party_address_id: this.selectedAddress.value, //required
        lease_party_id: this.leaseForm.lease_party_id, //required
        lease_prj_id: this.leaseForm.lease_prj_id, //required
        logic_note: this.logicNotes,
        transaction_currency_id: this.leaseForm.transaction_currency_id, //required
        locking_period: this.leaseForm.locking_period,
        locking_period_uom_vset: this.leaseForm.locking_period_uom_vset,
        notice_period: this.leaseForm.notice_period,
        notice_period_uom_vset: this.leaseForm.notice_period_uom_vset,
        menu_id: store.state.shared.menuId,
        ar_proj_receipt_method_mpng_id: this.leaseForm
          .ar_proj_receipt_method_mpng_id,
        car_park_open_exc: parseFloat(this.leaseForm.car_park_open_exc),
        car_park_open_nonexc: parseFloat(this.leaseForm.car_park_open_nonexc),
        car_park_cover_exc: parseFloat(this.leaseForm.car_park_cover_exc),
        car_park_cover_nonexc: parseFloat(this.leaseForm.car_park_cover_nonexc),

        car_park_open_exc_price: this.leaseForm.car_park_open_exc_price
          ? parseFloat(
              this.leaseForm.car_park_open_exc_price.replaceAll(',', '')
            )
          : null,
        car_park_open_nonexc_price: this.leaseForm.car_park_open_nonexc_price
          ? parseFloat(
              this.leaseForm.car_park_open_nonexc_price.replaceAll(',', '')
            )
          : null,
        car_park_cover_exc_price: this.leaseForm.car_park_cover_exc_price
          ? parseFloat(
              this.leaseForm.car_park_cover_exc_price.replaceAll(',', '')
            )
          : null,
        car_park_cover_nonexc_price: this.leaseForm.car_park_cover_nonexc_price
          ? parseFloat(
              this.leaseForm.car_park_cover_nonexc_price.replaceAll(',', '')
            )
          : null,
        car_parking_comments: this.leaseForm.car_parking_comments
      };
      this.loader = true;
      this.$store
        .dispatch('leases/addEditLeaseMasterAgreementList', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.$emit('updatList');
            this.$emit('rowSelected', response.data.data);
            this.showBankDetail = true;
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.copyWorkFlow = false;
            this.responseMsg.push(`Header Details: ${response.data.message}`);
            this.setLeaseFormData(response.data.data);

            this.leaseForm.lease_master_agreement_hdr_id =
              response.data.data.lease_master_agreement_hdr_id;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg.push(
              `Header Details: ${response.response.data.message}`
            );
          }
        })
        .then(() => {
          if (this.createCopyWorkflow) {
            this.addEditSpecification();
            this.addEditBrokerage();
            this.addEditTermsCondition();
            this.addEditRentFreePeriod();
            this.addEditTax();
            this.addEditEscalation();
            this.addEditSecurityDeposit();
            this.addEditRentalDetails();
          } else {
            this.addEditSpecification();
            this.addEditBrokerage();
            this.addEditTermsCondition();
            this.addEditRentFreePeriod();
            if (this.checkRentalClicked(this.activeTabList) >= 0) {
              this.addEditRentalDetails();
            }
            if (this.checkSecurityDepClicked(this.activeTabList) >= 0) {
              this.addEditSecurityDeposit();
            }
            if (this.checkEscalationClicked(this.activeTabList) >= 0) {
              this.addEditEscalation();
            }
            // this.addEditTermNotice();
            if (this.checkTaxClicked(this.activeTabList) >= 0) {
              this.addEditTax();
            }
            this.activeTabList = [];
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg.push(`Header Details: ${appStrings.autoFailedMsg}`);
        });
    },
    validate() {},
    addEditSpecification() {
      if (this.leaseForm.lease_master_agreement_hdr_id) {
        const specObjRef = this.$refs.specification.gridData.filter(data => {
          if (
            data.lease_master_agreement_spec_vset_code !== null &&
            data.lease_super_area !== null &&
            data.lease_super_area_uom_vset !== null &&
            data.rate_uom_vset !== null
          ) {
            return data;
          }
        });
        const specArray = specObjRef.map(data => {
          return {
            lease_carpet_area: data.lease_carpet_area, //NotNull
            lease_carpet_area_uom_vset: data.lease_carpet_area_uom_vset, //NotNull
            lease_master_agreement_spec_id: this.createCopyWorkflow
              ? 0
              : data.lease_master_agreement_spec_id, //NotNull
            lease_master_agreement_spec_vset_code:
              data.lease_master_agreement_spec_vset_code, //NotNull
            lease_super_area: data.lease_super_area, //NotNull
            lease_super_area_uom_vset: data.lease_super_area_uom_vset, //NotNull
            period_from: data.period_from,
            period_to: data.period_to,
            rate: data.rate, //NotNull
            rate_uom_vset: data.rate_uom_vset,
            active: data.active
          };
        });

        if (specArray && specArray.length > 0) {
          const payload = {
            tabName: 'specification',
            leaseMasterAgreementHdrId: this.leaseForm
              .lease_master_agreement_hdr_id,

            tabData: {
              lease_master_agreement_spec_details: specArray ? specArray : []
            }
          };
          this.$store
            .dispatch('leases/addEditMasterLeaseTabsData', payload)
            .then(response => {
              if (response.status === 200) {
                this.activeTabList = [];
                this.editMode = false;
                this.showAlert = true;
                this.isSuccess = true;
                this.responseMsg.push(
                  `Specification: ${response.data.message}`
                );
              } else {
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg.push(
                  `Specification: ${response.response.data.message}`
                );
              }
            })
            .catch(() => {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg.push(
                `Specification: ${appStrings.autoFailedMsg}`
              );
            });
        }
      }
    },
    addEditRentalDetails() {
      if (this.leaseForm.lease_master_agreement_hdr_id) {
        const objRefrence = this.$refs.rentalDetails.gridData.filter(data => {
          if (data.lease_rental_payment_term_id !== null) {
            return data;
          }
        });
        const tabDataArray = objRefrence.map(data => {
          return {
            lease_master_agreement_rental_id: this.createCopyWorkflow
              ? 0
              : data.lease_master_agreement_rental_id, //NotNull
            lease_rental_buffer_days: data.lease_rental_buffer_days, //NotNull
            lease_rental_payment_term_id: data.lease_rental_payment_term_id, // NotNull
            lease_rental_period: data.lease_rental_period, // NotNull
            lease_rental_period_uom_vset: data.lease_rental_period_uom_vset, //NotNull
            lease_rental_start_from: data.lease_rental_start_from
              ? format(new Date(data.lease_rental_start_from), 'dd-MMM-yyyy')
              : null,
            active: data.active
          };
        });
        if (tabDataArray.length) {
          const payload = {
            tabName: 'rental-details',
            leaseMasterAgreementHdrId: this.leaseForm
              .lease_master_agreement_hdr_id,
            tabData: {
              lease_master_agreement_ren_details: tabDataArray
                ? tabDataArray
                : []
            }
          };
          this.$store
            .dispatch('leases/addEditMasterLeaseTabsData', payload)
            .then(response => {
              if (response.status === 200) {
                this.activeTabList = [];
                this.editMode = false;
                this.showAlert = true;
                this.isSuccess = true;
                this.responseMsg.push(
                  `Rental Details: ${response.data.message}`
                );
              } else {
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg.push(
                  `Rental Details: ${response.response.data.message}`
                );
              }
            })
            .catch(() => {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg.push(
                `Rental Details: ${appStrings.autoFailedMsg}`
              );
            });
        }
      }
    },
    addEditSecurityDeposit() {
      if (this.leaseForm.lease_master_agreement_hdr_id) {
        const objRefrence = this.$refs.securityDeposit.gridData.filter(data => {
          if (
            data.period !== null &&
            data.lease_security_deposit !== null &&
            data.lease_security_deposit_uom_vset !== null
          ) {
            return data;
          }
        });
        const tabDataArray = objRefrence.map(data => {
          return {
            lease_master_agreement_sec_dpt_id: this.createCopyWorkflow
              ? 0
              : data.lease_master_agreement_sec_dpt_id, //NotNull
            period: data.period, //NotNull
            lease_security_deposit: data.lease_security_deposit, //NotNull
            lease_security_deposit_uom_vset:
              data.lease_security_deposit_uom_vset, //NotNull
            active: data.active,
            security_deposit_type_code: data.security_deposit_type_code
          };
        });
        if (tabDataArray.length) {
          const payload = {
            tabName: 'security-deposit',
            leaseMasterAgreementHdrId: this.leaseForm
              .lease_master_agreement_hdr_id,
            tabData: {
              leaseMasterAgreementSecDetails: tabDataArray ? tabDataArray : []
            }
          };
          this.$store
            .dispatch('leases/addEditMasterLeaseTabsData', payload)
            .then(response => {
              if (response.status === 200) {
                this.activeTabList = [];
                this.editMode = false;
                this.showAlert = true;
                this.isSuccess = true;
                this.responseMsg.push(
                  `Security Deposit: ${response.data.message}`
                );
              } else {
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg.push(
                  `Security Deposit: ${response.response.data.message}`
                );
              }
            })
            .catch(() => {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg.push(
                `Security Deposit: ${appStrings.autoFailedMsg}`
              );
            });
        }
      }
    },
    addEditEscalation() {
      if (this.leaseForm.lease_master_agreement_hdr_id) {
        const objRefrence = this.$refs.escalation.gridData.filter(data => {
          if (
            data.lease_escalation !== null &&
            data.lease_escalation_uom_vset !== null
          ) {
            return data;
          }
        });
        const tabDataArray = objRefrence.map(data => {
          return {
            lease_escalation: data.lease_escalation, //NotNull
            lease_escalation_uom_vset: data.lease_escalation_uom_vset, //NotNull
            lease_master_agreement_escalation_id: this.createCopyWorkflow
              ? 0
              : data.lease_master_agreement_escalation_id, //NotNull
            period_from: data.period_from,
            period_to: data.period_to,
            active: data.active,
            period: data.period,
            escalation_type_vset: data.escalation_type_vset
          };
        });
        if (tabDataArray.length) {
          const payload = {
            tabName: 'escalation',
            leaseMasterAgreementHdrId: this.leaseForm
              .lease_master_agreement_hdr_id,
            tabData: {
              lease_master_agreement_esc_details: tabDataArray
                ? tabDataArray
                : []
            }
          };
          this.$store
            .dispatch('leases/addEditMasterLeaseTabsData', payload)
            .then(response => {
              if (response.status === 200) {
                this.activeTabList = [];
                this.editMode = false;
                this.showAlert = true;
                this.isSuccess = true;
                this.responseMsg.push(`Escalation: ${response.data.message}`);
              } else {
                this.showAlert = true;
                this.responseMsg.push(
                  `Escalation: ${response.response.data.message}`
                );
              }
            })
            .catch(() => {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg.push(`Escalation: ${appStrings.autoFailedMsg}`);
            });
        }
      }
    },
    addEditTermNotice() {
      if (this.leaseForm.lease_master_agreement_hdr_id) {
        const objRefrence = this.$refs.terminationNotice.gridData.filter(
          data => {
            if (
              data.lease_termination_period !== null &&
              data.lease_termination_period_uom_vset !== null
            ) {
              return data;
            }
          }
        );
        const tabDataArray = objRefrence.map(data => {
          return {
            lease_master_agreement_termination_id: this.createCopyWorkflow
              ? 0
              : data.lease_master_agreement_termination_id, //NotNull
            lease_termination_period: data.lease_termination_period, //NotNull
            lease_termination_period_uom_vset:
              data.lease_termination_period_uom_vset, //NotNull
            period_from: commonHelper.formattedDate(data.period_from_to[0]),
            period_to: commonHelper.formattedDate(data.period_from_to[1]),
            active: data.active
          };
        });
        if (tabDataArray.length) {
          const payload = {
            tabName: 'termination-notice',
            leaseMasterAgreementHdrId: this.leaseForm
              .lease_master_agreement_hdr_id,
            tabData: {
              lease_master_agreement_ter_details: tabDataArray
                ? tabDataArray
                : []
            }
          };
          this.$store
            .dispatch('leases/addEditMasterLeaseTabsData', payload)
            .then(response => {
              if (response.status === 200) {
                this.activeTabList = [];
                this.editMode = false;
                this.showAlert = true;
                this.isSuccess = true;
                this.responseMsg.push(
                  `Termination Notice: ${response.data.message}`
                );
              } else {
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg.push(
                  `Termination Notice: ${response.response.data.message}`
                );
              }
            })
            .catch(() => {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg.push(
                `Termination Notice: ${appStrings.autoFailedMsg}`
              );
            });
        }
      }
    },
    addEditBrokerage() {
      if (this.leaseForm.lease_master_agreement_hdr_id) {
        const objRefrence = this.$refs.brokerInfo.gridData.filter(data => {
          if (data.currency_id !== null && data.brokerage_amount !== null) {
            return data;
          }
        });
        const brokerDataArray = objRefrence.map(data => {
          return {
            brokerage_hdr_id: this.createCopyWorkflow
              ? 0
              : data.brokerage_hdr_id,
            brokerage_amount: parseFloat(
              data.brokerage_amount.replaceAll(',', '')
            ),
            recoverable_amount: parseFloat(
              data.recoverable_amount.replaceAll(',', '')
            ),
            currency_id: data.currency_id,
            exchange_rate: data.exchange_rate,
            comments: data.comments
          };
        });
        if (brokerDataArray.length) {
          const payload = {
            lease_master_agreement_hdr_id: this.leaseForm
              .lease_master_agreement_hdr_id,
            brokerage_hdr: brokerDataArray ? brokerDataArray : []
          };
          this.$store
            .dispatch('leases/addEditBrokerHdrDetails', payload)
            .then(response => {
              if (response.status === 200) {
                // this.$emit('brokerageData', response.data.data.page);
                this.activeTabList = [];
                this.editMode = false;
                this.showAlert = true;
                this.isSuccess = true;
                this.responseMsg.push(`Broker Info: ${response.data.message}`);
              } else {
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg.push(
                  `Broker Info: ${response.response.data.message}`
                );
              }
            })
            .catch(() => {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg.push(`Broker Info: ${appStrings.autoFailedMsg}`);
            });
        }
      }
    },
    addEditTermsCondition() {
      if (this.leaseForm.lease_master_agreement_hdr_id) {
        const objRefrence = this.$refs.terms.gridData.filter(data => {
          if (data.term_con_vset !== null && data.term_con_type_vset !== null) {
            return data;
          }
        });
        const termsDataArray = objRefrence.map(data => {
          return {
            lease_master_term_con_id: this.createCopyWorkflow
              ? 0
              : data.lease_master_term_con_id,
            active: data.active,
            term_con_vset: data.term_con_vset,
            term_con_type_vset: data.term_con_type_vset,
            description: data.description
          };
        });
        if (termsDataArray.length) {
          const payload = {
            lease_master_agreement_hdr_id: this.leaseForm
              .lease_master_agreement_hdr_id,
            tabData: { terms_con: termsDataArray ? termsDataArray : [] }
          };
          this.$store
            .dispatch('leases/addEditTermsConditionDetails', payload)
            .then(response => {
              if (response.status === 200) {
                this.activeTabList = [];
                this.editMode = false;
                this.showAlert = true;
                this.isSuccess = true;
                this.responseMsg.push(
                  `Terms and Condition: ${response.data.message}`
                );
              } else {
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg.push(
                  `Terms and Condition: ${response.response.data.message}`
                );
              }
            })
            .catch(() => {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg.push(
                `Terms and Condition: ${appStrings.autoFailedMsg}`
              );
            });
        }
      }
    },
    //
    addEditRentFreePeriod() {
      if (this.leaseForm.lease_master_agreement_hdr_id) {
        const objRefrence = this.$refs.rent.gridData.filter(data => {
          if (data.period_from !== null) {
            return data;
          }
        });
        const rentDataArray = objRefrence.map(data => {
          return {
            lease_master_rent_period_id: this.createCopyWorkflow
              ? 0
              : data.lease_master_rent_period_id,
            active: data.active,
            period_from: data.period_from,
            period_to: data.period_to
          };
        });
        if (rentDataArray.length) {
          const payload = {
            lease_master_agreement_hdr_id: this.leaseForm
              .lease_master_agreement_hdr_id,
            tabData: { rent_free_period: rentDataArray ? rentDataArray : [] }
          };
          this.$store
            .dispatch('leases/addEditRentFreeDetails', payload)
            .then(response => {
              if (response.status === 200) {
                this.activeTabList = [];
                this.editMode = false;
                this.showAlert = true;
                this.isSuccess = true;
                this.responseMsg.push(
                  `Rent Free Period: ${response.data.message}`
                );
              } else {
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg.push(
                  `Rent Free Period: ${response.response.data.message}`
                );
              }
            })
            .catch(() => {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg.push(
                `Rent Free Period: ${appStrings.autoFailedMsg}`
              );
            });
        }
      }
    },
    //
    addEditTax() {
      if (this.leaseForm.lease_master_agreement_hdr_id) {
        const objRefrence = this.$refs.tax.gridData.filter(data => {
          if (
            data.tax_cat_id !== null &&
            data.tax_group_id !== null &&
            data.period_from_to !== null
          ) {
            return data;
          } else {
            if (!data.tax_cat_id) {
              this.responseMsg.push(`Tax: Tax Category cannot be blank`);
            }
            if (!data.tax_group_id) {
              this.responseMsg.push(`Tax: Tax Group cannot be blank`);
            }
            if (!data.period_from_to) {
              this.responseMsg.push(`Tax: Period cannot be blank`);
            }
          }
        });
        const tabDataArray = objRefrence.map(data => {
          return {
            lease_master_agreement_tax_id: this.createCopyWorkflow
              ? 0
              : data.lease_master_agreement_tax_id, //NotNull
            tax_cat_id: data.tax_cat_id, // NotNull
            tax_group_id: data.tax_group_id, // NotNull
            period_from: data.period_from,
            period_to: data.period_to,
            active: data.active,
            investor_inter_tax_cat: data.investor_inter_tax_cat,
            investor_intra_tax_cat: data.investor_intra_tax_cat
          };
        });
        if (tabDataArray.length) {
          const payload = {
            tabName: 'tax',
            leaseMasterAgreementHdrId: this.leaseForm
              .lease_master_agreement_hdr_id,
            tabData: {
              lease_master_agreement_tax_details: tabDataArray
                ? tabDataArray
                : []
            }
          };
          this.$store
            .dispatch('leases/addEditMasterLeaseTabsData', payload)
            .then(response => {
              if (response.status === 200) {
                this.activeTabList = [];
                this.editMode = false;
                this.showAlert = true;
                this.isSuccess = true;
                this.responseMsg.push(`Tax: ${response.data.message}`);
              } else {
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg.push(`Tax: ${response.response.data.message}`);
              }
            })
            .catch(() => {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg.push(`Tax: ${appStrings.autoFailedMsg}`);
            });
        }
      }
    },
    setLeaseFormData(leaseMasterAgreementHdrDetail) {
      this.editMode = false;
      this.leaseForm = leaseMasterAgreementHdrDetail;
      this.showBankDetail = true;
      this.selectedAddress.value = this.leaseForm.lease_party_address_id;

      this.leaseForm.lease_agreement_no = this.copyWorkFlow
        ? null
        : this.leaseForm.lease_agreement_no;

      this.leaseForm.approval_status = this.copyWorkFlow
        ? 'DRAFT'
        : this.leaseForm.approval_status;
      this.selectedAddress.text = this.leaseForm.address;

      this.tabTimeOut = setTimeout(() => {
        this.eventBus.$emit(
          this.activeTab,
          leaseMasterAgreementHdrDetail.lease_master_agreement_hdr_id
        );
        this.eventBus.$emit(
          'rd',
          leaseMasterAgreementHdrDetail.lease_master_agreement_hdr_id
        );
        this.eventBus.$emit(
          'sd',
          leaseMasterAgreementHdrDetail.lease_master_agreement_hdr_id
        );
        this.eventBus.$emit(
          'escalation',
          leaseMasterAgreementHdrDetail.lease_master_agreement_hdr_id
        );
        this.eventBus.$emit(
          'tax',
          leaseMasterAgreementHdrDetail.lease_master_agreement_hdr_id
        );
        this.eventBus.$emit(
          'brokerInfo',
          leaseMasterAgreementHdrDetail.lease_master_agreement_hdr_id
        );
        this.eventBus.$emit(
          'rent',
          leaseMasterAgreementHdrDetail.lease_master_agreement_hdr_id
        );
        this.eventBus.$emit(
          'terms',
          leaseMasterAgreementHdrDetail.lease_master_agreement_hdr_id
        );
      }, 0);
      this.getPartyDetails(this.leaseForm.lease_party_id);
      setTimeout(() => {
        this.fitoutScope = this.leaseForm.fitout_scope;
        this.logicNotes = this.leaseForm.logic_note;
      }, 2000);
      if (
        this.$route.name === 'notificationSummary' ||
        this.$route.name === 'dashboard' ||
        this.$route.name === 'workFlowSummary'
      ) {
        this.notificationSummary = true;
        this.getApprovalHistory(this.leaseForm.lease_master_agreement_hdr_id);
      }
    },
    getApprovalHistory(leaseMasterAgreementHdrId) {
      this.loader = true;
      this.$store.dispatch('auth/getUserDetail').then(response => {
        if (response.status === 200) {
          this.userInfo = response.data.data;
          const payload = {
            approvalStatus: 'Y',
            nfaHdrId: leaseMasterAgreementHdrId,
            eventId: this.urlSummaryData
              ? this.urlSummaryData.wf_event_id
              : this.eventId
          };
          this.$store
            .dispatch('intWorkflow/getApprovalList', payload)
            .then(response => {
              this.loader = false;
              if (response.status === 200) {
                this.approvalHistoryList = response.data.data;
                const findCurrentUser = this.approvalHistoryList.find(
                  user =>
                    user.approver_employee_id === this.userInfo.employee_id &&
                    (user.approval_status === 'INPROCESS' ||
                      user.approval_status === 'CONSULT_INITIATED')
                );
                this.isApprovedByCurrrentUser = findCurrentUser ? true : false;
                const findConsultInitUser = this.approvalHistoryList.find(
                  data => data.approval_status === 'CONSULT_INITIATED'
                );
                this.isConsultInitiated = findConsultInitUser ? true : false;
              }
            })
            .catch(() => {
              this.loader = false;
            });
        }
      });
    },
    selectedTab(tab) {
      this.activeTab = tab;
      const tabIndex = this.activeTabList.indexOf(tab);
      if (tabIndex === -1) {
        this.activeTabList.push(this.activeTab);
        this.tabTimeOut = setTimeout(() => {
          this.eventBus.$emit(
            this.activeTab,
            this.leaseForm.lease_master_agreement_hdr_id
          );
        }, 0);
        this.specCompRef = this.$refs.specification.gridData;
      }
    },
    showHidePartyModal(flag) {
      this.showPartyModal = flag;
      this.partyTypeTimeout = setTimeout(() => {
        this.eventBus.$emit('userType', 'CUST');
      }, 0);
    },
    showHideCurrencyModal(flag) {
      this.showCurrencyModal = flag;
    },
    getSelectedCurrency(item) {
      this.leaseForm.transaction_currency_id = item.currency_id;
      this.leaseForm.currency = item.currency_name;
      this.showCurrencyModal = false;
    },
    getSelectedPartyCustomer(item) {
      this.leaseForm.party_name = item.party_name;
      this.leaseForm.lease_party_id = item.party_id;
      this.showPartyModal = false;
      this.getPartyDetails(item.party_id);
    },
    showHideProjectModal(flag) {
      this.showProjectModal = flag;
    },
    getSelectedProject(item) {
      this.leaseForm.lease_prj_id = item.lease_project_id;
      this.leaseForm.lease_prj_name = item.lease_project_name;
      this.showProjectModal = false;
    },
    getPartyDetails(partyId) {
      this.$store
        .dispatch('party/getPartyDetailsById', partyId)
        .then(response => {
          this.loader = false;
          if (response.data.data) {
            const result = response.data.data;
            this.partyAddressList = result.party_addresses.map(data => {
              return {
                value: data.address_id,
                text: data.address
              };
            });
          }
        })
        .catch(error => {
          alert(error.message);
          this.loader = false;
        });
    },
    changeStatus(status) {
      this.loader = true;
      const payload = [
        {
          event_id: this.urlSummaryData
            ? this.urlSummaryData.wf_event_id
            : this.eventId,
          notification_comments: this.notificationComments,
          notification_status: status,
          wf_inprocess_dtl_id: this.wfDtlId,
          temp_approved_amount: 0
        }
      ];
      this.$store
        .dispatch('intWorkflow/approvalReject', { approval_requests: payload })
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.getApprovalHistory(
              this.leaseForm.lease_master_agreement_hdr_id
            );
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg.push(response.data.data.message);
          } else {
            this.isSuccess = false;
            this.responseMsg.push(response.response.data.message);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getLeaseMasterAgrDetails(leaseMasterHdrId) {
      this.loader = true;
      this.$store
        .dispatch('leases/getMasterAgrmentDetails', leaseMasterHdrId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.setLeaseFormData(response.data.data);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    opendDependentUom(vsetCode, periodType) {
      const payload = {
        valueSetName: vsetCode,
        dependentVariable: {
          user_id: this.$store.state.auth.userId,
          res_id: this.$store.state.shared.responsibilityId
        }
      };
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          this.loader = false;
          this.uomClassObj = null;
          if (response.status === 200) {
            const findTimeObj = response.data.data.page.find(
              vsetData => vsetData.value_code === 'TIME'
            );
            if (findTimeObj) {
              this.uomClassObj = findTimeObj;
              this.openValueSetModal('UOM', periodType);
            }
          }
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'UOM') {
        if (this.periodType === 'locking') {
          this.leaseForm.locking_period_uom_vset = item.value_code;
          this.leaseForm.locking_period_uom_vset_meaning = item.value_meaning;
        } else if (this.periodType === 'notice') {
          this.leaseForm.notice_period_uom_vset = item.value_code;
          this.leaseForm.notice_period_uom_vset_meaning = item.value_meaning;
        }
      } else if (
        this.vsetCode ===
        appStrings.VALUESETTYPE.MASTER_LEASE_RECEIVING_BANK_VSET
      ) {
        this.leaseForm.receipt_methods_name = item.value_code;
        this.leaseForm.ar_proj_receipt_method_mpng_id = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.TERM_CON_TYPE_VSET) {
        this.termGridData[this.specIndex].term_con_type_vset = item.value_code;
        this.termGridData[this.specIndex].term_con_type_vset_meaning =
          item.value_meaning;
        this.valueSetDtlId = item.value_set_dtl_id;
        this.termGridData[this.specIndex].term_con_vset = null;
        this.termGridData[this.specIndex].term_con_vset_meaning = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.TERM_CON_VSET) {
        this.termGridData[this.specIndex].term_con_vset = item.value_code;
        this.termGridData[this.specIndex].term_con_vset_meaning =
          item.value_meaning;
      }
      this.showValueSetModal = false;
    },
    openValueSetModal(vsetCode, periodType, index) {
      this.vsetCode = vsetCode;
      this.periodType = periodType;
      this.specIndex = index;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.uomClassObj) {
        this.parent_value_set_id = this.uomClassObj.value_set_dtl_id;
      }
      if (
        this.vsetCode ===
        appStrings.VALUESETTYPE.MASTER_LEASE_RECEIVING_BANK_VSET
      ) {
        this.parent_value_set_id = this.leaseForm.lease_prj_id;
      }
      if (this.vsetCode === appStrings.VALUESETTYPE.TERM_CON_VSET) {
        this.parent_value_set_id = this.valueSetDtlId;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    openReceivingBankDetails() {
      this.showReceivingBankForm = true;
    },
    closeReceivingBankDetails() {
      this.showReceivingBankForm = false;
    },
    showHideBrokerForm(flag) {
      this.showBrokerForm = flag;
    },
    getUploadedDocDtl() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        menu_id: store.state.shared.menuId
          ? store.state.shared.menuId
          : this.urlSummaryData
          ? this.urlSummaryData.wf_menu_id
          : this.leaseForm.menu_id,
        sub_menu_id: 0,
        record_id: this.leaseForm.lease_master_agreement_hdr_id
          ? this.leaseForm.lease_master_agreement_hdr_id
          : this.urlSummaryData.nfa_hdr_id
      };
      this.$store
        .dispatch('intWorkflow/getOpenKmDocDtl', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data.page.length > 0) {
              const docList = response.data.data.page;
              this.downloadItems = docList.map(data => {
                data.file_size = this.formatBytes(data.file_size);
                return data;
              });
            }
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    checkOpenKmPasswordSaved() {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/checkSavedPassword')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data) {
              this.getOpenKmAuthToken();
            } else {
              this.showHideOpenKmModal(true);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getOpenKmAuthToken() {
      const payload = {
        password: null,
        saved_password_flag: true,
        user_name: null
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmAuthenticate', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openkmAuthToken = response.data.data.token;
            this.downloadFiles(true);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideOpenKmModal(flag) {
      this.showOpenKmModal = flag;
    },
    setOpenKmToken(token) {
      this.showHideOpenKmModal(false);
      this.openkmAuthToken = token;
      this.downloadFiles(true);
    },
    downloadFiles(flag) {
      this.showDownloadModal = flag;
      this.getUploadedDocDtl();
    },
    checkTaxClicked(arr) {
      const checkTaxPresent = element => element === 'tax';
      const clicked = arr.findIndex(checkTaxPresent);
      return clicked;
    },
    checkEscalationClicked(arr) {
      const checkEscalationPresent = element => element === 'escalation';
      const clicked = arr.findIndex(checkEscalationPresent);
      return clicked;
    },
    checkSpecClicked(arr) {
      const checkSpecPresent = element => element === 'specification';
      const clicked = arr.findIndex(checkSpecPresent);
      return clicked;
    },
    checkRentalClicked(arr) {
      const checkRentalPresent = element => element === 'rd';
      const clicked = arr.findIndex(checkRentalPresent);
      return clicked;
    },
    checkSecurityDepClicked(arr) {
      const checkSecurityDepPresent = element => element === 'sd';
      const clicked = arr.findIndex(checkSecurityDepPresent);
      return clicked;
    },
    checkBrokerInfoClicked(arr) {
      const checkBrokerInfoPresent = element => element === 'brokerInfo';
      const clicked = arr.findIndex(checkBrokerInfoPresent);
      return clicked;
    },
    checkTermConditionClicked(arr) {
      const checkTermConditionPresent = element => element === 'terms';
      const clicked = arr.findIndex(checkTermConditionPresent);
      return clicked;
    },
    checkFreeRentClicked(arr) {
      const checkFreeRentPresent = element => element === 'rent';
      const clicked = arr.findIndex(checkFreeRentPresent);
      return clicked;
    },
    //
    getTermsConditionDetails() {
      const payload = {
        leaseMasterAgreementHdrId: this.leaseForm.lease_master_agreement_hdr_id
      };
      this.loader = true;
      this.$store
        .dispatch('leases/getTermsConditionDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200 && resp.data.data.length) {
            this.termGridData = resp.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addTermNewRow() {
      if (this.editMode) {
        this.termGridData.push({
          lease_master_agreement_hdr_id: 0,
          lease_master_term_con_id: 0,
          active: true,
          term_con_vset: null,
          term_con_vset_meaning: null,
          term_con_type_vset: null,
          term_con_type_vset_meaning: null,
          description: null
        });
      }
    },
    removeTermRow(index) {
      if (this.editMode) {
        this.termGridData.splice(index, 1);
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.tabTimeOut);
    clearTimeout(this.partyTypeTimeout);
    this.unsubscribe();
  }
};
