import DatePicker from 'vue2-datepicker';
import party from '@/app/components/generalLedger/party';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'rentalDetails',
  components: {
    DatePicker,
    party
  },
  props: ['editMode', 'investorLeaseApprovalStatus'],
  data() {
    return {
      vsetCode: null,
      uomClassObj: null,
      specIndex: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      areaType: null,
      setTimeVsetCode: null,
      gridIndex: null,
      gridData: [
        {
          sac: null,
          active: true,
          lease_investor_agreement_hdr_id: 0,
          request_num: null,
          lease_vendor_site_name: null,
          lease_vendor_name: null,
          lease_vendor_id: null,
          schedule_status: 'DRAFT',
          lease_investor_agreement_rental_id: null,
          lease_rental_period_uom_vset_meaning: null,
          lease_rental_period: null,
          lease_rental_end_date: null,
          lease_rental_buffer_days: null,
          lease_rental_period_uom_vset: null,
          lease_vendor_site_id: null,
          payment_term_name: null,
          lease_rental_start_date: null,
          lease_rental_payment_term_id: null,
          sharing_ratio: null,
        }
      ],
      gridFields: [
        {
          key: 'lease_vendor_name',
          label: 'Vendor Name'
        },
        {
          key: 'lease_vendor_site_name',
          label: 'Vendor Site'
        },
        {
          key: 'sac',
          label: 'SAC Code'
        },
        {
          key: 'sharing_ratio'
        },
        {
          key: 'bill_rate_uom_uset_meaning',
          label: 'UOM'
        },
        {
          key: 'bill_rate',
          label: 'Rate'
        },
        {
          key: 'period_from_to',
          label: 'Period (From - To)'
        },
        {
          key: 'lease_rental_payment_term_name'
        },
        {
          key: 'request_num'
        },
        {
          key: 'schedule_status_meaning',
          label: 'Schedule Status'
        }
      ],
      showCustomerSiteModal: false,
      gridDataSite: [],
      gridFieldsSite: [
        {
          key: 'site_name'
        },
        {
          key: 'site_type'
        }
      ],
      showPartyModal: false
    };
  },
  mounted() {
    this.eventBus.$on('rd', tabData => {
      if (tabData.leaseInvestorHdrId) {
        this.getDetails(tabData.leaseInvestorHdrId);
      }
    });
  },
  methods: {
    getDetails(leaseInvestorHdrId) {
      const payload = {
        tabName: 'rental-details',
        leaseInvestorHdrId: leaseInvestorHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('leases/getLeaseInvestorDataById', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200 && resp.data.data.length) {
            this.gridData = resp.data.data.map(data => {
              data.period_from_to = [
                new Date(data.lease_rental_start_date),
                new Date(data.lease_rental_end_date)
              ];
              return data;
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addNewRow() {
      this.gridData.push({
        customer_site: null,
        schedule_status: null,
        lease_tenant_agreement_hdr_id: null,
        sac: null,
        customer_name: null,
        active: true,
        lease_rental_period_uom_vset: null,
        lease_rental_end_date: null,
        lease_rental_buffer_days: null,
        lease_rental_period: null,
        lease_customer_id: null,
        lease_customer_site_id: null,
        lease_rental_payment_term_id: null,
        lease_rental_start_date: null,
        payment_term_name: null,
        request_num: null,
        sharing_ratio: null,
        lease_tenant_agreement_rental_id: 0,
        lease_rental_period_uom_vset_meaning: null
      });
    },
    removeRow(index) {
      this.gridData.splice(index, 1);
    },
    showHideCustomerSiteModal(flag) {
      this.showCustomerSiteModal = flag;
    },
    getCustomerSiteByPartyId(index) {
      this.gridIndex = index;
      const payload = {
        customerId: this.gridData[index].lease_customer_id,
        lease: {
          site_type_vset: appStrings.siteTypeVset
        }
      };
      this.$store
        .dispatch('party/getCustomerSiteById', payload)
        .then(response => {
          this.gridDataSite = response.data.data.customer_site_lists;
          this.showCustomerSiteModal = true;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedSite(item) {
      this.gridData[this.gridIndex].customer_site = item.site_name;
      this.gridData[this.gridIndex].lease_customer_site_id =
        item.customer_site_id;
      this.showCustomerSiteModal = false;
    },
    showHidePartyModal(flag, index) {
      this.showPartyModal = flag;
      this.gridIndex = index;
    },
    getSelectedPartyCustomer(item) {
      this.gridData[this.gridIndex].lease_vendor_name = item.party_name;
      this.showPartyModal = false;
      this.getPartyVendorDetails(item.party_id);
    },
    getPartyVendorDetails(partyId) {
      this.$store
        .dispatch('party/getPartyVenderSiteList', partyId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.gridData[this.gridIndex].lease_vendor_id =
              response.data.data.vendor_id;
          }
        });
    },
    opendDependentUom(vsetCode, index) {
      const payload = {
        valueSetName: vsetCode,
        dependentVariable: {
          user_id: this.$store.state.auth.userId,
          res_id: this.$store.state.shared.responsibilityId
        }
      };
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          this.loader = false;
          this.uomClassObj = null;
          if (response.status === 200) {
            const findTimeObj = response.data.data.page.find(
              vsetData => vsetData.value_code === 'TIME'
            );
            if (findTimeObj) {
              this.uomClassObj = findTimeObj;
              this.openValueSetModal('UOM', index);
            }
          }
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'UOM') {
        this.gridData[this.specIndex].lease_rental_period_uom_vset =
          item.value_code;
        this.gridData[this.specIndex].lease_rental_period_uom_vset_meaning =
          item.value_meaning;
      } else if (this.vsetCode === 'FMS_PAYMENT_TERM') {
        this.gridData[this.specIndex].lease_rental_payment_term_id =
          item.payment_term_id;
        this.gridData[this.specIndex].lease_rental_payment_term_name =
          item.payment_term_name;
      }
      this.showValueSetModal = false;
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.specIndex = index;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.uomClassObj && vsetCode !== 'FMS_PAYMENT_TERM') {
        this.parent_value_set_id = this.uomClassObj.value_set_dtl_id;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    setDate(dateRange, index) {
      this.gridData[index].period_from_to = commonHelper.setFutureDate(dateRange);
    }
  },
  beforeDestroy() {
    this.eventBus.$off('rd');
  }
};
