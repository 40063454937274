import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';

export default {
  name: 'applicableStatus',
  components: {
    appStrings,
    DatePicker
  },
  props: ['compMappingId', 'billPlanHdrId'],
  data() {
    return {
      loader: false,
      showAddFaLocationModal: false,
      assetLocators: null,
      showOpenModal: false,
      responseMsg: '',
      isSuccess: false,
      applicableStatusData: [],
      applicableStatusFields: [
        {
          key: 'is_applicable',
          label: 'Is Applicable',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'fms_comp_group_name',
          label: 'FMS Component Group'
        },
        {
          key: 'fms_comp_name',
          label: 'FMS Component'
        }
      ],
      showAlert: false,
      selectAllCheckBox: false
    };
  },
  mounted() {
    this.getApplicableStatus();
  },
  methods: {
    getApplicableStatus() {
      this.payload = {
        _page: 0,
        _limit: 200,
        billingPlanHdrId: this.billPlanHdrId,
        billingPlanCompMpngId: this.compMappingId
      };
      this.loader = true;
      this.$store
        .dispatch('masterData/getBillingPlanApplicableStatus', this.payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.applicableStatusData = resp.data.data.page;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectBoxChecked(index, flag) {
      this.applicableStatusData[index].is_applicable = flag;
    },
    selectAllBoxChecked(flag) {
      this.applicableStatusData.map(item => {
        return (item.is_applicable = flag);
      });
    },
    saveStatus() {
      const billing_plan_comp_mpng_dtl = [];
      this.applicableStatusData.map(item => {
        billing_plan_comp_mpng_dtl.push({
          billing_plan_comp_mpng_dtl_id: item.billing_plan_comp_mpng_dtl_id,
          billing_plan_comp_mpng_id: item.billing_plan_comp_mpng_id,
          comp_group_id: item.comp_group_id,
          comp_id: item.comp_id,
          start_date: item.start_date,
          end_date: item.end_date,
          is_applicable: item.is_applicable
        });
      });
      const payload = {
        requestParams: this.compMappingId,
        queryParams: {
          billing_plan_comp_mpng_dtl: billing_plan_comp_mpng_dtl
        }
      };
      this.loader = true;
      this.$store
        .dispatch('masterData/postBillingPlanApplicableStatus', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = resp.data.message;
            this.getApplicableStatus();
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = resp.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    deleteStatus() {
      const toDelete= confirm('Are you sure you want to delete.')
     if(toDelete){
      const payload = {
        requestParams: this.compMappingId,
      };
      this.loader = true;
      this.$store
        .dispatch('masterData/deleteBillingPlanApplicableStatus', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = resp.data.message;
            this.getApplicableStatus();
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = resp.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
     }
    },
    // getSavedApplicableStatus() {
    //   const payload = this.compMappingId;
    //   this.loader = true;
    //   this.$store
    //     .dispatch('masterData/getSavedApplicableStatus', payload)
    //     .then(resp => {
    //       this.loader = false;
    //       if (resp.status === 200) {
    //         this.applicableStatusData = resp.data.data;
    //       }
    //     })
    //     .catch(() => {
    //       this.loader = false;
    //     });
    // },
    beforeDestroy() {}
  }
};
