import commonHelper from '@/app/utility/common.helper.utility';
import URL_UTILITY from '../../../../../utility/url.utility';
import previewDoc from '../../../../onlineApproval/internalWorkFlow/history/uploadDocument/previewDoc';
export default {
  name: 'Documents',
  components: { previewDoc },
  props: [
    'partyRowDetails',
    'urlSummaryData',
    'workflowId',
    'dashboardDetails'
  ],
  data() {
    return {
      loader: false,
      documentsData: [],
      documentsFields: [
        {
          key: 'category'
        },
        {
          key: 'original_file_name',
          label: 'File Name'
        },
        {
          key: 'file_name',
          label: 'Preview'
        },
        {
          key: 'file_size'
        }
      ],
      partyReqId: null,
      wfDtlId: null,
      eventId: null,
      downloadDocUrl: URL_UTILITY.getOpenKmDocDeleteUrl,
      uploadedFileName: null,
      showOpenModal: false,
      docDtlId: null,
      authToken: null,
      openKmAuth: null,
      currentPageIndex: null,
      accessButtons: {
        deleteAllow: false,
        downloadAllow: false,
        uploadAllow: false,
        editAllow: false,
        addAllow: false,
        printAllow: false,
        approvalAllow: false,
        newTab: true
      },
      // previewFileName: null
    };
  },
  mounted() {
    if (this.partyRowDetails) {
      this.partyReqId = this.partyRowDetails.req_id;
      this.wfDtlId = this.partyRowDetails.inprocess_dtl_id;
      this.eventId = this.workflowId.eventId;
    }
    if (this.urlSummaryData) {
      this.partyReqId = this.urlSummaryData.PARTY_REQ_ID;
      this.wfDtlId = this.urlSummaryData.wf_inprocess_dtl_id;
      this.eventId = this.urlSummaryData.wf_event_id;
    }
    if (this.dashboardDetails) {
      this.partyReqId = this.dashboardDetails.party_req_id;
    }
    this.getAllDocumentsById();
  },
  methods: {
    getAllDocumentsById() {
      const payload = {
        party_req_id: this.partyReqId
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getAllDocumentsById', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const docList = resp.data.data;
            this.documentsData = docList.map(data => {
              data.file_size = this.formatBytes(data.file_size);
              return data;
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getOpenKmAuthTokenPreview(flag, docDtlId, fileName) {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmPartyPortalAuthenticate')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            if (this.openKmAuth) {
              // this.partOnboardingPreview(flag, docDtlId, this.openKmAuth, fileName);
              this.showHideOpenModal(flag, docDtlId, this.openKmAuth, fileName);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getOpenKmAuthToken(docDtlId, fileName) {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmPartyPortalAuthenticate')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            const url =
              this.downloadDocUrl +
              '/download/' +
              docDtlId +
              '?token=' +
              this.openKmAuth;
            commonHelper.downloadFile(url, fileName);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    openPreviewDoc(flag, docDtlId, fileName, index) {
      if (flag) {
        this.currentPageIndex = index + 1;
        this.getOpenKmAuthTokenPreview(flag, docDtlId, fileName);
        // this.timeout = setTimeout(() => {
        //   // this.showHideOpenModal(flag, docDtlId, this.openKmAuth, fileName); previos function
        //   this.partOnboardingPreview(flag, docDtlId, this.openKmAuth, fileName);
        // }, 1000);
      }
    },
    partOnboardingPreview(flag, docDtlId, authToken, fileName) {
      if (flag) {
        this.fileExtension = commonHelper.getFileExtension(fileName);
      }
      if (this.fileExtension === 'xlsx') {
        alert('Not Allowed');
      } else {
        // this.showOpenModal = flag;
        this.docDtlId = docDtlId;
        this.authToken = authToken;
        this.uploadedFileName = fileName;
        this.previewOpenDoc();
      }
    },
    previewOpenDoc() {
      this.fileExtension = commonHelper.getFileExtension(this.uploadedFileName);
      const payload = {
        docDetailId: this.docDtlId,
        token: this.authToken,
        fileExtension: this.fileExtension
      };
      this.loader = true;
      commonHelper.previewImgDocParty(payload);
      this.loader = false;
    },
    showHideOpenModal(flag, docDtlId, authToken, fileName) {
      if (flag) {
        this.fileExtension = commonHelper.getFileExtension(fileName);
      }
      if (this.fileExtension === 'xlsx') {
        alert('Not Allowed');
      } else {
        this.showOpenModal = flag;
        // this.previewFileName = null;
        this.docDtlId = docDtlId;
        this.authToken = authToken;
        this.uploadedFileName = fileName;
      }
    }
  },
  // created() {
  //   this.eventBus.$on('changeFileName', ChangeFileName => {
  //     this.previewFileName = ChangeFileName;
  //   });
  // },
  beforeDestroy() {
    clearTimeout(this.timeout);
    // this.eventBus.$off('changeFileName');
  }
};
