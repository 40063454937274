var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loader)?_c('div',{staticClass:"overlay"},[_c('b-spinner',{staticClass:"loader",attrs:{"variant":"primary","label":"Spinning"}})],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('b-alert',{attrs:{"variant":_vm.isSuccess ? 'success' : 'danger',"show":_vm.showAlert,"dismissible":"","fade":""},on:{"dismissed":function($event){_vm.showAlert = false}}},[_vm._v(_vm._s(_vm.responseMsg))])],1),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('label',{staticClass:"text-white"},[_vm._v("Legal Entity")]),_c('b-form-input',{attrs:{"title":_vm.legalEntity.text,"readonly":"","disabled":!_vm.editMode ||
          (_vm.approvalStatus !== 'DRAFT' &&
            _vm.approvalStatus !== 'REJECTED' &&
            _vm.approvalStatusKey !== 'INPROCESS')},on:{"click":function($event){return _vm.showHideCommonModal(true, 'legal_entity')}},model:{value:(_vm.legalEntity.text),callback:function ($$v) {_vm.$set(_vm.legalEntity, "text", $$v)},expression:"legalEntity.text"}}),(_vm.legalEntity.text && _vm.editMode && _vm.approvalStatus !== 'APPROVED')?_c('span',{staticClass:"clearable-icon",attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.clearVsetValues('legal_entity')}}},[_vm._v("×")]):_vm._e()],1),_c('b-col',{attrs:{"md":"3"}},[_c('label',{staticClass:"text-white"},[_vm._v("Currency")]),_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.currency),callback:function ($$v) {_vm.currency=$$v},expression:"currency"}})],1),_c('b-col',{attrs:{"md":"3"}},[_c('label',{staticClass:"text-white"},[_vm._v("Period")]),_c('b-form-input',{attrs:{"title":_vm.period,"readonly":"","disabled":!_vm.editMode ||
          (_vm.approvalStatus !== 'DRAFT' &&
            _vm.approvalStatus !== 'REJECTED' &&
            _vm.approvalStatusKey !== 'INPROCESS')},on:{"click":function($event){return _vm.showHideCommonModal(true, 'period')}},model:{value:(_vm.period),callback:function ($$v) {_vm.period=$$v},expression:"period"}}),(_vm.period && _vm.editMode && _vm.approvalStatus !== 'APPROVED')?_c('span',{staticClass:"clearable-icon",attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.clearVsetValues('period')}}},[_vm._v("×")]):_vm._e()],1),_c('b-col',{attrs:{"md":"3"}},[_c('label',{staticClass:"text-white"},[_vm._v("Batch Name")]),_c('b-form-input',{attrs:{"title":_vm.batchName,"disabled":!_vm.editMode ||
          (_vm.approvalStatus !== 'DRAFT' &&
            _vm.approvalStatus !== 'REJECTED' &&
            _vm.approvalStatusKey !== 'INPROCESS')},on:{"blur":function($event){return _vm.setBatchName(_vm.batchName)}},model:{value:(_vm.batchName),callback:function ($$v) {_vm.batchName=$$v},expression:"batchName"}})],1)],1),_c('b-row',{staticClass:"mt-1 mb-1"},[_c('b-col',{attrs:{"md":"3"}},[_c('label',{staticClass:"text-white"},[_vm._v("Approval Status")]),_c('b-form-input',{attrs:{"title":_vm.approvalStatus,"disabled":""},model:{value:(_vm.approvalStatus),callback:function ($$v) {_vm.approvalStatus=$$v},expression:"approvalStatus"}})],1),(
        _vm.approvalStatus === 'DRAFT' ||
        _vm.approvalStatus === 'REJECTED' ||
        _vm.approvalStatusKey === 'INPROCESS'
      )?_c('b-col',{attrs:{"md":"2"}},[_c('label',{staticClass:"text-white"},[_vm._v("No. of Lines")]),_c('b-form-input',{directives:[{name:"numericOnly",rawName:"v-numericOnly"}],attrs:{"disabled":!_vm.editMode ||
          (_vm.approvalStatus !== 'DRAFT' &&
            _vm.approvalStatus !== 'REJECTED' &&
            _vm.approvalStatusKey !== 'INPROCESS')},on:{"blur":function($event){return _vm.linesCount(_vm.rowCount)}},model:{value:(_vm.rowCount),callback:function ($$v) {_vm.rowCount=$$v},expression:"rowCount"}})],1):_vm._e()],1),_c('b-row',[_c('b-col',{staticClass:"mt-0 text-centre pl-3",attrs:{"md":"12"}},[_c('div',[_c('b-table',{staticClass:"bg-white",attrs:{"hover":"","striped":"","outlined":"","items":_vm.addProvData,"fields":_vm.addProvFields,"show-empty":"","sticky-header":""},scopedSlots:_vm._u([{key:"cell(po_type)",fn:function(data){return [(_vm.editMode)?_c('b-form-select',{attrs:{"options":_vm.options,"disabled":!_vm.editMode ||
                (_vm.approvalStatus !== 'DRAFT' &&
                  _vm.approvalStatus !== 'REJECTED' &&
                  _vm.approvalStatusKey !== 'INPROCESS')},on:{"change":function($event){return _vm.changePoType($event, data.index)}},model:{value:(data.item.po_type),callback:function ($$v) {_vm.$set(data.item, "po_type", $$v)},expression:"data.item.po_type"}}):_c('b-form-input',{attrs:{"title":data.item.po_type,"disabled":""},model:{value:(data.item.po_type),callback:function ($$v) {_vm.$set(data.item, "po_type", $$v)},expression:"data.item.po_type"}})]}},{key:"cell(concatenated_segments)",fn:function(data){return [_c('div',{staticClass:"position-rel"},[_c('b-form-input',{attrs:{"title":data.item.concatenated_segments,"readonly":"","disabled":data.item.po_type == null ||
                !_vm.editMode ||
                (_vm.approvalStatus !== 'DRAFT' &&
                  _vm.approvalStatus !== 'REJECTED' &&
                  _vm.approvalStatusKey !== 'INPROCESS')},on:{"click":function($event){return _vm.showHideCommonModal(true, 'account_code', data.index)}},model:{value:(data.item.concatenated_segments),callback:function ($$v) {_vm.$set(data.item, "concatenated_segments", $$v)},expression:"data.item.concatenated_segments"}}),(
                  data.item.concatenated_segments &&
                  _vm.editMode &&
                  _vm.approvalStatus !== 'APPROVED'
                )?_c('span',{staticClass:"clearable-icon-grid",attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.clearVsetValues('account_code', data.index)}}},[_vm._v("×")]):_vm._e()],1)]}},{key:"cell(state_code)",fn:function(data){return [_c('div',{staticClass:"position-rel"},[_c('b-form-input',{attrs:{"title":data.item.state_code,"readonly":"","disabled":data.item.po_type == 'With PO' ||
                data.item.po_type == null ||
                !_vm.editMode ||
                (_vm.approvalStatus !== 'DRAFT' &&
                  _vm.approvalStatus !== 'REJECTED' &&
                  _vm.approvalStatusKey !== 'INPROCESS')},on:{"click":function($event){return _vm.showHideCommonModal(true, 'state_code', data.index)}},model:{value:(data.item.state_code),callback:function ($$v) {_vm.$set(data.item, "state_code", $$v)},expression:"data.item.state_code"}}),(
                  data.item.state_code &&
                  _vm.editMode &&
                  _vm.approvalStatus !== 'APPROVED'
                )?_c('span',{staticClass:"clearable-icon-grid",attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.clearVsetValues('state_code', data.index)}}},[_vm._v("×")]):_vm._e()],1)]}},{key:"cell(cost_center)",fn:function(data){return [_c('div',{staticClass:"position-rel"},[_c('b-form-input',{attrs:{"title":data.item.cost_center,"readonly":"","disabled":data.item.po_type == 'With PO' ||
                data.item.po_type == null ||
                !_vm.editMode ||
                (_vm.approvalStatus !== 'DRAFT' &&
                  _vm.approvalStatus !== 'REJECTED' &&
                  _vm.approvalStatusKey !== 'INPROCESS')},on:{"click":function($event){return _vm.showHideCommonModal(true, 'cost_center', data.index)}},model:{value:(data.item.cost_center),callback:function ($$v) {_vm.$set(data.item, "cost_center", $$v)},expression:"data.item.cost_center"}}),(
                  data.item.cost_center &&
                  _vm.editMode &&
                  _vm.approvalStatus !== 'APPROVED'
                )?_c('span',{staticClass:"clearable-icon-grid",attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.clearVsetValues('cost_center', data.index)}}},[_vm._v("×")]):_vm._e()],1)]}},{key:"cell(account_num)",fn:function(data){return [_c('div',{staticClass:"position-rel"},[_c('b-form-input',{attrs:{"title":data.item.account_num,"readonly":"","disabled":data.item.po_type == 'With PO' ||
                data.item.po_type == null ||
                !_vm.editMode ||
                (_vm.approvalStatus !== 'DRAFT' &&
                  _vm.approvalStatus !== 'REJECTED' &&
                  _vm.approvalStatusKey !== 'INPROCESS')},on:{"click":function($event){return _vm.showHideCommonModal(true, 'account_num', data.index)}},model:{value:(data.item.account_num),callback:function ($$v) {_vm.$set(data.item, "account_num", $$v)},expression:"data.item.account_num"}}),(
                  data.item.account_num &&
                  _vm.editMode &&
                  _vm.approvalStatus !== 'APPROVED'
                )?_c('span',{staticClass:"clearable-icon-grid",attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.clearVsetValues('account_num', data.index)}}},[_vm._v("×")]):_vm._e()],1)]}},{key:"cell(sub_account_num)",fn:function(data){return [_c('div',{staticClass:"position-rel"},[_c('b-form-input',{attrs:{"title":data.item.sub_account_num,"readonly":"","disabled":data.item.po_type == 'With PO' ||
                data.item.po_type == null ||
                !_vm.editMode ||
                (_vm.approvalStatus !== 'DRAFT' &&
                  _vm.approvalStatus !== 'REJECTED' &&
                  _vm.approvalStatusKey !== 'INPROCESS')},on:{"click":function($event){return _vm.showHideCommonModal(true, 'sub_account_num', data.index)}},model:{value:(data.item.sub_account_num),callback:function ($$v) {_vm.$set(data.item, "sub_account_num", $$v)},expression:"data.item.sub_account_num"}}),(
                  data.item.sub_account_num &&
                  _vm.editMode &&
                  _vm.approvalStatus !== 'APPROVED'
                )?_c('span',{staticClass:"clearable-icon-grid",attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.clearVsetValues('sub_account_num', data.index)}}},[_vm._v("×")]):_vm._e()],1)]}},{key:"cell(company)",fn:function(data){return [_c('b-form-input',{attrs:{"disabled":""},model:{value:(data.item.company),callback:function ($$v) {_vm.$set(data.item, "company", $$v)},expression:"data.item.company"}})]}},{key:"cell(lob)",fn:function(data){return [_c('b-form-input',{attrs:{"disabled":""},model:{value:(data.item.lob),callback:function ($$v) {_vm.$set(data.item, "lob", $$v)},expression:"data.item.lob"}})]}},{key:"cell(department)",fn:function(data){return [_c('b-form-input',{attrs:{"disabled":""},model:{value:(data.item.department),callback:function ($$v) {_vm.$set(data.item, "department", $$v)},expression:"data.item.department"}})]}},{key:"cell(account_desc)",fn:function(data){return [_c('b-form-input',{attrs:{"disabled":""},model:{value:(data.item.account_desc),callback:function ($$v) {_vm.$set(data.item, "account_desc", $$v)},expression:"data.item.account_desc"}})]}},{key:"cell(accounting_date)",fn:function(data){return [_c('date-picker',{attrs:{"format":"DD-MMM-YYYY","valueType":"format","disabled":!_vm.editMode ||
                (_vm.approvalStatus !== 'DRAFT' &&
                  _vm.approvalStatus !== 'REJECTED' &&
                  _vm.approvalStatusKey !== 'INPROCESS')},model:{value:(data.item.accounting_date),callback:function ($$v) {_vm.$set(data.item, "accounting_date", $$v)},expression:"data.item.accounting_date"}})]}},{key:"cell(journal_name)",fn:function(data){return [_c('b-form-input',{attrs:{"disabled":!_vm.editMode ||
                (_vm.approvalStatus !== 'DRAFT' &&
                  _vm.approvalStatus !== 'REJECTED' &&
                  _vm.approvalStatusKey !== 'INPROCESS')},model:{value:(data.item.journal_name),callback:function ($$v) {_vm.$set(data.item, "journal_name", $$v)},expression:"data.item.journal_name"}})]}},{key:"cell(journal_description)",fn:function(data){return [_c('b-form-input',{attrs:{"disabled":!_vm.editMode ||
                (_vm.approvalStatus !== 'DRAFT' &&
                  _vm.approvalStatus !== 'REJECTED' &&
                  _vm.approvalStatusKey !== 'INPROCESS')},model:{value:(data.item.journal_description),callback:function ($$v) {_vm.$set(data.item, "journal_description", $$v)},expression:"data.item.journal_description"}})]}},{key:"cell(debit)",fn:function(data){return [_c('b-form-input',{directives:[{name:"numericOnly",rawName:"v-numericOnly"}],attrs:{"disabled":!_vm.editMode ||
                (_vm.approvalStatus !== 'DRAFT' &&
                  _vm.approvalStatus !== 'REJECTED' &&
                  _vm.approvalStatusKey !== 'INPROCESS')},on:{"input":function($event){data.item.credit = null}},model:{value:(data.item.debit),callback:function ($$v) {_vm.$set(data.item, "debit", $$v)},expression:"data.item.debit"}})]}},{key:"cell(credit)",fn:function(data){return [_c('b-form-input',{directives:[{name:"numericOnly",rawName:"v-numericOnly"}],attrs:{"disabled":!_vm.editMode ||
                (_vm.approvalStatus !== 'DRAFT' &&
                  _vm.approvalStatus !== 'REJECTED' &&
                  _vm.approvalStatusKey !== 'INPROCESS')},on:{"input":function($event){data.item.debit = null}},model:{value:(data.item.credit),callback:function ($$v) {_vm.$set(data.item, "credit", $$v)},expression:"data.item.credit"}})]}},{key:"cell(prov_acc_num)",fn:function(data){return [_c('div',{staticClass:"position-rel"},[_c('b-form-input',{attrs:{"title":data.item.prov_acc_num,"readonly":"","disabled":!_vm.editMode ||
                (_vm.approvalStatus !== 'DRAFT' &&
                  _vm.approvalStatus !== 'REJECTED' &&
                  _vm.approvalStatusKey !== 'INPROCESS')},on:{"click":function($event){return _vm.showHideCommonModal(true, 'provision_account', data.index)}},model:{value:(data.item.prov_acc_num),callback:function ($$v) {_vm.$set(data.item, "prov_acc_num", $$v)},expression:"data.item.prov_acc_num"}}),(
                  data.item.prov_acc_num &&
                  _vm.editMode &&
                  _vm.approvalStatus !== 'APPROVED'
                )?_c('span',{staticClass:"clearable-icon-grid",attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.clearVsetValues('prov_acc_num', data.index)}}},[_vm._v("×")]):_vm._e()],1)]}},{key:"cell(expense_month)",fn:function(data){return [_c('div',{staticClass:"position-rel"},[_c('b-form-input',{attrs:{"readonly":"","disabled":!_vm.editMode ||
                (_vm.approvalStatus !== 'DRAFT' &&
                  _vm.approvalStatus !== 'REJECTED' &&
                  _vm.approvalStatusKey !== 'INPROCESS')},on:{"click":function($event){return _vm.showHideCommonModal(true, 'expenseMonth', data.index)}},model:{value:(data.item.expense_month),callback:function ($$v) {_vm.$set(data.item, "expense_month", $$v)},expression:"data.item.expense_month"}}),(
                  data.item.expense_month &&
                  _vm.editMode &&
                  _vm.approvalStatus !== 'APPROVED'
                )?_c('span',{staticClass:"clearable-icon-grid",attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.clearVsetValues('expense_month', data.index)}}},[_vm._v("×")]):_vm._e()],1)]}},{key:"cell(vendor_name)",fn:function(data){return [_c('div',{staticClass:"position-rel"},[_c('b-form-input',{attrs:{"title":data.item.vendor_name,"readonly":"","disabled":data.item.po_type == null ||
                  !_vm.editMode ||
                  (_vm.approvalStatus !== 'DRAFT' &&
                    _vm.approvalStatus !== 'REJECTED' &&
                    _vm.approvalStatusKey !== 'INPROCESS')},on:{"click":function($event){return _vm.showHideCommonModal(true, 'vendor', data.index)}},model:{value:(data.item.vendor_name),callback:function ($$v) {_vm.$set(data.item, "vendor_name", $$v)},expression:"data.item.vendor_name"}}),(
                  data.item.vendor_name &&
                  _vm.editMode &&
                  _vm.approvalStatus !== 'APPROVED'
                )?_c('span',{staticClass:"clearable-icon-grid",attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.clearVsetValues('vendor', data.index)}}},[_vm._v("×")]):_vm._e()],1)]}},{key:"cell(vendor_code)",fn:function(data){return [_c('b-form-input',{attrs:{"disabled":""},model:{value:(data.item.vendor_code),callback:function ($$v) {_vm.$set(data.item, "vendor_code", $$v)},expression:"data.item.vendor_code"}})]}},{key:"cell(po_number)",fn:function(data){return [_c('div',{staticClass:"position-rel"},[_c('b-form-input',{directives:[{name:"numericOnly",rawName:"v-numericOnly"}],attrs:{"readonly":"","disabled":data.item.po_type == 'Without PO' ||
                  data.item.po_type == null ||
                  !_vm.editMode ||
                  (_vm.approvalStatus !== 'DRAFT' &&
                    _vm.approvalStatus !== 'REJECTED' &&
                    _vm.approvalStatusKey !== 'INPROCESS')},on:{"click":function($event){return _vm.showHideCommonModal(true, 'po_number', data.index)}},model:{value:(data.item.po_number),callback:function ($$v) {_vm.$set(data.item, "po_number", $$v)},expression:"data.item.po_number"}}),(
                  data.item.po_number &&
                  _vm.editMode &&
                  _vm.approvalStatus !== 'APPROVED'
                )?_c('span',{staticClass:"clearable-icon-grid",attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.clearVsetValues('po_number', data.index)}}},[_vm._v("×")]):_vm._e()],1)]}},{key:"cell(po_currency)",fn:function(data){return [_c('b-form-input',{directives:[{name:"numericOnly",rawName:"v-numericOnly"}],attrs:{"readonly":"","disabled":data.item.po_type == 'With PO' ||
                data.item.po_type == null ||
                !_vm.editMode ||
                (_vm.approvalStatus !== 'DRAFT' &&
                  _vm.approvalStatus !== 'REJECTED' &&
                  _vm.approvalStatusKey !== 'INPROCESS')},model:{value:(data.item.po_currency),callback:function ($$v) {_vm.$set(data.item, "po_currency", $$v)},expression:"data.item.po_currency"}})]}},{key:"cell(po_line_num)",fn:function(data){return [_c('b-form-input',{directives:[{name:"numericOnly",rawName:"v-numericOnly"}],attrs:{"readonly":"","disabled":data.item.po_type == 'With PO' ||
                data.item.po_type == null ||
                !_vm.editMode ||
                (_vm.approvalStatus !== 'DRAFT' &&
                  _vm.approvalStatus !== 'REJECTED' &&
                  _vm.approvalStatusKey !== 'INPROCESS')},model:{value:(data.item.po_line_num),callback:function ($$v) {_vm.$set(data.item, "po_line_num", $$v)},expression:"data.item.po_line_num"}})]}},{key:"cell(item_description)",fn:function(data){return [_c('b-form-input',{attrs:{"title":data.item.item_description,"disabled":data.item.po_type == 'With PO' ||
                data.item.po_type == null ||
                !_vm.editMode ||
                (_vm.approvalStatus !== 'DRAFT' &&
                  _vm.approvalStatus !== 'REJECTED' &&
                  _vm.approvalStatusKey !== 'INPROCESS')},model:{value:(data.item.item_description),callback:function ($$v) {_vm.$set(data.item, "item_description", $$v)},expression:"data.item.item_description"}})]}},{key:"cell(exchange_rate)",fn:function(data){return [_c('b-form-input',{directives:[{name:"numericOnly",rawName:"v-numericOnly"}],attrs:{"disabled":data.item.po_type == 'Without PO' ||
                data.item.po_type == null ||
                !_vm.editMode ||
                (_vm.approvalStatus !== 'DRAFT' &&
                  _vm.approvalStatus !== 'REJECTED' &&
                  _vm.approvalStatusKey !== 'INPROCESS')},model:{value:(data.item.exchange_rate),callback:function ($$v) {_vm.$set(data.item, "exchange_rate", $$v)},expression:"data.item.exchange_rate"}})]}},{key:"cell(working_days)",fn:function(data){return [_c('b-form-input',{directives:[{name:"numericOnly",rawName:"v-numericOnly"}],attrs:{"disabled":!_vm.editMode ||
                (_vm.approvalStatus !== 'DRAFT' &&
                  _vm.approvalStatus !== 'REJECTED' &&
                  _vm.approvalStatusKey !== 'INPROCESS')},model:{value:(data.item.working_days),callback:function ($$v) {_vm.$set(data.item, "working_days", $$v)},expression:"data.item.working_days"}})]}},{key:"cell(extra_days)",fn:function(data){return [_c('b-form-input',{directives:[{name:"numericOnly",rawName:"v-numericOnly"}],attrs:{"disabled":!_vm.editMode ||
                (_vm.approvalStatus !== 'DRAFT' &&
                  _vm.approvalStatus !== 'REJECTED' &&
                  _vm.approvalStatusKey !== 'INPROCESS')},model:{value:(data.item.extra_days),callback:function ($$v) {_vm.$set(data.item, "extra_days", $$v)},expression:"data.item.extra_days"}})]}},{key:"cell(leave_encashment)",fn:function(data){return [_c('b-form-input',{directives:[{name:"numericOnly",rawName:"v-numericOnly"}],attrs:{"disabled":!_vm.editMode ||
                (_vm.approvalStatus !== 'DRAFT' &&
                  _vm.approvalStatus !== 'REJECTED' &&
                  _vm.approvalStatusKey !== 'INPROCESS')},model:{value:(data.item.leave_encashment),callback:function ($$v) {_vm.$set(data.item, "leave_encashment", $$v)},expression:"data.item.leave_encashment"}})]}},{key:"cell(mis_4)",fn:function(data){return [_c('b-form-input',{directives:[{name:"numericOnly",rawName:"v-numericOnly"}],attrs:{"disabled":!_vm.editMode ||
                (_vm.approvalStatus !== 'DRAFT' &&
                  _vm.approvalStatus !== 'REJECTED' &&
                  _vm.approvalStatusKey !== 'INPROCESS')},model:{value:(data.item.mis_4),callback:function ($$v) {_vm.$set(data.item, "mis_4", $$v)},expression:"data.item.mis_4"}})]}},{key:"cell(mis_5)",fn:function(data){return [_c('b-form-input',{directives:[{name:"numericOnly",rawName:"v-numericOnly"}],attrs:{"disabled":!_vm.editMode ||
                (_vm.approvalStatus !== 'DRAFT' &&
                  _vm.approvalStatus !== 'REJECTED' &&
                  _vm.approvalStatusKey !== 'INPROCESS')},model:{value:(data.item.mis_5),callback:function ($$v) {_vm.$set(data.item, "mis_5", $$v)},expression:"data.item.mis_5"}})]}},{key:"cell(add)",fn:function(data){return [_c('div',{on:{"click":function($event){return _vm.addNewRow(data.index)}}},[_c('b-img',{staticStyle:{"cursor":"pointer"},attrs:{"src":require('@/assets/imgs/add.png'),"fluid":"","alt":"Vatika image"}})],1)]}},{key:"cell(remove)",fn:function(data){return [_c('div',{on:{"click":function($event){return _vm.removeRow(data.index)}}},[(data.item.prov_hdr_id === 0)?_c('b-img',{staticStyle:{"cursor":"pointer"},attrs:{"src":require('@/assets/imgs/delete.png'),"fluid":"","alt":"Vatika image"}}):_vm._e()],1)]}}])}),_c('b-col',{staticClass:"p-0",attrs:{"md":"3"}},[_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"align":"fill","size":"sm"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)]),_c('b-col',{staticClass:"mt-2"},[(_vm.wfApprovalStatus === 'INPROCESS')?_c('b-button',{staticClass:"mr-3",attrs:{"variant":"success"},on:{"click":function($event){return _vm.showHideApproveRejectModal(true, 'APPROVE')}}},[_vm._v("Approve")]):_vm._e(),(_vm.wfApprovalStatus === 'INPROCESS')?_c('b-button',{staticClass:"mr-3",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.showHideApproveRejectModal(true, 'REJECT')}}},[_vm._v("Reject")]):_vm._e()],1)],1),_c('b-modal',{attrs:{"no-close-on-esc":"","no-close-on-backdrop":"","hide-footer":"","modal-class":_vm.modalType === 'vendor' || _vm.modalType === 'po_number'
        ? ''
        : 'common-modal-width',"size":_vm.modalType === 'vendor' || _vm.modalType === 'po_number' ? 'lg' : 'md',"title":_vm.modalType === 'legal_entity'
        ? 'Legal Entity'
        : _vm.modalType === 'period'
        ? 'Period'
        : _vm.modalType === 'vendor'
        ? 'Vendor'
        : _vm.modalType === 'po_number'
        ? 'PO Number'
        : _vm.modalType === 'po_line_number'
        ? 'PO Line Number'
        : _vm.modalType === 'account_code'
        ? 'Account Code'
        : _vm.modalType === 'state_code'
        ? 'State Code'
        : _vm.modalType === 'cost_center'
        ? 'Cost Center'
        : _vm.modalType === 'account_num'
        ? 'Account No.'
        : _vm.modalType === 'sub_account_num'
        ? 'Sub Account No.'
        : _vm.modalType === 'provision_account'
        ? 'Provision Account'
        : ''},on:{"hidden":function($event){return _vm.showHideCommonModal(false)}},model:{value:(_vm.showCommonModal),callback:function ($$v) {_vm.showCommonModal=$$v},expression:"showCommonModal"}},[_c('commonModalOracle',{attrs:{"modalType":_vm.modalType,"vendorCode":_vm.vendorCode,"poNumber":_vm.poNumber,"stateCode":_vm.stateCode},on:{"mapSelectedVsetData":_vm.mapSelectedVsetData}})],1),_c('b-modal',{attrs:{"no-close-on-backdrop":"","no-close-on-esc":"","hide-footer":"","size":"md","title":_vm.approvRejectType === 'APPROVE'
        ? 'Approve'
        : _vm.approvRejectType === 'REJECT'
        ? 'Reject'
        : ''},on:{"hidden":function($event){return _vm.showHideApproveRejectModal(false)}},model:{value:(_vm.showApproveRejectModal),callback:function ($$v) {_vm.showApproveRejectModal=$$v},expression:"showApproveRejectModal"}},[(_vm.loader)?_c('div',{staticClass:"overlay"},[_c('b-spinner',{staticClass:"loader",attrs:{"variant":"primary","label":"Spinning"}})],1):_vm._e(),(_vm.approvRejectType === 'APPROVE')?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('label',{attrs:{"for":"text"}},[_vm._v("Approval Reason")]),_c('b-form-textarea',{attrs:{"type":"text","rows":"2","title":_vm.approvalReason},model:{value:(_vm.approvalReason),callback:function ($$v) {_vm.approvalReason=$$v},expression:"approvalReason"}})],1),_c('b-col',{staticClass:"mt-2"},[_c('b-button',{attrs:{"variant":"success","disabled":_vm.approvalRemarks == 'Y' && !_vm.approvalReason},on:{"click":function($event){return _vm.approveRejectProvisons('APPROVED')}}},[_vm._v("Approve")])],1)],1):_vm._e(),(_vm.approvRejectType === 'REJECT')?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('label',{staticClass:"mandatory",attrs:{"for":"text"}},[_vm._v("Rejected Reason")]),_c('b-form-textarea',{attrs:{"type":"text","rows":"2","title":_vm.rejectedReason},model:{value:(_vm.rejectedReason),callback:function ($$v) {_vm.rejectedReason=$$v},expression:"rejectedReason"}})],1),_c('b-col',{staticClass:"mt-2"},[_c('b-button',{attrs:{"variant":"danger","disabled":_vm.rejectionRemarks == 'Y' && !_vm.rejectedReason},on:{"click":function($event){return _vm.approveRejectProvisons('REJECTED')}}},[_vm._v("Reject")])],1)],1):_vm._e()],1),(_vm.showValueSetModal)?_c('valueSetModal',{attrs:{"parent_value_set_id":_vm.parent_value_set_id},on:{"closeValueSetModal":_vm.closeValueSetModal,"selectedvalueSet":_vm.selectedvalueSet}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }