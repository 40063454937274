export default {
  name: 'Category',
  watch: {},
  components: {},
  data() {
    return {
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      editMode: false,
      vendorCategoryData: [
        { active: false, category: null, ven_cat_dtl_id: 0 }
      ],
      vendorCategoryFields: [
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        },
        {
          key: 'active',
          stickyColumn: true
        },
        {
          key: 'category'
        }
      ],
      partyReqId: null,
      wfDtlId: null,
      eventId: null,
      approvalStatus: null,
      approvalStatusKey: null,
      // total: null,
      totalScore: null,
      rating: null,
      userId: null
    };
  },
  mounted() {
    // if (this.partyRowDetails) {
    //   this.partyReqId = this.partyRowDetails.req_id;
    //   this.wfDtlId = this.partyRowDetails.inprocess_dtl_id;
    //   this.eventId = this.workflowId.eventId;
    //   this.userId = this.workflowId.userId;
    //   // this.approvalStatus = this.partyRowDetails.approval_status;
    // }
    // if (this.urlSummaryData) {
    //   this.partyReqId = this.urlSummaryData.PARTY_REQ_ID;
    //   this.wfDtlId = this.urlSummaryData.wf_inprocess_dtl_id;
    //   this.eventId = this.urlSummaryData.wf_event_id;
    //   this.userId = this.urlSummaryData.wf_user_id;
    //   // this.approvalStatus = this.urlSummaryData.APPROVAL_STATUS;
    // }
    // if (this.dashboardDetails) {
    //   this.partyReqId = this.dashboardDetails.party_req_id;
    //   this.userId = this.dashboardDetails.wf_user_id;
    // }
    // this.getVendorEvaluationDetails();
    // this.getPartyOnBoardingDetailsById();
  },
  methods: {
    editModeChange() {
      this.editMode = true;
    },
    addNewRow() {
      if (this.editMode) {
        this.vendorCategoryData.push({
          ven_cat_dtl_id: 0,
          active: false,
          category: null
        });
      }
    },
    removeRow(index) {
      this.vendorCategoryData.splice(index, 1);
    }
  }
};
