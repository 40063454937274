import DatePicker from 'vue2-datepicker';
export default {
	name: 'specification',
	components: {
		DatePicker
	},
	data() {
		return {
			securityDepositList: [
				{
					spec_type: null,
					uom: null,
					super_area: null,
					carpet_area: null,
					from_date: null,
					to_date: null
				}
			],
			securityDepositFields: [
				{
					key: 'spec_type'
				},
				{
					key: 'uom',
					label: 'UOM'
				},
				{
					key: 'super_area'
				},
				{
					key: 'carpet_area'
				},
				{
					key: 'from_date'
				},
				{
					key: 'to_date'
				},
				{
					key: 'add'
				},
				{
					key: 'remove'
				}
			]
		}
	},
	mounted() { },
	methods: {
		addNewRow() {
			this.securityDepositList.push({
				spec_type: null,
				uom: null,
				super_area: null,
				carpet_area: null,
				from_date: null,
				to_date: null
			});
		},
		removeRow(index) {
			this.securityDepositList.splice(index, 1);
		},
	}
}