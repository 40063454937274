import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import addEditMasterAgreement from './addEditMasterAgreement';
import HistoryRecord from '@/app/components/onlineApproval/internalWorkFlow/history/historyRecord';
import ApprovalHistory from '@/app/components/onlineApproval/internalWorkFlow/history/approvalHistory';
import UploadDocument from '@/app/components/onlineApproval/internalWorkFlow/history/uploadDocument';
import sendForApproval from '@/app/components/onlineApproval/internalWorkFlow/history/sendForApproval';
import openKmAuthentication from '@/app/components/onlineApproval/internalWorkFlow/openKmAuthentication';
export default {
  name: 'masterAgreement',
  watch: {
    currentPage: function() {
      this.getMasterAgreementList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getMasterAgreementList();
    }
  },
  components: {
    commonHelper,
    addEditMasterAgreement,
    HistoryRecord,
    ApprovalHistory,
    UploadDocument,
    sendForApproval,
    openKmAuthentication
  },
  props: ['showMasterLeaseModal'],
  data() {
    return {
      payload: null,
      uploadDocData: null,
      openkmAuthToken: null,
      showOpenKmModal: false,
      showHistoryBtn: false,
      showHistory: false,
      historyType: null,
      appStatus: {
        value: null,
        text: null
      },
      mapped: false,
      leasePrj: {
        value: null,
        text: null
      },
      leaseTwr: {
        value: null,
        text: null
      },
      leaseFloor: {
        value: null,
        text: null
      },
      partyName: {
        value: null,
        text: null
      },
      unsubscribe: null,
      showAddLeaseUnitModal: false,
      showDetails: false,
      showValueSetModal: false,
      parent_value_set_id: null,
      unitName: null,
      loader: false,
      unitDisplay: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      leaseUnitRowDetails: null,
      agreementNumber: null,
      leaseMasterAgreementHdrDetails: null,
      gridData: [],
      masterLeaseHdrId: null,
      createCopyWorkflow: false,
      gridFields: [
        {
          key: 'select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'lease_master_agreement_date',
          label: 'Agreement Date'
        },
        {
          key: 'lease_agreement_no',
          label: 'MLA No.'
        },
        {
          key: 'lease_prj_name',
          label: 'Lease Project'
        },
        {
          key: 'lease_party_id',
          label: 'Party Id'
        },
        {
          key: 'party_name'
        },
        {
          key: 'currency'
        },
        {
          key: 'approval_status'
        },
        {
          key: 'lease_description'
        }
      ],
      selectedGridData: null
    };
  },
  mounted() {
    if (this.showMasterLeaseModal) {
      this.getMasterAgreementList();
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showAddLeaseUnitModal = true;
          this.leaseMasterAgreementHdrDetails = null;
          this.showHistoryBtn = false;
          this.masterLeaseHdrId = null;
          this.createCopyWorkflow = false;
        }
        if (
          actionName === 'download' &&
          !this.showAddLeaseUnitModal &&
          !this.showOpenKmModal
        ) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'leases/getLeaseMasterAgreementList',
            'master-agreement',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getMasterAgreementList() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        lease_prj_id: this.leasePrj.value,
        lease_agreement_no: this.agreementNumber,
        approval_status: this.showMasterLeaseModal
          ? 'APPROVED'
          : this.appStatus.value,
        mapped: this.mapped,
        party_id: this.partyName.value
      };
      this.loader = true;
      this.$store
        .dispatch('leases/getLeaseMasterAgreementList', this.payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.gridData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clearSearch() {
      this.agreementNumber = null;
      this.leasePrj = {
        value: null,
        text: null
      };
      this.appStatus = {
        value: null,
        text: null
      };
      this.partyName = {
        value: null,
        text: null
      };
      this.mapped = false;
      this.showHistoryBtn = false;
      this.gridData = [];
      this.totalRows = null;
      this.currentPage = 1;
    },
    hideAddLeaseModal() {
      this.showAddLeaseUnitModal = false;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.leasePrj = {
          value: item.value_set_dtl_id,
          text: item.lease_prj_name
        };
      } else if (this.vsetCode === 'APPROVAL_STATUS') {
        this.appStatus = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (this.vsetCode === 'LEASE_PARTY_MLA') {
        this.partyName = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    rowSelected(item) {
      if (this.showMasterLeaseModal) {
        this.$emit('selectedLeaseMaster', item);
      } else {
        this.leaseMasterAgreementHdrDetails = Object.assign({}, item);
        this.showAddLeaseUnitModal = true;
        this.masterLeaseHdrId = item.lease_master_agreement_hdr_id;
        this.createCopyWorkflow = false;
       
      }
    },
    async getMasterDetails() {
      if (this.checkMasterLeaseHdrId()) {
        const escalationPromise = await commonHelper.getMasterAgreementDetails(
          this.masterLeaseHdrId,
          'escalation'
        );
        const taxPromise = await commonHelper.getMasterAgreementDetails(
          this.masterLeaseHdrId,
          'tax'
        );
        Promise.all([escalationPromise, taxPromise])
          .then(values => {
            if (values[0].status === 200 && values[1].status === 200) {
              const escalationData = values[0].data.data;
              const taxData = values[1].data.data;
              if (escalationData && taxData) {
                if (escalationData.length > 0 && taxData.length > 0) {
                  this.isDocumentUploadedFunc();
                  return true;
                } else {
                  alert(
                    'Please enter data in Escalation & Tax before send for approval!'
                  );
                }
              }
            } else if (values[0].status !== 200 && values[1].status !== 200) {
              alert('Unable to fetch Escalation & Tax Data');
            } else {
              if (values[0].status !== 200) {
                alert('Unable to fetch Escalation Data');
              }
              if (values[1].status !== 200) {
                alert('Unable to fetch Tax Data');
              }
            }
            return false;
          })
          .catch(err => {
            alert(err);
          });
      }
    },
    closeLeaseUnitDetail() {
      this.showDetails = false;
    },
    updateLeaseUnitList() {
      this.leaseUnitData = [];
    },
    selectedLease(item) {
      this.leaseMasterAgreementHdrDetails = Object.assign({}, item);
      this.showHistoryBtn = true;
      this.masterLeaseHdrId = item.lease_master_agreement_hdr_id;
    },
    showHideHistory(flag, historyType) {
      if (this.checkMasterLeaseHdrId()) {
        this.showHistory = flag;
        this.historyType = historyType;
      }
    },
    checkOpenKmPasswordSaved() {
      if (this.checkMasterLeaseHdrId()) {
        this.loader = true;
        this.$store
          .dispatch('intWorkflow/checkSavedPassword')
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              if (response.data.data) {
                this.getOpenKmAuthToken();
              } else {
                this.showHideOpenKmModal(true);
              }
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    getOpenKmAuthToken() {
      const payload = {
        password: null,
        saved_password_flag: true,
        user_name: null
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmAuthenticate', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openkmAuthToken = response.data.data.token;
            this.selectedGridData = {
              ...this.leaseMasterAgreementHdrDetails,
              nfa_cat_vset: null,
              nfa_cat: null,
              nfa_sub_cat_vset: null,
              nfa_sub_cat: null
            };
            this.showHideHistory(true, 'uploadDocument');
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideOpenKmModal(flag) {
      this.showOpenKmModal = flag;
    },
    setOpenKmToken(token) {
      this.showHideOpenKmModal(false);
      this.openkmAuthToken = token;
      this.selectedGridData = {
        ...this.leaseMasterAgreementHdrDetails,
        nfa_cat_vset: null,
        nfa_cat: null,
        nfa_sub_cat_vset: null,
        nfa_sub_cat: null
      };
      this.showHideHistory(true, 'uploadDocument');
    },
    checkMasterLeaseHdrId() {
      if (!this.masterLeaseHdrId) {
        alert('Please save the record!');
        return false;
      }
      return true;
    },
    async isDocumentUploadedFunc() {
      const uploadedDocList = await commonHelper.getUploadedDocList(
        this.$store.state.shared.menuId,
        this.masterLeaseHdrId
      );
      const isDocumentUploaded = uploadedDocList.length > 0;
      isDocumentUploaded
        ? this.showHideHistory(true, 'sendForApproval')
        : alert('Please upload document!');
    },
    clearVsetValues(vsetCode){
      switch(vsetCode){
        case 'LEASE_PROJECT_LIST':
          this.leasePrj = {
            text: null,
            value: null
          }
          break;
        case 'APPROVAL_STATUS':
            this.appStatus = {
              text: null,
              value: null
            }
            break;
            case 'LEASE_PARTY_MLA':
            this.partyName = {
              text: null,
              value: null
            }
            break;
      }

    },
    copyWorkflow() {
      const promt = confirm('Are you sure, you want to create copy?');
      if (promt) {
        this.createCopyWorkflow = true;
        this.showAddLeaseUnitModal = true;
       }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
