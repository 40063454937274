import DatePicker from 'vue2-datepicker';
import purchaseReqVset from '../purchaseReqVset';

export default {
  name: 'purReqEntryForm',
  props: ['rowData'],
  watch: {},
  components: {
    DatePicker,
    purchaseReqVset
  },
  data() {
    return {
      loader: false,
      editMode: false,
      operatingUnit: {
        text: null,
        value: null
      },
      number: null,
      description: null,
      reqHdrType: {
        text: null,
        value: null
      },
      status: {
        text: null,
        value: null
      },
      preparer: null,
      currency: null,
      totalAmt: null,
      creationDate: null,
      lineDtlsData: [
        {
          num: 1,
          reqLineType: null,
          item: null,
          category: null,
          line_desc: null,
          uom: null,
          price: null,
          need_by_date: null,
          quantity: null,
          charge_acc: null,
          buyer: null,
          req_by: null,
          cost_center: null,
          requester: null,
          requestor_email: null,
          organization: null,
          location: null
        }
      ],
      lineDtlsFields: [
        {
          key: 'add',
          stickyColumn: true,
          class: 'text-center'
        },
        {
          key: 'remove',
          stickyColumn: true,
          class: 'text-center'
        },
        {
          key: 'num',
          label: 'Num',
          class: 'text-center'
        },
        {
          key: 'reqLineType',
          label: 'Line Type',
          class: 'text-center'
        },
        {
          key: 'item',
          label: 'Item',
          class: 'text-center'
        },
        {
          key: 'category',
          label: 'Category',
          class: 'text-center'
        },
        {
          key: 'line_desc',
          label: 'Description',
          class: 'text-center'
        },
        {
          key: 'uom',
          label: 'UOM',
          class: 'text-center'
        },
        {
          key: 'price',
          label: 'Price',
          class: 'text-center'
        },
        {
          key: 'quantity',
          label: 'Quantity',
          class: 'text-center'
        },
        {
          key: 'need_by_date',
          label: 'Need By Dtae',
          class: 'text-center'
        },
        {
          key: 'buyer',
          label: 'Buyer',
          class: 'text-center'
        },
        {
          key: 'charge_acc',
          label: 'Charge Account',
          class: 'text-center'
        },
        {
          key: 'req_by',
          label: 'Requested By',
          class: 'text-center'
        },
        {
          key: 'cost_center',
          label: 'Cost Center',
          class: 'text-center'
        },
        {
          key: 'requester',
          label: 'Requester',
          class: 'text-center'
        },
        {
          key: 'requestor_email',
          label: 'Requester Email',
          class: 'text-center'
        },
        {
          key: 'organization',
          label: 'Organization',
          class: 'text-center'
        },
        {
          key: 'location',
          label: 'Location',
          class: 'text-center'
        }
      ],
      supplierDtls: {
        // source: null,
        supplier: { text: null, value: null },
        site: { text: null, value: null },
        contact: null,
        phone: null
      },
      vsetName: null,
      openModalFormType: 'purReqEntryForm',
      openModalType: null,
      sepctIndex: null,
      showPurReqVsetModal: false,
      reqKeyFilter: {}
    };
  },
  mounted() {
    this.preparer = this.$store.state.auth.userName;
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        // if (
        //   actionName === 'save'
        // ) {
        //   this.saveEwayBill();
        // }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
    if (!this.rowData) {
      this.editMode = true;
    }
  },
  methods: {
    clearVsetValues(vsetCode, index) {
      if (vsetCode === this.operatingUnit.text) {
        this.operatingUnit = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.number) {
        this.number = null;
      } else if (vsetCode === this.description) {
        this.description = null;
      } else if (vsetCode === this.reqHdrType.text) {
        this.reqHdrType = {
          text: null,
          valye: null
        };
      } else if (vsetCode === this.preparer) {
        this.preparer = null;
      } else if (vsetCode === this.status.value) {
        this.status = {
          text: null,
          value: null
        };
      } else if (vsetCode === 'requester') {
        this.lineDtlsData[index].requester = null;
        this.lineDtlsData[index].requestorCode = null;
      } else if (vsetCode === 'buyer') {
        this.lineDtlsData[index].buyer = null;
        this.lineDtlsData[index].buyerCode = null;
      } else if (vsetCode === 'category') {
        this.lineDtlsData[index].category = null;
        this.lineDtlsData[index].categoryCode = null;
      } else if (vsetCode === this.supplierDtls.supplier.text) {
        this.supplierDtls.supplier = {
          text: null,
          value: null
        };
        this.supplierDtls.site = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.supplierDtls.site.text) {
        this.supplierDtls.site = {
          text: null,
          value: null
        };
      } else if (vsetCode === 'costCenter') {
        this.lineDtlsData[index].cost_center = null;
        this.lineDtlsData[index].location = null;
        this.lineDtlsData[index].locationCode = null;
        this.lineDtlsData[index].organization = null;
        this.lineDtlsData[index].orgCode = null;
      } else if (vsetCode === 'organization') {
        this.lineDtlsData[index].organization = null;
        this.lineDtlsData[index].orgCode = null;
      } else if (vsetCode === 'location') {
        this.lineDtlsData[index].location = null;
        this.lineDtlsData[index].locationCode = null;
      } else if (vsetCode === 'reqLineType') {
        this.lineDtlsData[index].reqLineType = null;
        this.lineDtlsData[index].reqLineTypeCode = null;
      }
    },
    addNewRow() {
      this.lineDtlsData.push({
        num: this.lineDtlsData.length + 1,
        reqLineType: null,
        item: null,
        category: null,
        line_desc: null,
        uom: null,
        price: null,
        need_by_date: null,
        quantity: null,
        charge_acc: null,
        buyer: null,
        req_by: null,
        cost_center: null,
        requester: null,
        requestor_email: null,
        organization: null,
        location: null
      });
    },
    openVsetModal(type, index) {
      this.sepctIndex = index;
      if (type === 'operationgUnit') {
        this.vsetName = 'Operating Unit Details';
        this.openModalType = 'operatingUnit';
        this.showHidePurReqVsetModal(true);
      } else if (type === 'requester') {
        this.vsetName = 'Requester Details';
        this.openModalType = 'requester';
        this.showHidePurReqVsetModal(true);
      } else if (type === 'buyer') {
        this.vsetName = 'Buyer Details';
        this.openModalType = 'buyer';
        this.showHidePurReqVsetModal(true);
      } else if (type === 'category') {
        this.vsetName = 'Categories Details';
        this.openModalType = 'category';
        this.showHidePurReqVsetModal(true);
      } else if (type === 'supplier') {
        this.vsetName = 'Supllier Details';
        this.openModalType = 'supplier';
        this.showHidePurReqVsetModal(true);
      } else if (type === 'invItem') {
        this.vsetName = 'Inventory Item Details';
        this.openModalType = 'invItem';
        this.showHidePurReqVsetModal(true);
      } else if (type === 'supplierSite') {
        if (this.supplierDtls.supplier.text) {
          this.vsetName = 'Supplier Site Details';
          this.openModalType = 'supplierSite';
          this.reqKeyFilter.vendorId = this.supplierDtls.supplier.value;
          this.showHidePurReqVsetModal(true);
        } else {
          this.$bvToast.toast('Please select supplier first', {
            title: 'Alert',
            variant: 'danger',
            solid: true
          });
        }
      } else if (type === 'costCenter') {
        this.vsetName = 'Cost Center Details';
        this.openModalType = 'costCenter';
        this.showHidePurReqVsetModal(true);
      } else if (type === 'organization') {
        if (this.lineDtlsData[index].cost_center) {
          this.vsetName = 'Organization Details';
          this.openModalType = 'organization';
          this.reqKeyFilter.cost_center = this.lineDtlsData[index].cost_center;
          this.showHidePurReqVsetModal(true);
        } else {
          this.$bvToast.toast('Please select cost center first', {
            title: 'Alert',
            variant: 'danger',
            solid: true
          });
        }
      } else if (type === 'location') {
        if (this.lineDtlsData[index].cost_center) {
          this.vsetName = 'Location Details';
          this.openModalType = 'location';
          this.reqKeyFilter.cost_center = this.lineDtlsData[index].cost_center;
          this.showHidePurReqVsetModal(true);
        } else {
          this.$bvToast.toast('Please select cost center first', {
            title: 'Alert',
            variant: 'danger',
            solid: true
          });
        }
      } else if (type === 'reqLineType') {
        this.vsetName = 'Requisition Line Type Details';
        this.openModalType = 'reqLineType';
        this.showHidePurReqVsetModal(true);
      } else if (type === 'reqHdrType') {
        this.vsetName = 'Requisition Type Details';
        this.openModalType = 'reqHdrType';
        this.showHidePurReqVsetModal(true);
      }
    },
    showHidePurReqVsetModal(flag) {
      this.showPurReqVsetModal = flag;
    },
    mapSelectedVsetData(item) {
      if (item.name === 'purReqOU') {
        this.operatingUnit = {
          text: item.ou_name,
          value: item.ou_id
        };
      } else if (item.name === 'purRequester') {
        this.lineDtlsData[this.sepctIndex].requester = item.global_name;
        this.lineDtlsData[this.sepctIndex].requestorCode = item.person_id;
        this.lineDtlsData = JSON.parse(JSON.stringify(this.lineDtlsData));
      } else if (item.name === 'purReqBuyer') {
        this.lineDtlsData[this.sepctIndex].buyer = item.global_name;
        this.lineDtlsData[this.sepctIndex].buyerCode = item.person_id;
        this.lineDtlsData = JSON.parse(JSON.stringify(this.lineDtlsData));
      } else if (item.name === 'purReqCategory') {
        this.lineDtlsData[this.sepctIndex].category = item.item_category;
        this.lineDtlsData[this.sepctIndex].categoryCode = item.category_id;
        this.lineDtlsData = JSON.parse(JSON.stringify(this.lineDtlsData));
      } else if (item.name === 'purReSupplier') {
        this.supplierDtls.supplier = {
          text: item.vendor_name,
          value: item.vendor_id
        };
      } else if (item.name === 'purReqSite') {
        this.supplierDtls.site = {
          text: item.Supplier_Site_name,
          value: item.vendor_site_id
        };
      } else if (item.name === 'purReqInvItem') {
        this.lineDtlsData[this.sepctIndex].category = item.item_category;
        this.lineDtlsData[this.sepctIndex].categoryCode = item.category_id;
        this.lineDtlsData[this.sepctIndex].item = item.Inv_Item;
        this.lineDtlsData[this.sepctIndex].uom = item.primary_uom_code;
        this.lineDtlsData[this.sepctIndex].line_desc = item.description;
        this.lineDtlsData = JSON.parse(JSON.stringify(this.lineDtlsData));
      } else if (item.name === 'purReqCostCenter') {
        this.lineDtlsData[this.sepctIndex].cost_center = item.flex_value;
        this.lineDtlsData = JSON.parse(JSON.stringify(this.lineDtlsData));
      } else if (item.name === 'purReqOrg') {
        this.lineDtlsData[this.sepctIndex].organization = item.ORGANIZATION_NAME;
        this.lineDtlsData[this.sepctIndex].orgCode = item.ORGANIZATION_CODE;
        this.lineDtlsData = JSON.parse(JSON.stringify(this.lineDtlsData));
      } else if (item.name === 'purReqLocation') {
        this.lineDtlsData[this.sepctIndex].location = item.LOCATION_CODE;
        this.lineDtlsData[this.sepctIndex].locationCode = item.location_id;
        this.lineDtlsData = JSON.parse(JSON.stringify(this.lineDtlsData));
      } else if (item.name === 'purReqLineType') {
        this.lineDtlsData[this.sepctIndex].reqLineType = item.LINE_TYPE;
        this.lineDtlsData[this.sepctIndex].reqLineTypeCode = item.LINE_TYPE_ID;
        this.lineDtlsData = JSON.parse(JSON.stringify(this.lineDtlsData));
      } else if (item.name === 'purReqLineType') {
        this.lineDtlsData[this.sepctIndex].reqLineType = item.LINE_TYPE;
        this.lineDtlsData[this.sepctIndex].reqLineTypeCode = item.LINE_TYPE_ID;
        this.lineDtlsData = JSON.parse(JSON.stringify(this.lineDtlsData));
      } else if (item.name === 'purReqHdrType') {
        this.reqHdrType = {
          text: item.description,
          value: item.lookup_code
        };
      }
      this.showPurReqVsetModal = false;
    },
    removeRow(index) {
      // if (this.edit) {
      this.lineDtlsData.splice(index, 1);
      // }
      this.lineDtlsData.map((ele, index) => {
        ele.num = index + 1;
      });
    }
  }
};
