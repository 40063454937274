export default {
  name: 'Contact',
  components: {},
  props: [
    'partyRowDetails',
    'urlSummaryData',
    'workflowId',
    'dashboardDetails'
  ],
  data() {
    return {
      loader: false,
      contactData: [],
      contactFields: [
        {
          key: 'name'
        },
        {
          key: 'mob',
          label: 'Mobile'
        },
        {
          key: 'email'
        }
      ],
      partyReqId: null,
      wfDtlId: null,
      eventId: null
    };
  },
  mounted() {
    if (this.partyRowDetails) {
      this.partyReqId = this.partyRowDetails.req_id;
      this.wfDtlId = this.partyRowDetails.inprocess_dtl_id;
      this.eventId = this.workflowId.eventId;
    }
    if (this.urlSummaryData) {
      this.partyReqId = this.urlSummaryData.PARTY_REQ_ID;
      this.wfDtlId = this.urlSummaryData.wf_inprocess_dtl_id;
      this.eventId = this.urlSummaryData.wf_event_id;
    }
    if (this.dashboardDetails) {
      this.partyReqId = this.dashboardDetails.party_req_id;
    }
    this.getVendorContactDetails();
  },
  methods: {
    getVendorContactDetails() {
      const payload = {
        party_req_id: this.partyReqId
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getVendorContactDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.contactData = resp.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  }
};
