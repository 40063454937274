import Organization from '../../../../admin/organization';
import EntityProjects from '../project';
export default {
  name: 'PrintWaiverWorkflow',
  props: ['approvalHistoryList', 'propsSelectedGridData'],
  components: {
    Organization,
    EntityProjects
  },
  data() {
    return {
      selectedGridData: null,
      negotiatedBy: 'N/A',
      negotiatedSummary: 'N/A',
      leAddress: 'N/A',
      entityArr: [{ unit_name: null }],
      recommendation:
        'In view of the above facts, we would like to recommend to waive off',
      waiverDistribution: [],
      waiverData: [],
      waiverOffAmt: null,
      suggestion: null,
      approvalHistoryFields: [
        {
          key: 'approver_name',
          label: 'Name'
        },
        {
          key: 'approval_status',
          label: 'Status'
        },
        {
          key: 'comments',
          label: 'Comments'
        }
      ],
      waiverDistributionFields: [
        {
          key: 'sequence'
        },
        {
          key: 'waiver',
          label: 'Components'
        },
        {
          key: 'waiver_amount',
          class: 'text-right',
          thClass: 'text-right'
        }
      ]
    };
  },
  mounted() {
    this.selectedGridData = this.propsSelectedGridData;
    this.validateData();
    this.item = this.selectedGridData.le_id;
    this.setTableWidth(this.selectedGridData.body);
    this.getOrgDetails(this.item);
    this.getProjectList(this.selectedGridData.nfa_hdr_id);
  },
  methods: {
    setTableWidth(changes) {
      this.selectedGridData.body = changes.replaceAll(
        '<table>',
        '<table style="width: 100%">'
      );
    },
    calculateWaiveOffAmt(budget, awarded) {
      const waiveOff = parseFloat(budget.replace(/,/g, ''));
      const awardedOff = parseFloat(awarded.replace(/,/g, ''));
      return waiveOff - awardedOff;
    },
    recommendationLine(recommendString, amount, currency) {
      return (
        recommendString +
        ' ' +
        `${parseFloat(amount).toLocaleString('hi-IN')}` +
        ' ' +
        currency +
        ' as under:'
      );
    },
    getOrgDetails(orgId) {
      this.loader = true;
      this.$store
        .dispatch('organization/getOrgDetailsById', orgId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data;
              this.leAddress= result.address
            }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getProjectList(wfHeaderId) {
      this.loader = true;
      const payload = {
        tabType: 'projects',
        wfHeaderId: wfHeaderId
      };
      this.$store
        .dispatch('intWorkflow/getWfTabsDetails', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200 && response.data.data.length > 0) {
            const addWaiverAmount = response.data.data.map(data => {
              data.waiver = data.waiver_amount;
              data.waiverList = data.waivers;
              return {
                entity_type: data.entity_type,
                entity_type_vset: data.entity_type_vset,
                sector_name: data.sector_name,
                sector_id: data.sector_id,
                pocket_name: data.pocket_name,
                pocket_id: data.pocket_id,
                prj_name: data.prj_name,
                prj_id: data.prj_id,
                tower_name: data.tower_name,
                tower_id: data.tower_id,
                floor_name: data.floor_name,
                floor_id: data.floor_id,
                unit_name: data.unit_name,
                unit_id: data.unit_id,
                waiver: data.waiver_amount,
                waiverList: data.waivers
              };
            });
            this.entityArr = addWaiverAmount;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    validateData() {
      this.selectedGridData.excptions = this.selectedGridData.excptions
        ? this.selectedGridData.excptions
        : 'N/A';
      this.selectedGridData.nfa_cat = this.selectedGridData.nfa_cat
        ? this.selectedGridData.nfa_cat
        : 'N/A';
      this.selectedGridData.nfa_subject = this.selectedGridData.nfa_subject
        ? this.selectedGridData.nfa_subject
        : 'N/A';
      this.selectedGridData.le_name = this.selectedGridData.le_name
        ? this.selectedGridData.le_name
        : 'N/A';
      this.selectedGridData.project_type = this.selectedGridData.project_type
        ? this.selectedGridData.project_type
        : 'N/A';
      this.selectedGridData.created_by = this.selectedGridData.created_by
        ? this.selectedGridData.created_by
        : 'N/A';
      this.selectedGridData.nfa_number = this.selectedGridData.nfa_number
        ? this.selectedGridData.nfa_number
        : 'N/A';
      this.selectedGridData.nature_of_purchase = this.selectedGridData
        .nature_of_purchase
        ? this.selectedGridData.nature_of_purchase
        : 'N/A';
      this.selectedGridData.body = this.selectedGridData.body
        ? this.selectedGridData.body
        : 'N/A';
      this.selectedGridData.budget_amount = this.selectedGridData.budget_amount
        ? this.selectedGridData.budget_amount
        : 'N/A';
      this.selectedGridData.awarded_amount = this.selectedGridData
        .awarded_amount
        ? this.selectedGridData.awarded_amount
        : 'N/A';
      this.selectedGridData.budget_ref_code = this.selectedGridData
        .budget_ref_code
        ? this.selectedGridData.budget_ref_code
        : 'N/A';
      this.selectedGridData.bidding_type = this.selectedGridData.bidding_type
        ? this.selectedGridData.bidding_type
        : 'N/A';
      this.selectedGridData.changes = this.selectedGridData.changes
        ? this.selectedGridData.changes
        : 'N/A';
    },
    getNegotiatedList() {
      const payload = {
        tabType: 'negotiated-by',
        wfHeaderId: this.selectedGridData.nfa_hdr_id
      };
      this.$store
        .dispatch('intWorkflow/getWfTabsDetails', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200 && response.data.data.length > 0) {
            const getEmpNameArr = response.data.data.map(
              data => data.employee_name
            );
            this.negotiatedBy =
              getEmpNameArr && getEmpNameArr.length > 0
                ? getEmpNameArr.split(',')
                : 'N/A';
            const summaryArr = response.data.data.map(data => data.remarks);
            this.negotiatedSummary =
              summaryArr && summaryArr.length > 0
                ? summaryArr.split(',')
                : 'N/A';
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  }
};
