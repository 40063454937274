import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'commonModalOracle',
  props: ['modalType', 'vendorCode', 'poNumber'],
  components: {},
  watch: {
    currentPageLegalEntity: function() {
      this.getLegalEntityData();
    },
    perPageLegalEntity: function() {
      this.currentPageLegalEntity = 1;
      this.getLegalEntityData();
    },
    currentPagePeriod: function() {
      this.getPeriodMonth();
    },
    perPagePeriod: function() {
      this.currentPagePeriod = 1;
      this.getPeriodMonth();
    },
    currentPageVendor: function() {
      this.getVendorDetails();
    },
    perPageVendor: function() {
      this.currentPageVendor = 1;
      this.getVendorDetails();
    },
    currentPagePoNumber: function() {
      this.getPoNumberDetails();
    },
    perPagePoNumber: function() {
      this.currentPagePoNumber = 1;
      this.getPoNumberDetails();
    },
    currentPageAccCode: function() {
      this.getAccCodeDetails();
    },
    perPageAccCode: function() {
      this.currentPageAccCode = 1;
      this.getAccCodeDetails();
    }
  },
  data() {
    return {
      // Common Variables
      loader: false,
      // Legal Entity Form Variables
      perPageLegalEntity: commonHelper.perPageRecord,
      pageOptionsLegalEntity: commonHelper.getPageOption(),
      totalRowsLegalEntity: null,
      currentPageLegalEntity: 1,
      legalEntityData: [],
      legalEntityFields: [
        {
          key: 'le_id',
          label: 'Legal Entity ID'
        },
        {
          key: 'le_name',
          label: 'Legal Entity Name'
        }
      ],
      legalEntitySearch: null,
      // Period Form Variables
      perPagePeriod: commonHelper.perPageRecord,
      pageOptionsPeriod: commonHelper.getPageOption(),
      totalRowsPeriod: null,
      currentPagePeriod: 1,
      periodData: [],
      periodFields: [
        {
          key: 'period_name'
        }
      ],
      periodSearch: null,
      // Vendor Form Variables
      perPageVendor: commonHelper.perPageRecord,
      pageOptionsVendor: commonHelper.getPageOption(),
      totalRowsVendor: null,
      currentPageVendor: 1,
      vendorData: [],
      vendorFields: [
        {
          key: 'vendor_name'
        },
        {
          key: 'vendor_code'
        },
        {
          key: 'pan_number',
          label: 'PAN No.'
        }
      ],
      vendorNameSearch: '',
      vendorCodeSearch: '',
      vendorPanSearch: '',
      // PO Number Form Variables
      perPagePoNumber: commonHelper.perPageRecord,
      pageOptionsPoNumber: commonHelper.getPageOption(),
      totalRowsPoNumber: null,
      currentPagePoNumber: 1,
      poNumberData: [],
      poNumberFields: [
        {
          key: 'po_number',
          label: 'PO Number'
        },
        {
          key: 'po_currency',
          label: 'PO Currency'
        },
        {
          key: 'line_num',
          label: 'PO Line Number'
        },
        {
          key: 'item_description'
        }
      ],
      poNumberSearch: '',
      // Account Code Form Variables
      perPageAccCode: commonHelper.perPageRecord,
      pageOptionsAccCode: commonHelper.getPageOption(),
      totalRowsAccCode: null,
      currentPageAccCode: 1,
      accCodeData: [],
      accCodeFields: [
        {
          key: 'code_combination_segment',
          label: 'Account Code'
        }
      ],
      accCodeSearch: null
    };
  },
  // computed: {
  //   filteredLegalEntityData() {
  //     if (!this.legalEntitySearch) {
  //       return this.legalEntityData;
  //     }
  //     const searchQuery = this.legalEntitySearch.toLowerCase();
  //     return this.legalEntityData.filter(item =>
  //       item.le_name.toLowerCase().includes(searchQuery)
  //     );
  //   }
  // },
  mounted() {
    if (this.modalType === 'legal_entity') {
      this.getLegalEntityData();
    } else if (this.modalType === 'period') {
      this.getPeriodMonth();
    } else if (this.modalType === 'po_number') {
      this.getPoNumberDetails();
    } else if (this.modalType === 'account_code') {
      this.getAccCodeDetails();
    }
  },
  methods: {
    // Functions for Legal Entity Form
    getLegalEntityData() {
      const payload = {
        _page: this.currentPageLegalEntity - 1,
        _limit: this.perPageLegalEntity,
        le_name: this.legalEntitySearch
      };
      this.loader = true;
      this.$store
        .dispatch('codReco/getlegalEntityList', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.legalEntityData = result;
            this.totalRowsLegalEntity = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelectedLegalEntity(item) {
      this.$emit('mapSelectedVsetData', item);
    },
    clearLegalEntity() {
      this.legalEntitySearch = null;
      this.getLegalEntityData();
    },
    // Functions for Period Form
    getPeriodMonth() {
      const payload = {
        _page: this.currentPagePeriod - 1,
        _limit: this.perPagePeriod,
        period_name: this.periodSearch
      };
      this.loader = true;
      this.$store
        .dispatch('provisionsAdmin/getExpenseMonth', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.periodData = result;
            this.totalRowsPeriod = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelectedPeriod(item) {
      this.$emit('mapSelectedVsetData', item);
    },
    clearPeriod() {
      this.periodSearch = null;
      this.getPeriodMonth();
    },
    // Functions for Vendor Form
    getVendorDetails() {
      const payload = {
        _page: this.currentPageVendor - 1,
        _limit: this.perPageVendor,
        vendor_name: this.vendorNameSearch,
        vendor_code: this.vendorCodeSearch,
        pan_number: this.vendorPanSearch
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getVendorDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 201) {
            const result = resp.data.data.data.page;
            this.vendorData = result;
            this.totalRowsVendor = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelectedVendor(item) {
      this.$emit('mapSelectedVsetData', item);
    },
    clearVendor() {
      this.vendorNameSearch = '';
      this.vendorCodeSearch = '';
      this.vendorPanSearch = '';
      this.getVendorDetails();
    },
    // Functions for PO Number Form
    getPoNumberDetails() {
      const payload = {
        _page: this.currentPagePoNumber - 1,
        _limit: this.perPagePoNumber,
        vendor_id: +this.vendorCode,
        po_number: this.poNumberSearch
      };
      this.loader = true;
      this.$store
        .dispatch('provisionsAdmin/getPoNumberDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.poNumberData = result;
            this.totalRowsPoNumber = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelectedPoNumber(item) {
      this.$emit('mapSelectedVsetData', item);
    },
    clearPoNumber() {
      this.poNumberSearch = '';
      this.getPoNumberDetails();
    },
    // Functions for Account Code Form
    getAccCodeDetails() {
      const payload = {
        _page: this.currentPagePoNumber - 1,
        _limit: this.perPagePoNumber,
        po_num: this.poNumber,
        account_code: this.accCodeSearch
      };
      this.loader = true;
      this.$store
        .dispatch('provisionsAdmin/getAccCodeDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.accCodeDataData = result;
            this.totalRowsAccCode = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelectedAccCode(item) {
      this.$emit('mapSelectedVsetData', item);
    },
    clearAccCode() {
      this.accCodeSearch = null;
      this.getAccCodeDetails();
    },
    // Common Functions
    clearVsetValues(vsetCode) {
      if (vsetCode === this.legalEntitySearch) {
        this.legalEntitySearch = null;
      } else if (vsetCode === this.periodSearch) {
        this.periodSearch = null;
      } else if (vsetCode === this.vendorNameSearch) {
        this.vendorNameSearch = null;
      } else if (vsetCode === this.vendorCodeSearch) {
        this.vendorCodeSearch = null;
      } else if (vsetCode === this.poNumberSearch) {
        this.poNumberSearch = '';
      } else if (vsetCode === this.accCodeSearch) {
        this.accCodeSearch = null;
      }
    }
  }
};
