import appStrings from '@/app/utility/string.utility';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'Details',
  watch: {},
  components: {},
  props: [
    'partyRowDetails',
    'urlSummaryData',
    'workflowId',
    'dashboardDetails'
  ],
  validations: {
    partyName: { required }
  },
  data() {
    return {
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      editMode: false,
      partyName: null,
      email: null,
      mobile: null,
      pan: null,
      panNotAvail: false,
      invoiceFlag: false,
      internalContactName: null,
      internalContactEmail: null,
      internalContactNumber: null,
      approvalStatus: null,
      partyReqId: null,
      wfDtlId: null,
      eventId: null,
      userId: null,
      templateType: { value: null, text: null },
      panVerifyDetails: {
        category: null,
        client_id: null,
        full_name: null,
        pan_number: null
      }
    };
  },
  mounted() {
    if (this.partyRowDetails) {
      this.partyReqId = this.partyRowDetails.req_id;
      this.wfDtlId = this.partyRowDetails.inprocess_dtl_id;
      this.eventId = this.workflowId.eventId;
      this.userId = this.workflowId.userId;
    }
    if (this.urlSummaryData) {
      this.partyReqId = this.urlSummaryData.PARTY_REQ_ID;
      this.wfDtlId = this.urlSummaryData.wf_inprocess_dtl_id;
      this.eventId = this.urlSummaryData.wf_event_id;
      this.userId = this.urlSummaryData.wf_user_id;
    }
    if (this.dashboardDetails) {
      this.partyReqId = this.dashboardDetails.party_req_id;
      this.userId = this.dashboardDetails.wf_user_id;
    }
    this.getPartyOnBoardingDetailsById();
  },
  methods: {
    getPanVerifyDetailsById() {
      const payload = {
        party_req_id: this.partyReqId
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getPanVerifyDetailsById', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            if (result) {
              this.panVerifyDetails = result;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getPartyOnBoardingDetailsById() {
      const payload = {
        party_req_id: this.partyReqId,
        user_id: this.userId
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getPartyOnBoardingDetailsById', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            this.partyReqId = result.req_id;
            this.partyName = result.party_name;
            this.email = result.email;
            this.mobile = result.mob;
            this.pan = result.pan;
            this.panNotAvail = result.pan_not_avl;
            this.invoiceFlag = result.einvoice;
            this.internalContactName = result.int_contact_name;
            this.internalContactEmail = result.int_contact_email;
            this.internalContactNumber = result.int_contact_mob;
            this.approvalStatus = result.approval_status;
            this.templateType.text = result.template_type_meaning;
            this.templateType.value = result.template_type_code;
            if (!this.panNotAvail) {
              this.getPanVerifyDetailsById();
            }
            this.$emit('templateTypeSend', result.template_type_code);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditPartyOnBoarding() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          req_id: this.partyReqId,
          party_name: this.partyName,
          email: this.email,
          mob: this.mobile,
          pan: this.pan,
          pan_not_avl: this.panNotAvail,
          int_contact_name: this.internalContactName,
          int_contact_email: this.internalContactEmail,
          int_contact_mob: this.internalContactNumber
        };
        this.loader = true;
        this.$store
          .dispatch('intWorkflow/addEditPartyOnBoarding', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 201) {
              this.editMode = false;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
              this.getPartyOnBoardingDetailsById(response.data.data.req_id);
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      }
    },
    editModeChange() {
      this.editMode = true;
    }
  }
};
