import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'specification',
  components: {
    DatePicker
  },
  props: ['editMode', 'masterAgreementApprovalStatus'],
  data() {
    return {
      loader: false,
      vsetCode: null,
      uomClassObj: null,
      specIndex: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      areaType: null,
      setTimeVsetCode: null,
      gridData: [
        {
          lease_carpet_area: null,
          lease_carpet_area_uom_vset: null,
          lease_carpet_area_uom_vset_meaning: null,
          lease_master_agreement_hdr_id: null,
          lease_master_agreement_spec_id: 0,
          lease_master_agreement_spec_vset_code: null,
          lease_master_agreement_spec_vset_meaning: null,
          lease_super_area: null,
          lease_super_area_uom_vset: null,
          lease_super_area_uom_vset_meaning: null,
          period_from: null,
          period_to: null,
          rate: null,
          rate_uom_vset: null,
          rate_uom_vset_meaning: null,
          active: true
        }
      ],
      gridFields: [
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        },
        {
          key: 'active',
          stickyColumn: true
        },
        {
          key: 'lease_master_agreement_spec_vset_meaning',
          label: 'Spec Type'
        },
        {
          key: 'rate_uom_vset_meaning',
          label: 'Rate UOM'
        },
        {
          key: 'rate'
        },
        {
          key: 'lease_super_area_uom_vset_meaning',
          label: 'Super Area UOM'
        },
        {
          key: 'lease_super_area',
          label: 'Super Area'
        },
        {
          key: 'lease_carpet_area_uom_vset_meaning',
          label: 'Carpet Area UOM'
        },
        {
          key: 'lease_carpet_area',
          label: 'Carpet Area'
        },
        {
          key: 'period_from'
        },
        {
          key: 'period_to'
        }
      ]
    };
  },
  mounted() {
    this.eventBus.$on('specification', leaseMasterAgreementHdrId => {
      if (leaseMasterAgreementHdrId) {
        this.getDetails(leaseMasterAgreementHdrId);
      }
    });
  },
  methods: {
    getDetails(leaseMasterAgreementHdrId) {
      const payload = {
        tabName: 'specification',
        leaseMasterAgreementHdrId: leaseMasterAgreementHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('leases/getLeaseMasterAgreementDataById', payload)
        .then(resp => {
          this.loader = false;
          if (
            resp.status === 200 &&
            resp.data.data.length &&
            this.gridData[0].lease_master_agreement_spec_id === 0
          ) {
            this.gridData = resp.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addNewRow() {
      if (this.editMode) {
        this.gridData.push({
          lease_carpet_area: null,
          lease_carpet_area_uom_vset: null,
          lease_carpet_area_uom_vset_meaning: null,
          lease_master_agreement_hdr_id: null,
          lease_master_agreement_spec_id: 0,
          lease_master_agreement_spec_vset_code: null,
          lease_master_agreement_spec_vset_meaning: null,
          lease_super_area: null,
          lease_super_area_uom_vset: null,
          lease_super_area_uom_vset_meaning: null,
          period_from: null,
          period_to: null,
          rate: null,
          rate_uom_vset: null,
          rate_uom_vset_meaning: null,
          active: true
        });
      }
    },
    removeRow(index) {
      if (this.editMode) {
        this.gridData.splice(index, 1);
      }
    },
    opendDependentUom(vsetCode, index, area) {
      this.areaType = area;
      const payload = {
        valueSetName: vsetCode,
        dependentVariable: {
          user_id: this.$store.state.auth.userId,
          res_id: this.$store.state.shared.responsibilityId
        }
      };
      this.loader = true;
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          this.loader = false;
          this.uomClassObj = null;
          if (response.status === 200) {
            const findTimeObj = response.data.data.page.find(
              vsetData => vsetData.value_code === 'AREA'
            );
            if (findTimeObj) {
              this.uomClassObj = findTimeObj;
              this.openValueSetModal('UOM', index);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'UOM') {
        this.gridData[this.specIndex].lease_super_area_uom_vset =
          item.value_code;
        this.gridData[this.specIndex].lease_super_area_uom_vset_meaning =
          item.value_meaning;
        this.gridData[this.specIndex].lease_carpet_area_uom_vset =
          item.value_code;
        this.gridData[this.specIndex].lease_carpet_area_uom_vset_meaning =
          item.value_meaning;
        this.gridData[this.specIndex].rate_uom_vset = item.value_code;
        this.gridData[this.specIndex].rate_uom_vset_meaning =
          item.value_meaning;
      } else if (this.vsetCode === 'LEASE_MASTER_AGREEMENT_SPECIFICATION') {
        this.gridData[this.specIndex].lease_master_agreement_spec_vset_meaning =
          item.value_meaning;
        this.gridData[this.specIndex].lease_master_agreement_spec_vset_code =
          item.value_code;
      }
      this.showValueSetModal = false;
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.specIndex = index;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (
        this.uomClassObj &&
        vsetCode !== 'LEASE_MASTER_AGREEMENT_SPECIFICATION'
      ) {
        this.parent_value_set_id = this.uomClassObj.value_set_dtl_id;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    checkUncheckRadio(specIndex) {
      this.gridData.forEach((element, index) => {
        return (element.active = index === specIndex ? true : false);
      });
    },
    setDate(dateRange, index) {
      this.gridData[index].period_from_to = commonHelper.setFutureDate(
        dateRange
      );
    },
    startDateIndex(index) {
      this.specIndex = index;
      this.gridData[index].period_to = null;
    },
    endDateIndex(index) {
      this.specIndex = index;
    },
    disabledDates(date) {
      return commonHelper.disabledDates(
        date,
        this.gridData[this.specIndex].period_from
      );
    },
    clearVsetValues(vsetCode, index) {
      if (vsetCode === 'SUPER_AREA') {
        this.gridData[index].lease_super_area_uom_vset = null;
        this.gridData[index].lease_super_area_uom_vset_meaning = null;
      } else if (vsetCode === 'CARPET_AREA') {
        this.gridData[index].lease_carpet_area_uom_vset = null;
        this.gridData[index].lease_carpet_area_uom_vset_meaning = null;
      } else if (vsetCode === 'RATE_UOM') {
        this.gridData[index].rate_uom_vset = null;
        this.gridData[index].rate_uom_vset_meaning = null;
      } else if (vsetCode === 'LEASE_MASTER_AGREEMENT_SPECIFICATION') {
        this.gridData[index].lease_master_agreement_spec_vset_meaning = null;
        this.gridData[index].lease_master_agreement_spec_vset_code = null;
      }
    }
  },
  beforeDestroy() {
    this.eventBus.$off('specification');
    clearInterval(this.setTimeVsetCode);
  }
};
