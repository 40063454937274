import commonHelper from '@/app/utility/common.helper.utility';
import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility';

export default {
  name: 'paymentDetails',
  components: {
    DatePicker
  },
  props: ['bankBatchId', 'eventId', 'wf_inprocess_dtl_id','stat'],
  watch: {
    currentPage: function() {
      this.getRecoveryList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getRecoveryList();
    },
    selectAllCheckBox: function() {
      this.checkUncheckAll();
    }
  },
  data() {
    return {
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      selectAllCheckBox: false,
      showRecoveryDetailModal: false,
      showValueSetModal: false,
      unsubscribe: null,
      loader: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      leName: {
        value: null,
        text: null
      },
      ouName: {
        value: null,
        text: null
      },
      bankBatchName: {
        value: null,
        text: null
      },
      fromDateToDate: [],
      paymentMethod: {
        value: null,
        text: null
      },
      paymentMode: {
        value: null,
        text: null
      },
      approvalStatus: {
        value: null,
        text: null
      },
      paymentDetailsData: [],
      gridFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'payment_batch_name'
        },
        {
          key: 'h2h_batch_name',
          label: 'Bank Batch'
        },
        {
          key: 'beneficiary_name',
          label: 'Payee Name'
        },
        {
          key: 'payment_amount'
        },
        {
          key: 'address_1'
        },
        {
          key: 'address_2'
        },
        {
          key: 'address_3'
        },
        {
          key: 'pan_no',
          label: 'PAN No.'
        },
        {
          key: 'payment_id',
          class: 'd-none'
        },
        {
          key: 'payment_ref_no',
          label: 'PRN'
        },
        {
          key: 'payment_document_no',
          label: 'Document Number'
        },
        {
          key: 'payment_method'
        },
        {
          key: 'beneficiary_name'
        },
        {
          key: 'payee_bank_account_number',
          label: 'Payee Account'
        },
        {
          key: 'ifsc_code',
          label: 'IFSC Code'
        },
        {
          key: 'payment_date'
        },
        {
          key: 'return_Value'
        },
        {
          key: 'remarks'
        },
        {
          key: 'created_by'
        },
        {
          key: 'creation_date'
        }
      ],
      approvalHistoryList: [],
      approvalHistoryFields: [
        {
          key: 'approver_name',
          label: 'Name'
        },
        {
          key: 'approval_status',
          label: 'Status'
        },
        {
          key: 'comments',
          label: 'Comments'
        },
        {
          key: 'action_date'
        }
      ],
      notificationSummary: false,
      isApprovedByCurrrentUser: false,
      isConsultInitiated: false,
      urlSummaryData: null,
      wfDtlId: null,
      notificationComments: null,
      calculateAmt: null,
      downloadPayload: null,
    };
  },
  mounted() {
      this.$store.commit(
          'hostToHostBankTransfer/downloadFlag',
          'paymentDetails'
        );
        const flags=this.$store.state.hostToHostBankTransfer.downloadFlag;

    if (this.bankBatchId) {
      this.getPaymentDetails(this.bankBatchId);
    }

    if (this.$route.params.summaryData) {
      this.urlSummaryData = JSON.parse(
        Buffer.from(this.$route.params.summaryData, 'base64').toString('ascii')
      );
    }

    if (this.urlSummaryData) {
      this.wfDtlId = this.urlSummaryData.wf_inprocess_dtl_id;
    } else {
      this.wfDtlId = this.wf_inprocess_dtl_id;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'delete') {
          this.getRejectingDetails();
        }
        if (actionName === 'download') {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          if(flags==='paymentDetails'){
            const downloadpayload = { ...this.downloadPayload };
            downloadpayload._limit = this.totalRows;
            this.downloadExcel.downloadData(
              downloadpayload,
              'hostToHostBankTransfer/getPaymentDetailsHdrById',
              'Payment-details',
              () => (this.loader = false),
              this.paymentDetailsData
            );
          }
        }
      }
    });

    if (this.urlSummaryData) {
      this.getPaymentDetails(this.urlSummaryData.ap_h2h_payment_batch_id);
      this.wfDtlId = this.urlSummaryData.wf_inprocess_dtl_id;
    }
  },
  methods: {
    getPaymentDetails(bankBatchId) {
      const payload = { bankBatchId: bankBatchId };
      // to get hdr details
      this.downloadPayload = payload;
      this.loader = true;
      this.$store
        .dispatch('hostToHostBankTransfer/getPaymentDetailsHdrById', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            this.leName.text = results.le_name;
            this.leName.value = results.le_id;
            this.ouName.text = results.ou_name;
            this.ouName.value = results.ou_id;
            this.bankBatchName.text = results.h2h_payment_batch_name;
            this.bankBatchName.value = results.h2h_payment_batch_id;
            this.fromDateToDate = [
              new Date(results.payment_date_from),
              new Date(results.payment_date_to)
            ];
            this.paymentMethod.text = results.payment_method;
            this.paymentMethod.value = results.payment_method_id;
            this.paymentMode.text = results.payment_mode_meaning;
            this.paymentMode.value = results.payment_mode_vset;
            this.approvalStatus.text = results.application_status_meaning;
            this.approvalStatus.value = results.application_status_vset;
            this.paymentDetailsData = response.data.data.h2h_bank_payments;
            this.calculateAmount();
          }
          if (
            this.$route.name === 'notificationSummary' ||
            this.$route.name === 'dashboard' ||
            this.$route.name === 'workFlowSummary'
          ) {
            this.notificationSummary = true;
            this.getApprovalHistory(this.bankBatchName.value);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getRejectingDetails() {
      // to delete record
      const filterData = this.paymentDetailsData.filter(data => data.selectBox);
      const paymentDetails = filterData.map(elem => {
        return {
          h2h_payment_id: elem.h2h_payment_id,
          h2h_payment_batch_id: elem.h2h_payment_batch_id,
          select: elem.selectBox
        };
      });
      const payload = {
        // bankBatchId: this.bankBatchName.value,
        h2HPaymentDetails: paymentDetails ? paymentDetails : null
      };
      this.loader = true;
      this.$store
        .dispatch('hostToHostBankTransfer/getRejectingPaymentById', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            const results = response.data.data;
            this.leName.text = results.le_name;
            this.leName.value = results.le_id;
            this.ouName.text = results.ou_name;
            this.ouName.value = results.ou_id;
            this.bankBatchName.text = results.h2h_payment_batch_name;
            this.bankBatchName.value = results.h2h_payment_batch_id;
            this.fromDateToDate = [
              new Date(results.payment_date_from),
              new Date(results.payment_date_to)
            ];
            this.paymentMethod.text = results.payment_method;
            this.paymentMethod.value = results.payment_method_id;
            this.paymentMode.text = results.payment_mode_meaning;
            this.paymentMode.value = results.payment_mode_vset;
            this.approvalStatus.text = results.application_status_meaning;
            this.approvalStatus.value = results.application_status_vset;
            this.paymentDetailsData = response.data.data.h2h_bank_payments;
            this.calculateAmount();
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    getApprovalHistory(ap_h2h_payment_batch_id) {
      // to get approval history
      this.loader = true;
      this.$store.dispatch('auth/getUserDetail').then(response => {
        if (response.status === 200) {
          this.userInfo = response.data.data;
          const payload = {
            approvalStatus: 'Y',
            nfaHdrId: ap_h2h_payment_batch_id,
            eventId: this.urlSummaryData
              ? this.urlSummaryData.wf_event_id
              : this.eventId
          };
          this.$store
            .dispatch('intWorkflow/getApprovalList', payload)
            .then(response => {
              this.loader = false;
              if (response.status === 200) {
                this.approvalHistoryList = response.data.data;
                const findCurrentUser = this.approvalHistoryList.find(
                  user =>
                    user.approver_employee_id === this.userInfo.employee_id &&
                    (user.approval_status === 'INPROCESS' ||
                      user.approval_status === 'CONSULT_INITIATED')
                );
                this.isApprovedByCurrrentUser = findCurrentUser ? true : false;
                const findConsultInitUser = this.approvalHistoryList.find(
                  data => data.approval_status === 'CONSULT_INITIATED'
                );
                this.isConsultInitiated = findConsultInitUser ? true : false;
              }
            })
            .catch(() => {
              this.loader = false;
            });
        }
      });
    },
    changeStatus(status) {
      // to change approval status
      this.loader = true;
      const payload = [
        {
          event_id: this.urlSummaryData
            ? this.urlSummaryData.wf_event_id
            : this.eventId,
          notification_comments: this.notificationComments,
          notification_status: status,
          wf_inprocess_dtl_id: this.wfDtlId,
          temp_approved_amount: 0
        }
      ];
      this.$store
        .dispatch('intWorkflow/approvalReject', { approval_requests: payload })
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.getApprovalHistory(this.bankBatchName.value);
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    checkUncheckAll() {
      // to select for all
      this.paymentDetailsData = this.paymentDetailsData.map(data => {
        data.selectBox = this.selectAllCheckBox;
        return data;
      });
    },
    selectBoxChecked(flag, index) {
      // to check select box by index
      this.paymentDetailsData[index].selectBox = flag;
    },
    calculateAmount() {
      let sum = 0;
      this.paymentDetailsData.forEach(element => {
        if (element) {
          sum += parseFloat(element.payment_amount);
        }
        this.calculateAmt = parseFloat(sum).toFixed(2);
      });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
