import appStrings from '@/app/utility/string.utility';
import UploadDocument from '@/app/components/onlineApproval/internalWorkFlow/history/uploadDocument';
export default {
  name: 'Evaluation',
  watch: {},
  components: {
    UploadDocument
  },
  props: [
    'partyRowDetails',
    'urlSummaryData',
    'workflowId',
    'dashboardDetails',
    'templateTypeProp'
  ],
  data() {
    return {
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      editMode: false,
      evaluationData: [],
      evaluationFields: [
        {
          key: 's_no',
          label: 'Serial No.',
          stickyColumn: true
        },
        {
          key: 'check_point',
          class: 'point-fix'
        },
        {
          key: 'scale',
          stickyColumn: true
        },
        {
          key: 'score',
          label: 'Actual Score'
        },
        {
          key: 'remarks'
        }
      ],
      partyReqId: null,
      wfDtlId: null,
      eventId: null,
      approvalStatus: null,
      approvalStatusKey: null,
      totalScore: null,
      rating: null,
      seqOrder: null,
      userId: null,
      backgroundVerificationFlag: {
        value: null,
        text: null
      },
      options: [
        {
          value: true,
          text: 'Yes'
        },
        {
          value: false,
          text: 'No'
        }
      ],
      showHistory: false,
      historyType: null,
      selectedGridData: {
        le_id: '67',
        le_name: 'Delhivery Limited',
        nfa_cat_vset: null,
        nfa_cat: null,
        nfa_sub_cat_vset: null,
        nfa_sub_cat: null
      },
      openKmAuth: null,
      totalEvaluationRows: 0
    };
  },
  mounted() {
    if (this.partyRowDetails) {
      this.partyReqId = this.partyRowDetails.req_id;
      this.wfDtlId = this.partyRowDetails.inprocess_dtl_id;
      this.eventId = this.workflowId.eventId;
      this.userId = this.workflowId.userId;
    }
    if (this.urlSummaryData) {
      this.partyReqId = this.urlSummaryData.PARTY_REQ_ID;
      this.wfDtlId = this.urlSummaryData.wf_inprocess_dtl_id;
      this.eventId = this.urlSummaryData.wf_event_id;
      this.userId = this.urlSummaryData.wf_user_id;
    }
    if (this.dashboardDetails) {
      this.partyReqId = this.dashboardDetails.party_req_id;
      this.userId = this.dashboardDetails.wf_user_id;
    }
    this.getVendorEvaluationDetails();
    this.getPartyOnBoardingDetailsById();
    this.getUploadedDocDtl();
  },
  created() {
    this.eventBus.$on('totalEvaluationRows', totalEvaluationRows => {
      this.totalEvaluationRows = totalEvaluationRows;
    });
  },
  methods: {
    validate(item) {
      if (item.score > item.scale) {
        alert('Actual Score should be less than Scale');
        item.score = null;
      }
      this.calculate();
    },
    calculate() {
      const totalLength = this.evaluationData.filter(item => {
        return item.score;
      });
      let sum = null;
      this.evaluationData.forEach(item => {
        if (item.score) {
          sum += item.score;
        }
      });
      if (this.templateTypeProp === 'TEMPLATE_1') {
        if (totalLength.length === 9) {
          this.totalScore = sum;
          this.validateRatingTemplate1();
        }
      } else if (this.templateTypeProp === 'TEMPLATE_2') {
        if (totalLength.length === 6) {
          this.totalScore = sum;
          this.validateRatingTemplate2();
        }
      } else if (this.templateTypeProp === 'TEMPLATE_3') {
        if (totalLength.length === 11) {
          this.totalScore = sum;
          this.validateRatingTemplate3();
        }
      } else if (this.templateTypeProp === 'OWN_FLEET') {
        this.totalScore = sum;
        this.rating = 'A';
      } else {
        this.totalScore = null;
        this.rating = null;
      }
    },
    showHideEvaluationRejectModal(flag) {
      this.showEvaluationRejectModal = flag;
    },
    validateRatingTemplate1() {
      this.rating =
        this.totalScore > 0 && this.totalScore <= 70
          ? 'D'
          : this.totalScore >= 71 && this.totalScore <= 80
          ? 'C'
          : this.totalScore >= 81 && this.totalScore <= 90
          ? 'B'
          : this.totalScore > 90
          ? 'A'
          : '';
    },
    validateRatingTemplate2() {
      this.rating =
        this.totalScore > 0 && this.totalScore < 70
          ? 'D'
          : this.totalScore >= 70 && this.totalScore <= 80
          ? 'C'
          : this.totalScore > 80 && this.totalScore < 90
          ? 'B'
          : this.totalScore >= 90
          ? 'A'
          : '';
    },
    validateRatingTemplate3() {
      this.rating = this.totalScore / 11;
      this.rating = parseFloat(this.rating).toFixed(2);
    },
    getVendorEvaluationDetails() {
      const payload = {
        party_req_id: this.partyReqId,
        type: this.templateTypeProp
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getVendorEvaluationDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            if (resp.data.data.length) {
              if(resp.data.data[0].background_ver_flag) {
                this.backgroundVerificationFlag = {
                  value: true,
                  text: 'Yes'
                }
              } else {
                this.backgroundVerificationFlag = {
                  value: false,
                  text: 'No'
                }
              }
              let total = 0;
              this.evaluationData = resp.data.data;
              this.evaluationData.forEach(item => {
                total += item.score;
              });
              this.totalScore = total;
              if (this.templateTypeProp === 'TEMPLATE_1') {
                this.validateRatingTemplate1();
              }
              if (this.templateTypeProp === 'TEMPLATE_2') {
                this.validateRatingTemplate2();
              }
              if (this.templateTypeProp === 'TEMPLATE_3') {
                this.validateRatingTemplate3();
              }
              if (this.templateTypeProp === 'OWN_FLEET') {
                this.validateRatingTemplate1();
              }
            } else {
              this.getPreEvaluationSupplier();
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getPreEvaluationSupplier() {
      const payload = {
        type: this.templateTypeProp
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getPreEvaluationSupplier', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.evaluationData = resp.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditPreEvaluationSupplier() {
      const supplierDetails = this.evaluationData.map(elem => {
        return {
          evaluation_req_id: elem.evaluation_req_id
            ? elem.evaluation_req_id
            : 0,
          party_req_id: this.partyReqId,
          check_point: elem.check_point,
          scale: elem.scale,
          score: elem.score,
          remarks: elem.remarks,
          type: this.templateTypeProp
        };
      });
      const filterData = supplierDetails.filter(data => !data.score);
      if (!filterData.length) {
        if((this.backgroundVerificationFlag.value === null || this.totalEvaluationRows === 0) && (this.templateTypeProp === 'TEMPLATE_1' || this.templateTypeProp === 'TEMPLATE_2')) {
          alert("Please select Background Verification Check");
        } else {
          const payload = {
            background_ver_flag: this.backgroundVerificationFlag.value,
            evaluation_party_req_detials: supplierDetails ? supplierDetails : null
          };
          this.loader = true;
          this.$store
            .dispatch('intWorkflow/addEditPreEvaluationSupplier', payload)
            .then(response => {
              this.loader = false;
              this.showAlert = true;
              if (response.status === 201) {
                this.editMode = false;
                this.isSuccess = true;
                this.responseMsg = response.data.message;
                this.getVendorEvaluationDetails();
              } else {
                this.isSuccess = false;
                this.responseMsg = response.response.data.message;
              }
            })
            .catch(() => {
              this.loader = false;
              this.isSuccess = false;
              this.responseMsg = appStrings.autoFailedMsg;
            });
        }  
      } else {
        alert('Please fill all the Actual Score');
      }
    },
    editModeChange() {
      this.editMode = true;
    },
    getPartyOnBoardingDetailsById() {
      const payload = {
        party_req_id: this.partyReqId,
        user_id: this.userId
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getPartyOnBoardingDetailsById', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.seqOrder = resp.data.data.seq_order;
            this.approvalStatus = resp.data.data.approval_status;
            this.approvalStatusKey = resp.data.data.approval_status_key;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getOpenKmAuthTokenUploadDocument() {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmPartyPortalAuthenticate')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            if (this.openKmAuth) {
                this.showHideHistory(true, 'invoice');
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideHistory(flag, historyType) {
      this.showHistory = flag;
      this.historyType = historyType;
    },
    getUploadedDocDtl() {
      const payload = {
        _page: 0,
        _limit: 100,
        menu_id: 374,
        sub_menu_id: 87,
        record_id: this.partyReqId
      };
      this.documentData = [];
      this.$store
        .dispatch('intWorkflow/getOpenKmDocDtl', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.totalEvaluationRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clearBackgroundVerification() {
      this.backgroundVerificationFlag = {
        value: null,
        text: null
      };
    }
  }
};
