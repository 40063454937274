import commonHelper from '@/app/utility/common.helper.utility';
import URL_UTILITY from '@/app/utility/url.utility';
import previewDoc from '../../onlineApproval/internalWorkFlow/history/uploadDocument/previewDoc';
export default {
  name: 'allDocument',
  components: { previewDoc },
  props: ['menuId', 'subMenuId', 'recordId', 'formName'],
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      loader: false,
      documentData: [],
      documentFields: [
        {
          key: 'original_file_name',
          label: 'File Name'
        },
        {
          key: 'file_name',
          label: 'Preview'
        },
        {
          key: 'file_size',
          label: 'Size',
          stickyColumn: true
        }
      ],
      showOpenModal: false,
      openKmAuth: null,
      docDtlId: null,
      authToken: null,
      uploadedFileName: null,
      downloadDocUrl: URL_UTILITY.getOpenKmDocDeleteUrl,
      accessButtons: {
        deleteAllow: false,
        downloadAllow: false,
        uploadAllow: false,
        editAllow: false,
        addAllow: false,
        printAllow: false,
        approvalAllow: false,
        newTab: true
      },
      dynamicData: null,
      currentPageIndex: null
    };
  },
  mounted() {
    if (this.menuId && this.subMenuId && this.recordId) {
      this.getUploadedDocDtl();
    }
  },
  methods: {
    getUploadedDocDtl() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        menu_id: this.menuId,
        sub_menu_id: this.subMenuId,
        record_id: this.recordId
      };
      this.documentData = [];
      this.$store
        .dispatch('intWorkflow/getOpenKmDocDtl', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data.page.length > 0) {
              const docList = response.data.data.page;
              this.documentData = docList.map(data => {
                data.file_size = this.formatBytes(data.file_size);
                return data;
              });
              this.totalRows = response.data.data.total_elements;
            } else {
              alert('Document not available for this record');
              this.$emit('closeModal');
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    getOpenKmAuthToken(docDtlId, fileName) {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmPartyPortalAuthenticate')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            const url =
              this.downloadDocUrl +
              '/download/' +
              docDtlId +
              '?token=' +
              this.openKmAuth;
            commonHelper.downloadFile(url, fileName);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openPreviewDoc(flag, docDtlId, fileName) {
      if (flag) {
        this.getOpenKmAuthTokenPreview();
        this.timeout = setTimeout(() => {
          this.showHideOpenModal(flag, docDtlId, this.openKmAuth, fileName);
        }, 1000);
      }
    },
    openPreviewDocInvoice(flag, docDtlId, fileName, index, data) {
      const filterDynamicData = data.filter(a => a.original_file_name);
      const getFileName = data[index].original_file_name;
      for (let i = 0; i < filterDynamicData.length; i++) {
        if (filterDynamicData[i].original_file_name == getFileName) {
          this.currentPageIndex = i + 1;
          break;
        }
      }
      const tempData = filterDynamicData.map((elem) => {
        return {
          original_file_name: elem.original_file_name,
          document_det_id: elem.doc_detail_id,
        };
      });
      this.dynamicData = tempData;
      if (flag) {
        this.getOpenKmAuthTokenPreview(flag, docDtlId, fileName);
      }
    },
    showHideOpenModal(flag, docDtlId, authToken, fileName) {
      if (flag) {
        this.fileExtension = commonHelper.getFileExtension(fileName);
      }
      if (this.fileExtension === 'xlsx') {
        alert('Preview Not Allowed');
      } else {
        this.showOpenModal = flag;
        this.docDtlId = docDtlId;
        this.authToken = authToken;
        this.uploadedFileName = fileName;
      }
    },
    getOpenKmAuthTokenPreview(flag, docDtlId, fileName) {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmPartyPortalAuthenticate')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            if (this.formName === 'invoiceApproval' && this.openKmAuth) {
              // this.partOnboardingPreview(flag, docDtlId, this.openKmAuth, fileName);
              this.showHideOpenModal(flag, docDtlId, this.openKmAuth, fileName);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    partOnboardingPreview(flag, docDtlId, authToken, fileName) {
      if (flag) {
        this.fileExtension = commonHelper.getFileExtension(fileName);
      }
      if (this.fileExtension === 'xlsx') {
        alert('Not Allowed');
      } else {
        this.docDtlId = docDtlId;
        this.authToken = authToken;
        this.uploadedFileName = fileName;
        this.previewOpenDoc();
      }
    },
    previewOpenDoc() {
      this.fileExtension = commonHelper.getFileExtension(this.uploadedFileName);
      const payload = {
        docDetailId: this.docDtlId,
        token: this.authToken,
        fileExtension: this.fileExtension
      };
      this.loader = true;
      commonHelper.previewImgDocParty(payload);
      this.loader = false;
    }
  }
};
