import DatePicker from 'vue2-datepicker';
import { format } from 'date-fns';
import appStrings from '@/app/utility/string.utility';
import party from '@/app/components/generalLedger/party';
import commonHelper from '@/app/utility/common.helper.utility';
import currency from '@/app/components/admin/currency';
import securityDeposit from './securityDeposit';
import escalation from './escalation';
import specification from './specification';
import terminationNotice from './ternimationNotice';
import rentalDetails from './rentalDetails';
import tax from './tax';
import VueCkeditor from 'vue-ckeditor5';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import buildingProject from '../../../masterLease/leaseProject';
import camAndOtherCharges from './camAndOtherCharges';
import masterAgreement from '../../masterAgreement';
import leaseUnit from '../../../masterLease/leaseUnit';
import store from '../../../../../../../store';
import tenantLease from '../../../leases/tenantLease';
export default {
  name: 'addEditInvestorLease',
  props: ['selectedLeaseDetails', 'eventId', 'wf_inprocess_dtl_id'],
  components: {
    DatePicker,
    party,
    currency,
    securityDeposit,
    escalation,
    specification,
    terminationNotice,
    rentalDetails,
    'vue-ckeditor': VueCkeditor.component,
    buildingProject,
    camAndOtherCharges,
    tax,
    masterAgreement,
    leaseUnit,
    tenantLease
  },
  
  data() {
    return {
      activeTab: 'escalation',
      activeTabList: [],
      tabTimeOut: null,
      editors: {
        classic: ClassicEditor
      },
      scopeOfWork: null,
      showProjectModal: false,
      showCurrencyModal: false,
      showPartyModal: false,
      unsubscribe: null,
      showModalComponent: false,
      showValueSetModal: false,
      editMode: false,
      loader: false,
      showAlert: false,
      isViewMore: false,
      isSuccess: false,
      responseMsg: [],
      parent_value_set_id: null,
      tab: null,
      tempParentData: null,
      approvalStatus: 'Draft',
      version: 0,
      unitDisplayName: null,
      unitName: null,
      unitId: 0,
      startDate: null,
      endDate: null,
      active: true,
      subunitMapping: true,
      selectedCurrency: {
        value: null,
        text: null
      },
      fitoutScope: null,
      logicNotes: null,
      notificationSummary: null,
      selectedProject: {
        value: null,
        text: null
      },
      leaseForm: {
        vendor_id: null, //new added
        vendor_name: null,
        vendor_site_id: null,
        vendor_site_name: null,
        vendor_site_address: null,
        lease_tenant_rental_start_date: null,
        le_id: null,
        le_name: null,
        org_ou_id: null,
        org_ou_name: null,
        lease_status_meaning: null,
        fms_billable_area: null,
        fms_billable_area_uom_meaning: null,
        fms_billable_area_uom_vset: null,
        carpet_area_uom_vset_meaning: null,
        lease_status: null,
        lease_investor_agreement_date: format(new Date(), 'dd-MMM-yyyy'),
        lease_prj_unit_id: null,
        currency: null,
        lease_prj_unit_name: null,
        lease_fms_sub_unit_name: null,
        lease_description: null,
        lease_approval_notes: null,
        lease_fms_sub_unit_id: null,
        transaction_currency_id: null,
        lease_investor_agreement_no: null,
        rate_uom_vset_meaning: null,
        lease_investor_agreement_hdr_id: 0,
        lease_tenant_agreement_hdr_id: null,
        lease_party_name: null,
        period_from_to: null,
        to_date: null,
        from_date: null,
        lease_party_id: null,
        logic_note: null,
        bill_rate: null,
        rate_uom_vset: null,
        sharing_ratio: null,
        notice_period: 0,
        notice_period_uom_vset: null,
        notice_period_uom_vset_meaning: null,
        locking_period: 0,
        locking_period_uom_vset: null,
        locking_period_uom_vset_meaning: null,
        rate: null,
        version: 0,
        approval_status: 'DRAFT'
      },
      showCommonModal: false,
      modalType: null,
      leaseMasterAgreementHdrId: null,
      notificationComments: null,
      approvalHistoryList: [],
      approvalHistoryFields: [
        {
          key: 'approver_name',
          label: 'Name'
        },
        {
          key: 'approval_status',
          label: 'Status'
        },
        {
          key: 'comments',
          label: 'Comments'
        },
        {
          key: 'action_date'
        }
      ],
      isApprovedByCurrrentUser: false,
      isConsultInitiated: false,
      urlSummaryData: null,
      wfDtlId: null,
      customerId: null
    };
  },
  mounted() {
    if (this.selectedLeaseDetails) {
      // this.setLeaseFormData(this.selectedLeaseDetails);
      this.getLeaseInvestorAgrDetails(
        this.selectedLeaseDetails.lease_investor_agreement_hdr_id
      );
    }
    if (this.$route.params.summaryData) {
      this.urlSummaryData = JSON.parse(
        Buffer.from(this.$route.params.summaryData, 'base64').toString('ascii')
      );
    }
    if (this.urlSummaryData) {
      this.wfDtlId = this.urlSummaryData.wf_inprocess_dtl_id;
    } else {
      this.wfDtlId = this.wf_inprocess_dtl_id;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.responseMsg = [];
          if (this.leaseForm.lease_investor_agreement_hdr_id === 0) {
            this.addEditTenantLease();
          } else {
            // this.addEditSpecification();
            // this.addEditRentalDetails();
            this.addEditEscalation();
            this.addEditTermNotice();
            this.addEditSecurityDeposit();
            this.addEditTax();
            this.addEditCamAndOther();
          }
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', { id: this.unitId });
        }
      }
    });
    if (this.urlSummaryData) {
      this.getLeaseInvestorAgrDetails(
        this.urlSummaryData.lease_investor_agreement_hdr_id
      );
      this.wfDtlId = this.urlSummaryData.wf_inprocess_dtl_id;
    }
  },
  methods: {
    getLeaseInvestorAgrDetails(leaseInvestorHdrId) {
      this.loader = true;
      this.$store
        .dispatch('leases/getLeaseInvestorDetails', leaseInvestorHdrId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.setLeaseFormData(response.data.data);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditTenantLease() {
      const payload = {
        approval_status: this.leaseForm.approval_status, //required
        fitout_scope: this.fitoutScope, //required
        lease_approval_notes: this.leaseForm.lease_approval_notes,
        lease_description: this.leaseForm.lease_description, //required
        lease_master_agreement_date: this.leaseForm.lease_master_agreement_date
          ? format(
              new Date(this.leaseForm.lease_master_agreement_date),
              'dd-MMM-yyyy'
            )
          : null, //required
        lease_master_agreement_hdr_id: this.leaseForm
          .lease_master_agreement_hdr_id, //not null 0 for new record
        lease_party_id: this.leaseForm.lease_party_id, //required
        lease_prj_id: this.leaseForm.lease_prj_id, //required
        logic_note: this.logicNotes, //required
        transaction_currency_id: this.leaseForm.transaction_currency_id, //required
        menu_id: store.state.shared.menuId,

        from_date: commonHelper.formattedDate(this.leaseForm.period_from_to[0]), //required
        to_date: commonHelper.formattedDate(this.leaseForm.period_from_to[1]), //required
        lease_prj_unit_id: this.leaseForm.lease_prj_unit_id, //NotNull
        lease_tenant_agreement_date: this.leaseForm.lease_tenant_agreement_date
          ? format(
              new Date(this.leaseForm.lease_tenant_agreement_date),
              'dd-MMM-yyyy'
            )
          : null,
        lease_tenant_agreement_hdr_id: this.leaseForm
          .lease_tenant_agreement_hdr_id,

        rate: this.leaseForm.rate,
        bill_rate: this.leaseForm.bill_rate,
        rate_uom_vset: this.leaseForm.rate_uom_vset
      };
      this.loader = true;
      this.$store
        .dispatch('leases/addEditTenantLease', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.activeTabList = [];
            this.$emit('updatList');
            this.$emit('rowSelected', response.data.data);
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg.push(`Header Details: ${response.data.message}`);
            this.setLeaseFormData(response.data.data);
            this.leaseForm.lease_tenant_agreement_hdr_id =
              response.data.data.lease_tenant_agreement_hdr_id;
            // this.addEditSpecification();
            // this.addEditRentalDetails();
            this.addEditEscalation();
            this.addEditTermNotice();
            this.addEditSecurityDeposit();
            this.addEditTax();
            this.addEditCamAndOther();
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg.push(
              `Header Details: ${response.response.data.message}`
            );
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg.push(`Header Details: ${appStrings.autoFailedMsg}`);
        });
    },
    addEditRentalDetails() {
      if (this.leaseForm.lease_investor_agreement_hdr_id) {
        const objRefrence = this.$refs.rentalDetails.gridData.filter(data => {
          if (
            data.lease_rental_buffer_days !== null &&
            data.lease_rental_payment_term_id !== null &&
            data.lease_rental_period !== null &&
            data.lease_rental_period_uom_vset !== null
          ) {
            return data;
          }
        });
        const tabDataArray = objRefrence.map(data => {
          return {
            active: data.active,
            lease_vendor_id: data.lease_vendor_id, //NotNull
            lease_vendor_site_id: data.lease_vendor_site_id, //NotNull
            lease_rental_buffer_days: data.lease_rental_buffer_days, //NotNull
            lease_rental_end_date: commonHelper.formattedDate(
              data.period_from_to[1]
            ), //can go null
            lease_rental_payment_term_id: data.lease_rental_payment_term_id, //NotNull
            lease_rental_period: data.lease_rental_period, //NotNull
            lease_rental_period_uom_vset: data.lease_rental_period_uom_vset, //NotBlank
            lease_rental_start_date: commonHelper.formattedDate(
              data.period_from_to[0]
            ), //can go null
            lease_investor_agreement_rental_id:
              data.lease_investor_agreement_rental_id, //NotNull {insert or update}
            sac: data.sac //NotBlank
          };
        });
        if (tabDataArray.length) {
          const payload = {
            tabName: 'rental-details',
            leaseInvestorHdrId: this.leaseForm.lease_investor_agreement_hdr_id,
            tabData: {
              lease_investor_agreement_ren_details: tabDataArray
                ? tabDataArray
                : []
            }
          };
          this.$store
            .dispatch('leases/addEditInvestorLeaseTabsData', payload)
            .then(response => {
              if (response.status === 200) {
                this.activeTabList = [];
                this.editMode = false;
                this.showAlert = true;
                this.isSuccess = true;
                this.responseMsg.push(
                  `Rental Details: ${response.data.message}`
                );
              } else {
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg.push(
                  `Rental Details: ${response.response.data.message}`
                );
              }
            })
            .catch(() => {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = appStrings.autoFailedMsg;
            });
        }
      }
    },
    addEditEscalation() {
      if (this.leaseForm.lease_investor_agreement_hdr_id) {
        const objRefrence = this.$refs.escalation.gridData.filter(data => {
          if (
            data.lease_escalation !== null &&
            data.lease_escalation_uom_vset !== null
          ) {
            return data;
          }
        });
        const tabDataArray = objRefrence.map(data => {
          return {
            lease_vendor_id: data.lease_vendor_id,
            lease_vendor_site_id: data.lease_vendor_site_id,
            lease_escalation_num: data.lease_escalation_num,
            sac: data.sac,
            escalation_date: data.escalation_date,
            charge_ac_ccid: data.charge_ac_ccid,
            lease_escalation: data.lease_escalation, //NotNull
            lease_escalation_uom_vset: data.lease_escalation_uom_vset, //NotNull
            lease_investor_agreement_escalation_id:
              data.lease_investor_agreement_escalation_id, //NotNull
            period_from: commonHelper.formattedDate(data.period_from_to[0]),
            period_to: commonHelper.formattedDate(data.period_from_to[1]),
            trx_type_dtl_id: data.trx_type_dtl_id,
            period: data.period,
            escalation_type_vset: data.escalation_type_vset,
            lease_tenant_agreement_escalation_id:
              data.lease_tenant_agreement_escalation_id
          };
        });
        if (tabDataArray.length) {
          const payload = {
            tabName: 'escalation',
            leaseInvestorHdrId: this.leaseForm.lease_investor_agreement_hdr_id,
            tabData: {
              lease_investor_agreement_esc_details: tabDataArray
                ? tabDataArray
                : []
            }
          };
          this.$store
            .dispatch('leases/addEditInvestorLeaseTabsData', payload)
            .then(response => {
              if (response.status === 200) {
                this.activeTabList = [];
                this.editMode = false;
                this.showAlert = true;
                this.isSuccess = true;
                this.responseMsg.push(`Escalation: ${response.data.message}`);
              } else {
                this.showAlert = true;
                this.responseMsg.push(
                  `Escalation: ${response.response.data.message}`
                );
              }
            })
            .catch(() => {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg.push(`Escalation: ${appStrings.autoFailedMsg}`);
            });
        }
      }
    },
    addEditTermNotice() {
      if (this.leaseForm.lease_investor_agreement_hdr_id) {
        const objRefrence = this.$refs.terminationNotice.gridData.filter(
          data => {
            if (
              data.lease_termination_period !== null &&
              data.lease_termination_period_uom_vset !== null
            ) {
              return data;
            }
          }
        );
        const tabDataArray = objRefrence.map(data => {
          return {
            lease_investor_agreement_termination_id:
              data.lease_investor_agreement_termination_id, //NotNull
            lease_termination_period: data.lease_termination_period, //NotNull
            lease_termination_period_uom_vset:
              data.lease_termination_period_uom_vset, //NotNull
            period_from: commonHelper.formattedDate(data.period_from_to[0]),
            period_to: commonHelper.formattedDate(data.period_from_to[1]),
            active: data.active
          };
        });
        if (tabDataArray.length) {
          const payload = {
            tabName: 'termination-notice',
            leaseInvestorHdrId: this.leaseForm.lease_investor_agreement_hdr_id,
            tabData: {
              lease_investor_agreement_ter_details: tabDataArray
                ? tabDataArray
                : []
            }
          };
          this.$store
            .dispatch('leases/addEditInvestorLeaseTabsData', payload)
            .then(response => {
              if (response.status === 200) {
                this.activeTabList = [];
                this.editMode = false;
                this.showAlert = true;
                this.isSuccess = true;
                this.responseMsg.push(
                  `Termination Notice: ${response.data.message}`
                );
              } else {
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg.push(
                  `Termination Notice: ${response.response.data.message}`
                );
              }
            })
            .catch(() => {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg.push(
                `Termination Notice: ${appStrings.autoFailedMsg}`
              );
            });
        }
      }
    },
    addEditSecurityDeposit() {
      if (this.leaseForm.lease_investor_agreement_hdr_id) {
        const objRefrence = this.$refs.securityDeposit.gridData.filter(data => {
          if (
            data.period !== null &&
            data.lease_security_deposit !== null &&
            data.lease_security_deposit_uom_vset !== null
          ) {
            return data;
          }
        });
        const tabDataArray = objRefrence.map(data => {
          return {
            lease_investor_agreement_sec_dpt_id:
              data.lease_investor_agreement_sec_dpt_id, //NotNull
            period: data.period, //NotNull
            lease_security_deposit: data.lease_security_deposit, //NotNull
            lease_security_deposit_uom_vset:
              data.lease_security_deposit_uom_vset, //NotNull
            active: data.active,
            security_deposit_type_code: data.security_deposit_type_code,
            charge_ac_ccid: data.charge_ac_ccid
          };
        });
        if (tabDataArray.length) {
          const payload = {
            tabName: 'security-deposit',
            leaseInvestorHdrId: this.leaseForm.lease_investor_agreement_hdr_id,
            tabData: {
              lease_investor_agreement_sec_details: tabDataArray
                ? tabDataArray
                : []
            }
          };
          this.$store
            .dispatch('leases/addEditInvestorLeaseTabsData', payload)
            .then(response => {
              if (response.status === 200) {
                this.activeTabList = [];
                this.editMode = false;
                this.showAlert = true;
                this.isSuccess = true;
                this.responseMsg.push(
                  `Security Deposit: ${response.data.message}`
                );
              } else {
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg.push(
                  `Security Deposit: ${response.response.data.message}`
                );
              }
            })
            .catch(() => {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg.push(
                `Security Deposit: ${appStrings.autoFailedMsg}`
              );
            });
        }
      }
    },
    addEditTax() {
      if (this.leaseForm.lease_investor_agreement_hdr_id) {
        const objRefrence = this.$refs.tax.gridData.filter(data => {
          if (data.tax_cat_id !== null && data.tax_group_id !== null) {
            return data;
          }
        });
        const tabDataArray = objRefrence.map(data => {
          return {
            lease_investor_agreement_tax_id:
              data.lease_investor_agreement_tax_id, //NotNull
            tax_cat_id: data.tax_cat_id, // NotNull
            tax_group_id: data.tax_group_id, // NotNull
            period_from: commonHelper.formattedDate(data.period_from_to[0]),
            period_to: commonHelper.formattedDate(data.period_from_to[1]),
            active: data.active
          };
        });
        if (tabDataArray.length) {
          const payload = {
            tabName: 'tax',
            leaseInvestorHdrId: this.leaseForm.lease_investor_agreement_hdr_id,
            tabData: {
              lease_investor_agreement_tax_details: tabDataArray
                ? tabDataArray
                : []
            }
          };
          this.$store
            .dispatch('leases/addEditInvestorLeaseTabsData', payload)
            .then(response => {
              if (response.status === 200) {
                this.activeTabList = [];
                this.editMode = false;
                this.showAlert = true;
                this.isSuccess = true;
                this.responseMsg.push(`Tax: ${response.data.message}`);
              } else {
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg.push(`Tax: ${response.response.data.message}`);
              }
            })
            .catch(() => {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg.push(`Tax: ${appStrings.autoFailedMsg}`);
            });
        }
      }
    },
    addEditCamAndOther() {
      if (this.leaseForm.lease_investor_agreement_hdr_id) {
        const objRefrence = this.$refs.camAndOtherCharges.gridData.filter(
          data => {
            if (
              data.lease_recovery_amount !== null &&
              data.lease_recovery_period !== null &&
              data.lease_recovery_period_uom_vset !== null
            ) {
              return data;
            }
          }
        );
        const tabDataArray = objRefrence.map(data => {
          return {
            lease_investor_agreement_recovery_id:
              data.lease_investor_agreement_recovery_id, //NotNull
            period_from: commonHelper.formattedDate(data.period_from_to[0]),
            period_to: commonHelper.formattedDate(data.period_from_to[1]),
            active: data.active,
            lease_vendor_id: data.lease_vendor_id, //NotNull
            lease_vendor_site_id: data.lease_vendor_site_id, //NotNull
            lease_recovery_amount: data.lease_recovery_amount, //NotNull
            lease_recovery_period: data.lease_recovery_period, //NotNull
            lease_recovery_period_uom_vset: data.lease_recovery_period_uom_vset, //NotBlank
            lease_recovery_rate: data.lease_recovery_rate, //NotNull
            lease_recovery_rate_uom_vset: data.lease_recovery_rate_uom_vset, //NotBlank
            lease_recovery_type_vset: data.lease_recovery_type_vset,
            recovery_basis: data.recovery_basis
          };
        });
        if (tabDataArray.length) {
          const payload = {
            tabName: 'recovery',
            leaseInvestorHdrId: this.leaseForm.lease_investor_agreement_hdr_id,
            tabData: {
              lease_investor_agreement_rec_details: tabDataArray
                ? tabDataArray
                : []
            }
          };
          this.$store
            .dispatch('leases/addEditInvestorLeaseTabsData', payload)
            .then(response => {
              if (response.status === 200) {
                this.activeTabList = [];
                this.editMode = false;
                this.showAlert = true;
                this.isSuccess = true;
                this.responseMsg.push(`Recovery: ${response.data.message}`);
              } else {
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg.push(
                  `Recovery: ${response.response.data.message}`
                );
              }
            })
            .catch(() => {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg.push(`Recovery: ${appStrings.autoFailedMsg}`);
            });
        }
      }
    },
    setLeaseFormData(selectedLeaseDetails) {
      this.editMode = false;
      this.leaseForm = selectedLeaseDetails;
      this.leaseForm.bill_rate = this.leaseForm.bill_rate
        ? this.leaseForm.bill_rate
        : this.leaseForm.rate;
      this.leaseForm.period_from_to = [
        new Date(this.leaseForm.from_date),
        new Date(this.leaseForm.to_date)
      ];
      this.selectedTab(this.activeTab);
      setTimeout(() => {
        this.fitoutScope = this.leaseForm.fitout_scope;
        this.logicNotes = this.leaseForm.logic_note;
      }, 2000);
      if (
        this.$route.name === 'notificationSummary' ||
        this.$route.name === 'dashboard' ||
        this.$route.name === 'workFlowSummary'
      ) {
        this.notificationSummary = true;
        this.getApprovalHistory(this.leaseForm.lease_investor_agreement_hdr_id);
      }
    },
    getApprovalHistory(leaseMasterAgreementHdrId) {
      this.loader = true;
      this.$store.dispatch('auth/getUserDetail').then(response => {
        if (response.status === 200) {
          this.userInfo = response.data.data;
          const payload = {
            approvalStatus: 'Y',
            nfaHdrId: leaseMasterAgreementHdrId,
            eventId: this.urlSummaryData
              ? this.urlSummaryData.wf_event_id
              : this.eventId
          };
          this.$store
            .dispatch('intWorkflow/getApprovalList', payload)
            .then(response => {
              this.loader = false;
              if (response.status === 200) {
                this.approvalHistoryList = response.data.data;
                const findCurrentUser = this.approvalHistoryList.find(
                  user =>
                    user.approver_employee_id === this.userInfo.employee_id &&
                    (user.approval_status === 'INPROCESS' ||
                      user.approval_status === 'CONSULT_INITIATED')
                );
                this.isApprovedByCurrrentUser = findCurrentUser ? true : false;
                const findConsultInitUser = this.approvalHistoryList.find(
                  data => data.approval_status === 'CONSULT_INITIATED'
                );
                this.isConsultInitiated = findConsultInitUser ? true : false;
              }
            })
            .catch(() => {
              this.loader = false;
            });
        }
      });
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    getSelectedCurrency(item) {
      this.leaseForm.currency = item.currency_name;
      this.leaseForm.transaction_currency_id = item.currency_id;
      this.showCommonModal = false;
    },
    getSelectedPartyCustomer(item) {
      this.leaseForm.lease_party_id = item.party_id;
      this.leaseForm.lease_party_name = item.party_name;
      this.showCommonModal = false;
    },
    getSelectedProject(item) {
      this.selectedProject.text = item.lease_project_name;
      this.selectedProject.value = item.lease_project_id;
      this.leaseForm.lease_prj_name = item.lease_project_name;
      this.leaseForm.lease_prj_id = item.lease_project_id;
      this.showCommonModal = false;
    },
    selectedTab(tab) {
      this.activeTab = tab;
      const tabIndex = this.activeTabList.indexOf(tab);
      if (tabIndex === -1) {
        this.activeTabList.push(this.activeTab);
        this.tabTimeOut = setTimeout(() => {
          this.eventBus.$emit(this.activeTab, {
            leaseInvestorHdrId: this.leaseForm.lease_investor_agreement_hdr_id,
            leaseMasterAgreementHdrId: this.selectedLeaseDetails
              ? null
              : this.leaseForm.lease_master_agreement_hdr_id,
            customerName: this.leaseForm.lease_party_name,
            customerId: this.customerId,
            leaseForm: this.leaseForm
          });
        }, 0);
      }
    },
    showHideCommonLeaseModal(flag, modalType) {
      this.showCommonModal = flag;
      this.modalType = modalType;
    },
    selectedLeaseMaster(item) {
      this.leaseForm.lease_master_agreement_hdr_id =
        item.lease_master_agreement_hdr_id;
      this.leaseForm.lease_agreement_no = item.lease_agreement_no;
      this.leaseForm.lease_prj_id = item.lease_prj_id;
      this.leaseForm.lease_prj_name = item.lease_prj_name;
      this.leaseForm.lease_party_id = item.lease_party_id;
      this.leaseForm.lease_party_name = item.party_name;
      this.selectedProject.text = item.lease_prj_name;
      this.selectedProject.value = item.lease_prj_id;
      this.leaseForm.currency = item.currency;
      this.leaseForm.transaction_currency_id = item.transaction_currency_id;
      this.showCommonModal = false;
      this.getCustomerByPartyId(this.leaseForm.lease_party_id);
      this.getMasterSpec(this.leaseForm.lease_master_agreement_hdr_id);
    },
    selectedLeaseTenant(item) {
      this.leaseForm.lease_master_agreement_hdr_id =
        item.lease_master_agreement_hdr_id;
      this.leaseForm.lease_agreement_no = item.lease_agreement_no;
      this.leaseForm.lease_prj_id = item.lease_prj_id;
      this.leaseForm.lease_prj_name = item.lease_prj_name;
      this.leaseForm.lease_party_id = item.lease_party_id;
      this.leaseForm.lease_party_name = item.party_name;
      this.selectedProject.text = item.lease_prj_name;
      this.selectedProject.value = item.lease_prj_id;
      this.leaseForm.currency = item.currency;
      this.leaseForm.transaction_currency_id = item.transaction_currency_id;
      this.showCommonModal = false;
      this.getCustomerByPartyId(this.leaseForm.lease_party_id);
      this.getMasterSpec(this.leaseForm.lease_master_agreement_hdr_id);
    },
    getSelectedUnit(item) {
      this.leaseForm.lease_prj_unit_name = item.unit_name;
      this.leaseForm.lease_prj_unit_id = item.lease_prj_unit_id;
      this.showCommonModal = false;
      this.getLeaseUnitArea();
    },
    getLeaseUnitArea() {
      const payload = {
        prjUnitId: this.leaseForm.lease_prj_unit_id
      };
      this.$store
        .dispatch('leaseUnit/getLeaseUnitArea', payload)
        .then(response => {
          const results = response.data.data;
          this.leaseForm.super_area_uom_vset_meaning =
            results.super_area_uom_vset_mean;
          this.leaseForm.super_area_uom_vset = results.super_area_uom_vset;
          this.leaseForm.super_area = results.super_area;
          this.leaseForm.carpet_area_uom_vset_meaning =
            results.carpet_area_uom_vset_mean;
          this.leaseForm.carpet_area_uom_vset = results.carpet_area_uom_vset;
          this.leaseForm.carpet_area = results.carpet_area;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getMasterSpec(leaseMasterAgreementHdrId) {
      const payload = {
        tabName: 'specification',
        leaseMasterAgreementHdrId: leaseMasterAgreementHdrId
      };
      this.$store
        .dispatch('leases/getLeaseMasterAgreementDataById', payload)
        .then(resp => {
          if (resp.status === 200 && resp.data.data.length) {
            const activeData = resp.data.data.find(
              item => item.active === true
            );
            if (activeData) {
              this.leaseForm.rate_uom_vset_meaning =
                activeData.rate_uom_vset_meaning;
              this.leaseForm.rate_uom_vset = activeData.rate_uom_vset;
              this.leaseForm.rate = activeData.rate;
              this.leaseForm.bill_rate = this.leaseForm.rate;
              this.selectedTab(this.activeTab);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    changeStatus(status) {
      this.loader = true;
      const payload = [
        {
          event_id: this.urlSummaryData
            ? this.urlSummaryData.wf_event_id
            : this.eventId,
          notification_comments: this.notificationComments,
          notification_status: status,
          wf_inprocess_dtl_id: this.wfDtlId,
          temp_approved_amount: 0
        }
      ];
      this.$store
        .dispatch('intWorkflow/approvalReject', { approval_requests: payload })
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.getApprovalHistory(
              this.leaseForm.lease_investor_agreement_hdr_id
            );
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg.push(response.data.message);
          } else {
            this.isSuccess = false;
            this.responseMsg.push(response.response.data.message);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getCustomerByPartyId(partyId) {
      this.$store
        .dispatch('leases/getCustomerByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            this.customerId = response.data.data.customer_id;
          }
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
