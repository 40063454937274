import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import addLeaseUnit from './addLeaseUnit';
import leaseUnitDetails from './leaseUnitDetails';
import { required } from 'vuelidate/lib/validators';
import HistoryRecord from '@/app/components/onlineApproval/internalWorkFlow/history/historyRecord';
import ApprovalHistory from '@/app/components/onlineApproval/internalWorkFlow/history/approvalHistory';
import UploadDocument from '@/app/components/onlineApproval/internalWorkFlow/history/uploadDocument';
import sendForApproval from '@/app/components/onlineApproval/internalWorkFlow/history/sendForApproval';
import openKmAuthentication from '@/app/components/onlineApproval/internalWorkFlow/openKmAuthentication';
import downlaodFiles from '../leaseAssignSubUnit/assignSubUnitDetails/downloadFiles';
export default {
  name: 'leaseUnit',
  watch: {
    currentPage: function() {
      this.getLeaseUnitList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getLeaseUnitList();
    },
    downloadCurrentPage: function() {
      this.getUploadedDocDtl();
    },
    downloadPerPage: function() {
      this.downloadCurrentPage = 1;
      this.getUploadedDocDtl();
    }
  },
  components: {
    commonHelper,
    addLeaseUnit,
    leaseUnitDetails,
    HistoryRecord,
    ApprovalHistory,
    UploadDocument,
    sendForApproval,
    openKmAuthentication,
    downlaodFiles
  },
  props: [
    'showLeaseModal',
    'selectedProject',
    'selectedTower',
    'selectedFloor',
    'showLeaseModalUnit'
  ],
  data() {
    return {
      leasePrj: {
        value: null,
        text: null
      },
      leaseTwr: {
        value: null,
        text: null
      },
      leaseFloor: {
        value: null,
        text: null
      },
      approvalStatus: {
        value: null,
        text: null
      },
      unsubscribe: null,
      showAddLeaseUnitModal: false,
      showDetails: false,
      showValueSetModal: false,
      parent_value_set_id: null,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      unitName: null,
      loader: false,
      unitDisplay: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      mapped: false,
      leaseUnitRowDetails: null,
      leaseUnitData: [],
      leaseUnitFields: [
        {
          key: 'active_radio',
          label: 'Select',
          stickyColumn: true,
          variant: 'primary',
          class: 'stickyColumn'
        },
        {
          key: 'lease_prj_id',
          class: 'd-none'
        },
        {
          key: 'lease_prj_name',
          label: 'Project'
        },
        {
          key: 'lease_prj_unit_id',
          class: 'd-none'
        },
        {
          key: 'unit_name',
          label: 'Office'
        },
        {
          key: 'unit_display_name',
          label: 'Office Display'
        },
        {
          key: 'tenant_name'
        },
        {
          key: 'super_area'
        },
        {
          key: 'super_area_uom_vset_meaning',
          label: 'Super Area UOM'
        },
        {
          key: 'billable_area'
        },
        {
          key: 'billable_area_uom_vset_meaning',
          label: 'Billable Area UOM'
        },
        {
          key: 'is_sub_unit_mapped'
        },
        {
          key: 'floor_id',
          class: 'd-none'
        },
        {
          key: 'floor_name',
          label: 'Floor'
        },
        {
          key: 'tower_id',
          class: 'd-none'
        },
        {
          key: 'tower_name',
          label: 'Tower'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        },
        {
          key:'is_show_on_portal',
          label:'Is Shown On Portal'
        },
        {
          key: 'active'
        },
        {
          key: 'approval_status'
        }
      ],
      routeName: this.$router.currentRoute.name,
      selectedGridData: null,
      leasePrjUnitId: null,
      showDownloadModal: false,
      showHistoryBtn: false,
      showOpenKmModal: false,
      historyType: null,
      showHistory: false,
      isShownOnPortal:false
    };
  },
  validations: {
    leasePrj: {
      text: {
        required
      }
    }
  },
  mounted() {
   if (this.showLeaseModal) {
      this.leasePrj.value = this.selectedProject.value;
      this.leasePrj.text = this.selectedProject.text;
      this.getLeaseUnitList();
    }
    if (this.showLeaseModalUnit) {
      this.leasePrj.value = this.selectedProject.value;
      this.leasePrj.text = this.selectedProject.text;
      this.leaseTwr = this.selectedTower;
      this.leaseFloor = this.selectedFloor;
      this.getLeaseUnitList();
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showAddLeaseUnitModal = true;
          this.leaseUnitRowDetails = null;
        }
        if (
          actionName === 'download' &&
          !this.showAddLeaseUnitModal &&
          !this.showDetails
        ) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'leaseUnit/getLeaseUnitList',
            'lease-unit',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getLeaseUnitList() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          lease_project_id: this.leasePrj.value,
          lease_tower_id: this.leaseTwr.value,
          lease_floor_id: this.leaseFloor.value,
          unit_name: this.unitName,
          unit_display_name: this.unitDisplay,
          mapped: this.showLeaseModal ? true : this.mapped,
          approval_status: this.approvalStatus.value,
          is_show_on_portal:this.isShownOnPortal
        };
        this.loader = true;
        this.$store
          .dispatch('leaseUnit/getLeaseUnitList', this.payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              this.leaseUnitData = resp.data.data.page;
              this.totalRows = resp.data.data.total_elements;
            } else {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = resp.response.data.message;
              alert(this.responseMsg);
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    clearSearchFilters() {
      this.leasePrj = {
        value: null,
        text: null
      };
      this.leaseTwr = {
        value: null,
        text: null
      };
      this.leaseFloor = {
        value: null,
        text: null
      };
      this.approvalStatus = {
        value: null,
        text: null
      };
      this.unitName = null;
      this.unitDisplay = null;
      this.mapped = false;
      this.parent_value_set_id = null;
      this.leaseUnitData = [];
      this.totalRows = null;
      this.currentPage = 1;
      this.showHistoryBtn = false,
      this.isShownOnPortal = false
    },
    hideAddLeaseModal() {
      this.showAddLeaseUnitModal = false;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.leasePrj = {
          value: item.value_set_dtl_id,
          text: item.lease_prj_name
        };
        this.valueClearFunction();
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_TOWER_LIST) {
        this.leaseTwr = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.valueClearFunction();
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_FLOOR_LIST) {
        this.leaseFloor = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.APPROVAL_STATUS_LEASE
      ) {
        this.approvalStatus = {
          value: item.value_meaning,
          text: item.value_code
        };
      }
    },
    valueClearFunction() {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.leaseTwr = {
          value: null,
          text: null
        };
        this.leaseFloor = {
          value: null,
          text: null
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_TOWER_LIST) {
        this.leaseFloor = {
          value: null,
          text: null
        };
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode; 
      this.parent_value_set_id = null;
      if (vsetCode === appStrings.VALUESETTYPE.LEASE_TOWER_LIST) {
        this.parent_value_set_id = this.leasePrj.value;
      } else if (vsetCode === appStrings.VALUESETTYPE.LEASE_FLOOR_LIST) {
        this.parent_value_set_id = this.leaseTwr.value;
      }
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    rowSelected(item) {
      if (this.showLeaseModal || this.showLeaseModalUnit) {
        this.$emit('getSelectedUnit', item);
      } else {
        this.leaseUnitRowDetails = item;
        (this.leasePrjUnitId = item.lease_prj_unit_id),
          (this.showDetails = true);
      }
    },
    closeLeaseUnitDetail() {
      this.showDetails = false;
      this.getLeaseUnitList();

    },
    clearVsetValues(vsetCode) {
      switch (vsetCode) {
        case this.leasePrj.value:
          this.leasePrj = {
            text: null,
            value: null
          };
          break;
        case this.leaseTwr.value:
          this.leaseTwr = {
            text: null,
            value: null
          };
          break;
        case this.leaseFloor.value:
          this.leaseFloor = {
            text: null,
            value: null
          };
          break;
        case this.approvalStatus.value:
          this.approvalStatus = {
            text: null,
            value: null
          };
          break;
      }
    },
    selectedApproval(item) {
      this.leaseUnitRowDetails = item;
      this.leasePrjUnitId = item.lease_prj_unit_id;
      // this.showHideAddLeaseModal(true);
      this.showHistoryBtn = true;
    },
    showHideHistory(flag, historyType) {
      this.showHistory = flag;
      this.historyType = historyType;
    },
    checkOpenKmPasswordSaved(modalType) {
      // to check password save or not
      this.modalType = modalType;
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/checkSavedPassword')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data) {
              this.getOpenKmAuthToken();
            } else {
              this.showHideOpenKmModal(true);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getOpenKmAuthToken() {
      // to get auth token for open km
      const payload = {
        password: null,
        saved_password_flag: true,
        user_name: null
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmAuthenticate', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openkmAuthToken = response.data.data.token;
            if (this.modalType === 'DOWNLOAD') {
              this.downloadFiles(true);
            } else {
              this.selectedGridData = {
                ...this.leaseUnitRowDetails,
                nfa_cat_vset: null,
                nfa_cat: null,
                nfa_sub_cat_vset: null,
                nfa_sub_cat: null
              };
              this.showHideHistory(true, 'uploadDocument');
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },

    showHideOpenKmModal(flag) {
      // to open km modal
      this.showOpenKmModal = flag;
    },
    setOpenKmToken(token) {
      // to set token in km
      this.showHideOpenKmModal(false);
      if (this.modalType === 'DOWNLOAD') {
        this.downloadFiles(true);
      } else {
        this.openkmAuthToken = token;
        this.selectedGridData = {
          ...this.leaseUnitRowDetails,
          nfa_cat_vset: null,
          nfa_cat: null,
          nfa_sub_cat_vset: null,
          nfa_sub_cat: null
        };
        this.showHideHistory(true, 'uploadDocument');
      }
    },
    downloadFiles(flag) {
      this.showDownloadModal = flag;
      // this.getUploadedDocDtl();
    },
    
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
