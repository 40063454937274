import URL_UTILITY from '../../../../../utility/url.utility';
export default {
  name: 'termsConditon',
  components: {},
  props: ['partyRowDetails', 'dashboardDetails', 'formName'],
  data() {
    return {
      loader: false,
      vendorName: null,
      termsCondition: null,
      clauseLabel1: null,
      clauseLabel2: null,
      clauseLabel3: null,
      clauseStatus1: false,
      clauseStatus2: false,
      clauseStatus3: false,
      clauseReason1: null,
      clauseReason2: null,
      clauseReason3: null,
      signedOn: null,
      partyReqId: null,
      requestId: null,
      templateId: null,
      requestNumId: null,
      downloadFlag: false,
      dowloadReport: URL_UTILITY.getDownloadReportUrl
    };
  },
  mounted() {
    if (this.partyRowDetails) {
      this.partyReqId = this.partyRowDetails.req_id;
      this.vendorName = this.partyRowDetails.party_name;
    }
    if (this.dashboardDetails) {
      this.partyReqId = this.dashboardDetails.party_req_id;
      this.vendorName = this.dashboardDetails.vendor_name;
    }
    this.getTermsCondition();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'download' && this.formName == 'dashboard') {
          this.getTemplateDetails();
        }
      }
    });
  },
  methods: {
    getTermsCondition() {
      const payload = {
        party_req_id: this.partyReqId
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getTermsCondition', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            if (result) {
              this.termsCondition = result.terms;
              this.clauseLabel1 = result.disclosure_text1;
              this.clauseLabel2 = result.disclosure_text2;
              this.clauseLabel3 = result.disclosure_text3;
              this.clauseStatus1 = result.disclosure_status1;
              this.clauseStatus2 = result.disclosure_status2;
              this.clauseStatus3 = result.disclosure_status3;
              this.clauseReason1 = result.disclosure_reason1;
              this.clauseReason2 = result.disclosure_reason2;
              this.clauseReason3 = result.disclosure_reason3;
              this.signedOn = result.disclosure_creation_date;
            } else {
              alert('No Terms and Condition Found');
              this.$emit('closeModal');
            }
          }
        })
        .catch(() => {
          this.loader = false;
          alert('Something went wrong');
        });
    },
    getTemplateDetails() {
      const payload = {
        _page: 0,
        _limit: 10,
        template_name: 'Vendor_Terms_Conditions_temp'
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getTemplateDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.requestId = resp.data.data.page[0].request_id;
            this.templateId = resp.data.data.page[0].template_id;
            this.saveSubmitRequest();
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    saveSubmitRequest() {
      const payload = {
        admin_submit_req_details: [
          {
            request_num: 0,
            request_id: this.requestId,
            template_id: this.templateId,
            schedule_type: 'asap', //pass hardcode
            request_start_date: null,
            request_end_date: null,
            resubmit_interval: null,
            resubmit_interval_unit: null,
            day_of_month: null,
            day_of_week: null,
            output_format_id: 'PDF', //pass hardcode
            no_of_args: 25,
            request_parameter: this.partyReqId,
            sms_flag: false,
            email_flag: false,
            whatsapp_flag: false,
            child_req_count: null,
            email_comm_template_id: null,
            sms_comm_template_id: null,
            whatsapp_comm_template_id: null,
            argument1: this.partyReqId,
            argument2: null,
            argument3: null,
            argument4: null,
            argument5: null,
            argument6: null,
            argument7: null,
            argument8: null,
            argument9: null,
            argument10: null,
            argument11: null,
            argument12: null,
            argument13: null,
            argument14: null,
            argument15: null,
            argument16: null,
            argument17: null,
            argument18: null,
            argument19: null,
            argument20: null,
            argument21: null,
            argument22: null,
            argument23: null,
            argument24: null,
            argument25: null
          }
        ]
      };
      this.downloadFlag = true;
      this.loader = true;
      this.$store
        .dispatch('template/saveSubmitRequest', payload)
        .then(resp => {
          this.loader = false;
          this.downloadFlag = false;
          if (resp.status === 201) {
            this.requestNumId = resp.data.data[0].id;
            this.getSubmitRequest();
          }
        })
        .catch(() => {
          this.loader = false;
          this.downloadFlag = false;
        });
    },
    getSubmitRequest() {
      this.loader = true;
      this.$store
        .dispatch('template/getSubmitRequest', this.requestNumId)
        .then(resp => {
          if (resp.status === 200) {
            this.requestStatus = resp.data.data[0].status;
            this.reqId = resp.data.data[0].id;
            if (this.requestStatus === 'COMPLETED') {
              this.loader = false;
              window.location.href = this.dowloadReport + '/' + this.reqId;
            } else if (this.requestStatus === 'ERROR') {
              this.loader = false;
              alert('Request in ERROR can not Preview');
            } else {
              setTimeout(() => {
                this.getSubmitRequest();
              }, 3000);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
