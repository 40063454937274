import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
import codeCombination from '@/app/components/shared/codeCombination';
export default {
  name: 'SecurityDeposit',
  components: {
    DatePicker,
    codeCombination
  },
  props: ['editMode', 'leaseForm'],
  data() {
    return {
      revenue_account: null,
      revenue_account_code: null,
      revenue_account_meaning: null,
      investor_ccid: null,
      concatenated_segment: null,
      concatenated_segment_meaning: null,
      vsetCode: null,
      uomClassObj: null,
      specIndex: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      areaType: null,
      setTimeVsetCode: null,
      revenueVset: null,
      gridData: [
        {
          active: true,
          lease_tenant_agreement_hdr_id: null,
          lease_tenant_agreement_sec_dpt_id: 0,
          lease_security_deposit_uom_vset_meaning: null,
          period_from: null,
          period_to: null,
          sac: null,
          period: null,
          revenue_account: null,
          revenue_account_code: null,
          revenue_account_meaning: null,
          investor_ccid: null,
          concatenated_segment: null,
          concatenated_segment_meaning: null,
          lease_security_deposit_uom_vset: null,
          lease_security_deposit: null,
          request_num: null,
          security_deposit_type_meaning: null,
          security_deposit_cat_meaning: null,
          security_deposit_cat: null,
          security_deposit_type_code: null,
          security_deposit_status: null,
          create_security_deposit: false
        }
      ],
      gridFields: [
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        },
        {
          key: 'create_security_deposit'
        },
        {
          key: 'lease_security_deposit_uom_vset_meaning',
          label: 'UOM'
        },
        {
          key: 'security_deposit_type_meaning',
          label: 'Deposit Type'
        },
        {
          key: 'security_deposit_cat_meaning',
          label: 'Security Deposit'
        },
        {
          key: 'period'
        },
        {
          key: 'lease_security_deposit',
          label: 'Amount'
        },
        {
          key: 'revenue_account_meaning',
          label: 'Tenant Account'
        },
        {
          key: 'concatenated_segment_meaning',
          label: 'Investor Account'
        },
        {
          key: 'security_deposit_status_meaning',
          label: 'Status'
        },
        {
          key: 'request_num'
        },
        {
          key: 'error_msg'
        },
        {
          key: 'period_from'
        },
        {
          key: 'period_to'
        },
        {
          key: 'active'
        }
      ],
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: null,
      periodTo: null,
      StartDate: null,
      EndDate: null,
      showCodeCombinationModal: false,
      codeCombDtls: {
        le_id: null,
        lease_prj_code: null
      },
      combinationDetails: {
        le_id: null,
        project_code: null
      }
    };
  },
  mounted() {
    this.setDefaultRevenueAccount();
    this.eventBus.$on('periodFromTo', data => {
      if (this.gridData[0].security_deposit_status_meaning !== 'PROCESSED') {
        this.StartDate = commonHelper.formattedDate(data.periodFrom);
        this.EndDate = commonHelper.formattedDate(data.periodTo);
        this.gridData[0].period_from = commonHelper.formattedDate(data.periodFrom);
        this.gridData[0].period_to = commonHelper.formattedDate(data.periodTo);
      }
      this.EndDate = commonHelper.formattedDate(data.periodTo);
      this.gridData = JSON.parse(JSON.stringify(this.gridData));
    });
    this.eventBus.$on('sd', tabData => {
      this.codeCombDtls.le_id = tabData.leaseForm.le_id;
      this.codeCombDtls.lease_prj_code = tabData.leaseForm.lease_prj_code;
      this.EndDate = tabData.leaseForm.to_date;
      if (tabData.leaseTenantHdrId) {
        setTimeout(() => {
          this.getDetails(tabData.leaseTenantHdrId);
        }, 1000);
      }
      if (tabData.leaseMasterAgreementHdrId && !tabData.leaseTenantHdrId) {
        this.getMasterData(tabData.leaseMasterAgreementHdrId);
      }
    });
  },
  methods: {
    getDetails(leaseTenantHdrId) {
      const payload = {
        tabName: 'security-deposit',
        leaseTenantHdrId: leaseTenantHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('leases/getLeaseTenantDataById', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200 && resp.data.data.length) {
            this.gridData = resp.data.data;
            const createSecurityDepositIndex = resp.data.data.findIndex(
              data =>
                data.security_deposit_status === 'U' ||
                data.security_deposit_status === 'E'
            );
            if (createSecurityDepositIndex !== -1)
              this.gridData[
                createSecurityDepositIndex
              ].create_security_deposit = true;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getMasterData(leaseMasterAgreementHdrId) {
      const payload = {
        tabName: 'security-deposit',
        leaseMasterAgreementHdrId: leaseMasterAgreementHdrId
      };
      this.$store
        .dispatch('leases/getLeaseMasterAgreementDataById', payload)
        .then(resp => {
          if (resp.status === 200 && resp.data.data.length > 0) {
            // const filterData = resp.data.data.filter(
            //   item => item.active === true
            // );
            this.gridData = resp.data.data.map(item => {
              return {
                active: item.active,
                lease_tenant_agreement_hdr_id: null,
                lease_tenant_agreement_sec_dpt_id: 0,
                lease_security_deposit_uom_vset_meaning:
                  item.lease_security_deposit_uom_vset_meaning,
                period_from: this.StartDate,
                period_to: this.EndDate,
                sac: null,
                period: item.period,
                revenue_account: this.revenue_account,
                revenue_account_code: this.revenue_account_code,
                revenue_account_meaning: this.revenue_account_meaning,
                investor_ccid: this.investor_ccid,
                concatenated_segment: this.concatenated_segment,
                concatenated_segment_meaning: this.concatenated_segment_meaning,
                lease_security_deposit_uom_vset:
                  item.lease_security_deposit_uom_vset,
                lease_security_deposit:
                  item.period *
                  this.leaseForm.rate *
                  this.leaseForm.billable_area,
                request_num: null,
                security_deposit_type_code: item.security_deposit_type_code,
                security_deposit_type_meaning:
                  item.security_deposit_type_meaning,
                security_deposit_cat: item.security_deposit_cat,
                security_deposit_cat_meaning: item.security_deposit_cat_meaning,
                security_deposit_status: item.security_deposit_status,
                create_security_deposit: false
              };
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addNewRow(index) {
      if (this.editMode) {
        if (new Date(this.EndDate) <= new Date(this.gridData[index].period_to)) {
          alert('Lease Period Exhaust');
        } else {
          this.gridData.push({
            active: true,
            lease_tenant_agreement_hdr_id: null,
            lease_tenant_agreement_sec_dpt_id: 0,
            lease_security_deposit_uom_vset_meaning: null,
            period_from: commonHelper.formattedDate(
              this.findNexDate(new Date(this.gridData[index].period_to))
            ),
            period_to: this.EndDate,
            sac: null,
            period: null,
            revenue_account: null,
            revenue_account_code: null,
            revenue_account_meaning: null,
            investor_ccid: null,
            concatenated_segment: null,
            concatenated_segment_meaning: null,
            lease_security_deposit_uom_vset: null,
            lease_security_deposit: null,
            request_num: null,
            security_deposit_type_meaning: null,
            security_deposit_type_code: null,
            security_deposit_cat: null,
            security_deposit_cat_meaning: null,
            security_deposit_status: null,
            create_security_deposit: false
          });
        }
      }
    },
    removeRow(index) {
      if (this.editMode) {
        this.gridData.splice(index, 1);
      }
    },
    opendDependentUom(vsetCode, index) {
      const payload = {
        valueSetName: vsetCode,
        dependentVariable: {
          user_id: this.$store.state.auth.userId,
          res_id: this.$store.state.shared.responsibilityId
        }
      };
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          this.loader = false;
          this.uomClassObj = null;
          if (response.status === 200) {
            const findTimeObj = response.data.data.page.find(
              vsetData => vsetData.value_code === 'TIME'
            );
            if (findTimeObj) {
              this.uomClassObj = findTimeObj;
              this.openValueSetModal('UOM', index);
            }
          }
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'UOM') {
        this.gridData[this.specIndex].lease_security_deposit_uom_vset =
          item.value_code;
        this.gridData[this.specIndex].lease_security_deposit_uom_vset_meaning =
          item.value_meaning;
      } else if (this.vsetCode === 'SECURITY_DEPOSIT_TYPE') {
        this.gridData[this.specIndex].security_deposit_type_code =
          item.value_code;
        this.gridData[this.specIndex].security_deposit_type_meaning =
          item.value_meaning;
      } else if (this.vsetCode === 'SECURITY_DEPOSIT_CAT') {
        this.gridData[this.specIndex].security_deposit_cat =
          item.value_code;
        this.gridData[this.specIndex].security_deposit_cat_meaning =
          item.value_meaning;
      } else if (this.vsetCode === this.revenueVset) {
        this.gridData[this.specIndex].revenue_account = item.value_code;
        this.gridData[this.specIndex].revenue_account_code = item.value_code;
        this.gridData[this.specIndex].revenue_account_meaning =
          item.value_meaning;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.specIndex = index;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.uomClassObj && vsetCode !== 'SECURITY_DEPOSIT_TYPE') {
        this.parent_value_set_id = this.uomClassObj.value_set_dtl_id;
      }
      this.uomClassObj = null;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    calcAmount(period, index) {
      this.gridData[index].lease_security_deposit =
        period * this.leaseForm.rate * this.leaseForm.billable_area;
    },
    checkUncheckRadio(specIndex) {
      this.gridData.forEach((element, index) => {
        return (element.active = index === specIndex ? true : false);
      });
    },
    validateCheckBox(row) {
      // validation of checkbox
      if (row.request_num) {
        if (!/e/i.test(row.security_deposit_status)) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    createSecDeposit(index) {
      const payload = {
        lease_schd_template: [
          {
            entity_id: this.gridData[index].lease_tenant_agreement_sec_dpt_id,
            entity_type: 'SDEP',
            lease_id: this.gridData[index].lease_tenant_agreement_hdr_id
          }
        ]
      };
      this.loader = true;
      this.$store
        .dispatch('leases/createRentaldetails', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 201) {
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.getDetails(this.gridData[index].lease_tenant_agreement_hdr_id);
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsgmessage;
        });
    },
    getValueSetNameForRevenueAcc(index) {
      this.$store
        .dispatch('leases/getLeBasedNaturalAccountVset', this.leaseForm.le_id)
        .then(resp => {
          if (resp.status === 200) {
            this.revenueVset = resp.data.data;
            if (!this.revenueVset) {
              return alert(
                'Natural Account Setup is Pending for this Legal Entity'
              );
            }
            this.openValueSetModal(resp.data.data, index);
          }
        })
        .catch(err => {
          alert(err);
        });
    },
    setDefaultRevenueAccount() {
      const revenueAccount = commonHelper.getValuesetData(
        appStrings.VALUESETTYPE.VATIKA_GL_Accounts,
        14500001
      );
      revenueAccount.then(key => {
        this.revenue_account = key[0].value_code;
        this.revenue_account_code = key[0].value_set_dtl_id;
        this.revenue_account_meaning =
          key[0].value_code + ' - ' + key[0].value_meaning;
      });
    },
    periodFromIndex(index) {
      this.specIndex = index;
      // this.gridData[this.specIndex].period_to = null;
    },
    periodToIndex(index) {
      this.specIndex = index;
    },
    disabledDates(date) {
      return commonHelper.disabledDates(
        date,
        this.gridData[this.specIndex].period_from
      );
    },
    findNexDate(date) {
      let nextFromDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() + 1
      );
      return nextFromDate;
    },
    clearVsetValues(vsetCode, index) {
      switch (vsetCode) {
        case 'UOM_CLASS':
          this.gridData[index].lease_security_deposit_uom_vset_meaning = null;
          break;

        case 'SECURITY_DEPOSIT_TYPE':
          this.gridData[index].security_deposit_type_meaning = null;
          break;

        case 'SECURITY_DEPOSIT_CAT':
          this.gridData[index].security_deposit_cat_meaning = null;
          break;

        case 'INPROCESS':
          this.gridData[index].revenue_account_meaning = null;
          break;
      }
    },
    showHideCodeCombinationModal(flag, index) {
      this.segmentIndex = index;
      if (flag) {
        if (!this.codeCombDtls.le_id) {
          return alert(appStrings.DEPENDENTVSETMSG.LEMSG);
        }
        if (!this.codeCombDtls.lease_prj_code) {
          return alert(appStrings.DEPENDENTVSETMSG.FMSPRJMSG);
        }
        this.combinationDetails.le_id = this.codeCombDtls.le_id;
        this.combinationDetails.project_code = this.codeCombDtls.lease_prj_code;
      }
      this.showCodeCombinationModal = flag;
    },
    selectedSegment(item) {
      this.gridData[this.segmentIndex].investor_ccid = item.ccid;
      this.gridData[this.segmentIndex].concatenated_segment = item.segment_code;
      this.gridData[this.segmentIndex].concatenated_segment_meaning =
        item.segment_meaning;
      this.gridData = JSON.parse(JSON.stringify(this.gridData));
      this.showCodeCombinationModal = false;
    }
  },
  beforeDestroy() {
    this.eventBus.$off('sd');
    this.eventBus.$off('periodFromTo');
  }
};
