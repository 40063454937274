<template>
  <div>
    <div class="d-flex px-2" style="margin-top: 50px">
      <div class="p-2">
        <div class="dropdown">
          <button
            class="btn btn-vatika dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {{ responsibility_name }}
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <div
              class="dropdown-item px-2"
              v-for="menu in menus"
              :key="menu.responsibility_name"
            >
              <a
                href="javascript:void(0)"
                @click="setActiveSideMenu(menu, 'Responsibility')"
                class="text-dark p-0"
                style="font-size: 0.8rem"
                >{{ menu.responsibility_name }}</a
              >
            </div>
          </div>
        </div>
        <!-- <div
          class="py-2"
          v-if="activeView != 'Administrator' && activeView != 'ADMINISTRATOR'"
        >
          <button
            class="btn btn-vatika text-start"
            style="width: 100%; text-align: left"
          >
            {{ activeTitle }}
          </button>
        </div> -->
      </div>
      <div class="p-2 flex-grow-1">
        <div class="d-flex">
          <div class="card rounded-0 bg-transparent responsibility_menu">
            <div
              class="panel-group responsibility_menu_items"
              id="accordionMenu"
              role="tablist"
              aria-multiselectable="true"
            >
              <div
                class="menu-list navbar panel panel-default mr-1"
                v-for="responsibility in activeMenu"
                :key="responsibility.menu_name.replace(' ', '')"
              >
                <ul id="menu">
                  <li class="parent panel-heading">
                    <button
                      class="btn btn-vatika dropdown-toggle"
                      type="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {{ responsibility.menu_name }}
                    </button>
                    <ul class="child">
                      <li
                        class="parent"
                        v-for="submenu1 in responsibility.sub_menu"
                        :key="submenu1.menu_name"
                        @click="
                          submenu1.menu_action != null
                            ? setActiveView(submenu1)
                            : null
                        "
                      >
                        <a
                          :draggable="
                            submenu1.menu_action != null ? true : false
                          "
                          @dragstart="startDrag($event, submenu1)"
                          >{{ submenu1.menu_name }}
                          <span
                            class="expand"
                            v-if="submenu1.sub_menu && submenu1.sub_menu.length"
                            >»</span
                          ></a
                        >
                        <ul style="width:95%" class="child">
                          <li
                            v-for="submenu2 in submenu1.sub_menu"
                            :key="submenu2.menu_name"
                            :class="
                              submenu2.menu_action === activeView
                                ? 'active'
                                : null
                            "
                            @click="
                              submenu2.menu_action != null
                                ? setActiveView(submenu2)
                                : null
                            "
                          >
                            <a
                              draggable="true"
                              @dragstart="startDrag($event, submenu2)"
                              >{{ submenu2.menu_name }}
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="access-menu-id"
        class="p-2 btn-action"
        v-if="
          activeResponsibility != 'Responsibility' &&
            activeResponsibility != 'Administrator'
        "
      >
      <b-row class="justify-content-end" style="font-size: 1rem" v-if="$route.name=='LocDash'">
          <!-- <div class="p-1 " v-if="accessRights.addAllow"> -->
          <div class="p-1">
            <b-button block title="Add" class="p-1" @click="usersAction('add')" v-if="this.flagLoc">
              <b-icon icon="plus" aria-hidden="true"></b-icon>
            </b-button>
          </div>

          <div class="p-1" v-if="accessRights.editAllow && this.flagLoc">
            <b-button
              block
              title="Update"
              class="p-1"
              @click="usersAction('update')"
            >
              <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
            </b-button>
          </div>

          <div class="p-1" v-if="accessRights.addAllow && this.flagLoc">
            <b-button
              block
              title="Save"
              class="p-1"
              @click="usersAction('save')"
            >
              <b-icon icon="check" aria-hidden="true"></b-icon>
            </b-button>
          </div>

          <div class="p-1" v-if="accessRights.deleteAllow && this.flagLoc">
            <b-button
              block
              title="Delete"
              class="p-1"
              @click="usersAction('delete')"
            >
              <b-icon icon="x" aria-hidden="true"></b-icon>
            </b-button>
          </div>

          <div class="p-1" v-if="accessRights.uploadAllow && this.flagLoc">
            <b-button
              block
              title="Upload"
              class="p-1"
              @click="usersAction('upload')"
            >
              <b-icon icon="upload" aria-hidden="true"></b-icon>
            </b-button>
          </div>

          <div class="p-1" v-if="accessRights.downloadAllow && this.flagLoc">
            <b-button
              block
              title="Download"
              class="p-1"
              @click="usersAction('download')"
            >
              <b-icon icon="cloud-download" aria-hidden="true"></b-icon>
            </b-button>
          </div>
          <div class="p-1" v-if="accessRights.printAllow && this.flagLoc">
            <b-button
              block
              title="Print"
              class="p-1"
              @click="usersAction('print')"
            >
              <b-icon icon="printer" aria-hidden="true"></b-icon>
            </b-button>
          </div>
          <div class="p-1" v-if="accessRights.approvalAllow && this.flagLoc">
            <b-button
              block
              title="Approval"
              class="p-1"
              @click="usersAction('approval')"
            >
              <b-icon icon="arrow-up-circle" aria-hidden="true"></b-icon>
            </b-button>
          </div>
        </b-row>
        <b-row class="justify-content-end" style="font-size: 1rem" v-else>
          <!-- <div class="p-1 " v-if="accessRights.addAllow"> -->
          <div class="p-1">
            <b-button block title="Add" class="p-1" @click="usersAction('add')">
              <b-icon icon="plus" aria-hidden="true"></b-icon>
            </b-button>
          </div>

          <div class="p-1" v-if="accessRights.editAllow">
            <b-button
              block
              title="Update"
              class="p-1"
              @click="usersAction('update')"
            >
              <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
            </b-button>
          </div>

          <div class="p-1" v-if="accessRights.addAllow">
            <b-button
              block
              title="Save"
              class="p-1"
              @click="usersAction('save')"
            >
              <b-icon icon="check" aria-hidden="true"></b-icon>
            </b-button>
          </div>

          <div class="p-1" v-if="accessRights.deleteAllow">
            <b-button
              block
              title="Delete"
              class="p-1"
              @click="usersAction('delete')"
            >
              <b-icon icon="x" aria-hidden="true"></b-icon>
            </b-button>
          </div>

          <div class="p-1" v-if="accessRights.uploadAllow">
            <b-button
              block
              title="Upload"
              class="p-1"
              @click="usersAction('upload')"
            >
              <b-icon icon="upload" aria-hidden="true"></b-icon>
            </b-button>
          </div>

          <div class="p-1" v-if="accessRights.downloadAllow">
            <b-button
              block
              title="Download"
              class="p-1"
              @click="usersAction('download')"
            >
              <b-icon icon="cloud-download" aria-hidden="true"></b-icon>
            </b-button>
          </div>
          <div class="p-1" v-if="accessRights.printAllow">
            <b-button
              block
              title="Print"
              class="p-1"
              @click="usersAction('print')"
            >
              <b-icon icon="printer" aria-hidden="true"></b-icon>
            </b-button>
          </div>
          <div class="p-1" v-if="accessRights.approvalAllow">
            <b-button
              block
              title="Approval"
              class="p-1"
              @click="usersAction('approval')"
            >
              <b-icon icon="arrow-up-circle" aria-hidden="true"></b-icon>
            </b-button>
          </div>
        </b-row>
      </div>
    </div>
    <div>
      <div
        v-if="
          activeResponsibility === 'Responsibility' ||
            activeResponsibility === 'Administrator'
        "
      >
        <div>
          <administrator
            :preferences="preferences"
            :responsibilities="responsibilities"
            :activeMenu="activeMenu"
            :reports="reports"
            @loaded="
              {
                getPreferences;
              }
            "
          />
        </div>
        <b-row style="margin-top: -0.5rem;">
          <span >
            <h4 class="text-white pl-2" style="font-weight: bold;">
              Preferences :-
            </h4>
          </span>
          <b-col md="12" class="p-2">
            <div v-if="error === 'preferences'">
              <b-alert show variant="danger">Cannot Add Preferences </b-alert>
            </div>
            <div class="">
              <div
                style="margin-top: -12px;"
                class="drop-zone"
                @drop="onDrop($event)"
                @dragover.prevent
                @dragenter.prevent
              >
                <b-card>
                  <b-button
                    variant="outline-success btn-preferences"
                    v-for="preference in preferences"
                    :key="preference.index"
                  >
                    <b-icon :icon="preference.icon"></b-icon>
                    <span @click="setActiveView(preference)" class="pref">{{
                      preference.menu_name
                    }}</span>
                    <b-icon
                      icon="trash"
                      @click="deletePreference(preference)"
                    ></b-icon>
                  </b-button>
                </b-card>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div></div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import {
  getPreferences,
  addPreferences,
  getAccessRightDataByMenuId,
  deletePreferences
} from '@/app/services/api';
import { Administrator } from './index';

export default {
  name: 'landing',
  components: {
    Administrator
  },
  computed: {
    filteredList() {
      return this.reports.filter(report => {
        return report.report_name
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
    },
  },
  data() {
    return {
      isCurrentPath: false,
      dragItemData: null,
      accessRights: {},
      content: '',
      responsibilities: [],
      responsibility_name: '',
      menus: [],
      activeView: 'Administrator',
      activeTitle: '',
      activeMenu: [],
      reports: [],
      search: '',
      activeTab: 0,
      activeSubmenu2: true,
      activeSubmenu3: false,
      error: '',
      activeButton: 'landing',
      preferences: [],
      activeResponsibility: 'Administrator',
      showModalState: false,
      formState: 'view',
      unsubscribe: null,
      flagLoc:false,
    };
  },
  mounted() {
    if (localStorage.responsibilities != '') {
      this.responsibilities = JSON.parse(localStorage.responsibilities);
      this.setMenus();
    }
    this.unsubscribe = this.$store.subscribe(mutation => {
      if (mutation.type === 'shared/homeIconClicked') {
        this.responsibilities = JSON.parse(localStorage.responsibilities);
        this.setMenus();
        this.$router.push('/dashboard');
      }
    });
  },
  watch: {
    activeTab: function(newId) {
      this.getPreferences(newId);
      // this.getLeList(sessionStorage.userId, this.activeTab);
      this.getLeList(this.$store.state.auth.userId, this.activeTab);
    },
    '$route.name': function() {
      if (this.$router.currentRoute.name === 'dashboard') {
        this.activeResponsibility = 'Administrator';
      } else {
        this.activeResponsibility = '';
      }
      this.isCurrentPath = false;
      if (this.$router.currentRoute.name !== 'Organization') {
        this.isCurrentPath = true;
      }
       if (this.$route.name !== 'LocDash') {
        this.flagLoc=false;
      }
    }
  },
  methods: {
    usersAction(actionName) {
      this.$store.dispatch('shared/setActionName', actionName);
      this.eventBus.$emit('formActions', actionName);
    },
    /**
     * @method: getAccessRightsData(menuId: number)
     * @desc: Fetch users access rights for the add/edit/delete/ actions
     * @param: menuId: string
     */
    getAccessRightsData(menuId) {
      getAccessRightDataByMenuId(menuId)
        .then(response => {
          if (response && response.data && response.data.data) {
            const res = response.data.data;
            this.accessRights = {
              deleteAllow: res.delete_allow,
              downloadAllow: res.download_allow,
              uploadAllow: res.upload_allow,
              editAllow: res.edit_allow,
              addAllow: res.add_allow,
              printAllow: res.print_allow,
              approvalAllow: res.approval_allow
            };
            this.$store.dispatch('shared/setAccessRights', res);
          }
        })
        .catch(err => {
          this.error = err;
        });
    },
    showMenu: function() {
      this.activeButton = '';
    },
    resetForm(value) {
      this.formState = value;
    },
    updateAddress: function(item) {
      if (item === 'edit') {
        this.formState = 'edit';
      }
      if (item === 'add') {
        this.formState = 'add';
      }
      if (item === 'update') {
        this.formState = 'update';
      }
      if (item === 'save') {
        this.formState = 'save';
      }
    },
    setMenus: function() {
      const response = this.responsibilities;
      const toArray = Object.values(response);
      this.menus = toArray;
      this.activeMenu = toArray[0].menu;
      // this.preferences = toArray[0].preferences;
      this.activeTab = toArray[0].responsibility_id;
      this.reports = toArray[0].reports;
      this.responsibility_name = toArray[0].responsibility_name;
      this.$store.dispatch('shared/setRespId', this.activeTab);
    },
    setActiveSideMenu: function(menu, name) {
      this.activeResponsibility = name;
      this.activeView = name;
      const toArray = Object.values(menu);
      this.activeMenu = toArray[0];
      this.reports = menu.reports;
      // this.preferences = menu.preferences;
      this.activeTab = menu.responsibility_id;
      this.responsibility_name = menu.responsibility_name;
      this.eventBus.$emit('menutTitle');
      this.$store.dispatch('shared/setRespId', this.activeTab);
      if (this.$route.name !== 'dashboard') {
        this.$router.push('/dashboard');
      }
    },

    startDrag(evt, item) {
      this.dragItemData = item;
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
      evt.dataTransfer.setData('itemID', item.menu_id);
    },
    onDrop() {
      const responsibility_id = this.activeTab;
      const menu_data = {
        menu_id: this.dragItemData.menu_id,
        responsibility_id: responsibility_id,
        role_menu_mpng_id: this.dragItemData.role_menu_mpng_id
      };

      if (this.preferences.length < 16) {
        this.addPreference(menu_data);
      }
    },
    addPreference: function(menu_data) {
      addPreferences(menu_data)
        .then(resp => {
          const response = resp.data.data;
          this.preferences = response.preferences;
        })
        .catch(err => {
          this.error = err;
        });
    },
    getPreferences: function(id) {
      getPreferences(id).then(response => {
        this.preferences = response.data.data.preferences;
      });
    },
    deletePreference: function(preference) {
      const prompt = confirm('Are you sure you wan to delete preference');
      if (prompt) {
        const responsibility_id = this.activeTab;
        const preference_id = preference.menu_id;

        const data = {
          menu_id: preference_id,
          responsibility_id: responsibility_id,
          role_menu_mpng_id: preference.role_menu_mpng_id
        };
        deletePreferences(data)
          .then(resp => {
            const response = resp.data.data;
            this.preferences = response.preferences;
          })
          .catch(err => {
            this.error = err;
          });
      }
    },
    setActiveView: function(item, name) {
      this.activeResponsibility = '';
      this.activeView = item.menu_action
        ? item.menu_action.toUpperCase()
        : item.action_name.toUpperCase();
      this.activeTitle = item.menu_title;
      this.eventBus.$emit('menutTitle', item.menu_title);
      this.activeButton = name;
      this.$store.dispatch('shared/setRoleMenuMpngId', item.role_menu_mpng_id);
      this.$store.dispatch('shared/setMenuId', item.menu_id);
      this.$store.dispatch('shared/setModuleId', item.module_id);
      this.$router.push({ name: item.menu_action });
      this.getAccessRightsData(item.role_menu_mpng_id);
    },
    singout: function() {
      this.$store.dispatch('logout').then(() => {
        this.leList = null;
        location.reload(true);
      });
    },
    handleDataChange: function(event) {
      this.dataSearch = event;
    },
    onFiltered: function(data) {
      this.totalRows = data.length;
    },
    getLeList(user_id, res_id) {
      const payload = {
        valueSetName: 'LEGAL_ENTITY_LIST',
        dependentVariable: {}
      };
      payload.dependentVariable.user_id = user_id;
      payload.dependentVariable.res_id = res_id;

      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data.data;
            this.eventBus.$emit('setLeData', results);
          }
        });
    }
  },
  created() {
    if (this.$route.name !== 'dashboard') {
      this.activeResponsibility = 'Administrator';
      this.$router.push('/dashboard');
    }
       this.eventBus.$on('upData', (data)=>{
      this.flagLoc=data
    })
  },
  beforeDestroy() {
    this.unsubscribe();
    this.eventBus.$off('upData');
}
};
</script>

<style scoped>
.responsibility_menu {
  position: relative;
}
.responsibility_menu_items {
  top: 0;
  width: 10px;
  position: absolute;
  transform: translate3d(0px, 0px, 0px);
  top: 0px;
  left: 0px;
  will-change: transform;
  z-index: 999;
}

.menu-list {
  margin-right: 20px;
}

.header {
  display: flex;
  position: fixed;
  justify-content: space-around;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #064b35 !important;
  color: white;
}

.parent {
  display: block;
  position: relative;
  float: left;
  line-height: 30px;
  width: 200px;
}
.parent button {
  width: 100%;
  background: #00b6c4 !important;
}
.parent a {
  margin: 10px;
  color: #ffffff;
  text-decoration: none;
}
.parent:hover > ul {
  display: block;
  position: absolute;
}
.child {
  display: none;
}
.child li {
  padding: 5px;
  line-height: 30px;
  width: 110%;
  background-color: white;
  border: #ccc 1px solid;
  cursor: pointer;
}
.child li a {
  color: #000000;
  font-weight: 600;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0px;
  min-width: 12em;
}
ul ul ul {
  left: 100%;
  top: 0;
  margin-left: 1px;
}
/* li:hover {background-color: #95B4CA;} */
.parent li:hover {
  background-color: #f0f0f0;
}
.expand {
  font-size: 12px;
  float: right;
  margin-right: 5px;
}

.text-dark {
  font-weight: 600;
  color: #000000 !important;
}
.pref {
  font-weight: 600;
}
</style>
