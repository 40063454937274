import DatePicker from 'vue2-datepicker';
import party from '@/app/components/generalLedger/party';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'camAndOtherCharges',
  components: {
    DatePicker,
    party
  },
  props: ['editMode', 'tenantLeaseApprovalStatus'],
  data() {
    return {
      setCustCode: null,
      vsetCode: null,
      uomClassObj: null,
      specIndex: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      areaType: null,
      setTimeVsetCode: null,
      customerName: null,
      customerId: null,
      customerSiteId: null,
      customerSiteName: null,
      gridData: [
        {
          period_from: null,
          period_to: null,
          customer_site_name: null,
          customer_site_id: null,
          lease_tenant_agreement_hdr_id: null,
          customer_id: null,
          customer_name: null,
          active: true,
          recovery_process_status: null,
          lease_recovery_period: null,
          lease_recovery_rate_uom_vset: null,
          lease_recovery_period_uom_vset: null,
          lease_recovery_amount: null,
          lease_recovery_rate: null,
          lease_recovery_type_vset: null,
          request_num: null,
          lease_tenant_agreement_recovery_id: 0,
          lease_recovery_type_vset_meaning: null,
          lease_recovery_period_uom_vset_meaning: null,
          lease_recovery_rate_uom_vset_meaning: null,
          recovery_basis: null,
          recovery_basis_meaning: null
        }
      ],
      gridFields: [
        {
          key: 'create_recovery'
        },
        {
          key: 'customer_name'
        },
        {
          key: 'customer_site_name',
          label: 'Customer Site'
        },
        {
          key: 'trx_type',
          label: 'Transaction Type'
        },
        {
          key: 'schedule_type_vset_meaning',
          label: 'Schedule Type'
        },
        {
          key: 'lease_recovery_type_vset_meaning',
          label: 'Recovery Type'
        },
        {
          key: 'recovery_basis_meaning',
          label: 'Recovery Basis'
        },
        // {
        //   key: 'lease_recovery_period_uom_vset_meaning',
        //   label: 'Period UOM'
        // },
        // {
        //   key: 'lease_recovery_period',
        //   label: 'Period'
        // },
        {
          key: 'lease_recovery_rate_uom_vset_meaning',
          label: 'Rate UOM'
        },
        {
          key: 'lease_recovery_rate',
          label: 'Rate'
        },
        {
          key: 'lease_recovery_amount',
          label: 'Amount'
        },
        {
          key: 'charge_ac_ccid_dtl',
          label: 'Charge Account'
        },
        {
          key: 'debtor_ac_ccid_dtl',
          label: 'Debtor Account'
        },
        {
          key: 'tax_cat_name',
          label: 'Tax Category'
        },
        {
          key: 'sac_code',
          label: 'SAC Code'
        },
        {
          key: 'hsn_code',
          label: 'HSN Code'
        },
        {
          key: 'period_from_to',
          label: 'Period (From - To)'
        },
        {
          key: 'request_num'
        },
        {
          key: 'recovery_process_status_meaning',
          label: 'Process Status'
        },
        {
          key: 'error_msg'
        },
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        }
      ],
      showCustomerSiteModal: false,
      gridDataSite: [],
      gridFieldsSite: [
        {
          key: 'site_name'
        },
        {
          key: 'site_type'
        },
        {
          key: 'bill_to_address',
          label: 'Site Address'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'gstn',
          label: 'GSTN'
        }
      ],
      showPartyModal: false,
      uomVsetType: null,
      leaseFormData: null,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: []
    };
  },
  mounted() {
    this.eventBus.$on('camAndOtherCharges', tabData => {
      this.customerId = tabData.leaseForm.customer_id;
      this.customerName = tabData.leaseForm.customer_name;
      this.customerSiteId = tabData.leaseForm.customer_site_id;
      this.customerSiteName = tabData.leaseForm.customer_site_name;
      if (tabData.leaseTenantHdrId) {
        this.getDetails(tabData.leaseTenantHdrId);
        this.leaseFormData = tabData.leaseForm;
      }
      if (tabData.leaseMasterAgreementHdrId) {
        this.gridData[0].customer_name = tabData.customerName;
        this.gridData[0].customer_id = tabData.customerId;
        this.leaseFormData = tabData.leaseForm;
      }
    });
    this.eventBus.$on('tenantHdrCustSiteData', custSiteData => {
      if (custSiteData.site_name && custSiteData.customer_site_id) {
        this.gridData[0].customer_site_name = custSiteData.site_name;
        this.gridData[0].customer_site_id = custSiteData.customer_site_id;
      }
    });
  },
  methods: {
    getDetails(leaseTenantHdrId) {
      const payload = {
        tabName: 'recovery',
        leaseTenantHdrId: leaseTenantHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('leases/getLeaseTenantDataById', payload)
        .then(resp => {
          this.loader = false;
          if (
            resp.status === 200 &&
            resp.data.data.length > 0
            //  &&
            // this.gridData[0].lease_tenant_agreement_recovery_id === 0
          ) {
            this.gridData = resp.data.data.map(data => {
              data.period_from_to = [
                new Date(data.period_from),
                new Date(data.period_to)
              ];
              return data;
            });
            const createRecoveryIndex = resp.data.data.findIndex(
              data =>
                data.recovery_process_status === 'U' ||
                data.recovery_process_status === 'E'
            );
            if (createRecoveryIndex !== -1)
              this.gridData[createRecoveryIndex].create_recovery = true;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addNewRow() {
      if (this.editMode) {
        this.gridData.push({
          period_from: null,
          period_to: null,
          customer_site_name: this.customerSiteName,
          customer_site_id: this.customerSiteId,
          lease_tenant_agreement_hdr_id: null,
          customer_id: this.customerId,
          customer_name: this.customerName,
          active: true,
          recovery_process_status: null,
          lease_recovery_period: null,
          lease_recovery_rate_uom_vset: null,
          lease_recovery_period_uom_vset: null,
          lease_recovery_amount: null,
          lease_recovery_rate: null,
          lease_recovery_type_vset: null,
          request_num: null,
          lease_tenant_agreement_recovery_id: 0,
          lease_recovery_type_vset_meaning: null,
          lease_recovery_period_uom_vset_meaning: null,
          lease_recovery_rate_uom_vset_meaning: null,
          recovery_basis: null,
          recovery_basis_meaning: null
        });
      }
    },
    removeRow(index) {
      if (this.editMode) {
        this.gridData.splice(index, 1);
      }
    },
    opendDependentUom(vsetCode, index, uomVsetType) {
      this.uomVsetType = uomVsetType;
      const payload = {
        valueSetName: vsetCode,
        dependentVariable: {
          user_id: this.$store.state.auth.userId,
          res_id: this.$store.state.shared.responsibilityId
        }
      };
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          this.loader = false;
          this.uomClassObj = null;
          if (response.status === 200) {
            const findTimeObj = response.data.data.page.find(
              vsetData => vsetData.value_code === 'TIME'
            );
            if (findTimeObj) {
              this.uomClassObj = findTimeObj;
              this.openValueSetModal('UOM', index);
            }
          }
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'UOM') {
        if (this.uomVsetType === 'PERIOD_UOM') {
          this.gridData[this.specIndex].lease_recovery_period_uom_vset =
            item.value_code;
          this.gridData[this.specIndex].lease_recovery_period_uom_vset_meaning =
            item.value_meaning;
        } else if (this.uomVsetType === 'RATE_UOM') {
          this.gridData[this.specIndex].lease_recovery_rate_uom_vset =
            item.value_code;
          this.gridData[this.specIndex].lease_recovery_rate_uom_vset_meaning =
            item.value_meaning;
        }
      } else if (this.vsetCode === 'LEASE_RECOVERY') {
        this.gridData[this.specIndex].lease_recovery_type_vset =
          item.value_code;
        this.gridData[this.specIndex].lease_recovery_type_vset_meaning =
          item.value_meaning;
      } else if (this.vsetCode === 'RECOVERY_BASIS') {
        this.gridData[this.specIndex].recovery_basis = item.value_code;
        this.gridData[this.specIndex].recovery_basis_meaning =
          item.value_meaning;
      }
      this.showValueSetModal = false;
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.specIndex = index;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.uomClassObj && vsetCode === 'UOM') {
        this.parent_value_set_id = this.uomClassObj.value_set_dtl_id;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    showHideCustomerSiteModal(flag) {
      this.showCustomerSiteModal = flag;
    },
    getCustomerSiteByPartyId(index) {
      this.gridIndex = index;
      const payload = {
        customerId: this.gridData[index].customer_id,
        lease: {
          site_type_vset: appStrings.siteTypeVset
        }
      };
      this.$store
        .dispatch('party/getCustomerSiteById', payload)
        .then(response => {
          this.gridDataSite = response.data.data.customer_site_lists;
          this.showCustomerSiteModal = true;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedSite(item) {
      this.gridData[this.gridIndex].customer_site_name = item.site_name;
      this.gridData[this.gridIndex].customer_site_id = item.customer_site_id;
      this.showCustomerSiteModal = false;
    },
    showHidePartyModal(flag, index) {
      this.showPartyModal = flag;
      this.gridIndex = index;
      this.setCustCode = setTimeout(() => {
        this.eventBus.$emit('userType', 'CUST');
      }, 0);
    },
    getSelectedPartyCustomer(item) {
      this.gridData[this.gridIndex].customer_name = item.party_name;
      this.showPartyModal = false;
      this.getCustomerByPartyId(item.party_id);
    },
    getCustomerByPartyId(partyId) {
      this.$store
        .dispatch('leases/getCustomerByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            this.gridData[this.gridIndex].customer_id =
              response.data.data.customer_id;
          }
        });
    },
    calculateRate(index) {
      if (this.gridData[index].recovery_basis === 'RSUPER_AREA') {
        this.gridData[index].lease_recovery_amount =
          this.gridData[index].lease_recovery_period *
          this.gridData[index].lease_recovery_rate *
          this.leaseFormData.super_area;
      } else if (this.gridData[index].recovery_basis === 'RCARPET_AREA') {
        this.gridData[index].lease_recovery_amount =
          this.gridData[index].lease_recovery_period *
          this.gridData[index].lease_recovery_rate *
          this.leaseFormData.carpet_area;
      }
      if (this.gridData[index].recovery_basis === 'RENTAL') {
        this.gridData[index].lease_recovery_amount =
          this.gridData[index].lease_recovery_period *
          this.gridData[index].lease_recovery_rate *
          this.leaseFormData.rate;
      }
    },
    setDate(dateRange, index) {
      this.gridData[index].period_from_to = commonHelper.setFutureDate(
        dateRange
      );
    },
    createRecovery(index) {
      const payload = {
        lease_schd_template: [
          {
            entity_id: this.gridData[index].lease_tenant_agreement_recovery_id,
            entity_type: 'SRECOVERY',
            lease_id: this.gridData[index].lease_tenant_agreement_hdr_id
          }
        ]
      };
      this.loader = true;
      this.$store
        .dispatch('leases/createRentaldetails', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 201) {
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.getDetails(this.gridData[index].lease_tenant_agreement_hdr_id);
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsgmessage;
        });
    },
    validateCheckBox(row) {
      // validation of checkbox
      if (row.request_num) {
        if (!/e/i.test(row.recovery_process_status)) {
          return true;
        } else {
          return false;
        }
      } else if (!row.sac_code) {
        return true;
      } else {
        return false;
      }
    },
    clearVsetValues(vsetCode, index) {
      switch (vsetCode) {
        case 'LEASE_RECOVERY':
          this.gridData[index].lease_recovery_type_vset_meaning = null;
          break;
        case 'RECOVERY_BASIS':
          this.gridData[index].recovery_basis_meaning = null;
          break;
        case 'UOM_CLASS':
          this.gridData[index].lease_recovery_rate_uom_vset_meaning = null;
          break;
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.setCustCode);
    this.eventBus.$off('tenantHdrCustSiteData');
    this.eventBus.$off('camAndOtherCharges');
  }
};
