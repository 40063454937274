import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import party from '@/app/components/generalLedger/party';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'camAndOtherCharges',
  components: {
    DatePicker,
    party
  },
  props: ['editMode', 'investorLeaseApprovalStatus'],
  data() {
    return {
      setCustCode: null,
      vsetCode: null,
      uomClassObj: null,
      specIndex: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      areaType: null,
      setTimeVsetCode: null,
      gridData: [
        {
          active: true,
          lease_vendor_site_id: null,
          recovery_basis_meaning: null,
          lease_investor_agreement_hdr_id: null,
          period_to: null,
          period_from: null,
          recovery_process_status: 'DRAFT',
          lease_recovery_amount: null,
          lease_recovery_type_vset: null,
          lease_recovery_rate_uom_vset: null,
          lease_recovery_rate: null,
          lease_recovery_period: null,
          lease_recovery_period_uom_vset: null,
          lease_vendor_id: null,
          lease_vendor_site_name: null,
          lease_vendor_name: null,
          recovery_basis: null,
          lease_recovery_period_uom_vset_meaning: null,
          lease_recovery_rate_uom_vset_meaning: null,
          lease_recovery_type_vset_meaning: null,
          lease_investor_agreement_recovery_id: 0,
          partyId: null
        }
      ],
      gridFields: [
        {
          key: 'active'
        },
        {
          key: 'lease_vendor_name',
          label: 'Vendor Name'
        },
        {
          key: 'lease_vendor_site_name',
          label: 'Vendor Site'
        },
        {
          key: 'trx_type',
          label: 'Transaction Type'
        },
        {
          key: 'schedule_type_vset_meaning',
          label: 'Schedule Type'
        },
        {
          key: 'lease_recovery_type_vset_meaning',
          label: 'Recovery Type'
        },
        {
          key: 'recovery_basis_meaning',
          label: 'Recovery Basis'
        },
        {
          key: 'lease_recovery_period_uom_vset_meaning',
          label: 'Period UOM'
        },
        {
          key: 'lease_recovery_period',
          label: 'Period'
        },
        {
          key: 'lease_recovery_rate_uom_vset_meaning',
          label: 'Rate UOM'
        },
        {
          key: 'lease_recovery_rate',
          label: 'Rate'
        },
        {
          key: 'lease_recovery_amount',
          label: 'Amount'
        },
        {
          key: 'gl_date',
          label: 'GL Date'
        },
        {
          key: 'invoice_date'
        },
        {
          key: 'charge_ac_ccid_dtl',
          label: 'Charge Account'
        },
        {
          key: 'liability_ac_ccid_dtl',
          label: 'Liability Account'
        },
        {
          key: 'tax_cat_name',
          label: 'Tax Category'
        },
        {
          key: 'tds_override_flag',
          label: 'TDS Override Flag',
          class: 'text-center'
        },
        {
          key: 'tax_category_name',
          label: 'TDS Tax Category Actual'
        },
        {
          key: 'section',
          label: 'TDS Section Actual'
        },
        {
          key: 'sac_code',
          label: 'SAC Code'
        },
        {
          key: 'hsn_code',
          label: 'HSN Code'
        },
        {
          key: 'period_from_to',
          label: 'Period (From - To)'
        },
        {
          key: 'request_num'
        },
        {
          key: 'recovery_process_status_meaning',
          label: 'Process Status'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ],
      showCustomerSiteModal: false,
      gridDataSite: [],
      gridFieldsSite: [
        {
          key: 'site_name'
        },
        {
          key: 'site_type'
        },
        {
          key: 'bill_to_address',
          label: 'Site Address'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'gstn',
          label: 'GSTN'
        }
      ],
      showPartyModal: false,
      uomVsetType: null,
      leaseFormData: null
    };
  },
  mounted() {
    this.eventBus.$on('camAndOtherCharges', tabData => {
      if (tabData.leaseInvestorHdrId) {
        this.getDetails(tabData.leaseInvestorHdrId);
      }
    });
  },
  methods: {
    getDetails(leaseInvestorHdrId) {
      const payload = {
        tabName: 'recovery',
        leaseInvestorHdrId: leaseInvestorHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('leases/getLeaseInvestorDataById', payload)
        .then(resp => {
          this.loader = false;
          if (
            resp.status === 200 &&
            resp.data.data.length > 0 &&
            this.gridData[0].lease_investor_agreement_recovery_id === 0
          ) {
            this.gridData = resp.data.data.map(data => {
              data.period_from_to = [
                new Date(data.period_from),
                new Date(data.period_to)
              ];
              return data;
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addNewRow() {
      this.gridData.push({
        period_from: null,
        period_to: null,
        lease_tenant_agreement_hdr_id: null,
        lease_vendor_site_id: null,
        lease_vendor_id: null,
        lease_vendor_site_name: null,
        lease_vendor_name: null,
        active: true,
        recovery_process_status: null,
        lease_recovery_period: null,
        lease_recovery_rate_uom_vset: null,
        lease_recovery_period_uom_vset: null,
        lease_recovery_amount: null,
        lease_recovery_rate: null,
        lease_recovery_type_vset: null,
        request_num: null,
        lease_tenant_agreement_recovery_id: 0,
        lease_recovery_type_vset_meaning: null,
        lease_recovery_period_uom_vset_meaning: null,
        lease_recovery_rate_uom_vset_meaning: null,
        recovery_basis: null,
        recovery_basis_meaning: null,
        partyId: null
      });
    },
    removeRow(index) {
      this.gridData.splice(index, 1);
    },
    opendDependentUom(vsetCode, index, uomVsetType) {
      this.uomVsetType = uomVsetType;
      const payload = {
        valueSetName: vsetCode,
        dependentVariable: {
          user_id: this.$store.state.auth.userId,
          res_id: this.$store.state.shared.responsibilityId
        }
      };
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          this.loader = false;
          this.uomClassObj = null;
          if (response.status === 200) {
            const findTimeObj = response.data.data.page.find(
              vsetData => vsetData.value_code === 'TIME'
            );
            if (findTimeObj) {
              this.uomClassObj = findTimeObj;
              this.openValueSetModal('UOM', index);
            }
          }
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'UOM') {
        if (this.uomVsetType === 'PERIOD_UOM') {
          this.gridData[this.specIndex].lease_recovery_period_uom_vset =
            item.value_code;
          this.gridData[this.specIndex].lease_recovery_period_uom_vset_meaning =
            item.value_meaning;
        } else if (this.uomVsetType === 'RATE_UOM') {
          this.gridData[this.specIndex].lease_recovery_rate_uom_vset =
            item.value_code;
          this.gridData[this.specIndex].lease_recovery_rate_uom_vset_meaning =
            item.value_meaning;
        }
      } else if (this.vsetCode === 'LEASE_RECOVERY') {
        this.gridData[this.specIndex].lease_recovery_type_vset =
          item.value_code;
        this.gridData[this.specIndex].lease_recovery_type_vset_meaning =
          item.value_meaning;
      } else if (this.vsetCode === 'RECOVERY_BASIS') {
        this.gridData[this.specIndex].recovery_basis = item.value_code;
        this.gridData[this.specIndex].recovery_basis_meaning =
          item.value_meaning;
      }
      this.showValueSetModal = false;
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.specIndex = index;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.uomClassObj && vsetCode === 'UOM') {
        this.parent_value_set_id = this.uomClassObj.value_set_dtl_id;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    showHideCustomerSiteModal(flag) {
      this.showCustomerSiteModal = flag;
    },
    getVendorSiteByPartyId(index) {
      this.gridIndex = index;
      const payload = {
        partyId: this.gridData[index].partyId,
        lease: {
          site_type_vset: appStrings.siteTypeVset
        }
      };
      this.$store
        .dispatch('party/getPartyVenderSiteById', payload)
        .then(response => {
          this.gridDataSite = response.data.data.vendor_sites;
          this.showCustomerSiteModal = true;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedSite(item) {
      this.gridData[this.gridIndex].lease_vendor_site_name = item.site_name;
      this.gridData[this.gridIndex].lease_vendor_site_id = item.vendor_site_id;
      this.showCustomerSiteModal = false;
    },
    showHidePartyModal(flag, index) {
      this.showPartyModal = flag;
      this.gridIndex = index;
      this.setCustCode = setTimeout(() => {
        this.eventBus.$emit('userType', 'VEND');
      }, 0);
    },
    getSelectedPartyCustomer(item) {
      this.gridData[this.gridIndex].lease_vendor_name = item.party_name;
      this.gridData[this.gridIndex].partyId = item.party_id;
      this.showPartyModal = false;
      this.getPartyVendorDetails(item.party_id);
    },
    getPartyVendorDetails(partyId) {
      this.$store
        .dispatch('party/getPartyVenderSiteList', partyId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.gridData[this.gridIndex].lease_vendor_id =
              response.data.data.vendor_id;
          }
        });
    },
    calculateRate(index) {
      if (this.gridData[index].recovery_basis === 'SUPER_AREA') {
        this.gridData[index].lease_recovery_amount =
          this.gridData[index].lease_recovery_period *
          this.gridData[index].lease_recovery_rate *
          this.leaseFormData.super_area;
      } else if (this.gridData[index].recovery_basis === 'CARPET_AREA') {
        this.gridData[index].lease_recovery_amount =
          this.gridData[index].lease_recovery_period *
          this.gridData[index].lease_recovery_rate *
          this.leaseFormData.carpet_area;
      }
      if (this.gridData[index].recovery_basis === 'RENTAL') {
        this.gridData[index].lease_recovery_amount =
          this.gridData[index].lease_recovery_period *
          this.gridData[index].lease_recovery_rate *
          this.leaseFormData.bill_rate;
      }
    },
    setDate(dateRange, index) {
      this.gridData[index].period_from_to = commonHelper.setFutureDate(
        dateRange
      );
    },
    clearVsetValues(vsetCode, index) {
      if (vsetCode === 'LEASE_RECOVERY') {
        this.gridData[index].lease_recovery_type_vset = null;
        this.gridData[index].lease_recovery_type_vset_meaning = null;
      } else if (vsetCode === 'RECOVERY_BASIS') {
        this.gridData[index].recovery_basis = null;
        this.gridData[index].recovery_basis_meaning = null;
      } else if (vsetCode === 'PERIOD_UOM') {
        (this.gridData[this.specIndex].lease_recovery_period_uom_vset = null),
          (this.gridData[
            this.specIndex
          ].lease_recovery_period_uom_vset_meaning = null);
      } else if (vsetCode === 'RATE_UOM') {
        (this.gridData[index].lease_recovery_rate_uom_vset = null),
          (this.gridData[index].lease_recovery_rate_uom_vset_meaning = null);
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.setCustCode);
    this.eventBus.$off('camAndOtherCharges');
  }
};
