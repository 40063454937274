import { ModelSelect } from 'vue-search-select';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import VueCkeditor from 'vue-ckeditor5';
import Location from './location';
import Project from './project';
import Department from './department';
import NegotiatedBy from './negotiatedBy';
import InitiatedBy from './initiatedBy';
import Consult from './consult';
import PrintWorklfow from './printInternalWorkflow';
import PrintWaiverWorkflow from './printWaiverWorkflow';
import DatePicker from 'vue2-datepicker';
import { format } from 'date-fns';
import store from '../../../../../store';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'addInternalWorkflow',
  components: {
    ModelSelect,
    'vue-ckeditor': VueCkeditor.component,
    Location,
    Project,
    Department,
    DatePicker,
    NegotiatedBy,
    InitiatedBy,
    Consult,
    PrintWorklfow,
    PrintWaiverWorkflow
  },
  props: ['propsSelectedGridData', 'eventId', 'wf_inprocess_dtl_id'],
  data() {
    return {
      isConsultInitiated: false,
      approvedAmount: null,
      requiredAmount: null,
      userInfo: {
        employee_id: null,
        user_id: null,
        user_name: null
      },
      isApprovedByCurrrentUser: false,
      urlSummaryData: null,
      wfDtlId: null,
      selectedGridData: null,
      showPrintModal: false,
      setEditorData: null,
      scrapRecord: null,
      showConsultModal: false,
      waiverTimeout: null,
      tabTimeOut: null,
      activeTab: 'location',
      wfHeaderId: 0,
      enableNatureAndBiddingType: true,
      parent_value_set_id: null,
      setTimeVsetCode: null,
      valueSetCode: null,
      showValueSetModal: false,
      textEditorOne: '',
      editorFlagOne: false,
      textEditorTwo: '',
      editorFlagTwo: false,
      textEditorThree: '',
      editorFlagThree: false,
      editors: {
        classic: ClassicEditor
      },
      unsubscribe: null,
      loader: false,
      editMode: true,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      selectedType: {
        value: null,
        text: null
      },
      typeList: [],
      wfEventId: null,
      wfType: {
        value_code: 'Non Projects',
        value_meaning: null,
        value_set_dtl_id: null
      },
      wfCategory: {
        value_code: null,
        value_meaning: null,
        value_set_dtl_id: null
      },
      wfSubcategory: {
        value_code: null,
        value_meaning: null,
        value_set_dtl_id: null
      },
      wfLegalEntity: {
        leName: null,
        leId: null
      },
      wfProjectType: {
        value_code: null,
        value_meaning: null
      },
      purchase: {
        value_code: null,
        value_meaning: null
      },
      biddingType: {
        value_code: null,
        value_meaning: null
      },
      wfNumber: null,
      wfVersion: 0,
      expectedApproval: null,
      wfStatus: 'DRAFT',
      currency: {
        code: null,
        id: null
      },
      wfBudgetAmount: null,
      wfAwardedAmount: null,
      wfRefCode: null,
      wfUrgent: false,
      wfComment: null,
      wfSubject: null,
      notificationSummary: false,
      notificationComments: null,
      approvalHistoryList: [],
      approvalHistoryFields: [
        {
          key: 'approver_name',
          label: 'Name'
        },
        {
          key: 'approval_status',
          label: 'Status'
        },
        {
          key: 'comments',
          label: 'Comments'
        },
        {
          key: 'action_date'
        },
        {
          key: 'temp_approved_amount',
          label: 'Approved Amount'
        }
      ]
    };
  },
  mounted() {
    if (this.propsSelectedGridData) {
      this.wfHeaderId = this.propsSelectedGridData.nfa_hdr_id;
    }
    this.getEventId();
    if (this.$route.params.summaryData) {
      this.urlSummaryData = JSON.parse(
        Buffer.from(this.$route.params.summaryData, 'base64').toString('ascii')
      );
    }
    this.selectedGridData = this.propsSelectedGridData;
    this.getCurrency();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.scrapRecord = null;
          this.addEditIntWorkflow();
        } else if (actionName === 'update') {
          this.editMode = true;
          this.setEditorData = setTimeout(() => {
            this.textEditorOne = this.propsSelectedGridData.body;
            this.textEditorTwo = this.propsSelectedGridData.changes;
            this.textEditorThree = this.propsSelectedGridData.excptions;
          }, 1000);
        }
      }
    });
    if (this.urlSummaryData) {
      this.getWfDetail(this.urlSummaryData.nfa_hdr_id);
      this.wfDtlId = this.urlSummaryData.wf_inprocess_dtl_id;
    } else {
      this.setHeaderData(this.selectedGridData);
      this.wfDtlId = this.wf_inprocess_dtl_id;
    }
  },
  methods: {
    addEditIntWorkflow() {
      const filterLocation = this.$refs.location.locationList.filter(
        data => data.location_id !== null
      );
      const localtionDetails = filterLocation.map(data => {
        return {
          location_id: data.location_id
        };
      });
      const filteredDep = this.$refs.department.departmentList.filter(
        data => data.key_flexfield_comb_id !== null
      );
      const departmentDetails = filteredDep.map(data => {
        return {
          department_id: data.key_flexfield_comb_id
        };
      });
      const filterProj = this.$refs.project.projectList.filter(
        data => data.entity_type_vset !== null
      );
      const projectDetails = filterProj.map(data => {
        return {
          entity_type: data.entity_type_vset,
          floor_id: data.floor_id,
          pocket_id: data.pocket_id,
          project_id: data.prj_id,
          sector_id: data.sector_id,
          tower_id: data.tower_id,
          unit_id: data.unit_id,
          waivers: this.getWaiverData(data.waiverList)
        };
      });
      const filteredInit = this.$refs.initiated.initiateList.filter(
        data => data.employee_id !== null
      );
      const initiatedDetails = filteredInit.map(data => {
        return {
          initiator_id: data.employee_id
        };
      });
      const filteredNego = this.$refs.negotiated.negotiatedList.filter(
        data => data.employee_id !== null
      );
      const negotiated = filteredNego.map(data => {
        return {
          comments: data.remarks,
          negotiate_id: data.employee_id,
          primary_flag: data.primary_flag
        };
      });
      const payload = {
        nfa_hdr_id: this.wfHeaderId,
        nfa_type_vset: this.wfType.value_code,
        nfa_cat_vset: this.wfCategory.value_code,
        nfa_sub_cat_vset: this.wfSubcategory.value_code,
        le_id: this.wfLegalEntity.leId,
        expected_approval_date: this.expectedApproval
          ? format(new Date(this.expectedApproval), 'dd-MMM-yyyy')
          : null,
        urgent: this.wfUrgent,
        urgent_comment: this.wfUrgent ? this.wfComment : '',
        nfa_subject: this.wfSubject,
        body: this.textEditorOne,
        changes: this.textEditorTwo,
        excptions: this.textEditorThree,
        current_flag: this.editorFlagOne,
        change_flag: this.editorFlagTwo,
        exception_flag: this.editorFlagThree,
        transaction_currency: this.currency.code,
        base_currency: this.currency.code,
        budget_amount: this.wfBudgetAmount
          ? parseFloat(this.wfBudgetAmount.replace(/,/g, ''))
          : 0,
        exchange_rate: 1,
        awarded_amount: this.wfAwardedAmount
          ? parseFloat(this.wfAwardedAmount.replace(/,/g, ''))
          : 0,
        bidding_type: this.biddingType.value_code,
        budget_ref_code: this.wfRefCode,
        departments:
          departmentDetails && departmentDetails.length > 0
            ? departmentDetails
            : null,
        event_id: this.wfEventId,
        initiators:
          initiatedDetails && initiatedDetails.length > 0
            ? initiatedDetails
            : null,
        locations:
          localtionDetails && localtionDetails.length > 0
            ? localtionDetails
            : null,
        menu_id: this.$store.state.shared.menuId,
        nature_of_purchase: this.purchase.value_code,
        negotiates: negotiated && negotiated.length > 0 ? negotiated : null,
        project_type_vset: this.wfProjectType.value_code,
        projects:
          projectDetails && projectDetails.length > 0 ? projectDetails : null,
        request_for: this.scrapRecord
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/addEditIntWorkflow', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.wfNumber = response.data.data.nfa_number;
            this.setHeaderData(response.data.data);
            this.editMode = false;
            this.isSuccess = true;
            this.respMessage = response.data.message;
          } else {
            this.isSuccess = false;
            this.respMessage = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        })
        .finally(() => {
          this.loader = false;
        });
    },
    getCurrency() {
      const payload = {
        currentPage: 0,
        perPage: 10,
        search: 'inr'
      };
      this.$store
        .dispatch('currency/getCurrencyPagination', payload)
        .then(response => {
          const results = response.data.data.page;
          if (results) {
            this.currency.code = results[0].currency_code;
            this.currency.id = results[0].currency_id;
          }
        });
    },
    setHeaderData(selectedGridData) {
      if (selectedGridData) {
        this.editMode = false;
        this.wfHeaderId = selectedGridData.nfa_hdr_id;
        this.wfType.value_meaning = selectedGridData.nfa_type;
        this.wfType.value_code = selectedGridData.nfa_type_vset;
        this.wfCategory.value_meaning = selectedGridData.nfa_cat;
        this.wfCategory.value_code = selectedGridData.nfa_cat_vset;
        this.wfSubcategory.value_code = selectedGridData.nfa_sub_cat_vset;
        this.wfSubcategory.value_meaning = selectedGridData.nfa_sub_cat;
        this.wfLegalEntity.leName = selectedGridData.le_name;
        this.wfLegalEntity.leId = selectedGridData.le_id.toString();
        this.wfNumber = selectedGridData.nfa_number;
        this.wfVersion = selectedGridData.version;
        this.expectedApproval = selectedGridData.expected_approval_date
          ? new Date(selectedGridData.expected_approval_date)
          : null;
        this.wfStatus = selectedGridData.approval_status;
        this.currency.code = selectedGridData.transaction_currency;
        this.wfBudgetAmount = selectedGridData.budget_amount;
        this.wfAwardedAmount = selectedGridData.awarded_amount;
        this.approvedAmount = selectedGridData.awarded_amount;
        this.wfRefCode = selectedGridData.budget_ref_code;
        this.wfProjectType.value_meaning = selectedGridData.project_type;
        this.wfProjectType.value_code = selectedGridData.project_type_vset;
        this.purchase.value_meaning = selectedGridData.nature_of_purchase;
        this.biddingType.value_meaning = selectedGridData.bidding_type;
        this.wfUrgent = selectedGridData.urgent === '0' ? false : true;
        this.wfComment = selectedGridData.urgent_comment;
        this.wfSubject = selectedGridData.nfa_subject;
        this.editorFlagOne = selectedGridData.current_flag;
        this.editorFlagTwo = selectedGridData.change_flag;
        this.editorFlagThree = selectedGridData.exception_flag;
        this.eventBus.$emit(this.activeTab, this.wfHeaderId);
        this.textEditorOne = selectedGridData.body;
        this.textEditorTwo = selectedGridData.changes;
        this.textEditorThree = selectedGridData.excptions;
        if (
          this.$route.name === 'notificationSummary' ||
          this.$route.name === 'dashboard' ||
          this.$route.name === 'workFlowSummary'
        ) {
          this.notificationSummary = true;
          this.getApprovalHistory(this.wfHeaderId);
        }
      }
    },
    openValueSetModal(vsetCode) {
      if (vsetCode === appStrings.VALUESETTYPE.NFA_SUB_CATEGORY && this.wfCategory.value_set_dtl_id) {
        this.parent_value_set_id = this.wfCategory.value_set_dtl_id.toString();
      } else {
        this.parent_value_set_id = null;
      }
      this.valueSetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    alertUser(message) {
      alert(message);
    },
    selectedvalueSet(item) {
      if (this.valueSetCode === 'NFA_TYPE') {
        this.wfType.value_code = item.value_code;
        this.wfType.value_meaning = item.value_meaning;
        this.wfType.value_set_dtl_id = item.value_set_dtl_id;
      } else if (this.valueSetCode === 'NFA_CATEGORY') {
        this.wfCategory.value_code = item.value_code;
        this.wfCategory.value_meaning = item.value_meaning;
        this.wfCategory.value_set_dtl_id = item.value_set_dtl_id;
        this.enableNatureAndBiddingType = true;
        if (item.value_code !== 'APP_NOTE') {
          this.enableNatureAndBiddingType = false;
        }
      } else if (this.valueSetCode === appStrings.VALUESETTYPE.NFA_SUB_CATEGORY) {
        this.wfSubcategory.value_code = item.value_code;
        this.wfSubcategory.value_meaning = item.value_meaning;
        this.wfSubcategory.value_set_dtl_id = item.value_set_dtl_id;
        if (this.wfSubcategory.value_code === 'Waiver') {
          this.enableWaiver(true);
        } else {
          this.enableWaiver(false);
        }
      } else if (this.valueSetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.wfLegalEntity.leName = item.org_name;
        this.wfLegalEntity.leId = item.org_id;
      } else if (this.valueSetCode === 'NFA_PROJECT_TYPES') {
        this.wfProjectType.value_code = item.value_code;
        this.wfProjectType.value_meaning = item.value_meaning;
      } else if (this.valueSetCode === 'NFA_NATURE_OF_PURCHASE') {
        this.purchase.value_code = item.value_code;
        this.purchase.value_meaning = item.value_meaning;
      } else if (this.valueSetCode === 'NFA_BIDDING_TYPE') {
        this.biddingType.value_code = item.value_code;
        this.biddingType.value_meaning = item.value_meaning;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    selectedTab(tab) {
      this.activeTab = tab;
      this.tabTimeOut = setTimeout(() => {
        this.eventBus.$emit(this.activeTab, this.wfHeaderId);
        if (tab === 'project' && this.wfSubcategory.value_code === 'Waiver') {
          this.enableWaiver(true);
        }
      }, 0);
    },
    enableWaiver(flag) {
      this.waiverTimeout = setTimeout(() => {
        this.eventBus.$emit('enbaleWaiver', flag);
      }, 0);
    },
    getWaiverData(data) {
      const filterNullData = data.filter(data => data.waiver_vset !== null);
      const getWaiverAmount = filterNullData.map(waiv => {
        return {
          waiver_amount: waiv.waiver_amount,
          waiver_vset: waiv.waiver_vset
        };
      });
      return getWaiverAmount && getWaiverAmount.length ? getWaiverAmount : [];
    },
    setTotalWaiver(waiverAmount) {
      this.wfBudgetAmount = waiverAmount;
    },
    getApprovalHistory(wfHeaderId) {
      this.loader = true;
      this.$store.dispatch('auth/getUserDetail').then(response => {
        if (response.status === 200) {
          this.userInfo = response.data.data;
          const payload = {
            nfaHdrId: wfHeaderId,
            eventId: this.urlSummaryData
              ? this.urlSummaryData.wf_event_id
              : this.eventId
          };
          this.$store
            .dispatch('intWorkflow/getApprovalList', payload)
            .then(response => {
              this.loader = false;
              if (response.status === 200) {
                this.approvalHistoryList = response.data.data;
                const findCurrentUser = this.approvalHistoryList.find(
                  user =>
                    user.approver_employee_id === this.userInfo.employee_id &&
                    (user.approval_status === 'INPROCESS' ||
                      user.approval_status === 'CONSULT_INITIATED')
                );
                this.isApprovedByCurrrentUser = findCurrentUser ? true : false;
                const findConsultInitUser = this.approvalHistoryList.find(
                  data => data.approval_status === 'CONSULT_INITIATED'
                );
                this.isConsultInitiated = findConsultInitUser ? true : false;
              }
            })
            .catch(() => {
              this.loader = false;
            });
        }
      });
    },
    showHideConsult(flag) {
      this.showConsultModal = flag;
    },
    changeStatus(status) {
      this.loader = true;
      const payload = [
        {
          event_id: this.urlSummaryData
            ? this.urlSummaryData.wf_event_id
            : this.eventId,
          notification_comments: this.notificationComments,
          notification_status: status,
          wf_inprocess_dtl_id: this.wfDtlId,
          temp_approved_amount:
            status === 'APPROVED'
              ? this.approvedAmount
                ? parseFloat(this.approvedAmount.replace(/,/g, ''))
                : 0
              : 0
        }
      ];
      this.$store
        .dispatch('intWorkflow/approvalReject', { approval_requests: payload })
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.getApprovalHistory(this.wfHeaderId);
            this.editMode = false;
            this.isSuccess = true;
            this.respMessage = response.data.message;
          } else {
            this.isSuccess = false;
            this.respMessage = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    saveScrapRecord() {
      const promt = confirm('Scrap Record ?');
      if (promt) {
        this.scrapRecord = 'scrap';
        this.addEditIntWorkflow();
      }
    },
    showHidePrint(flag) {
      this.showPrintModal = flag;
    },
    disabledDates(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date < today;
    },
    async printIntWorkflow() {
      if (this.selectedGridData.nfa_sub_cat === 'Waiver') {
        this.nfaSubCategory;
      }
      await this.$htmlToPaper('print-workflow', {
        windowTitle: this.wfNumber
      });
    },
    getWfDetail(nfaHdrId) {
      this.wfHeaderId = nfaHdrId;
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getWfDetailsById', nfaHdrId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.selectedGridData = response.data.data;
            this.setHeaderData(this.selectedGridData);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getEventId() {
      if (store.state.shared.menuId) {
        this.$store
          .dispatch('intWorkflow/getEventId', store.state.shared.menuId)
          .then(response => {
            if (response.status === 200) {
              this.wfEventId = response.data.data;
            }
          });
      }
    },
    clearVsetValues(valueSetCode){
      if (valueSetCode === 'NFA_TYPE') {
        this.wfType.value_code = null;
        this.wfType.value_meaning = null;
        this.wfType.value_set_dtl_id = null;
      } else if (valueSetCode === 'NFA_CATEGORY') {
        this.wfCategory.value_code = null;
        this.wfCategory.value_meaning = null;
        this.wfCategory.value_set_dtl_id = null;
        
        
      } else if (valueSetCode === 'NFA_SUB_CATEGORY') {
        this.wfSubcategory.value_code = null;
        this.wfSubcategory.value_meaning = null;
        this.wfSubcategory.value_set_dtl_id = null;
        
      } else if (valueSetCode === 'LEGAL_ENTITY_LIST') {
        this.wfLegalEntity.leName = null;
        this.wfLegalEntity.leId = null;
      } else if (valueSetCode === 'NFA_PROJECT_TYPES') {
        this.wfProjectType.value_code = null;
        this.wfProjectType.value_meaning = null;
      } else if (valueSetCode === 'NFA_NATURE_OF_PURCHASE') {
        this.purchase.value_code = null;
        this.purchase.value_meaning = null;
      } else if (valueSetCode === 'NFA_BIDDING_TYPE') {
        this.biddingType.value_code = null;
        this.biddingType.value_meaning = null;
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.tabTimeOut);
    clearTimeout(this.waiverTimeout);
    clearTimeout(this.setEditorData);
    this.unsubscribe();
  },
  filters: {
    formatNumber: function (value) {
      if (!value) return 0.0;
      return parseFloat(value).toFixed(2);
    }
  }
};
