
export default {
  name: 'ReceivingBankDetails',
  props: ['masterDetails'],
  data() {
    return {
      receivingBankData: {
        le_id: null,
        lease_prj_id: null,
        lease_prj_name: null,
        lease_party_id: null,
        lease_agreement_no: null,
        lease_master_agreement_hdr_id: null,
        branch_name: null,
        lease_party_name: null,
        ifsc_code: null,
        account_name: null,
        bank_name: null,
        le_name: null,
        receipt_methods_name: null,
        ar_proj_receipt_method_mpng_id: null
      },
      receivingBankDetailsVisible: false,
      loader: false
    };
  },
  mounted() {
    this.receivingBankDetailsVisible = true;
    if (this.masterDetails.ar_proj_receipt_method_mpng_id) {
    this.getReceivingBankData();
    }
  },
  methods: {
    getReceivingBankData() {
      const payload = {
        leaseMasterAgreementHdrId: this.masterDetails.lease_master_agreement_hdr_id,
        arProjReceiptMethodMpngId: this.masterDetails.ar_proj_receipt_method_mpng_id
      }
      this.loader = true;
      this.$store.dispatch('leases/getRecevingBankDetailsById', payload)
      .then(response => {
        this.loader = false;
        if(response.status === 200) {
          this.receivingBankData = response.data.data;
        }
      })
      .catch(() => {
        this.loader = false;
      })
    },    
    closeReceivingBankDetails() {
      this.receivingBankDetailsVisible = false;
      this.$emit('closeReceivingBankDetails');
    },
  },
  beforeDestroy() {
    this.receivingBankDetailsVisible = false;
  }
};
