import party from '@/app/components/generalLedger/party';
import DatePicker from 'vue2-datepicker';
export default {
  name: 'InestorDetails',
  props: ['scheduleHdrId', 'eventId', 'wf_inprocess_dtl_id'],
  components: {
    party,
    DatePicker
  },
  watch: {
    selectAllCheckBox: function() {
      this.checkUncheckAll();
    }
  },
  data() {
    return {
      showHoldModal: false,
      editMode: true,
      loader: false,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      holdReason: null,
      holdUnholdData: [],
      selectAllCheckBox: false,
      leaseForm: {
        approval_status: null,
        fms_sub_unit_id: null,
        fms_sub_unit_name: null,
        le_id: null,
        le_name: null,
        lease_agreement_no: null,
        lease_investor_agreement_hdr_id: null,
        lease_investor_agreement_no: null,
        lease_investor_schedule_hdr_id: null,
        lease_master_agreement_hdr_id: null,
        lease_prj_unit_id: null,
        lease_schedule_type_id: null,
        lease_schedule_type_vset: null,
        lease_schedule_type_vset_meaning: null,
        lease_tenant_agreement_hdr_id: null,
        lease_tenant_agreement_no: null,
        lease_tenant_schedule_hdr_id: null,
        lease_unit_name: null,
        menu_id: null,
        ou_id: null,
        ou_name: null,
        recovery_type_vset: null,
        recovery_type_vset_meaning: null,
        request_num: null,
        vendor_id: null,
        vendor_name: null,
        vendor_site: null,
        vendor_site_id: null,
        version: null,
        investor_schedule_hdr_details: [
          {
            amount: null,
            approval_status: null,
            area: null,
            area_uom_vset: null,
            hold_reason: null,
            discount_value: null,
            sharing_ratio: null,
            period_from: null,
            period_to: null,
            lease_tenant_schedule_no: null,
            hold_status_vset_meaning: null,
            area_uom_vset_meaning: null,
            category_name: null,
            currency: null,
            discount_type_vset: null,
            discount_type_vset_meaning: null,
            exchange_rate: null,
            exchange_rate_type: null,
            gl_date: null,
            hold_status_vset: null,
            invoice_date: null,
            lease_investor_schedule_dtl_id: null,
            lease_investor_schedule_hdr_id: null,
            lease_investor_schedule_no: null,
            lease_tenant_schedule_dtl_id: null,
            payment_term_id: null,
            payment_term_name: null,
            rate: null,
            rate_uom_vset: null,
            rate_uom_vset_meaning: null,
            request_num: null,
            revenue_account: null,
            sac: null,
            schedule_status_vset: null,
            schedule_status_vset_meaning: null,
            tax_cat_id: null,
            transaction_currency_id: null,
            vendor_id: null,
            vendor_name: null,
            vendor_site_id: null,
            vendor_site_name: null
          }
        ]
      },
      gridFields: [
        {
          key: 'selectbox',
          label: 'Select All',
          class: 'text-center'
        },
        {
          key: 'lease_investor_schedule_no',
          label: 'Schedule No.'
        },
        {
          key: 'period_from'
        },
        {
          key: 'period_to'
        },
        {
          key: 'gl_date',
          label: 'GL Date'
        },
        {
          key: 'invoice_date'
        },
        {
          key: 'rate_uom_vset_meaning',
          label: 'UOM'
        },
        {
          key: 'area'
        },
        {
          key: 'rate'
        },
        {
          key: 'currency'
        },
        {
          key: 'amount'
        },
        {
          key: 'period_days'
        },
        {
          key: 'bill_days'
        },
        {
          key: 'act_bill_date_from'
        },
        {
          key: 'act_bill_date_to'
        },
        {
          key: 'discount_type_vset_meaning',
          label: 'Discount Type'
        },
        {
          key: 'discount_value',
          label: 'Discount',
          class: 'right-align'
        },
        {
          key: 'sharing_ratio',
          class: 'right-align'
        },
        {
          key: 'schedule_status_vset_meaning',
          label: 'Schedule Status'
        },
        {
          key: 'approval_status'
        },
        {
          key: 'hold_status_vset_meaning',
          label: 'Hold Status'
        },
        {
          key: 'hold_reason'
        },
        {
          key: 'charge_account',
          label: 'Expense Code'
        },
        {
          key: 'charge_account_code',
          label: 'Expense Account'
        },
        {
          key: 'vendor_name'
        },
        {
          key: 'vendor_site_name',
          label: 'Vendor Site'
        },
        {
          key: 'vendor_site_address'
        },
        {
          key: 'invoice_num',
          label: 'Invoice Number'
        },
        {
          key: 'bill_num',
          label: 'Bill Number'
        },
        {
          key: 'error_msg'
        }
      ],
      gridIndex: null,
      showCustomerSiteModal: false,
      showPartyModal: false,
      gridDataSite: [],
      gridFieldsSite: [
        {
          key: 'site_name'
        },
        {
          key: 'site_type'
        },
        {
          key: 'bill_to_address',
          label: 'Site Address'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'gstn',
          label: 'GSTN'
        }
      ],
      showValueSetModal: false,
      parent_value_set_id: null,
      vsetCode: null,
      unsubscribe: null,
      notificationSummary: false,
      approvalHistoryList: [],
      approvalHistoryFields: [
        {
          key: 'approver_name',
          label: 'Name'
        },
        {
          key: 'approval_status',
          label: 'Status'
        },
        {
          key: 'comments',
          label: 'Comments'
        },
        {
          key: 'action_date'
        }
      ],
      isApprovedByCurrrentUser: false,
      isConsultInitiated: false,
      urlSummaryData: null,
      wfDtlId: null,
      notificationComments: null
    };
  },
  mounted() {
    if (this.scheduleHdrId) {
      this.getScheduleDetails(this.scheduleHdrId);
    }
    if (this.$route.params.summaryData) {
      this.urlSummaryData = JSON.parse(
        Buffer.from(this.$route.params.summaryData, 'base64').toString('ascii')
      );
    }
    if (this.urlSummaryData) {
      this.wfDtlId = this.urlSummaryData.wf_inprocess_dtl_id;
    } else {
      this.wfDtlId = this.wf_inprocess_dtl_id;
    }

    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        // if (actionName === 'save' && this.editMode) {
        //   this.addEditInvestorScheduleDetails();
        // }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });

    if (this.urlSummaryData) {
      this.getScheduleDetails(
        this.urlSummaryData.lease_investor_schedule_hdr_id
      );
      this.wfDtlId = this.urlSummaryData.wf_inprocess_dtl_id;
    }
  },
  methods: {
    addEditInvestorScheduleDetails() {
      const filterSelectedData = this.leaseForm.investor_schedule_hdr_details.filter(
        data => data.selectbox
      );
      if (filterSelectedData && filterSelectedData.length > 0) {
        const prepareData = filterSelectedData.map(data => {
          return {
            vendor_id: data.vendor_id, //NotNull
            vendor_site_id: data.vendor_site_id, //NotNull
            discount_type_vset: data.discount_type_vset, //NotBlank
            discount_value: data.discount_value, //NotNull
            lease_investor_schedule_dtl_id: data.lease_investor_schedule_dtl_id, //NotNull
            gl_date: data.gl_date,
            invoice_date: data.invoice_date
          };
        });
        const payload = {
          scheduleHdrId: this.leaseForm.lease_investor_schedule_hdr_id,
          data: {
            investor_schedule_details: prepareData
          }
        };
        this.loader = true;
        this.$store
          .dispatch('leaseSchedule/addEditInvestorScheduleDetails', payload)
          .then(resp => {
            this.loader = false;
            this.showAlert = true;
            if (resp.status === 200) {
              this.isSuccess = true;
              this.respMessage = resp.data.message;
              this.leaseForm = resp.data.data;
              this.leaseForm.investor_schedule_hdr_details = this.leaseForm.investor_schedule_hdr_details.map(
                data => {
                  data.selectbox = false;
                  data.discount_value = data.discount_value
                    ? data.discount_value.toFixed(2)
                    : null;
                  data.amount = data.amount ? data.amount.toFixed(2) : null;
                  return data;
                }
              );
            } else {
              this.isSuccess = false;
              this.respMessage = resp.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    getScheduleDetails(scheduleHdrId) {
      this.loader = true;
      this.$store
        .dispatch('leaseSchedule/getInvestorScheduleDetails', scheduleHdrId)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.leaseForm = resp.data.data;
            this.leaseForm.investor_schedule_hdr_details = this.leaseForm.investor_schedule_hdr_details.map(
              data => {
                data.selectbox = false;
                data.discount_value = data.discount_value
                  ? data.discount_value.toFixed(2)
                  : null;
                data.amount = data.amount ? data.amount.toFixed(2) : null;
                return data;
              }
            );
            if (
              this.$route.name === 'notificationSummary' ||
              this.$route.name === 'dashboard' ||
              this.$route.name === 'workFlowSummary'
            ) {
              this.notificationSummary = true;
              this.getApprovalHistory(
                this.leaseForm.lease_investor_schedule_hdr_id
              );
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getApprovalHistory(leaseMasterAgreementHdrId) {
      this.loader = true;
      this.$store.dispatch('auth/getUserDetail').then(response => {
        if (response.status === 200) {
          this.userInfo = response.data.data;
          const payload = {
            approvalStatus: 'Y',
            nfaHdrId: leaseMasterAgreementHdrId,
            eventId: this.urlSummaryData
              ? this.urlSummaryData.wf_event_id
              : this.eventId
          };
          this.$store
            .dispatch('intWorkflow/getApprovalList', payload)
            .then(response => {
              this.loader = false;
              if (response.status === 200) {
                this.approvalHistoryList = response.data.data;
                const findCurrentUser = this.approvalHistoryList.find(
                  user =>
                    user.approver_employee_id === this.userInfo.employee_id &&
                    (user.approval_status === 'INPROCESS' ||
                      user.approval_status === 'CONSULT_INITIATED')
                );
                this.isApprovedByCurrrentUser = findCurrentUser ? true : false;
                const findConsultInitUser = this.approvalHistoryList.find(
                  data => data.approval_status === 'CONSULT_INITIATED'
                );
                this.isConsultInitiated = findConsultInitUser ? true : false;
              }
            })
            .catch(() => {
              this.loader = false;
            });
        }
      });
    },
    changeStatus(status) {
      this.loader = true;
      const payload = [
        {
          event_id: this.urlSummaryData
            ? this.urlSummaryData.wf_event_id
            : this.eventId,
          notification_comments: this.notificationComments,
          notification_status: status,
          wf_inprocess_dtl_id: this.wfDtlId,
          temp_approved_amount: 0
        }
      ];
      this.$store
        .dispatch('intWorkflow/approvalReject', { approval_requests: payload })
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.getApprovalHistory(
              this.leaseForm.lease_investor_schedule_hdr_id
            );
            this.editMode = false;
            this.isSuccess = true;
            this.respMessage = response.data.message;
          } else {
            this.isSuccess = false;
            this.respMessage = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openCloseHoldModal(flag) {
      this.showHoldModal = flag;
      this.holdReason = null;
    },
    submitHold() {
      const filterSelectedData = this.leaseForm.investor_schedule_hdr_details.filter(
        // data => data.selectbox && data.hold_status_vset !== 'STATUS_HOLD'
        data => data.selectbox && data.hold_status_vset !== 'Y'
      );
      const filterHoldRecords = this.leaseForm.investor_schedule_hdr_details.filter(
        // data => data.selectbox && data.hold_status_vset === 'STATUS_HOLD'
        data => data.selectbox && data.hold_status_vset === 'Y'
      );
      if (
        (filterSelectedData && filterSelectedData.length > 0) ||
        (filterHoldRecords && filterHoldRecords.length > 0)
      ) {
        if (filterSelectedData.length > 0) {
          this.holdUnholdData = filterSelectedData.map(data => {
            return {
              lease_investor_schedule_dtl_id:
                data.lease_investor_schedule_dtl_id //NotNull
            };
          });
        } else if (filterHoldRecords.length > 0) {
          this.holdUnholdData = filterHoldRecords.map(data => {
            return {
              lease_investor_schedule_dtl_id:
                data.lease_investor_schedule_dtl_id //NotNull
            };
          });
        }
        const payload = {
          scheduleHdrId: this.leaseForm.lease_investor_schedule_hdr_id,
          data: {
            hold_reason:
              filterSelectedData.length > 0
                ? this.holdReason
                : filterHoldRecords.length > 0
                ? this.holdReason
                : null,
            hold_status_vset:
              filterSelectedData.length > 0
                ? // ? 'STATUS_HOLD'
                  'Y'
                : filterHoldRecords.length > 0
                ? // ? 'STATUS_UNHOLD'
                  'N'
                : null,
            investor_schedule_details: this.holdUnholdData
          }
        };
        this.loader = true;
        this.$store
          .dispatch('leaseSchedule/investorScheduleHold', payload)
          .then(resp => {
            this.loader = false;
            this.showAlert = true;
            if (resp.status === 200) {
              this.isSuccess = true;
              this.respMessage = resp.data.message;
              this.leaseForm = resp.data.data;
              this.leaseForm.investor_schedule_hdr_details = this.leaseForm.investor_schedule_hdr_details.map(
                data => {
                  data.selectbox = false;
                  data.discount_value = data.discount_value
                    ? data.discount_value.toFixed(2)
                    : null;
                  data.amount = data.amount ? data.amount.toFixed(2) : null;
                  return data;
                }
              );
              this.openCloseHoldModal(false);
            } else {
              this.isSuccess = false;
              this.respMessage = resp.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    processRecord() {},
    checkUncheckAll() {
      this.leaseForm.investor_schedule_hdr_details = this.leaseForm.investor_schedule_hdr_details.map(
        data => {
          data.selectbox = this.selectAllCheckBox;
          return data;
        }
      );
    },
    showHideCustomerSiteModal(flag) {
      this.showCustomerSiteModal = flag;
    },
    getVendorSiteByVendorId(index) {
      this.gridIndex = index;
      this.$store
        .dispatch(
          'leaseSchedule/getVendorSiteByVendorId',
          this.leaseForm.investor_schedule_hdr_details[index].vendor_id
        )
        .then(response => {
          this.gridDataSite = response.data.data.vendor_sites;
          this.showCustomerSiteModal = true;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedSite(item) {
      this.leaseForm.investor_schedule_hdr_details[
        this.gridIndex
      ].vendor_site_name = item.site_name;
      this.leaseForm.investor_schedule_hdr_details[
        this.gridIndex
      ].vendor_site_id = item.vendor_site_id;
      this.showCustomerSiteModal = false;
    },
    showHidePartyModal(flag, index) {
      this.showPartyModal = flag;
      this.gridIndex = index;
    },
    getSelectedPartyCustomer(item) {
      this.leaseForm.investor_schedule_hdr_details[this.gridIndex].vendor_name =
        item.party_name;
      this.showPartyModal = false;
      this.getVendorIdByPartyId(item.party_id);
    },
    getVendorIdByPartyId(partyId) {
      this.$store
        .dispatch('leaseSchedule/getVendorIdByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            this.leaseForm.investor_schedule_hdr_details[
              this.gridIndex
            ].vendor_id = response.data.data;
          }
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'DISCOUNT_TYPE_VSET') {
        this.leaseForm.investor_schedule_hdr_details[
          this.specIndex
        ].discount_type_vset = item.value_code;
        this.leaseForm.investor_schedule_hdr_details[
          this.specIndex
        ].discount_type_vset_meaning = item.value_meaning;
      } else if (this.vsetCode === 'LEASE_SCHEDULE_TYPE_VSET') {
        this.leaseForm.lease_schedule_type_vset = item.value_code;
        this.leaseForm.lease_schedule_type_vset_meaning = item.value_meaning;
      } else if (this.vsetCode === 'LEASE_RECOVERY') {
        this.leaseForm.recovery_type_vset = item.value_code;
        this.leaseForm.recovery_type_vset_meaning = item.value_meaning;
      }
      this.showValueSetModal = false;
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.specIndex = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
