import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'rent',
  props: ['editMode', 'masterAgreementApprovalStatus'],
  components: { DatePicker },
  data() {
    return {
      showValueSetModal: false,
      parent_value_set_id: null,
      valueSetDtlId: null,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      gridData: [
        {
          lease_master_agreement_hdr_id: 0,
          lease_master_rent_period_id: 0,
          active: true,
          period_from: null,
          period_to: null
        }
      ],
      gridFields: [
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        },
        {
          key: 'active',
          stickyColumn: true
        },
        {
          key: 'period_from'
        },
        {
          key: 'period_to'
        }
      ],
      specIndex: null
    };
  },
  mounted() {
    this.eventBus.$on('rent', leaseMasterAgreementHdrId => {
      if (leaseMasterAgreementHdrId) {
        this.getRentFreeDetails(leaseMasterAgreementHdrId);
      }
    });
  },
  methods: {
    getRentFreeDetails(leaseMasterAgreementHdrId) {
      const payload = {
        leaseMasterAgreementHdrId: leaseMasterAgreementHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('leases/getRentFreeDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200 && resp.data.data.length) {
            this.gridData = resp.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    startDateIndex(index) {
      this.specIndex = index;
      this.gridData[index].period_to = null;
    },
    endDateIndex(index) {
      this.specIndex = index;
    },
    disabledDates(date) {
      return commonHelper.disabledDates(
        date,
        this.gridData[this.specIndex].period_from
      );
    },
    addNewRow() {
      if (this.editMode) {
        this.gridData.push({
          lease_master_agreement_hdr_id: 0,
          lease_master_rent_period_id: 0,
          active: true,
          period_from: null,
          period_to: null
        });
      }
    },
    removeRow(index) {
      if (this.editMode) {
        this.gridData.splice(index, 1);
      }
    }
  },
  beforeDestroy() {
    this.eventBus.$off('rent');
  }
};
