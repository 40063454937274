import Employee from '../../../../admin/employee';

export default {
  name: 'initiatedBy',
  components: { Employee },
  props: { editMode: Boolean, wfHeaderId: Number },
  data() {
    return {
      showEmployeeModal: false,
      loader: false,
      initatedIndex: null,
      initiateList: [
        {
          employee_name: null,
          employee_id: null
        }
      ],
      locationFeilds: [
        {
          key: 'employee_name',
          label: 'Name'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ]
    };
  },
  mounted() {
    if (this.wfHeaderId) {
      this.initiatedList(this.wfHeaderId);
    }
    this.eventBus.$on('initiatedBy', wfHeaderId => {
      if (wfHeaderId) {
        this.initiatedList(wfHeaderId);
      }
    });
  },
  methods: {
    initiatedList(wfHeaderId) {
      this.loader = true;
      const payload = {
        tabType: 'initiated-by',
        wfHeaderId: wfHeaderId
      };
      this.$store
        .dispatch('intWorkflow/getWfTabsDetails', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200 && response.data.data.length > 0) {
            this.initiateList = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addNewRow() {
      if (this.editMode) {
        this.initiateList.push({
          employee_name: null,
          employee_id: null
        });
      }
    },
    showHideEmpModal(flag, index) {
      this.initatedIndex = index;
      this.showEmployeeModal = flag;
    },
    employeeDetails(emp) {
      this.initiateList[this.initatedIndex].employee_name = emp.name;
      this.initiateList[this.initatedIndex].employee_id = emp.employee_id;
      this.showEmployeeModal = false;
    },
    removeRow(index) {
      this.initiateList.splice(index, 1);
    },
    rowSelected() {}
  },
  beforeDestroy() {
    this.eventBus.$off('initiatedBy');
    clearTimeout(this.setTimeVsetCode);
  }
};
