import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import VueCkeditor from 'vue-ckeditor5';
export default {
  name: 'description',
  props: ['descriptionValue'],
  components: {
    'vue-ckeditor': VueCkeditor.component
  },
  data() {
    return {
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      editors: {
        classic: ClassicEditor
      },
      description: null
    };
  },
  mounted() {
    setTimeout(() => {
      this.description = this.descriptionValue;
    }, 0);
  },
  methods: {
    saveFunction() {
      this.$emit('updatedValue', this.description);
    }
  }
};
