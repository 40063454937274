import DatePicker from 'vue2-datepicker';
import { BasicSelect } from 'vue-search-select';
import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
import { format } from 'date-fns';
export default {
  name: 'LeaseUnitMapping',
  components: {
    DatePicker,
    BasicSelect
  },
  watch: {
    currentPage: function() {
      // let offset = this.perPage * (this.currentPage - 1);
      // this.copySubUnitMappingList = this.addSubUnitMappingList.splice(
      //   offset,
      //   this.perPage
      // );
      this.getAllLeaseUnitList();
    },
    perPage: function() {
      // this.currentPage = 1;
      // let offset = this.perPage * (this.currentPage - 1);
      // this.copySubUnitMappingList = this.addSubUnitMappingList.splice(
      //   offset,
      //   this.perPage
      // );
      this.getAllLeaseUnitList();
    },
    listCurrentPage: function() {
      this.getBillingSubUnitList();
    },
    listPerPage: function() {
      this.listCurrentPage = 1;
      this.getBillingSubUnitList();
    },
    selectAllCheckBox: function() {
      this.checkUncheckAll();
    },
    defaultStartDate: function() {
      this.copySubUnitMappingList = this.copySubUnitMappingList.map(data => {
        data.start_date = this.defaultStartDate;
        return data;
      });
    }
  },
  data() {
    return {
      defaultStartDate: null,
      selectAllCheckBox: false,
      showValueSetModal: false,
      parent_value_set_id: null,
      isDependent: false,
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      totalRows: null,
      currentPage: 1,
      listPageOptions: commonHelper.getPageOption(),
      listPerPage: 10,
      listTotalRows: null,
      listCurrentPage: 1,
      editMode: false,
      projListIndex: null,
      unsubscribe: null,
      isSuccess: false,
      showAlert: false,
      respMessage: '',
      addIsSuccess: false,
      addShowAlert: false,
      addRespMessage: '',
      loader: false,
      showProjMapping: false,
      billingPlanName: null,
      subUnitMappingList: [],
      projMappingList: [],
      projeMappingFields: [
        {
          key: 'unit_name'
        },
        {
          key: 'customer_name'
        },
        {
          key: 'billable_area'
        },
        {
          key: 'fms_billable_area'
        },
        {
          key: 'uom'
        },
        {
          key: 'last_bill_period_from'
        },
        {
          key: 'last_bill_period_to'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        }
      ],
      selectedProject: {
        value: null,
        text: null
      },
      projectList: [],
      selectedTower: {
        value: null,
        text: null
      },
      selectedFloor: {
        value: null,
        text: null
      },
      selectedUnit: {
        value: null,
        text: null
      },
      selectedCustomer:{
        value: null,
        text: null
      },
      copySubUnitMappingList: [],
      // addSubUnitMappingList: [],
      addSubUnitFields: [
        {
          key: 'selectBox',
          label: 'Select All',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'unit_name',
          label: 'Lease Unit'
        },
        {
          key: 'customer_name'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        }
      ],
      addSelectedProject: {
        value: null,
        text: null
      },
      addSelectedTower: {
        value: null,
        text: null
      },
      addSelectedFloor: {
        value: null,
        text: null
      },
      addSelectedUnit: {
        value: null,
        text: null
      },
      addSelectedCustomer:{
        value: null,
        text: null
      },
      defaultValue:{
        value: null,
        text: null
      },
      vsetCode: null,
      depVsetParam: null,
      depTowerParam: null,
      plan_status: this.$store.state.masterData.planStatus
    };
  },
  mounted() {
    if (this.$route.params.billingPlanId) {
      this.getProjectList();
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update' && this.plan_status === 'BPINCOMPLETE') {
          this.editMode = true;
        }
        if (actionName === 'save' && this.plan_status === 'BPINCOMPLETE') {
          this.showProjMapping ? this.addSubUnit() : this.editSubUnit();
        }
      }
    });
  },
  methods: {
    getProjectList() {
      this.loader = true;
      this.$store
        .dispatch(
          'masterData/getBillingPlanProjList',
          this.$route.params.billingPlanId
        )
        .then(response => {
          this.editMode = false;
          this.loader = false;
          if (
            response.status === 200 &&
            response.data.data.billing_plan_project_details.length > 0
          ) {
            this.billingPlanName = response.data.data.billing_plan_name;
            this.projMappingList =
              response.data.data.billing_plan_project_details;
            this.projMappingList = this.projMappingList.map(data => {
              return {
                value: data.project_id,
                text: data.project_name
              };
            });
            this.selectedProject = this.projMappingList[0];
            this.getBillingSubUnitList();
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getBillingSubUnitList() {
      this.loader = true;
      const payload = {
        queryParams: {
          fmsPrjId: this.selectedProject.value,
          billingPlanId: this.$route.params.billingPlanId
        },
        searchParams: {
          _page: this.listCurrentPage - 1,
          _limit: this.listPerPage,
          tower_id: this.selectedTower.value,
          floor_id: this.selectedFloor.value,
          unit_id: this.selectedUnit.value,
          customer_id: this.selectedCustomer.value
        }
      };
      this.$store
        .dispatch('masterData/getBillingSubUnitList', payload)
        .then(response => {
          this.editMode = false;
          this.loader = false;
          this.subUnitMappingList = [];
          if (response.status === 200 && response.data.data.page.length > 0) {
            this.subUnitMappingList = response.data.data.page.map(data => {
              data.start_date = data.start_date
                ? new Date(data.start_date)
                : null;
              data.end_date = data.end_date ? new Date(data.end_date) : null;
              return data;
            });
          }
          this.listTotalRows = response.data.data.total_elements;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    onSelectProj(event) {
      this.selectedProject = event;
      this.selectedTower = this.defaultValue;
      this.selectedFloor = this.defaultValue;
      this.selectedUnit = this.defaultValue;
      this.getBillingSubUnitList();
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.parent_value_set_id = null;
      this.isDependent = false;
      this.depVsetParam = this.selectedProject.value;
      this.showValueSetModal = true;
      if (this.showProjMapping) {
        if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_TOWER_LIST) {
          this.parent_value_set_id = this.selectedProject.value;
        } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_FLOOR_LIST) {
          this.parent_value_set_id = this.addSelectedTower.value;
        } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_UNIT_LIST_FOR_FMS) {
          this.parent_value_set_id = {
            lease_tower_id: this.addSelectedTower.value,
            lease_floor_id: this.addSelectedFloor.value,
            customer_id: this.addSelectedCustomer.value
          }
        } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_CUSTOMER_LIST_FOR_FMS) {
          this.parent_value_set_id = this.selectedProject.value;
        } 
      } else {
        if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_TOWER_LIST) {
          this.parent_value_set_id = this.selectedProject.value;
        } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_FLOOR_LIST) {
          this.parent_value_set_id = this.selectedTower.value;
        } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_UNIT_LIST_FOR_FMS) {
          this.parent_value_set_id = {
            lease_tower_id: this.selectedTower.value,
            lease_floor_id: this.selectedFloor.value,
            customer_id: this.selectedCustomer.value
          }
        } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_CUSTOMER_LIST_FOR_FMS) {
          this.parent_value_set_id = this.selectedProject.value;
        } 
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.showProjMapping) {
        if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_TOWER_LIST) {
          this.addSelectedTower = {
            text: item.value_code,
            value: item.value_set_dtl_id
          };
          this.addSelectedFloor = this.defaultValue;
          this.addSelectedUnit = this.defaultValue;
        } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_FLOOR_LIST) {
          this.addSelectedTower = {
            text: item.lease_tower_name,
            value: item.lease_tower_id
          };
          this.addSelectedFloor = {
            text: item.value_code,
            value: item.value_set_dtl_id
          };
          this.addSelectedUnit = this.defaultValue;
        } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_UNIT_LIST_FOR_FMS) {
          this.addSelectedUnit = {
            text: item.value_code,
            value: item.value_set_dtl_id
          };
          this.addSelectedTower = {
            text: item.lease_tower_name,
            value: item.lease_tower_id
          };
          this.addSelectedFloor = {
            text: item.lease_floor_name,
            value: item.lease_floor_id
          };
        } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_CUSTOMER_LIST_FOR_FMS) {
          this.addSelectedCustomer = {
            text: item.value_code,
            value: item.value_set_dtl_id
          };
        }
      } else {
        if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PRJ_USING_FMS) {
          this.selectedProject = {
            text: item.value_code,
            value: item.value_set_dtl_id
          };
          this.selectedTower = this.defaultValue;
          this.selectedFloor = this.defaultValue;
          this.selectedUnit = this.defaultValue;
        } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_TOWER_LIST) {
          this.selectedTower = {
            text: item.value_code,
            value: item.value_set_dtl_id
          };
          this.selectedFloor = this.defaultValue;
          this.selectedUnit = this.defaultValue;
        } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_FLOOR_LIST) {
          this.selectedTower = {
            text: item.lease_tower_name,
            value: item.lease_tower_id
          };
          this.selectedFloor = {
            text: item.value_code,
            value: item.value_set_dtl_id
          };
          this.selectedUnit = this.defaultValue;
        } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_UNIT_LIST_FOR_FMS) {
          this.selectedTower = {
            text: item.lease_tower_name,
            value: item.lease_tower_id
          };
          this.selectedFloor = {
            text: item.lease_floor_name,
            value: item.lease_floor_id
          };
          this.selectedUnit = {
            text: item.value_code,
            value: item.value_set_dtl_id
          };
        } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_CUSTOMER_LIST_FOR_FMS) {
          this.selectedCustomer = {
            text: item.value_code,
            value: item.value_set_dtl_id
          };
        }
      }
    },
    clearVsetValues(vsetCode) {
      if (this.showProjMapping) {
        if (vsetCode === appStrings.VALUESETTYPE.LEASE_TOWER_LIST) {
          this.addSelectedTower = this.defaultValue;
        } else if (vsetCode === appStrings.VALUESETTYPE.LEASE_FLOOR_LIST) {
          this.addSelectedFloor = this.defaultValue;
        } else if (vsetCode === appStrings.VALUESETTYPE.LEASE_UNIT_LIST_FOR_FMS) {
          this.addSelectedUnit = this.defaultValue;
        }  else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_CUSTOMER_LIST_FOR_FMS) {
          this.addSelectedCustomer = this.defaultValue;
        }
      } else {
        if (vsetCode === appStrings.VALUESETTYPE.LEASE_TOWER_LIST) {
          this.selectedTower = this.defaultValue;
        } else if (vsetCode === appStrings.VALUESETTYPE.LEASE_FLOOR_LIST) {
          this.selectedFloor = this.defaultValue;
        } else if (vsetCode === appStrings.VALUESETTYPE.LEASE_UNIT_LIST_FOR_FMS) {
          this.selectedUnit = this.defaultValue;
        } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_CUSTOMER_LIST_FOR_FMS) {
          this.selectedCustomer = this.defaultValue;
        }
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    getAllLeaseUnitList() {
      const payload = {
        valueSetName: appStrings.VALUESETTYPE.LEASE_UNIT_LIST_FOR_FMS,
        dependentVariable: {
          search_key: this.searchText,
          _page: this.currentPage - 1,
          _limit: this.perPage,
          lease_prj_id: this.selectedProject.value,
          lease_tower_id: this.addSelectedTower.value,
          lease_floor_id: this.addSelectedFloor.value,
          lease_unit_id: this.addSelectedUnit.value,
          customer_id: this.addSelectedCustomer.value
        }
      };
      this.loader = true;
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.copySubUnitMappingList = response.data.data.data.map(
              data => data
            );
            this.totalRows = response.data.data.total_elements;
            this.isSuccess = true;
          }
        })
        .catch(error => {
          this.loader = false;
          alert(error.message);
        });
    },
    async addSubUnit() {
      const selectedData = this.copySubUnitMappingList.filter(
        data => data.selectBox
      );
      if (selectedData.length > 0) {
        const subUnitIds = selectedData.map(data => {
          return data.lease_prj_unit_id;
        });
        const payloadSubUnitIds = {
          entity_ids: subUnitIds.join(',')
        };
        const response = await this.$store.dispatch(
          'masterData/getBillingPlanAllSubUnits',
          payloadSubUnitIds
        );
        const addedSubUnitData = response.data.data;
        let overLapUnits = [];
        if (addedSubUnitData && addedSubUnitData.length > 0) {
          addedSubUnitData.forEach(subUnitObj => {
            const findSubUnit = selectedData.find(
              subUnit =>
                subUnit.lease_prj_unit_id === subUnitObj.lease_prj_unit_id
            );
            if (findSubUnit) {
              const newStartDate = findSubUnit.start_date
                ? new Date(findSubUnit.start_date)
                : null;
              const prevAddedDate = subUnitObj.start_date
                ? new Date(subUnitObj.start_date)
                : null;
              const preAddedEndDate = subUnitObj.end_date
                ? subUnitObj.end_date
                : null;
              if (!preAddedEndDate) {
                overLapUnits.push(findSubUnit.unit_name);
              } else if (
                prevAddedDate.getTime() <= newStartDate.getTime() &&
                newStartDate.getTime() <= preAddedEndDate.getTime()
              ) {
                overLapUnits.push(findSubUnit.unit_name);
              }
            }
          });
        }
        if (overLapUnits.length > 0) {
          this.loader = false;
          this.addShowAlert = true;
          this.addIsSuccess = false;
          this.addRespMessage = overLapUnits;
        } else {
          const subUnitPayload = selectedData.map(data => {
            return {
              active: true,
              billing_plan_sub_unit_mapping_id: 0,
              hold: false,
              last_bill_period_from: data.last_bill_period_from,
              last_bill_period_to: data.last_bill_period_to,
              provisional: false,
              start_date: data.start_date
                ? format(new Date(data.start_date), 'dd-MMM-yyyy')
                : null,
              end_date: data.end_date
                ? format(new Date(data.end_date), 'dd-MMM-yyyy')
                : null,
              entity_id: data.lease_prj_unit_id,
              void: false
            };
          });
          const payload = {
            subUnitData: {
              billing_plan_sub_units: subUnitPayload
            },
            queryParams: {
              fmsPrjId: this.selectedProject.value,
              billingPlanId: this.$route.params.billingPlanId
            }
          };
          this.loader = true;
          this.$store
            .dispatch('masterData/addEditBillingSubUnitList', payload)
            .then(response => {
              this.loader = false;
              this.showAlert = true;
              this.showProjMapping = false;
              // this.copySubUnitMappingList = this.addSubUnitMappingList.splice(
              //   0,
              //   this.perPage
              // );
              if (response.status === 200) {
                this.subUnitMappingList = response.data.data.page.map(data => {
                  data.start_date = data.start_date
                    ? new Date(data.start_date)
                    : null;
                  data.end_date = data.end_date
                    ? new Date(data.end_date)
                    : null;
                  return data;
                });
                this.editMode = false;
                this.isSuccess = true;
                this.respMessage = response.data.message;
              } else {
                this.isSuccess = false;
                this.respMessage = response.response.data.message;
              }
            })
            .catch(() => {
              this.loader = false;
            });
        }
      }
    },
    editSubUnit() {
      if (this.subUnitMappingList.length > 0 && this.editMode) {
        const subUnitPayload = this.subUnitMappingList.map(data => {
          return {
            active: true,
            billing_plan_sub_unit_mapping_id:
              data.billing_plan_sub_unit_mpng_id,
            hold: data.hold,
            last_bill_period_from: data.last_bill_period_from,
            last_bill_period_to: data.last_bill_period_to,
            provisional: false,
            start_date: data.start_date
              ? format(new Date(data.start_date), 'dd-MMM-yyyy')
              : null,
            end_date: data.end_date
              ? format(new Date(data.end_date), 'dd-MMM-yyyy')
              : null,
            entity_id: data.unit_id,
            void: false
          };
        });
        const payload = {
          subUnitData: {
            billing_plan_sub_units: subUnitPayload
          },
          queryParams: {
            fmsPrjId: this.selectedProject.value,
            billingPlanId: this.$route.params.billingPlanId
          }
        };
        this.loader = true;
        this.$store
          .dispatch('masterData/addEditBillingSubUnitList', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            this.showProjMapping = false;
            if (response.status === 200) {
              this.subUnitMappingList = response.data.data.page.map(data => {
                data.start_date = data.start_date
                  ? new Date(data.start_date)
                  : null;
                data.end_date = data.end_date ? new Date(data.end_date) : null;
                return data;
              });
              this.editMode = false;
              this.isSuccess = true;
              this.respMessage = response.data.message;
            } else {
              this.isSuccess = false;
              this.respMessage = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    showHideSubunitMappingModal() {
      // this.copySubUnitMappingList = this.addSubUnitMappingList.splice(
      //   0,
      //   this.perPage
      // );
      this.showProjMapping = true;
    },
    hideProjMapping() {
      this.clearFilter();
      this.selectAllCheckBox = false;
      this.showProjMapping = false;
    },
    checkUncheckAll() {
      this.copySubUnitMappingList = this.copySubUnitMappingList.map(data => {
        data.selectBox = this.selectAllCheckBox;
        return data;
      });
    },
    clearFilter() {
      this.defaultStartDate = null;
      this.totalRows=null;
      this.copySubUnitMappingList=[];
      if (this.showProjMapping) {
        this.addSelectedTower = this.defaultValue;
        this.addSelectedFloor = this.defaultValue;
        this.addSelectedUnit = this.defaultValue;
        this.addSelectedCustomer = this.defaultValue;
      } else {
        this.selectedTower = this.defaultValue;
        this.selectedFloor = this.defaultValue;
        this.selectedUnit = this.defaultValue;
        this.selectedCustomer = this.defaultValue;
        this.copySubUnitMappingList = this.copySubUnitMappingList.map(data => {
          data.start_date = null;
          return data;
        });
        this.getBillingSubUnitList();
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
