export default {
  name: 'OpenKmAuthentication',
  data() {
    return {
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      loader: false,
      userName: '',
      savePassword: false,
      password: '',
      isValid: false,
      verifyPassword: ''
    };
  },
  methods: {
    handleOnChange: function(value) {
      this.verifyPassword = value;
      this.error = {
        setPassword: ''
      };
      //   this.reset = false;
    },
    handleOnComplete: function(value) {
      this.password = value;
      this.loader = true;
      const payload = {
        password: value,
        saved_password_flag: this.savePassword,
        user_name: this.userName
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmAuthenticate', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.$emit('sendOpenKmToken', response.data.data.token);
            this.respMessage = response.data.message;
          } else {
            this.isSuccess = false;
            this.respMessage = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  }
};
