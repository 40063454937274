export default {
  name: 'printInternalWorkflow',
  props: ['approvalHistoryList', 'propsSelectedGridData'],
  data() {
    return {
      selectedGridData: null,
      negotiatedBy: 'N/A',
      negotiatedSummary: 'N/A',
      approvalHistoryFields: [
        {
          key: 'approver_name',
          label: 'Name'
        },
        {
          key: 'approval_status',
          label: 'Status'
        },
        {
          key: 'comments',
          label: 'Comments'
        }
      ]
    };
  },
  mounted() {
    this.selectedGridData = this.propsSelectedGridData;
    this.validateData();
  },
  methods: {
    validateData() {
      this.selectedGridData.excptions = this.selectedGridData.excptions
        ? this.selectedGridData.excptions
        : 'N/A';
      this.selectedGridData.nfa_cat = this.selectedGridData.nfa_cat
        ? this.selectedGridData.nfa_cat
        : 'N/A';
      this.selectedGridData.nfa_subject = this.selectedGridData.nfa_subject
        ? this.selectedGridData.nfa_subject
        : 'N/A';
      this.selectedGridData.le_name = this.selectedGridData.le_name
        ? this.selectedGridData.le_name
        : 'N/A';
      this.selectedGridData.project_type = this.selectedGridData.project_type
        ? this.selectedGridData.project_type
        : 'N/A';
      this.selectedGridData.created_by = this.selectedGridData.created_by
        ? this.selectedGridData.created_by
        : 'N/A';
      this.selectedGridData.nfa_number = this.selectedGridData.nfa_number
        ? this.selectedGridData.nfa_number
        : 'N/A';
      this.selectedGridData.nature_of_purchase = this.selectedGridData
        .nature_of_purchase
        ? this.selectedGridData.nature_of_purchase
        : 'N/A';
      this.selectedGridData.body = this.selectedGridData.body
        ? this.selectedGridData.body
        : 'N/A';
      this.selectedGridData.budget_amount = this.selectedGridData.budget_amount
        ? this.selectedGridData.budget_amount
        : 'N/A';
      this.selectedGridData.awarded_amount = this.selectedGridData
        .awarded_amount
        ? this.selectedGridData.awarded_amount
        : 'N/A';
      this.selectedGridData.budget_ref_code = this.selectedGridData
        .budget_ref_code
        ? this.selectedGridData.budget_ref_code
        : 'N/A';
      this.selectedGridData.bidding_type = this.selectedGridData.bidding_type
        ? this.selectedGridData.bidding_type
        : 'N/A';
      this.selectedGridData.changes = this.selectedGridData.changes
        ? this.selectedGridData.changes
        : 'N/A';
    },
    getNegotiatedList() {
      const payload = {
        tabType: 'negotiated-by',
        wfHeaderId: this.selectedGridData.nfa_hdr_id
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getWfTabsDetails', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200 && response.data.data.length > 0) {
            const getEmpNameArr = response.data.data.map(
              data => data.employee_name
            );
            this.negotiatedBy =
              getEmpNameArr && getEmpNameArr.length > 0
                ? getEmpNameArr.split(',')
                : 'N/A';
            const summaryArr = response.data.data.map(data => data.remarks);
            this.negotiatedSummary =
              summaryArr && summaryArr.length > 0
                ? summaryArr.split(',')
                : 'N/A';
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  }
};
