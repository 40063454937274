import commonHelper from '@/app/utility/common.helper.utility';
import URL_UTILITY from '../../../../../utility/url.utility';
import previewDoc from '../../../../onlineApproval/internalWorkFlow/history/uploadDocument/previewDoc';
export default {
  name: 'Additional',
  components: { previewDoc },
  props: [
    'partyRowDetails',
    'urlSummaryData',
    'workflowId',
    'dashboardDetails'
  ],
  data() {
    return {
      loader: false,
      additionalData: [],
      additionalFields: [
        {
          key: 'original_file_name',
          label: 'File Name'
        },
        {
          key: 'file_name',
          label: 'Preview'
        },
        {
          key: 'add_info_desc_meaning',
          label: 'Additional Info'
        },
        {
          key: 'additional_info_val',
          label: 'Additional Value'
        },
        {
          key: 'certificate_no'
        }
      ],
      partyReqId: null,
      wfDtlId: null,
      eventId: null,
      approvalStatus: null,
      uploadedFileName: null,
      showOpenModal: false,
      docDtlId: null,
      authToken: null,
      openKmAuth: null,
      downloadDocUrl: URL_UTILITY.getOpenKmDocDeleteUrl,
      dynamicData:null,
      currentPageIndex:null,
      accessButtons: {
        deleteAllow: false,
        downloadAllow: false,
        uploadAllow: false,
        editAllow: false,
        addAllow: false,
        printAllow: false,
        approvalAllow: false,
        newTab: true,
      },
    };
  },
  mounted() {
    if (this.partyRowDetails) {
      this.partyReqId = this.partyRowDetails.req_id;
      this.wfDtlId = this.partyRowDetails.inprocess_dtl_id;
      this.eventId = this.workflowId.eventId;
    }
    if (this.urlSummaryData) {
      this.partyReqId = this.urlSummaryData.PARTY_REQ_ID;
      this.wfDtlId = this.urlSummaryData.wf_inprocess_dtl_id;
      this.eventId = this.urlSummaryData.wf_event_id;
    }
    if (this.dashboardDetails) {
      this.partyReqId = this.dashboardDetails.party_req_id;
    }
    this.getAdditionalDetails();
  },
  methods: {
    getAdditionalDetails() {
      const payload = {
        party_req_id: this.partyReqId
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getAdditionalDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.additionalData = resp.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openPreviewDoc(flag, docDtlId, fileName, index, data) {
      const filterDynamicData = data.filter((a) => a.original_file_name);
      const getFileName = data[index].original_file_name;
      for (let i = 0; i < filterDynamicData.length; i++) {
        if (filterDynamicData[i].original_file_name == getFileName) {
          this.currentPageIndex = i + 1;
          break;
        }
      }
      this.dynamicData = filterDynamicData;
      if (flag) {
        this.getOpenKmAuthTokenPreview(flag, docDtlId, fileName);
        // this.timeout = setTimeout(() => {
        //   // this.showHideOpenModal(flag, docDtlId, this.openKmAuth, fileName);   previous function
        //   this.partOnboardingPreview(flag, docDtlId, this.openKmAuth, fileName);
        // }, 1000);
      }
    },
    partOnboardingPreview(flag, docDtlId, authToken, fileName){
      if (flag) {
        this.fileExtension = commonHelper.getFileExtension(fileName);
      }
      if (this.fileExtension === 'xlsx') {
        alert('Not Allowed');
      } else {
        // this.showOpenModal = flag;
        this.docDtlId = docDtlId;
        this.authToken = authToken;
        this.uploadedFileName = fileName;
        this.previewOpenDoc();        
      } 
    },
    previewOpenDoc() {
      this.fileExtension = commonHelper.getFileExtension(this.uploadedFileName);
      const payload = {
        docDetailId: this.docDtlId,
        token: this.authToken,
        fileExtension: this.fileExtension
      };
      this.loader = true;
      commonHelper.previewImgDocParty(payload);
       this.loader =false;
    },
    showHideOpenModal(flag, docDtlId, authToken, fileName) {
      if (flag) {
        this.fileExtension = commonHelper.getFileExtension(fileName);
      }
      if (this.fileExtension === 'xlsx') {
        alert('Not Allowed');
      } else {
        this.showOpenModal = flag;
        this.docDtlId = docDtlId;
        this.authToken = authToken;
        this.uploadedFileName = fileName;
      }
    },
    getOpenKmAuthTokenPreview(flag, docDtlId, fileName) {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmPartyPortalAuthenticate')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            if(this.openKmAuth){
              // this.partOnboardingPreview(flag, docDtlId, this.openKmAuth, fileName);
              this.showHideOpenModal(flag, docDtlId, this.openKmAuth, fileName);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getOpenKmAuthToken(docDtlId, fileName) {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmPartyPortalAuthenticate')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            const url =
              this.downloadDocUrl +
              '/download/' +
              docDtlId +
              '?token=' +
              this.openKmAuth;
            commonHelper.downloadFile(url, fileName);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  }
};
