var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loader)?_c('div',{staticClass:"overlay"},[_c('b-spinner',{staticClass:"loader",attrs:{"variant":"primary","label":"Spinning"}})],1):_vm._e(),_c('b-row',[_c('b-col',{staticClass:"mt-0 text-centre pl-3",attrs:{"md":"12"}},[_c('b-table',{staticClass:"bg-white font-fix",attrs:{"striped":"","hover":"","outlined":"","items":_vm.documentData,"fields":_vm.documentFields,"show-empty":"","sticky-header":""},scopedSlots:_vm._u([{key:"cell(original_file_name)",fn:function(data){return [_c('a',{staticStyle:{"cursor":"pointer"},attrs:{"href":"#"},on:{"click":function($event){return _vm.getOpenKmAuthToken(
                data.item.doc_detail_id,
                data.item.original_file_name
              )}}},[_vm._v(_vm._s(data.item.original_file_name))])]}},{key:"cell(file_name)",fn:function(data){return [(_vm.formName == 'invoiceApproval')?_c('span',{staticStyle:{"color":"#007BFF","cursor":"pointer"},on:{"click":function($event){return _vm.openPreviewDocInvoice(
                true,
                data.item.doc_detail_id,
                data.item.original_file_name,
                data.index,
                _vm.documentData
              )}}},[(data.item.doc_detail_id)?_c('b-icon',{attrs:{"icon":"eye-fill","font-scale":"1.5"}}):_vm._e()],1):_c('span',{staticStyle:{"color":"#007BFF","cursor":"pointer"},on:{"click":function($event){return _vm.openPreviewDoc(
                true,
                data.item.doc_detail_id,
                data.item.original_file_name
              )}}},[(data.item.doc_detail_id)?_c('b-icon',{attrs:{"icon":"eye-fill","font-scale":"1.5"}}):_vm._e()],1)]}}])})],1)],1),_c('b-modal',{attrs:{"no-close-on-esc":"","no-close-on-backdrop":"","hide-footer":"","size":"lg","title":"Preview"},on:{"hidden":function($event){return _vm.showHideOpenModal(false)}},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v("Preview"),_c('access-right-button',{attrs:{"accessButtons":_vm.accessButtons}})]},proxy:true}]),model:{value:(_vm.showOpenModal),callback:function ($$v) {_vm.showOpenModal=$$v},expression:"showOpenModal"}},[_c('previewDoc',{attrs:{"authToken":_vm.authToken,"docDtlId":_vm.docDtlId,"uploadedFileName":_vm.uploadedFileName,"data":_vm.dynamicData,"currentPageIndex":_vm.currentPageIndex}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }