import party from '@/app/components/generalLedger/party';
import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'TenantDetails',
  props: ['scheduleHdrId', 'eventId', 'wf_inprocess_dtl_id'],
  components: {
    party,
    DatePicker
  },
  watch: {
    selectAllCheckBox: function() {
      this.checkUncheckAll();
    }
  },
  data() {
    return {
      showHoldModal: false,
      editMode: true,
      loader: false,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      holdReason: null,
      holdUnholdData: [],
      selectAllCheckBox: false,
      leaseForm: {
        lease_tenant_schedule_hdr_id: null,
        lease_agreement_no: null,
        lease_tenant_agreement_no: null,
        lease_prj_unit_id: null,
        lease_unit_name: null,
        party_id: null,
        party: null,
        approval_status: null,
        lease_tenant_agreement_hdr_id: null,
        lease_master_agreement_hdr_id: null,
        lease_schedule_type_vset: null,
        lease_schedule_type_vset_meaning: null,
        recovery_type_vset: null,
        recovery_type_vset_meaning: null,
        le_id: null,
        ou_id: null,
        tenant_schedule_hdr_details: [
          {
            rate_uom_vset: null,
            rate: null,
            customer_site: null,
            currency: null,
            transaction_currency_id: null,
            rate_uom_vset_meaning: null,
            customer_name: null,
            customer_id: null,
            customer_site_id: null,
            lease_tenant_schedule_hdr_id: null,
            area_uom_vset: null,
            area: null,
            hold_reason: null,
            discount_value: null,
            period_from: null,
            period_to: null,
            lease_tenant_schedule_no: null,
            hold_status_vset_meaning: null,
            area_uom_vset_meaning: null,
            lease_tenant_schedule_dtl_id: null,
            schedule_no_status_vset_meaning: null,
            discount_type_vset: null,
            discount_type_vset_meaning: null,
            payment_status_vset: null,
            hold_status_vset: null,
            schedule_no_status_vset: null,
            payment_status_vset_meaning: null,
            amount: null,
            gl_date: null,
            invoice_date: null,
            period_days: null,
            bill_days: null,
            act_bill_date_from: null,
            act_bill_date_to: null
          }
        ]
      },
      gridFields: [
        {
          key: 'selectbox',
          label: 'Select All',
          class: 'text-center'
        },
        {
          key: 'lease_tenant_schedule_no',
          label: 'Schedule No.'
        },
        {
          key: 'period_from'
        },
        {
          key: 'period_to'
        },
        {
          key: 'gl_date',
          label: 'GL Date'
        },
        {
          key: 'invoice_date'
        },
        {
          key: 'rate_uom_vset_meaning',
          label: 'UOM'
        },
        {
          key: 'area'
        },
        {
          key: 'rate'
        },
        {
          key: 'currency'
        },
        {
          key: 'amount'
        },
        {
          key: 'period_days'
        },
        {
          key: 'bill_days'
        },
        {
          key: 'act_bill_date_from'
        },
        {
          key: 'act_bill_date_to'
        },
        {
          key: 'discount_type_vset_meaning',
          label: 'Discount Type'
        },
        {
          key: 'discount_value',
          label: 'Discount',
          class: 'right-align'
        },
        {
          key: 'schedule_status_vset_meaning',
          label: 'Schedule Status'
        },
        {
          key: 'schedule_status_vset'
        },
        {
          key: 'approval_status'
        },
        {
          key: 'hold_status_vset_meaning',
          label: 'Hold Status'
        },
        {
          key: 'hold_reason'
        },
        {
          key: 'revenue_account',
          label: 'Revenue Code'
        },
        {
          key: 'revenue_account_meaning',
          label: 'Revenue Account'
        },
        {
          key: 'customer_name'
        },
        {
          key: 'customer_site'
        },
        {
          key: 'customer_site_address'
        },
        {
          key: 'trx_num',
          label: 'Transaction Number'
        },
        {
          key: 'bill_num',
          label: 'Bill Number'
        },
        {
          key: 'error_msg'
        }
      ],
      gridIndex: null,
      showCustomerSiteModal: false,
      showPartyModal: false,
      gridDataSite: [],
      gridFieldsSite: [
        {
          key: 'site_name'
        },
        {
          key: 'site_type'
        },
        {
          key: 'bill_to_address',
          label: 'Site Address'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'gstn',
          label: 'GSTN'
        }
      ],
      showValueSetModal: false,
      parent_value_set_id: null,
      vsetCode: null,
      unsubscribe: null,
      notificationSummary: false,
      approvalHistoryList: [],
      approvalHistoryFields: [
        {
          key: 'approver_name',
          label: 'Name'
        },
        {
          key: 'approval_status',
          label: 'Status'
        },
        {
          key: 'comments',
          label: 'Comments'
        },
        {
          key: 'action_date'
        }
      ],
      isApprovedByCurrrentUser: false,
      isConsultInitiated: false,
      urlSummaryData: null,
      wfDtlId: null,
      notificationComments: null,
      billingPeriodFromDate: null,
      billingPeriodToDate: null,
      billingPeriodDate: null
    };
  },
  mounted() {
    if (this.scheduleHdrId) {
      this.getScheduleDetails(this.scheduleHdrId);
    }
    if (this.$route.params.summaryData) {
      this.urlSummaryData = JSON.parse(
        Buffer.from(this.$route.params.summaryData, 'base64').toString('ascii')
      );
    }
    if (this.urlSummaryData) {
      this.wfDtlId = this.urlSummaryData.wf_inprocess_dtl_id;
    } else {
      this.wfDtlId = this.wf_inprocess_dtl_id;
    }

    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditTenantScheduleDetails();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });

    if (this.urlSummaryData) {
      this.getScheduleDetails(this.urlSummaryData.lease_tenant_schedule_hdr_id);
      this.wfDtlId = this.urlSummaryData.wf_inprocess_dtl_id;
    }
  },
  methods: {
    addEditTenantScheduleDetails() {
      const filterSelectedData = this.leaseForm.tenant_schedule_hdr_details.filter(
        data => data.selectbox
      );
      if (filterSelectedData && filterSelectedData.length > 0) {
        const prepareData = filterSelectedData.map(data => {
          return {
            customer_id: data.customer_id, //NotNull
            customer_site_id: data.customer_site_id, //NotNull
            discount_type_vset: data.discount_type_vset, //NotBlank
            discount_value: data.discount_value, //NotNull
            lease_tenant_schedule_dtl_id: data.lease_tenant_schedule_dtl_id, //NotNull
            gl_date: data.gl_date,
            invoice_date: data.invoice_date,
            revenue_account: data.revenue_account,
            bill_days: data.bill_days,
            period_days: data.period_days,
            act_bill_date_from: data.act_bill_date_from,
            act_bill_date_to: data.act_bill_date_to
          };
        });
        const payload = {
          scheduleHdrId: this.leaseForm.lease_tenant_schedule_hdr_id,
          data: {
            lease_tenant_schedule_details: prepareData
          }
        };
        this.loader = true;
        this.$store
          .dispatch('leaseSchedule/addEditTenantScheduleDetails', payload)
          .then(resp => {
            this.loader = false;
            this.showAlert = true;
            if (resp.status === 200) {
              this.isSuccess = true;
              this.respMessage = resp.data.message;
              this.leaseForm = resp.data.data;
              this.leaseForm.tenant_schedule_hdr_details = this.leaseForm.tenant_schedule_hdr_details.map(
                data => {
                  data.selectbox = false;
                  data.discount_value = data.discount_value
                    ? data.discount_value.toFixed(2)
                    : null;
                  data.amount = data.amount ? data.amount.toFixed(2) : null;

                  if (!data.period_days) {
                    const periodFrom = new Date(data.period_from).setHours(
                      0,
                      0,
                      0,
                      0
                    );
                    const periodTo = new Date(data.period_to).setHours(
                      0,
                      0,
                      0,
                      0
                    );
                    data.period_days =
                      Math.abs(periodFrom - periodTo) / (24 * 3600 * 1000) + 1;
                  }
                  return data;
                }
              );
            } else {
              this.isSuccess = false;
              this.respMessage = resp.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    getScheduleDetails(scheduleHdrId) {
      this.loader = true;
      this.$store
        .dispatch('leaseSchedule/getTenantScheduleDetails', scheduleHdrId)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.leaseForm = resp.data.data;
            this.leaseForm.tenant_schedule_hdr_details = this.leaseForm.tenant_schedule_hdr_details.map(
              data => {
                data.selectbox = false;
                data.discount_value = data.discount_value
                  ? data.discount_value.toFixed(2)
                  : null;
                data.amount = data.amount ? data.amount.toFixed(2) : null;

                if (!data.period_days) {
                  const periodFrom = new Date(data.period_from).setHours(
                    0,
                    0,
                    0,
                    0
                  );
                  const periodTo = new Date(data.period_to).setHours(
                    0,
                    0,
                    0,
                    0
                  );
                  data.period_days =
                    Math.abs(periodFrom - periodTo) / (24 * 3600 * 1000) + 1;
                }
                return data;
              }
            );
            if (
              this.$route.name === 'notificationSummary' ||
              this.$route.name === 'dashboard' ||
              this.$route.name === 'workFlowSummary'
            ) {
              this.notificationSummary = true;
              this.getApprovalHistory(
                this.leaseForm.lease_tenant_schedule_hdr_id
              );
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getApprovalHistory(leaseMasterAgreementHdrId) {
      this.loader = true;
      this.$store.dispatch('auth/getUserDetail').then(response => {
        if (response.status === 200) {
          this.userInfo = response.data.data;
          const payload = {
            approvalStatus: 'Y',
            nfaHdrId: leaseMasterAgreementHdrId,
            eventId: this.urlSummaryData
              ? this.urlSummaryData.wf_event_id
              : this.eventId
          };
          this.$store
            .dispatch('intWorkflow/getApprovalList', payload)
            .then(response => {
              this.loader = false;
              if (response.status === 200) {
                this.approvalHistoryList = response.data.data;
                const findCurrentUser = this.approvalHistoryList.find(
                  user =>
                    user.approver_employee_id === this.userInfo.employee_id &&
                    (user.approval_status === 'INPROCESS' ||
                      user.approval_status === 'CONSULT_INITIATED')
                );
                this.isApprovedByCurrrentUser = findCurrentUser ? true : false;
                const findConsultInitUser = this.approvalHistoryList.find(
                  data => data.approval_status === 'CONSULT_INITIATED'
                );
                this.isConsultInitiated = findConsultInitUser ? true : false;
              }
            })
            .catch(() => {
              this.loader = false;
            });
        }
      });
    },
    changeStatus(status) {
      this.loader = true;
      const payload = [
        {
          event_id: this.urlSummaryData
            ? this.urlSummaryData.wf_event_id
            : this.eventId,
          notification_comments: this.notificationComments,
          notification_status: status,
          wf_inprocess_dtl_id: this.wfDtlId,
          temp_approved_amount: 0
        }
      ];
      this.$store
        .dispatch('intWorkflow/approvalReject', { approval_requests: payload })
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.getApprovalHistory(
              this.leaseForm.lease_tenant_schedule_hdr_id
            );
            this.editMode = false;
            this.isSuccess = true;
            this.respMessage = response.data.message;
          } else {
            this.isSuccess = false;
            this.respMessage = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openCloseHoldModal(flag) {
      this.showHoldModal = flag;
      this.holdReason = null;
    },
    submitHold() {
      const filterSelectedData = this.leaseForm.tenant_schedule_hdr_details.filter(
        // data => data.selectbox && data.hold_status_vset !== 'STATUS_HOLD'
        data => data.selectbox && data.hold_status_vset !== 'Y'
      );
      const filterHoldRecords = this.leaseForm.tenant_schedule_hdr_details.filter(
        // data => data.selectbox && data.hold_status_vset === 'STATUS_HOLD'
        data => data.selectbox && data.hold_status_vset === 'Y'
      );
      if (
        (filterSelectedData && filterSelectedData.length > 0) ||
        (filterHoldRecords && filterHoldRecords.length > 0)
      ) {
        if (filterSelectedData.length > 0) {
          this.holdUnholdData = filterSelectedData.map(data => {
            return {
              lease_tenant_schedule_dtl_id: data.lease_tenant_schedule_dtl_id //NotNull
            };
          });
        } else if (filterHoldRecords.length > 0) {
          this.holdUnholdData = filterHoldRecords.map(data => {
            return {
              lease_tenant_schedule_dtl_id: data.lease_tenant_schedule_dtl_id //NotNull
            };
          });
        }
        const payload = {
          scheduleHdrId: this.leaseForm.lease_tenant_schedule_hdr_id,
          data: {
            hold_reason:
              filterSelectedData.length > 0
                ? this.holdReason
                : filterHoldRecords.length > 0
                ? null
                : null,
            hold_status_vset:
              filterSelectedData.length > 0
                // ? 'STATUS_HOLD'
                ? 'Y'
                : filterHoldRecords.length > 0
                // ? 'STATUS_UNHOLD'
                ? 'N'
                : null,
            lease_tenant_schedule_details: this.holdUnholdData
          }
        };
        this.loader = true;
        this.$store
          .dispatch('leaseSchedule/tenantScheduleHold', payload)
          .then(resp => {
            this.loader = false;
            this.showAlert = true;
            if (resp.status === 200) {
              this.isSuccess = true;
              this.respMessage = resp.data.message;
              this.leaseForm = resp.data.data;
              this.leaseForm.tenant_schedule_hdr_details = this.leaseForm.tenant_schedule_hdr_details.map(
                data => {
                  data.selectbox = false;
                  data.discount_value = data.discount_value
                    ? data.discount_value.toFixed(2)
                    : null;
                  data.amount = data.amount ? data.amount.toFixed(2) : null;
                  return data;
                }
              );
              this.openCloseHoldModal(false);
            } else {
              this.isSuccess = false;
              this.respMessage = resp.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    processRecord() {},
    checkUncheckAll() {
      this.leaseForm.tenant_schedule_hdr_details = this.leaseForm.tenant_schedule_hdr_details.map(
        (data, index) => {
          if (data.schedule_status_vset !== 'U') {
            data.selectbox = false;
          } else {
            data.selectbox = this.selectAllCheckBox;
          }
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        }
      );
    },
    validateCheckBox(row) {
      if (!row.gl_date || !row.invoice_date) {
        return true;
      } else {
        return false;
      }
    },
    showHideCustomerSiteModal(flag) {
      this.showCustomerSiteModal = flag;
    },
    getCustomerSiteByPartyId(index) {
      this.gridIndex = index;
      const payload = {
        customerId: this.leaseForm.tenant_schedule_hdr_details[index]
          .customer_id,
        lease: {
          site_type_vset: appStrings.siteTypeVset
        }
      };
      this.$store
        .dispatch('party/getCustomerSiteById', payload)
        .then(response => {
          this.gridDataSite = response.data.data.customer_site_lists;
          this.showCustomerSiteModal = true;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedSite(item) {
      this.leaseForm.tenant_schedule_hdr_details[
        this.gridIndex
      ].customer_site_name = item.site_name;
      this.leaseForm.tenant_schedule_hdr_details[
        this.gridIndex
      ].customer_site_id = item.customer_site_id;
      this.showCustomerSiteModal = false;
    },
    showHidePartyModal(flag, index) {
      this.showPartyModal = flag;
      this.gridIndex = index;
    },
    getSelectedPartyCustomer(item) {
      this.leaseForm.tenant_schedule_hdr_details[this.gridIndex].customer_name =
        item.party_name;
      this.showPartyModal = false;
      this.getCustomerByPartyId(item.party_id);
    },
    getCustomerByPartyId(partyId) {
      this.$store
        .dispatch('leases/getCustomerByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            this.leaseForm.tenant_schedule_hdr_details[
              this.gridIndex
            ].customer_id = response.data.data.customer_id;
          }
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'DISCOUNT_TYPE_VSET') {
        this.leaseForm.tenant_schedule_hdr_details[
          this.specIndex
        ].discount_type_vset = item.value_code;
        this.leaseForm.tenant_schedule_hdr_details[
          this.specIndex
        ].discount_type_vset_meaning = item.value_meaning;
      } else if (this.vsetCode === 'LEASE_SCHEDULE_TYPE_VSET') {
        this.leaseForm.lease_schedule_type_vset = item.value_code;
        this.leaseForm.lease_schedule_type_vset_meaning = item.value_meaning;
      } else if (this.vsetCode === 'LEASE_RECOVERY') {
        this.leaseForm.recovery_type_vset = item.value_code;
        this.leaseForm.recovery_type_vset_meaning = item.value_meaning;
      }
      this.showValueSetModal = false;
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.specIndex = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },

    getPeriodDaysCount(index) {
      const currentRow = this.leaseForm.tenant_schedule_hdr_details[index];
      let periodCount = 0;
      if (
        currentRow &&
        currentRow.act_bill_date_to &&
        currentRow.act_bill_date_from
      ) {
        const dateFrom = new Date(currentRow.act_bill_date_from).setHours(
          0,
          0,
          0,
          0
        );
        const dateTo = new Date(currentRow.act_bill_date_to).setHours(
          0,
          0,
          0,
          0
        );
        periodCount = Math.abs(dateFrom - dateTo) / (24 * 3600 * 1000) + 1;
        currentRow.bill_days = periodCount;
        this.getPerDayAmount(index);
        return currentRow.bill_days;
      } else {
        currentRow.bill_days = null;
      }
    },
    getPerDayAmount(index) {
      const currentRow = this.leaseForm.tenant_schedule_hdr_details[index];

      let amount = currentRow.amount / currentRow.period_days;

      const amountPerDay = amount;

      this.calcDiscount(index, amountPerDay);
    },
    calcDiscount(index, amountPerDay) {
      const currentRow = this.leaseForm.tenant_schedule_hdr_details[index];

      const discAmount =
        amountPerDay * (currentRow.period_days - currentRow.bill_days);

      currentRow.discount_value = Math.round(discAmount * 100) / 100;

      currentRow.discount_type_vset_meaning = 'ADHOC';
      currentRow.discount_type_vset = 'DIS_ADHOC';
    },
    disabledDates(date) {
      const restrictedFromDate = new Date(this.billingPeriodFromDate);
      restrictedFromDate.setHours(0, 0, 0, 0);

      const restrictedToDate = new Date(this.billingPeriodToDate);
      restrictedToDate.setHours(0, 0, 0, 0);

      return date < restrictedFromDate || date > restrictedToDate;
    },
    setReferenceDate(row) {
      this.billingPeriodFromDate = row.period_from;
      this.billingPeriodToDate = row.period_to;
    },

    changeDate(date, index, column) {
      if (column === 'gl_date') {
        const validatePeriodDatePromise = commonHelper.getValidatePeriodDate(
          date,
          22,
          this.leaseForm.le_id,
          this.leaseForm.ou_id
        );
        validatePeriodDatePromise.then(isPeriodOpen => {
          if (isPeriodOpen === 'NOTDEFINED') {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = `Lease Tenant Agreement: ${this.leaseForm.lease_tenant_agreement_no}, Input GL Date is Not Defined`;
            const validatePeriodDatePromise = commonHelper.getValidatePeriodDate(
              this.leaseForm.tenant_schedule_hdr_details[index].invoice_date,
              22,
              this.leaseForm.le_id,
              this.leaseForm.ou_id
            );
            validatePeriodDatePromise.then(isPeriodOpen => {
              this.leaseForm.tenant_schedule_hdr_details[index].gl_date =
                isPeriodOpen === 'NOTDEFINED'
                  ? null
                  : this.leaseForm.tenant_schedule_hdr_details[index]
                      .invoice_date;
            });
          } else {
            this.validateGlDate(index, date);
          }
        });
      } else {
        if (this.leaseForm.tenant_schedule_hdr_details[index].gl_date) {
          const checkGlDate = commonHelper.validateInvoiceAndPeriodDate(
            this.leaseForm.tenant_schedule_hdr_details[index].gl_date,
            date
          );
          if (checkGlDate) {
            this.leaseForm.tenant_schedule_hdr_details[
              index
            ].invoice_date = date;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = `Lease Tenant Agreement: ${this.leaseForm.lease_tenant_agreement_no}, Please select Invoice date greater than GL date!`;
          }
        } else {
          this.leaseForm.tenant_schedule_hdr_details[index].invoice_date = date;
        }
      }
    },
    disabledGlDates(date) {
      const restrictedDate = new Date(this.billingPeriodDate);
      restrictedDate.setHours(0, 0, 0, 0);

      return date < restrictedDate;
    },
    setReferenceDateForGl(row) {
      this.billingPeriodDate = row.period_from;
    },

    selectBoxChecked(flag, index) {
      if (
        this.leaseForm.tenant_schedule_hdr_details[index]
          .schedule_status_vset !== 'P'
      ) {
        this.leaseForm.tenant_schedule_hdr_details[index].selectBox = flag;
        if (flag) {
          this.leaseForm.tenant_schedule_hdr_details[index].invoice_date = this
            .leaseForm.tenant_schedule_hdr_details[index].invoice_date
            ? this.leaseForm.tenant_schedule_hdr_details[index].invoice_date
            : this.leaseForm.tenant_schedule_hdr_details[index].period_from;

          if (this.leaseForm.tenant_schedule_hdr_details[index].gl_date) {
            const validatePeriodDatePromise = commonHelper.getValidatePeriodDate(
              this.leaseForm.tenant_schedule_hdr_details[index].gl_date,
              22,
              this.leaseForm.le_id,
              this.leaseForm.ou_id
            );
            validatePeriodDatePromise.then(isPeriodOpen => {
              if (isPeriodOpen === 'NOTDEFINED') {
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg = `Lease Tenant Agreement: ${this.leaseForm.tenant_schedule_hdr_details[index].lease_tenant_agreement_no}, Input GL Date is Not Defined`;
                const validatePeriodDatePromise = commonHelper.getValidatePeriodDate(
                  this.leaseForm.tenant_schedule_hdr_details[index]
                    .invoice_date,
                  22,
                  this.leaseForm.le_id,
                  this.leaseForm.ou_id
                );
                validatePeriodDatePromise.then(isPeriodOpen => {
                  this.leaseForm.tenant_schedule_hdr_details[index].gl_date =
                    isPeriodOpen === 'NOTDEFINED'
                      ? null
                      : this.leaseForm.tenant_schedule_hdr_details[index]
                          .invoice_date;
                });
              } else {
                this.validateGlDate(
                  index,
                  this.leaseForm.tenant_schedule_hdr_details[index].gl_date
                );
              }
            });
          } else {
            const glDatePromiseResult = commonHelper.getModuleBasedPeriodDate(
              22,
              this.leaseForm.le_id,
              this.leaseForm.ou_id
            );
            glDatePromiseResult.then(defaultOpenPeriodDate => {
              this.validateGlDate(index, defaultOpenPeriodDate);
            });
          }
        }
      }
    },
    validateGlDate(index, defaultOpenPeriodDate) {
      const checkGlDate = commonHelper.validateInvoiceAndPeriodDate(
        defaultOpenPeriodDate,
        this.leaseForm.tenant_schedule_hdr_details[index].invoice_date
      );
      if (checkGlDate) {
        this.leaseForm.tenant_schedule_hdr_details[
          index
        ].gl_date = defaultOpenPeriodDate;
      } else {
        const validatePeriodDatePromise = commonHelper.getValidatePeriodDate(
          this.leaseForm.tenant_schedule_hdr_details[index].invoice_date,
          22,
          this.leaseForm.le_id,
          this.leaseForm.ou_id
        );
        validatePeriodDatePromise.then(isPeriodOpen => {
          this.leaseForm.tenant_schedule_hdr_details[index].gl_date =
            isPeriodOpen === 'NOTDEFINED'
              ? null
              : this.leaseForm.tenant_schedule_hdr_details[index].invoice_date;
        });
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
