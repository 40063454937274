import DatePicker from 'vue2-datepicker';
import { format } from 'date-fns';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'ProjectMapping',
  components: { DatePicker },
  props: ['planTypeCodeObj','projectTypeStatus'],
  data() {
    return {
      showValueSetModal: false,
      setTimeVsetCode: null,
      editMode: true,
      projListIndex: null,
      unsubscribe: null,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      loader: false,
      showProjMapping: false,
      billingPlanName: null,
      parent_value_set_id: null,
      planType: null,
      projMappingList: [
        {
          active: true,
          billing_plan_project_mpng_id: 0,
          billing_plan_subunits: null,
          end_date: null,
          project_id: null,
          project_name: null,
          start_date: null
        }
      ],
      projeMappingFields: [
        {
          key: 'project_name',
          label: 'FMS/Lease Project'
        },
        {
          key: 'start_date'
        },
        {
          key: 'is_external'
        },
        {
          key: 'active'
        },
        {
          key: 'end_date',
          class: 'd-none'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ],
      plan_status: this.$store.state.masterData.planStatus,
      dateData: null
    };
  },
  mounted() {
    this.dateData = this.$store.state.masterData.dateData;
    if (this.$route.params.billingPlanId) {
      this.getProjectList();
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditPrjMapping();
        }
        if (actionName === 'update' && this.plan_status === 'BPINCOMPLETE') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    addEditPrjMapping() {
      if (this.projMappingList.length > 0) {
        const prjDetail = this.projMappingList.map(data => {
          return {
            active: data.active,
            billing_plan_project_mpng_id: data.billing_plan_project_mpng_id,
            end_date: data.end_date
              ? format(new Date(data.end_date), 'dd-MMM-yyyy')
              : null,
            project_id: data.project_id,
            start_date: data.start_date
              ? format(new Date(data.start_date), 'dd-MMM-yyyy')
              : null
          };
        });
        const payload = {
          billingPlanPrjDetails: {
            billing_plan_project_detail: prjDetail
          },
          billingPlanHdrId: this.$route.params.billingPlanId
        };
        this.loader = true;
        this.$store
          .dispatch('masterData/addEditBillingPlanProject', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.projMappingList =
                response.data.data.billing_plan_project_details;
              this.projMappingList = this.projMappingList.map(data => {
                (this.project_id = data.project_id),
                  (data.start_date = data.start_date
                    ? new Date(data.start_date)
                    : null);
                data.end_date = data.end_date ? new Date(data.end_date) : null;
                return data;
              });
              this.editMode = false;
              this.isSuccess = true;
              this.respMessage = response.data.message;
            } else {
              this.isSuccess = false;
              this.respMessage = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    getProjectList() {
      this.loader = true;
      this.$store
        .dispatch(
          'masterData/getBillingPlanProjList',
          this.$route.params.billingPlanId
        )
        .then(response => {
          this.editMode = false;
          this.loader = false;
          if (
            response.status === 200 &&
            response.data.data.billing_plan_project_details.length > 0
          ) {
            this.billingPlanName = response.data.data.billing_plan_name;
            this.projMappingList =
              response.data.data.billing_plan_project_details;
            this.projectTypeStatus(this.projMappingList);
            this.projMappingList = this.projMappingList.map(data => {
              data.start_date = data.start_date
                ? new Date(data.start_date)
                : null;
              data.end_date = data.end_date ? new Date(data.end_date) : null;
              return data;
            });
          } else {
            this.projMappingList[0].start_date = new Date(
              this.dateData.billing_start_date
            );
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addNewRow() {
      if (this.editMode) {
        this.projMappingList.push({
          active: true,
          billing_plan_project_mpng_id: 0,
          billing_plan_subunits: null,
          end_date: new Date(this.dateData.billing_end_date),
          project_id: null,
          project_name: null,
          start_date: new Date(this.dateData.billing_start_date)
        });
      }
    },
    removeRow(index) {
      this.projMappingList.splice(index, 1);
    },
    selectedvalueSet(item) {
      if (this.planType === appStrings.VALUESETTYPE.FMS_PROJECT) {
        let duplicateFmsProjectList = this.projMappingList.filter(
          element => element.project_id === item.proj_id
        );
        if (duplicateFmsProjectList.length === 0) {
          this.projMappingList[this.projListIndex].project_name =
            item.fms_prj_name;
          this.projMappingList[this.projListIndex].project_id = item.proj_id;
        }
      } else {
        let leaseFmsProjectList = this.projMappingList.filter(
          element => element.project_id === item.lease_prj_id
        );
        if (leaseFmsProjectList.length === 0) {
          this.projMappingList[this.projListIndex].project_name =
            item.lease_prj_name;
          this.projMappingList[this.projListIndex].project_id =
            item.lease_prj_id;
        }
      }
    },
    openValueSetModal(index) {
      this.planTypeCodeObj === 'LEASE_UNIT'
        ? (this.planType = 'LEASE_PRJ_USING_FMS')
        : (this.planType = 'FMS_PROJECT');
      this.projListIndex = index;
      this.$store
        .dispatch(
          'masterData/getBillingPlanLEId',
          this.$route.params.billingPlanId
        )
        .then(response => {
          if (response.status === 200) {
            this.showValueSetModal = true;
            this.parent_value_set_id = response.data.data.le_id.toString();
            this.setTimeVsetCode = setTimeout(() => {
              const vSetData = {
                valueSetName: this.planType,
                multiFlag: null
              };
              this.eventBus.$emit('valueSetCode', vSetData);
            }, 0);
          }
        });
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    hideProjMapping() {}
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
