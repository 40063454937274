import DatePicker from 'vue2-datepicker';
import { format } from 'date-fns';
import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
import party from '@/app/components/generalLedger/party';
import currency from '@/app/components/admin/currency';
import securityDeposit from './securityDeposit';
import escalation from './escalation';
import specification from './specification';
import terminationNotice from './ternimationNotice';
import rentalDetails from './rentalDetails';
import tax from './tax';
import VueCkeditor from 'vue-ckeditor5';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import buildingProject from '../../../masterLease/leaseProject';
import camAndOtherCharges from './camAndOtherCharges';
import masterAgreement from '../../masterAgreement';
import leaseUnit from '../../../masterLease/leaseUnit';
import store from '../../../../../../../store';
import downlaodFiles from '@/app/components/onlineApproval/nfaApproval/addNfaApproval/downloadFiles';
import URL_UTILITY from '@/app/utility/url.utility';
import openKmAuthentication from '../../../../../onlineApproval/internalWorkFlow/openKmAuthentication';
import addEditTerminationLease from '../addEditTenantLease/addEditTerminationLease';
import { required } from 'vuelidate/lib/validators';
import brokerInfo from './brokerInfo';
import terms from './termsCondition';
import rent from './rentFreePeriod';
export default {
  name: 'addEditTenantLeases',
  props: [
    'selectedLeaseDetails',
    'eventId',
    'wf_inprocess_dtl_id',
    'createCopyWorkflow'
  ],
  components: {
    DatePicker,
    party,
    currency,
    securityDeposit,
    escalation,
    specification,
    terminationNotice,
    rentalDetails,
    'vue-ckeditor': VueCkeditor.component,
    buildingProject,
    camAndOtherCharges,
    tax,
    masterAgreement,
    leaseUnit,
    downlaodFiles,
    openKmAuthentication,
    addEditTerminationLease,
    brokerInfo,
    terms,
    rent
  },
  data() {
    return {
      setTimeForCustSite: null,
      setCustCode: null,
      isViewMore: false,
      activeTab: 'escalation',
      activeTabList: [],
      tabTimeOut: null,
      editors: {
        classic: ClassicEditor
      },
      scopeOfWork: null,
      showProjectModal: false,
      showCurrencyModal: false,
      showPartyModal: false,
      unsubscribe: null,
      showModalComponent: false,
      showValueSetModal: false,
      editMode: true,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: [],
      parent_value_set_id: null,
      periodType: null,
      tab: null,
      tempParentData: null,
      version: 0,
      unitId: 0,
      startDate: null,
      endDate: null,
      active: true,
      subunitMapping: true,
      selectedCurrency: {
        value: null,
        text: null
      },
      fitoutScope: null,
      logicNotes: null,
      notificationSummary: null,
      selectedProject: {
        value: null,
        text: null
      },
      parkingDetails: {
        car_park_cover_exc: null,
        car_park_cover_nonexc: null,
        car_park_open_exc: null,
        car_park_open_nonexc: null,
        car_parking_comments: null
      },
      leaseForm: {
        status: null,
        customer_id: null, //new added
        customer_name: null,
        customer_site_id: null,
        customer_site_name: null,
        customer_site_address: null,
        lease_tenant_rental_start_date: format(new Date(), 'dd-MMM-yyyy'),
        le_id: null,
        le_name: null,
        org_ou_id: null,
        org_ou_name: null,
        lease_status_meaning: null,
        copyWorkFlow: null,
        lease_status: 'U',
        from_date: null,
        to_date: null,
        period_from_to: [],
        super_area: null,
        carpet_area: null,
        billable_area: null,
        billable_area_uom_vset: null,
        billable_area_uom_meaning: null,
        carpet_area_uom_vset_meaning: null,
        lease_prj_unit_name: null,
        lease_tenant_agreement_hdr_id: 0,
        lease_tenant_agreement_date: format(new Date(), 'dd-MMM-yyyy'),
        transaction_currency_id: null,
        lease_approval_notes: null,
        rate_uom_vset_meaning: null,
        lease_tenant_agreement_no: null,
        lease_agreement_no: null,
        lease_master_agreement_hdr_id: null,
        lease_description: null,
        lease_party_id: null,
        fitout_scope: null,
        rate: null,
        rate_uom_vset: null,
        logic_note: null,
        carpet_area_uom_vset: null,
        lease_party_name: null,
        currency: null,
        notice_period: 0,
        notice_period_uom_vset: null,
        notice_period_uom_vset_meaning: null,
        locking_period: 0,
        locking_period_uom_vset: null,
        locking_period_uom_vset_meaning: null,
        lease_prj_id: null,
        lease_prj_name: null,
        lease_prj_code: null,
        super_area_uom_vset: null,
        super_area_uom_vset_meaning: null,
        lease_prj_unit_id: null,
        version: 0,
        approval_status: 'DRAFT',
        menu_id: null,
        mark_for_termination: false,
        expected_termination_date: null,
        car_park_open_exc_price: null,
        car_park_open_nonexc_price: null,
        car_park_cover_exc_price: null,
        car_park_cover_nonexc_price: null,
        tenant_po: null
      },
      showCommonModal: false,
      modalType: null,
      leaseMasterAgreementHdrId: null,
      notificationComments: null,
      approvalHistoryList: [],
      approvalHistoryFields: [
        {
          key: 'approver_name',
          label: 'Name'
        },
        {
          key: 'approval_status',
          label: 'Status'
        },
        {
          key: 'comments',
          label: 'Comments'
        },
        {
          key: 'action_date'
        }
      ],
      isApprovedByCurrrentUser: false,
      isConsultInitiated: false,
      urlSummaryData: null,
      wfDtlId: null,
      customerId: null,
      showCustomerSiteModal: false,
      gridDataSite: [],
      gridFieldsSite: [
        {
          key: 'site_name'
        },
        {
          key: 'site_type'
        },
        {
          key: 'bill_to_address',
          label: 'Site Address'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        }
      ],
      vsetCode: null,
      setTimeVsetCode: null,
      customerIdByLeaseCustomerSites: null,

      showDownloadModal: false,

      downloadItems: [],
      downloadFields: [
        {
          key: 'original_file_name',
          label: 'File Name'
        },
        {
          key: 'doc_version',
          label: 'Version'
        },
        {
          key: 'file_size',
          label: 'Size'
        },
        {
          key: 'comments'
        },
        {
          key: 'created_by'
        },
        {
          key: 'creation_date'
        }
      ],
      downloadDocUrl: URL_UTILITY.getOpenKmDocDeleteUrl,
      totalRows: null,
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      showOpenKmModal: false,
      showLeaseTerminationModal: false,
      leasePrjUnitId: null,
      requestNumId: null,
      dowloadReport: URL_UTILITY.getDownloadReportUrl,
      downloadFlag: false,
    };
  },
  validations: {
    leaseForm: {
      expected_termination_date: {
        required
      }
    }
  },
  mounted() {
    if (this.selectedLeaseDetails) {
      this.copyWorkFlow = this.createCopyWorkflow;
      this.setLeaseFormData(this.selectedLeaseDetails);
      this.leasePrjUnitId = this.selectedLeaseDetails.lease_prj_unit_id;
      this.getProjectUnitSpec();
    }
    if (this.$route.params.summaryData) {
      this.urlSummaryData = JSON.parse(
        Buffer.from(this.$route.params.summaryData, 'base64').toString('ascii')
      );
    }
    if (this.urlSummaryData) {
      this.leasePrjUnitId = this.urlSummaryData.lease_prj_unit_id;
      this.wfDtlId = this.urlSummaryData.wf_inprocess_dtl_id;
      this.getProjectUnitSpec();
    } else {
      this.wfDtlId = this.wf_inprocess_dtl_id;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.responseMsg = [];
          if (
            this.leaseForm.lease_tenant_agreement_hdr_id === 0 ||
            this.leaseForm.approval_status === 'DRAFT' ||
            this.leaseForm.approval_status === 'APPROVED' ||
            this.leaseForm.approval_status === 'RESUBMIT'
          ) {
            this.addEditTenantLease();
          } else {
            // this.addEditSpecification();
            // this.addEditRentalDetails();
            this.addEditEscalation();
            this.addEditTermNotice();
            this.addEditSecurityDeposit();
            this.addEditTax();
            this.addEditBrokerage();
            this.addEditTermsCondition();
            this.addEditRentFreePeriod();
            // this.addEditCamAndOther();
          }
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', { id: this.unitId });
        }
        if (
          actionName === 'delete' &&
          this.leaseForm.lease_tenant_agreement_hdr_id !== 0
        ) {
          this.isDocumentUploadedFunc();
          // this.showLeaseTerminationModal = true;
        }
        if (
          actionName === 'print' &&
          this.leaseForm.lease_tenant_agreement_hdr_id > 0
        ) {
          this.saveSubmitRequest();
        }
      }
    });
    if (this.urlSummaryData) {
      this.getLeaseTenantAgrDetails(
        this.urlSummaryData.lease_tenant_agreement_hdr_id
      );
      this.wfDtlId = this.urlSummaryData.wf_inprocess_dtl_id;
    }
  },
  methods: {
    getLeaseTenantAgrDetails(leaseHdrId) {
      this.loader = true;
      this.$store
        .dispatch('leases/getTenantLeaseDetails', leaseHdrId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.setLeaseFormData(response.data.data);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    leaseRentDateToEsc(date) {
      this.eventBus.$emit('rentPeriodFrom', date);
    },
    addEditTenantLease() {
      const payload = {
        customer_id: this.leaseForm.customer_id,
        customer_site_id: this.leaseForm.customer_site_id,
        lease_tenant_rental_start_date: this.leaseForm
          .lease_tenant_rental_start_date,
        approval_status: this.leaseForm.approval_status, //required
        fitout_scope: this.fitoutScope,
        lease_approval_notes: this.leaseForm.lease_approval_notes,
        lease_description: this.leaseForm.lease_description,
        lease_master_agreement_date: this.leaseForm.lease_master_agreement_date,
        lease_master_agreement_hdr_id: this.leaseForm
          .lease_master_agreement_hdr_id, //not null 0 for new record
        lease_party_id: this.leaseForm.lease_party_id, //required
        lease_prj_id: this.leaseForm.lease_prj_id, //required
        logic_note: this.logicNotes,
        transaction_currency_id: this.leaseForm.transaction_currency_id, //required
        menu_id: store.state.shared.menuId,
        from_date: commonHelper.formattedDate(this.leaseForm.period_from_to[0]),
        to_date: commonHelper.formattedDate(this.leaseForm.period_from_to[1]),
        lease_prj_unit_id: this.leaseForm.lease_prj_unit_id, //NotNull
        lease_tenant_agreement_date: this.leaseForm.lease_tenant_agreement_date,
        // lease_tenant_agreement_hdr_id: this.leaseForm
        //   .lease_tenant_agreement_hdr_id,
        lease_tenant_agreement_hdr_id: this.createCopyWorkflow
          ? 0
          : this.leaseForm.lease_tenant_agreement_hdr_id,
        locking_period: this.leaseForm.locking_period,
        locking_period_uom_vset: this.leaseForm.locking_period_uom_vset,
        notice_period: this.leaseForm.notice_period,
        notice_period_uom_vset: this.leaseForm.notice_period_uom_vset,
        rate: this.leaseForm.rate,
        rate_uom_vset: this.leaseForm.rate_uom_vset,
        mark_for_termination: this.leaseForm.mark_for_termination,
        expected_termination_date: this.leaseForm.expected_termination_date,
        tenant_po: this.leaseForm.tenant_po,
        car_park_open_exc_price: this.leaseForm.car_park_open_exc_price
          ? parseFloat(
            this.leaseForm.car_park_open_exc_price.replaceAll(',', '')
          )
          : null,
        car_park_open_nonexc_price: this.leaseForm.car_park_open_nonexc_price
          ? parseFloat(
            this.leaseForm.car_park_open_nonexc_price.replaceAll(',', '')
          )
          : null,
        car_park_cover_exc_price: this.leaseForm.car_park_cover_exc_price
          ? parseFloat(
            this.leaseForm.car_park_cover_exc_price.replaceAll(',', '')
          )
          : null,
        car_park_cover_nonexc_price: this.leaseForm.car_park_cover_nonexc_price
          ? parseFloat(
            this.leaseForm.car_park_cover_nonexc_price.replaceAll(',', '')
          )
          : null
      };
      this.loader = true;
      this.$store
        .dispatch('leases/addEditTenantLease', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.activeTabList = [];
            this.$emit('updatList');
            this.$emit('rowSelected', response.data.data);
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.copyWorkFlow = false;
            this.responseMsg.push(`Header Details: ${response.data.message}`);
            this.setLeaseFormData(response.data.data);
            this.leaseForm.lease_tenant_agreement_hdr_id =
              response.data.data.lease_tenant_agreement_hdr_id;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg.push(
              `Header Details: ${response.response.data.message}`
            );
          }
        })
        .then(() => {
          // this.addEditSpecification();
          // this.addEditRentalDetails();
          this.addEditEscalation();
          this.addEditTermNotice();
          this.addEditSecurityDeposit();
          this.addEditTax();
          this.addEditBrokerage();
          this.addEditTermsCondition();
          this.addEditRentFreePeriod();
          // this.addEditCamAndOther();
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg.push(`Header Details: ${appStrings.autoFailedMsg}`);
        });
    },
    addEditRentalDetails() {
      if (this.leaseForm.lease_tenant_agreement_hdr_id) {
        const objRefrence = this.$refs.rentalDetails.gridData.filter(data => {
          if (
            data.lease_rental_buffer_days !== null &&
            data.lease_rental_payment_term_id !== null &&
            data.lease_rental_period !== null &&
            data.lease_rental_period_uom_vset !== null
          ) {
            return data;
          }
        });
        const tabDataArray = objRefrence.map(data => {
          return {
            active: data.active,
            lease_customer_id: data.lease_customer_id, //NotNull
            lease_customer_site_id: data.lease_customer_site_id, //NotNull
            lease_rental_buffer_days: data.lease_rental_buffer_days, //NotNull
            lease_rental_end_date: commonHelper.formattedDate(
              data.period_from_to[1]
            ), //can go null
            lease_rental_payment_term_id: data.lease_rental_payment_term_id, //NotNull
            lease_rental_period: data.lease_rental_period, //NotNull
            lease_rental_period_uom_vset: data.lease_rental_period_uom_vset, //NotBlank
            lease_rental_start_date: commonHelper.formattedDate(
              data.period_from_to[0]
            ), //can go null
            lease_tenant_agreement_rental_id: this.createCopyWorkflow
              ? 0
              : data.lease_tenant_agreement_rental_id, //NotNull {insert or update}
            sac: data.sac //NotBlank
          };
        });
        if (tabDataArray.length) {
          const payload = {
            tabName: 'rental-details',
            leaseTenantHdrId: this.leaseForm.lease_tenant_agreement_hdr_id,
            tabData: {
              lease_tenant_agreement_ren_details: tabDataArray
                ? tabDataArray
                : []
            }
          };
          this.$store
            .dispatch('leases/addEditTenantLeaseTabsData', payload)
            .then(response => {
              if (response.status === 200) {
                this.activeTabList = [];
                this.editMode = false;
                this.showAlert = true;
                this.isSuccess = true;
                this.responseMsg.push(
                  `Rental Details: ${response.data.message}`
                );
              } else {
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg.push(
                  `Rental Details: ${response.response.data.message}`
                );
              }
            })
            .catch(() => {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = appStrings.autoFailedMsg;
            });
        }
      }
    },
    addEditEscalation() {
      if (this.leaseForm.lease_tenant_agreement_hdr_id) {
        const objRefrence = this.$refs.escalation.gridData.filter(data => {
          if (
            data.lease_escalation !== null &&
            data.lease_customer_id !== null &&
            data.lease_escalation_uom_vset !== null
          ) {
            return data;
          }
        });
        const tabDataArray = objRefrence.map(data => {
          return {
            lease_customer_id: data.lease_customer_id,
            lease_customer_site_id: data.lease_customer_site_id,
            lease_escaltion_num: data.lease_escaltion_num,
            lease_escalation: data.lease_escalation, //NotNull
            lease_escalation_uom_vset: data.lease_escalation_uom_vset, //NotNull
            lease_tenant_agreement_escalation_id: this.createCopyWorkflow
              ? 0
              : data.lease_tenant_agreement_escalation_id, //NotNull
            // period_from: commonHelper.formattedDate(data.period_from_to[0]),
            // period_to: commonHelper.formattedDate(data.period_from_to[1]),
            period_from: data.period_from,
            period_to: data.period_to,
            sac: data.sac,
            revenue_account: data.revenue_account,
            period: data.period,
            escalation_type_vset: data.escalation_type_vset,
            charge_account: data.charge_account
          };
        });
        if (tabDataArray.length) {
          const payload = {
            tabName: 'escalation',
            leaseTenantHdrId: this.leaseForm.lease_tenant_agreement_hdr_id,
            tabData: {
              lease_tenant_agreement_esc_details: tabDataArray
                ? tabDataArray
                : []
            }
          };
          this.$store
            .dispatch('leases/addEditTenantLeaseTabsData', payload)
            .then(response => {
              if (response.status === 200) {
                this.activeTabList = [];
                this.editMode = false;
                this.showAlert = true;
                this.isSuccess = true;
                this.responseMsg.push(`Escalation: ${response.data.message}`);
              } else {
                this.showAlert = true;
                this.responseMsg.push(
                  `Escalation: ${response.response.data.message}`
                );
              }
            })
            .catch(() => {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg.push(`Escalation: ${appStrings.autoFailedMsg}`);
            });
        }
      }
    },
    addEditTermNotice() {
      if (this.leaseForm.lease_tenant_agreement_hdr_id) {
        const objRefrence = this.$refs.terminationNotice.gridData.filter(
          data => {
            if (
              data.lease_termination_period !== null &&
              data.lease_termination_period_uom_vset !== null
            ) {
              return data;
            }
          }
        );
        const tabDataArray = objRefrence.map(data => {
          return {
            lease_tenant_agreement_termination_id: this.createCopyWorkflow
              ? 0
              : data.lease_tenant_agreement_termination_id, //NotNull
            lease_termination_period: data.lease_termination_period, //NotNull
            lease_termination_period_uom_vset:
              data.lease_termination_period_uom_vset, //NotNull
            period_from: commonHelper.formattedDate(data.period_from_to[0]),
            period_to: commonHelper.formattedDate(data.period_from_to[1]),
            active: data.active
          };
        });
        if (tabDataArray.length) {
          const payload = {
            tabName: 'termination-notice',
            leaseTenantHdrId: this.leaseForm.lease_tenant_agreement_hdr_id,
            tabData: {
              lease_tenant_agreement_ter_details: tabDataArray
                ? tabDataArray
                : []
            }
          };
          this.$store
            .dispatch('leases/addEditTenantLeaseTabsData', payload)
            .then(response => {
              if (response.status === 200) {
                this.activeTabList = [];
                this.editMode = false;
                this.showAlert = true;
                this.isSuccess = true;
                this.responseMsg.push(
                  `Termination Notice: ${response.data.message}`
                );
              } else {
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg.push(
                  `Termination Notice: ${response.response.data.message}`
                );
              }
            })
            .catch(() => {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg.push(
                `Termination Notice: ${appStrings.autoFailedMsg}`
              );
            });
        }
      }
    },
    addEditSecurityDeposit() {
      if (this.leaseForm.lease_tenant_agreement_hdr_id) {
        const objRefrence = this.$refs.securityDeposit.gridData.filter(data => {
          if (
            data.period !== null &&
            data.lease_security_deposit !== null &&
            data.lease_security_deposit_uom_vset !== null
          ) {
            return data;
          }
        });
        const tabDataArray = objRefrence.map(data => {
          return {
            lease_tenant_agreement_sec_dpt_id: this.createCopyWorkflow
              ? 0
              : data.lease_tenant_agreement_sec_dpt_id, //NotNull
            period: data.period, //NotNull
            lease_security_deposit: data.lease_security_deposit, //NotNull
            lease_security_deposit_uom_vset:
              data.lease_security_deposit_uom_vset, //NotNull
            active: data.active,
            security_deposit_type_code: data.security_deposit_type_code,
            sac: data.sac,
            security_deposit_cat: data.security_deposit_cat,
            period_from: data.period_from,
            period_to: data.period_to,
            revenue_account: data.revenue_account,
            investor_ccid: data.investor_ccid
          };
        });
        if (tabDataArray.length) {
          const payload = {
            tabName: 'security-deposit',
            leaseTenantHdrId: this.leaseForm.lease_tenant_agreement_hdr_id,
            tabData: {
              lease_tenant_agreement_sec_details: tabDataArray
                ? tabDataArray
                : []
            }
          };
          this.$store
            .dispatch('leases/addEditTenantLeaseTabsData', payload)
            .then(response => {
              if (response.status === 200) {
                this.activeTabList = [];
                this.editMode = false;
                this.showAlert = true;
                this.isSuccess = true;
                this.responseMsg.push(
                  `Security Deposit: ${response.data.message}`
                );
              } else {
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg.push(
                  `Security Deposit: ${response.response.data.message}`
                );
              }
            })
            .catch(() => {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg.push(
                `Security Deposit: ${appStrings.autoFailedMsg}`
              );
            });
        }
      }
    },
    addEditBrokerage() {
      if (this.leaseForm.lease_tenant_agreement_hdr_id) {
        const objRefrence = this.$refs.brokerInfo.gridData.filter(data => {
          if (data.currency_id !== null && data.brokerage_amount !== null) {
            return data;
          }
        });
        const brokerDataArray = objRefrence.map(data => {
          return {
            brokerage_hdr_id: this.createCopyWorkflow
              ? 0
              : data.brokerage_hdr_id,
            brokerage_amount: parseFloat(
              data.brokerage_amount.replaceAll(',', '')
            ),
            recoverable_amount: parseFloat(
              data.recoverable_amount.replaceAll(',', '')
            ),
            currency_id: data.currency_id,
            exchange_rate: data.exchange_rate,
            comments: data.comments
          };
        });
        if (brokerDataArray.length) {
          const payload = {
            lease_tenant_agreement_hdr_id: this.leaseForm
              .lease_tenant_agreement_hdr_id,
            brokerage_hdr: brokerDataArray ? brokerDataArray : []
          };
          this.$store
            .dispatch('leases/addEditTenantLeaseBrokerHdrDetails', payload)
            .then(response => {
              if (response.status === 200) {
                this.activeTabList = [];
                this.editMode = false;
                this.showAlert = true;
                this.isSuccess = true;
                this.responseMsg.push(`Broker Info: ${response.data.message}`);
              } else {
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg.push(
                  `Broker Info: ${response.response.data.message}`
                );
              }
            })
            .catch(() => {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg.push(`Broker Info: ${appStrings.autoFailedMsg}`);
            });
        }
      }
    },
    addEditTermsCondition() {
      if (this.leaseForm.lease_tenant_agreement_hdr_id) {
        const objRefrence = this.$refs.terms.gridData.filter(data => {
          if (data.term_con_vset !== null && data.term_con_type_vset !== null) {
            return data;
          }
        });
        const termsDataArray = objRefrence.map(data => {
          return {
            lease_tenant_term_con_id: this.createCopyWorkflow
              ? 0
              : data.lease_tenant_term_con_id,
            active: data.active,
            term_con_vset: data.term_con_vset,
            term_con_type_vset: data.term_con_type_vset,
            description: data.description
          };
        });
        if (termsDataArray.length) {
          const payload = {
            lease_tenant_agreement_hdr_id: this.leaseForm
              .lease_tenant_agreement_hdr_id,
            tabData: { terms_con: termsDataArray ? termsDataArray : [] }
          };
          this.$store
            .dispatch('leases/addEditTenantLeaseTermsConditionDetails', payload)
            .then(response => {
              if (response.status === 200) {
                this.activeTabList = [];
                this.editMode = false;
                this.showAlert = true;
                this.isSuccess = true;
                this.responseMsg.push(
                  `Terms and Condition: ${response.data.message}`
                );
              } else {
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg.push(
                  `Terms and Condition: ${response.response.data.message}`
                );
              }
            })
            .catch(() => {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg.push(
                `Terms and Condition: ${appStrings.autoFailedMsg}`
              );
            });
        }
      }
    },
    addEditRentFreePeriod() {
      if (this.leaseForm.lease_tenant_agreement_hdr_id) {
        const objRefrence = this.$refs.rent.gridData.filter(data => {
          if (data.period_from !== null && data.period_to !== null) {
            return data;
          }
        });
        const rentDataArray = objRefrence.map(data => {
          return {
            lease_tenant_rent_period_id: this.createCopyWorkflow
              ? 0
              : data.lease_tenant_rent_period_id,
            active: data.active,
            period_from: data.period_from,
            period_to: data.period_to
          };
        });
        if (rentDataArray.length) {
          const payload = {
            lease_tenant_agreement_hdr_id: this.leaseForm
              .lease_tenant_agreement_hdr_id,
            tabData: { rent_free_period: rentDataArray ? rentDataArray : [] }
          };
          this.$store
            .dispatch('leases/addEditRentFreeTenantLeaseDetails', payload)
            .then(response => {
              if (response.status === 200) {
                this.activeTabList = [];
                this.editMode = false;
                this.showAlert = true;
                this.isSuccess = true;
                this.responseMsg.push(
                  `Rent Free Period: ${response.data.message}`
                );
              } else {
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg.push(
                  `Rent Free Period: ${response.response.data.message}`
                );
              }
            })
            .catch(() => {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg.push(
                `Rent Free Period: ${appStrings.autoFailedMsg}`
              );
            });
        }
      }
    },
    addEditTax() {
      if (this.leaseForm.lease_tenant_agreement_hdr_id) {
        const objRefrence = this.$refs.tax.gridData.filter(data => {
          if (data.tax_cat_id !== null && data.tax_group_id !== null) {
            return data;
          }
        });
        const tabDataArray = objRefrence.map(data => {
          return {
            lease_tenant_agreement_tax_id: this.createCopyWorkflow
              ? 0
              : data.lease_tenant_agreement_tax_id, //NotNull
            tax_cat_id: data.tax_cat_id, // NotNull
            tax_group_id: data.tax_group_id, // NotNull
            period_from: commonHelper.formattedDate(data.period_from_to[0]),
            period_to: commonHelper.formattedDate(data.period_from_to[1]),
            active: data.active,
            investor_inter_tax_cat: data.investor_inter_tax_cat,
            investor_intra_tax_cat: data.investor_intra_tax_cat
          };
        });
        if (tabDataArray.length) {
          const payload = {
            tabName: 'tax',
            leaseTenantHdrId: this.leaseForm.lease_tenant_agreement_hdr_id,
            tabData: {
              lease_tenant_agreement_tax_details: tabDataArray
                ? tabDataArray
                : []
            }
          };
          this.$store
            .dispatch('leases/addEditTenantLeaseTabsData', payload)
            .then(response => {
              if (response.status === 200) {
                this.activeTabList = [];
                this.editMode = false;
                this.showAlert = true;
                this.isSuccess = true;
                this.responseMsg.push(`Tax: ${response.data.message}`);
              } else {
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg.push(`Tax: ${response.response.data.message}`);
              }
            })
            .catch(() => {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg.push(`Tax: ${appStrings.autoFailedMsg}`);
            });
        }
      }
    },
    addEditCamAndOther() {
      if (this.leaseForm.lease_tenant_agreement_hdr_id) {
        const objRefrence = this.$refs.camAndOtherCharges.gridData.filter(
          data => {
            if (
              data.lease_recovery_period !== null &&
              data.lease_recovery_period_uom_vset !== null
            ) {
              return data;
            }
          }
        );
        const tabDataArray = objRefrence.map(data => {
          return {
            lease_tenant_agreement_recovery_id: this.createCopyWorkflow
              ? 0
              : data.lease_tenant_agreement_recovery_id, //NotNull
            period_from: commonHelper.formattedDate(data.period_from_to[0]),
            period_to: commonHelper.formattedDate(data.period_from_to[1]),
            active: data.active,
            customer_id: data.customer_id, //NotNull
            customer_site_id: data.customer_site_id, //NotNull
            lease_recovery_amount: data.lease_recovery_amount, //NotNull
            lease_recovery_period: data.lease_recovery_period, //NotNull
            lease_recovery_period_uom_vset: data.lease_recovery_period_uom_vset, //NotBlank
            lease_recovery_rate: data.lease_recovery_rate, //NotNull
            lease_recovery_rate_uom_vset: data.lease_recovery_rate_uom_vset, //NotBlank
            lease_recovery_type_vset: data.lease_recovery_type_vset,
            recovery_basis: data.recovery_basis
          };
        });
        if (tabDataArray.length) {
          const payload = {
            tabName: 'recovery',
            leaseTenantHdrId: this.leaseForm.lease_tenant_agreement_hdr_id,
            tabData: {
              lease_tenant_agreement_rec_details: tabDataArray
                ? tabDataArray
                : []
            }
          };
          this.$store
            .dispatch('leases/addEditTenantLeaseTabsData', payload)
            .then(response => {
              if (response.status === 200) {
                this.activeTabList = [];
                this.editMode = false;
                this.showAlert = true;
                this.isSuccess = true;
                this.responseMsg.push(`Recovery: ${response.data.message}`);
              } else {
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg.push(
                  `Recovery: ${response.response.data.message}`
                );
              }
            })
            .catch(() => {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg.push(`Recovery: ${appStrings.autoFailedMsg}`);
            });
        }
      }
    },
    setLeaseFormData(selectedLeaseDetails) {
      this.editMode = false;
      this.leaseForm = selectedLeaseDetails;
      this.leaseForm.period_from_to = [
        new Date(this.leaseForm.from_date),
        new Date(this.leaseForm.to_date)
      ];
      (this.leaseForm.lease_tenant_agreement_no = this.copyWorkFlow
        ? null
        : this.leaseForm.lease_tenant_agreement_no),
        (this.leaseForm.approval_status = this.copyWorkFlow
          ? 'DRAFT'
          : this.leaseForm.approval_status);
      this.getCustomerByPartyId(this.leaseForm.lease_party_id);
      this.selectedTab(this.activeTab);
      setTimeout(() => {
        this.fitoutScope = this.leaseForm.fitout_scope;
        this.logicNotes = this.leaseForm.logic_note;
      }, 2000);
      if (
        this.$route.name === 'notificationSummary' ||
        this.$route.name === 'dashboard' ||
        this.$route.name === 'workFlowSummary'
      ) {
        this.notificationSummary = true;
        this.getApprovalHistory(this.leaseForm.lease_tenant_agreement_hdr_id);
      }
    },
    getApprovalHistory(leaseMasterAgreementHdrId) {
      this.loader = true;
      this.$store.dispatch('auth/getUserDetail').then(response => {
        if (response.status === 200) {
          this.userInfo = response.data.data;
          const payload = {
            approvalStatus: 'Y',
            nfaHdrId: leaseMasterAgreementHdrId,
            eventId: this.urlSummaryData
              ? this.urlSummaryData.wf_event_id
              : this.eventId
          };
          this.$store
            .dispatch('intWorkflow/getApprovalList', payload)
            .then(response => {
              this.loader = false;
              if (response.status === 200) {
                this.approvalHistoryList = response.data.data;
                const findCurrentUser = this.approvalHistoryList.find(
                  user =>
                    user.approver_employee_id === this.userInfo.employee_id &&
                    (user.approval_status === 'INPROCESS' ||
                      user.approval_status === 'CONSULT_INITIATED')
                );
                this.isApprovedByCurrrentUser = findCurrentUser ? true : false;
                const findConsultInitUser = this.approvalHistoryList.find(
                  data => data.approval_status === 'CONSULT_INITIATED'
                );
                this.isConsultInitiated = findConsultInitUser ? true : false;
              }
            })
            .catch(() => {
              this.loader = false;
            });
        }
      });
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    getSelectedCurrency(item) {
      this.leaseForm.currency = item.currency_name;
      this.leaseForm.transaction_currency_id = item.currency_id;
      this.showCommonModal = false;
    },
    getSelectedPartyCustomer(item) {
      this.leaseForm.lease_party_id = item.party_id;
      this.leaseForm.lease_party_name = item.party_name;
      this.leaseForm.customer_name = item.party_name;
      this.leaseForm.customer_site_name = null;
      this.leaseForm.customer_site_id = null;
      this.getCustomerByPartyId(item.party_id);
      this.showCommonModal = false;
    },
    getSelectedProject(item) {
      this.selectedProject.text = item.lease_project_name;
      this.selectedProject.value = item.lease_project_id;
      this.leaseForm.lease_prj_name = item.lease_project_name;
      this.leaseForm.lease_prj_id = item.lease_project_id;
      this.showCommonModal = false;
    },
    selectedTab(tab) {
      this.activeTab = tab;
      const tabIndex = this.activeTabList.indexOf(tab);
      if (tabIndex === -1) {
        this.activeTabList.push(this.activeTab);
        this.tabTimeOut = setTimeout(() => {
          this.eventBus.$emit(this.activeTab, {
            leaseTenantHdrId: this.leaseForm.lease_tenant_agreement_hdr_id,
            leaseMasterAgreementHdrId: this.selectedLeaseDetails
              ? null
              : this.leaseForm.lease_master_agreement_hdr_id,
            customerName: this.leaseForm.customer_name,
            customerId: this.leaseForm.customer_id,
            leaseForm: this.leaseForm
          });
        }, 0);
      }
    },
    showHideCommonLeaseModal(flag, modalType) {
      this.showCommonModal = flag;
      this.modalType = modalType;
      if (this.modalType === 'PARTY') {
        this.setCustCode = setTimeout(() => {
          this.eventBus.$emit('userType', 'CUST');
        }, 0);
      }
    },
    selectedLeaseMaster(item) {
      this.itemData = item;
      this.leaseForm.lease_master_agreement_hdr_id =
        item.lease_master_agreement_hdr_id;
      this.leaseForm.lease_agreement_no = item.lease_agreement_no;
      this.leaseForm.le_id = item.le_id;
      this.leaseForm.le_name = item.le_name;
      this.leaseForm.lease_prj_id = item.lease_prj_id;
      this.leaseForm.lease_prj_name = item.lease_prj_name;
      this.leaseForm.lease_party_id = item.lease_party_id;
      this.leaseForm.lease_party_name = item.party_name;
      this.selectedProject.text = item.lease_prj_name;
      this.selectedProject.value = item.lease_prj_id;
      this.leaseForm.currency = item.currency;
      this.leaseForm.transaction_currency_id = item.transaction_currency_id;
      this.leaseForm.customer_name = item.party_name;
      this.leaseForm.org_ou_id = item.ou_id;
      this.fitoutScope = item.fitout_scope;
      this.logicNotes = item.logic_note;
      this.leaseForm.lease_approval_notes = item.lease_approval_notes;
      this.showCommonModal = false;
      this.leaseForm.lease_prj_unit_name = null;
      this.leaseForm.lease_prj_unit_id = null;
      this.leaseForm.super_area_uom_vset_meaning = null;
      this.leaseForm.super_area = null;
      this.leaseForm.carpet_area_uom_vset_meaning = null;
      this.leaseForm.carpet_area = null;
      this.leaseForm.locking_period = item.locking_period;
      this.leaseForm.locking_period_uom_vset_meaning =
        item.locking_period_uom_vset_meaning;
      this.leaseForm.locking_period_uom_vset = item.locking_period_uom_vset;
      this.leaseForm.notice_period = item.notice_period;
      this.leaseForm.notice_period_uom_vset_meaning =
        item.notice_period_uom_vset_meaning;
      this.leaseForm.notice_period_uom_vset = item.notice_period_uom_vset;
      // this.leaseForm.le_id = item.le_id;
      this.leaseForm.lease_prj_code = item.lease_prj_code;
      this.getCustomerByPartyId(this.leaseForm.lease_party_id);
      this.getMasterSpec(this.leaseForm.lease_master_agreement_hdr_id);
    },
    getSelectedUnit(item) {
      this.leaseForm.lease_prj_unit_name = item.unit_name;
      this.leaseForm.lease_prj_unit_id = item.lease_prj_unit_id;
      this.showCommonModal = false;
      this.getLeaseUnitArea();
      this.leasePrjUnitId = item.lease_prj_unit_id;
      this.leaseForm.car_park_open_exc_price = this.itemData.car_park_open_exc_price;
      this.leaseForm.car_park_open_nonexc_price = this.itemData.car_park_open_nonexc_price;
      this.leaseForm.car_park_cover_exc_price = this.itemData.car_park_cover_exc_price;
      this.leaseForm.car_park_cover_nonexc_price = this.itemData.car_park_cover_nonexc_price;
      this.getProjectUnitSpec();
    },
    getLeaseUnitArea() {
      const payload = {
        prjUnitId: this.leaseForm.lease_prj_unit_id
      };
      this.$store
        .dispatch('leaseUnit/getLeaseUnitArea', payload)
        .then(response => {
          const results = response.data.data;
          this.leaseForm.super_area_uom_vset_meaning =
            results.super_area_uom_vset_mean;
          this.leaseForm.super_area_uom_vset = results.super_area_uom_vset;
          this.leaseForm.super_area = results.super_area;
          this.leaseForm.billable_area_uom_meaning =
            results.billable_area_uom_vset_mean;
          this.leaseForm.billable_area_uom_vset =
            results.billable_area_uom_vset;
          this.leaseForm.billable_area = results.billable_area;

          this.leaseForm.carpet_area_uom_vset_meaning =
            results.carpet_area_uom_vset_mean;
          this.leaseForm.carpet_area_uom_vset = results.carpet_area_uom_vset;
          this.leaseForm.carpet_area = results.carpet_area;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getMasterSpec(leaseMasterAgreementHdrId) {
      const payload = {
        tabName: 'specification',
        leaseMasterAgreementHdrId: leaseMasterAgreementHdrId
      };
      this.$store
        .dispatch('leases/getLeaseMasterAgreementDataById', payload)
        .then(resp => {
          if (resp.status === 200 && resp.data.data.length) {
            const activeData = resp.data.data.find(
              item => item.active === true
            );
            if (activeData) {
              this.leaseForm.rate_uom_vset_meaning =
                activeData.rate_uom_vset_meaning;
              this.leaseForm.rate_uom_vset = activeData.rate_uom_vset;
              this.leaseForm.rate = activeData.rate;
              this.selectedTab(this.activeTab);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    changeStatus(status) {
      this.loader = true;
      const payload = [
        {
          event_id: this.urlSummaryData
            ? this.urlSummaryData.wf_event_id
            : this.eventId,
          notification_comments: this.notificationComments,
          notification_status: status,
          wf_inprocess_dtl_id: this.wfDtlId,
          temp_approved_amount: 0
        }
      ];
      this.$store
        .dispatch('intWorkflow/approvalReject', { approval_requests: payload })
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.getApprovalHistory(
              this.leaseForm.lease_tenant_agreement_hdr_id
            );
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg.push(response.data.data.message);
          } else {
            this.isSuccess = false;
            this.responseMsg.push(response.response.data.message);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getCustomerByPartyId(partyId) {
      this.loader = true;
      this.$store
        .dispatch('leases/getCustomerByPartyId', partyId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.customerId = response.data.data.customer_id;
            this.leaseForm.customer_id = response.data.data.customer_id;
            this.getCustomerSiteByPartyId();
          }
        });
    },
    getCustomerSiteByPartyId() {
      this.loader = true;
      const payload = {
        customerId: this.leaseForm.customer_id,
        lease: {
          site_type_vset: appStrings.siteTypeVset,
          ou_id: this.leaseForm.org_ou_id
        }
      };
      this.$store
        .dispatch('party/getCustomerSiteById', payload)
        .then(response => {
          this.loader = false;
          this.customerIdByLeaseCustomerSites = response.data.data.customer_id;
          this.gridDataSite = response.data.data.customer_site_lists;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideCustomerSiteModal(flag) {
      this.showCustomerSiteModal = flag;
    },
    selectedSite(item) {
      this.leaseForm.customer_site_name = item.site_name;
      this.leaseForm.customer_site_id = item.customer_site_id;
      this.leaseForm.customer_site_address = item.bill_to_address;
      this.showCustomerSiteModal = false;
      this.setTimeForCustSite = setTimeout(() => {
        this.eventBus.$emit('tenantHdrCustSiteData', this.leaseForm);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'SCHEDULE_STATUS_VSET') {
        this.leaseForm.lease_status_meaning = item.value_meaning;
        this.leaseForm.lease_status = item.value_code;
      } else if (this.vsetCode === 'UOM') {
        if (this.periodType === 'locking') {
          this.leaseForm.locking_period_uom_vset = item.value_code;
          this.leaseForm.locking_period_uom_vset_meaning = item.value_meaning;
        } else if (this.periodType === 'notice') {
          this.leaseForm.notice_period_uom_vset = item.value_code;
          this.leaseForm.notice_period_uom_vset_meaning = item.value_meaning;
        }
      }
      this.showValueSetModal = false;
    },
    opendDependentUom(vsetCode, periodType) {
      const payload = {
        valueSetName: vsetCode,
        dependentVariable: {
          user_id: this.$store.state.auth.userId,
          res_id: this.$store.state.shared.responsibilityId
        }
      };
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          this.loader = false;
          this.uomClassObj = null;
          if (response.status === 200) {
            const findTimeObj = response.data.data.page.find(
              vsetData => vsetData.value_code === 'TIME'
            );
            if (findTimeObj) {
              this.uomClassObj = findTimeObj;
              this.openValueSetModal('UOM', periodType);
            }
          }
        });
    },
    openValueSetModal(vsetCode, periodType) {
      this.vsetCode = vsetCode;
      this.periodType = periodType;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.uomClassObj) {
        this.parent_value_set_id = this.uomClassObj.value_set_dtl_id;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    setDate(dateRange) {
      this.leaseForm.period_from_to = commonHelper.setFutureDate(dateRange);
      this.eventBus.$emit('periodFromTo', {
        periodFrom: this.leaseForm.period_from_to[0],
        periodTo: this.leaseForm.period_from_to[1],
        rentPeriodFrom: this.leaseForm.lease_tenant_rental_start_date
      });
    },
    getUploadedDocDtl() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        menu_id: store.state.shared.menuId
          ? store.state.shared.menuId
          : this.urlSummaryData
            ? this.urlSummaryData.wf_menu_id
            : this.leaseForm.menu_id,
        sub_menu_id: 0,
        record_id: this.leaseForm.lease_tenant_agreement_hdr_id
          ? this.leaseForm.lease_tenant_agreement_hdr_id
          : this.urlSummaryData.nfa_hdr_id
      };
      this.$store
        .dispatch('intWorkflow/getOpenKmDocDtl', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data.page.length > 0) {
              const docList = response.data.data.page;
              this.downloadItems = docList.map(data => {
                data.file_size = this.formatBytes(data.file_size);
                return data;
              });
            }
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    checkOpenKmPasswordSaved() {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/checkSavedPassword')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data) {
              this.getOpenKmAuthToken();
            } else {
              this.showHideOpenKmModal(true);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getOpenKmAuthToken() {
      const payload = {
        password: null,
        saved_password_flag: true,
        user_name: null
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmAuthenticate', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openkmAuthToken = response.data.data.token;
            this.downloadFiles(true);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideOpenKmModal(flag) {
      this.showOpenKmModal = flag;
    },
    setOpenKmToken(token) {
      this.showHideOpenKmModal(false);
      this.openkmAuthToken = token;
      this.downloadFiles(true);
    },
    downloadFiles(flag) {
      this.showDownloadModal = flag;
      this.getUploadedDocDtl();
    },
    getProjectUnitSpec() {
      const payload = {
        prjUnitId: this.leasePrjUnitId
      };
      this.loader = true;
      this.$store
        .dispatch('leaseUnit/getLeaseUnitSpecification', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            if (results) {
              this.parkingDetails = results;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    hideLeaseTerminationModal() {
      this.showLeaseTerminationModal = false;
    },
    async isDocumentUploadedFunc() {
      const uploadedDocList = await commonHelper.getUploadedDocList(
        this.$store.state.shared.menuId,
        (this.tenantLeasehdrId = this.leaseForm.lease_tenant_agreement_hdr_id)
      );
      const isDocumentUploaded = uploadedDocList.length > 0;
      isDocumentUploaded
        ? (this.showLeaseTerminationModal = true)
        : alert('Please upload document!');
    },
    saveSubmitRequest() {
      const payload = {
        admin_submit_req_details: [
          {
            request_num: 0,
            request_id: 183,
            template_id: 134,
            schedule_type: 'asap', //pass hardcode
            request_start_date: null,
            request_end_date: null,
            resubmit_interval: null,
            resubmit_interval_unit: null,
            day_of_month: null,
            day_of_week: null,
            output_format_id: 'PDF', //pass hardcode
            no_of_args: 25,
            request_parameter: this.batchData,
            sms_flag: false,
            email_flag: false,
            whatsapp_flag: false,
            child_req_count: null,
            email_comm_template_id: null,
            sms_comm_template_id: null,
            whatsapp_comm_template_id: null,
            argument1: this.selectedLeaseDetails.lease_tenant_agreement_hdr_id,
            argument2: null,
            argument3: null,
            argument4: null,
            argument5: null,
            argument6: null,
            argument7: null,
            argument8: null,
            argument9: null,
            argument10: null,
            argument11: null,
            argument12: null,
            argument13: null,
            argument14: null,
            argument15: null,
            argument16: null,
            argument17: null,
            argument18: null,
            argument19: null,
            argument20: null,
            argument21: null,
            argument22: null,
            argument23: null,
            argument24: null,
            argument25: null
          }
        ]
      };
      this.downloadFlag = true;
      this.loader = true;
      this.$store
        .dispatch('template/saveSubmitRequest', payload)
        .then(resp => {
          this.loader = false;
          this.downloadFlag = false;
          if (resp.status === 201) {
            this.requestNumId = resp.data.data[0].id;
            this.getSubmitRequest();
          }
        })
        .catch(() => {
          this.loader = false;
          this.downloadFlag = false;
        });
    },
    getSubmitRequest() {
      this.loader = true;
      this.downloadFlag = true;
      this.$store
        .dispatch('template/getSubmitRequest', this.requestNumId)
        .then(resp => {
          this.loader = false;
          this.downloadFlag = false;
          if (resp.status === 200) {
            this.requestStatus = resp.data.data[0].status;
            this.reqId = resp.data.data[0].id;
            if (this.requestStatus === 'COMPLETED') {
              window.location.href = this.dowloadReport + '/' + this.reqId;
            } else if (this.requestStatus === 'ERROR') {
              alert('Request in ERROR can not Download');
            } else {
              this.getSubmitRequest();
            }
          }
        })
        .catch(() => {
          this.loader = false;
          this.downloadFlag = false;
        });
    }
  },
  beforeDestroy() {
    clearTimeout(this.setCustCode);
    clearTimeout(this.setTimeForCustSite);
    this.unsubscribe();
  }
};
