import DatePicker from 'vue2-datepicker';
import { format } from 'date-fns';
import { BasicSelect } from 'vue-search-select';
import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'componentPricingMapping',
  props: ['compMappingDetails'],
  components: {
    DatePicker,
    BasicSelect
  },
  watch: {
    currentPage: function () {
      this.getCompPricingList();
    },
    perPage: function () {
      this.currentPage = 1;
      this.getCompPricingList();
    },
    rateType: function () {
      this.checkRateType();
    }
  },
  data() {
    return {
      totalRows: null,
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      mappingListIndex: null,
      parent_value_set_id: null,
      showValueSetModal: false,
      editMode: true,
      projListIndex: null,
      unsubscribe: null,
      isSuccess: false,
      showAlert: false,
      respMessage: '',
      loader: false,
      showProjMapping: false,
      billingPlanName: null,
      subUnitMappingList: [
        {
          active: true,
          billing_plan_comp_mpng_id: null,
          billing_plan_comp_price_mpng_id: 0,
          comp_id: null,
          comp_name: null,
          from_basis: null,
          to_basis: null,
          rate: null,
          uom_vset: this.uomVset,
          over_time_rate: null,
          over_time_uom: null,
          over_time_uom_meaning: null,
          over_time_uom_hour_type: null,
          over_time_uom_hour_type_vset_meaning: null,
          over_time_uom_hour_days: null,
          start_date: null,
          end_date: null,
          void: false
        }
      ],
      compMappingList: [],
      projeMappingFields: [
        {
          key: 'breakup_details'
        },
        {
          key: 'from_basis'
        },
        {
          key: 'to_basis'
        },
        {
          key: 'uom_vset',
          label: 'UOM'
        },
        {
          key: 'rate'
        },
        {
          key: 'over_time_uom_meaning',
          label: 'Overtime UOM'
        },
        {
          key: 'over_time_rate'
        },
        {
          key: 'over_time_uom_hour_type_vset_meaning',
          label: 'Overtime Hour Type'
        },
        {
          key: 'over_time_uom_hour_days',
          label: 'Hour Days'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ],
      selectedComponent: {
        value: null,
        text: null
      },
      selectedTower: {
        value: null,
        text: null
      },
      selectedFloor: {
        value: null,
        text: null
      },
      selectedUnit: {
        value: null,
        text: null
      },
      selectedSubUnit: {
        value: null,
        text: null
      },
      addSubUnitMappingList: [],
      addSubUnitFields: [
        {
          key: 'selectbox',
          label: 'Select'
        },
        {
          key: 'sub_unit'
        }
      ],
      fromBase: null,
      toBase: null,
      selectedUom: {
        value: null,
        text: null
      },
      uomVset: null,
      setTimeVsetCode: null,
      vsetCode: null,
      rateType: null,
      rateBasis: null,
      plan_status: this.$store.state.masterData.planStatus,
      showBreakupDetailModal: false,
      billingPlanPriceMappingId: 0,
      breakupDetailsData: [
        {
          billing_plan_comp_price_mpng_id: 0,
          salary_comp_id: 0,
          salary_comp_type_vset: null,
          salary_comp_type_meaning: null,
          salary_comp_rate: null
        }
      ],
      breakupDetailsFields: [
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        },
        {
          key: 'salary_comp_type_meaning',
          label: 'Salary Comp Type'
        },
        {
          key: 'salary_comp_rate'
        }
      ],
      dateData: null,
      totalRate: 0
    };
  },
  mounted() {
    this.dateData = this.$store.state.masterData.dateData;
    if (this.$route.params.billingPlanId) {
      this.getComponentList();
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update' && this.plan_status === 'BPINCOMPLETE') {
          this.editMode = true;
        }
        if (actionName === 'add') {
          this.showProjMapping = true;
        }
        if (actionName === 'save' && this.editMode) {
          this.addEditPricingMapping();
        }
      }
    });
  },
  methods: {
    checkRateType() {
      if (this.rateType === 'Fixed ') {
        this.projeMappingFields.splice(1, 2);
      } else {
        if (this.projeMappingFields[1].key !== 'from_basis') {
          this.projeMappingFields.splice(
            1,
            0,
            {
              key: 'from_basis'
            },
            {
              key: 'to_basis'
            }
          );
        }
      }
    },
    getComponentList() {
      this.loader = true;
      this.$store
        .dispatch(
          'masterData/getBillingPlanCompList',
          this.$route.params.billingPlanId
        )
        .then(response => {
          this.editMode = false;
          this.loader = false;
          if (
            response.status === 200 &&
            response.data.data.billing_plan_components.length > 0
          ) {
            this.rateType =
              response.data.data.billing_plan_components[0].rate_type;
            this.rateBasis =
              response.data.data.billing_plan_components[0].rate_basis;
            this.uomVset = response.data.data.billing_plan_components[0].uom;
            this.compMappingList = response.data.data.billing_plan_components;
            this.compMappingList = this.compMappingList.map((data, index) => {
              return {
                index: index,
                value: data.billing_plan_comp_mpng_id,
                text: data.fms_comp_name,
                rateType: data.rate_type,
                rateBasis: data.rate_basis,
                uom: data.uom
              };
            });
            this.selectedComponent = this.compMappingList[0];
            this.getCompPricingList();
            if (this.$route.params.salesAgreementData) {
              this.selectedComponent.text = this.$route.params.salesAgreementData.comp_name
              this.selectedComponent.value = this.$route.params.salesAgreementData.comp_id
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    onSelectProj(event) {
      this.uomVset = event.uom;
      this.selectedComponent = event;
      this.rateType = event.rateType;
      this.rateBasis = event.rateBasis;
      this.getCompPricingList();
    },
    getCompPricingList() {
      this.loader = true;
      const payload = {
        billingPlanComponentMpngId: this.compMappingDetails
          ? this.compMappingDetails.billing_plan_comp_mpng_id
          : this.selectedComponent.value,
        billingPlanId: this.$route.params.billingPlanId,
        searchParam: {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          from_basis: this.fromBase,
          to_basis: this.toBase,
          uom_vset: this.selectedUom.value
        }
      };
      this.$store
        .dispatch('masterData/getBillingPlanCompPricingList', payload)
        .then(response => {
          this.editMode = false;
          this.loader = false;
          this.subUnitMappingList = [];
          if (response.status === 200) {
            if (response.data.data.page.length > 0) {
              this.subUnitMappingList = response.data.data.page.map(data => {
                data.start_date = data.start_date
                  ? new Date(data.start_date)
                  : null;
                data.end_date = data.end_date ? new Date(data.end_date) : null;
                return data;
              });
            } else {
              this.subUnitMappingList.push({
                active: true,
                billing_plan_comp_mpng_id: null,
                billing_plan_comp_price_mpng_id: 0,
                comp_id: null,
                comp_name: null,
                end_date: this.dateData.billing_end_date
                  ? new Date(this.dateData.billing_end_date)
                  : null,
                from_basis: null,
                rate: null,
                start_date: new Date(this.dateData.billing_start_date),
                to_basis: null,
                over_time_uom: null,
                over_time_uom_meaning: null,
                uom_vset: this.uomVset,
                void: false
              });
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditPricingMapping() {
      if (this.subUnitMappingList.length > 0) {
        const compPricingPayload = this.subUnitMappingList.map(data => {
          return {
            active: true,
            billing_plan_comp_price_mpng_id:
              data.billing_plan_comp_price_mpng_id,
            from_basis: data.from_basis,
            rate: data.rate,
            to_basis: data.to_basis,
            over_time_rate: data.over_time_rate,
            over_time_uom: data.over_time_uom,
            over_time_uom_hour_type: data.over_time_uom_hour_type,
            over_time_uom_hour_days: data.over_time_uom_hour_days,
            uom_vset: data.uom_vset,
            void: false,
            start_date: data.start_date
              ? format(new Date(data.start_date), 'dd-MMM-yyyy')
              : null,
            end_date: data.end_date
              ? format(new Date(data.end_date), 'dd-MMM-yyyy')
              : null
          };
        });
        const payload = {
          compPricingData: {
            billing_plan_component_prices: compPricingPayload
          },
          queryParams: {
            billingPlanComponentMpngId: this.compMappingDetails
              ? this.compMappingDetails.billing_plan_comp_mpng_id
              : this.selectedComponent.value,
            billingPlanId: this.$route.params.billingPlanId
          }
        };
        this.loader = true;
        this.$store
          .dispatch('masterData/addEditBillingPlanCompPricingList', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.editMode = false;
              this.isSuccess = true;
              this.subUnitMappingList = response.data.data.page.map(data => {
                data.start_date = data.start_date
                  ? new Date(data.start_date)
                  : null;
                data.end_date = data.end_date ? new Date(data.end_date) : null;
                return data;
              });
              this.respMessage = response.data.message;
            } else {
              this.isSuccess = false;
              this.respMessage = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    selectedSite(index) {
      this.projListIndex = index;
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.specIndex = index;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.SALARY_COMPONENT_VSET) {
        this.breakupDetailsData[this.specIndex].salary_comp_type_vset =
          item.value_code;
        this.breakupDetailsData[this.specIndex].salary_comp_type_meaning =
          item.value_meaning;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OVERTIME_UOM) {
        this.subUnitMappingList[this.specIndex].over_time_uom = item.value_code;
        this.subUnitMappingList[this.specIndex].over_time_uom_meaning =
          item.value_meaning;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.OVER_TIME_UOM_HOUR_TYPE_VSET
      ) {
        this.subUnitMappingList[this.specIndex].over_time_uom_hour_type =
          item.value_code;
        this.subUnitMappingList[
          this.specIndex
        ].over_time_uom_hour_type_vset_meaning = item.value_meaning;
      }
      this.subUnitMappingList = JSON.parse(
        JSON.stringify(this.subUnitMappingList)
      );
      this.subUnitMappingList.map(elem => {
        elem.start_date = new Date(elem.start_date);
        elem.end_date = new Date(elem.end_date);
        return elem
      });
    },
    clearVsetValuesOnGrid(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.specIndex = index;
      if (this.vsetCode === appStrings.VALUESETTYPE.SALARY_COMPONENT_VSET) {
        this.breakupDetailsData[this.specIndex].salary_comp_type_vset = null;
        this.breakupDetailsData[this.specIndex].salary_comp_type_meaning = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OVERTIME_UOM) {
        this.subUnitMappingList[this.specIndex].over_time_uom = null;
        this.subUnitMappingList[this.specIndex].over_time_uom_meaning = null;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.OVER_TIME_UOM_HOUR_TYPE_VSET
      ) {
        this.subUnitMappingList[this.specIndex].over_time_uom_hour_type = null;
        this.subUnitMappingList[
          this.specIndex
        ].over_time_uom_hour_type_vset_meaning = null;
      }
    },
    clearVsetValues(vsetCode, index) {
      if (vsetCode === 'UOM_CLASS') {
        this.selectedUom.value = null;
        this.selectedUom.text = null;
      } else if (
        vsetCode === 'UOM_CLASS' &&
        this.subUnitMappingList.length > 0
      ) {
        this.subUnitMappingList[index].uom_vset = null;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    checkValidate() {
      if (this.subUnitMappingList[this.addIndex].to_basis) {
        this.subUnitMappingList[this.addIndex + 1].from_basis =
          this.subUnitMappingList[this.addIndex].to_basis + 1;
      }
    },
    getBreakUpDetailsById(Id) {
      this.loader = true;
      this.$store
        .dispatch('masterData/getBreakUpDetailsById', Id)
        .then(response => {
          this.loader = false;
          if (response.status === 200 && response.data.data.length > 0) {
            this.breakupDetailsData = response.data.data;
          } else {
            this.breakupDetailsData = [];
            this.breakupDetailsData.push({
              billing_plan_comp_price_mpng_id: 0,
              salary_comp_id: 0,
              salary_comp_type_vset: null,
              salary_comp_type_meaning: null,
              salary_comp_rate: null
            });
          }
        });
    },
    showHideBreakupDetailsModal(flag, item) {
      this.showBreakupDetailModal = flag;
      if (item) {
        this.billingPlanPriceMappingId = item.billing_plan_comp_price_mpng_id;
        this.totalRate = item.rate;
        this.getBreakUpDetailsById(this.billingPlanPriceMappingId);
      }
    },
    addNewRow(index, uom) {
      this.addIndex = index;
      if (this.editMode) {
        this.subUnitMappingList.push({
          active: true,
          billing_plan_comp_mpng_id: null,
          billing_plan_comp_price_mpng_id: 0,
          comp_id: null,
          comp_name: null,
          end_date: this.dateData.billing_end_date
            ? new Date(this.dateData.billing_end_date)
            : null,
          from_basis: null,
          rate: null,
          over_time_rate: null,
          over_time_uom: null,
          over_time_uom_meaning: null,
          start_date: new Date(this.dateData.billing_start_date),
          to_basis: null,
          uom_vset: uom,
          void: false
        });
        this.checkValidate();
      }
    },
    removeRow(index) {
      this.subUnitMappingList.splice(index, 1);
    },
    addNewDetailsRow() {
      if (this.editMode) {
        this.breakupDetailsData.push({
          billing_plan_comp_price_mpng_id: 0,
          salary_comp_id: 0,
          salary_comp_type_vset: null,
          salary_comp_type_meaning: null,
          salary_comp_rate: null
        });
      } else if (!this.editMode) {
        alert('Please Enable Edit Mode');
      }
    },
    removeDetailsRow(index) {
      this.breakupDetailsData.splice(index, 1);
    },
    saveDetails() {
      this.showBreakupDetailModal = false;
      const requestPayload = {
        fms_bill_rate_component: this.breakupDetailsData
      };
      const payload = {
        billingPlanPriceMappingId: this.billingPlanPriceMappingId,
        requestPayload: requestPayload
      };
      this.loader = true;
      this.$store
        .dispatch('masterData/saveBreakUpDetails', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.billingPlanPriceMappingId =
              response.data.data.billing_plan_comp_price_mpng_id;
            this.editMode = false;
            this.isSuccess = true;
            this.showAlert = true;
            this.respMessage = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.respMessage = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.respMessage = appStrings.autoFailedMsg;
        });
    },
    clearFilter() {
      this.fromBase = null;
      this.toBase = null;
      this.selectedUom = {
        value: null,
        text: null
      };
      this.getCompPricingList();
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
