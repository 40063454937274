import Details from './details';
import Address from './address';
import Bank from './bank';
import Contact from './contact';
import Compliance from './compliance';
import Additional from './additional';
import Documents from './allDocuments';
import Evaluation from './evaluation';
import AccountingDetails from './accountingDetails';
import VendorCategory from './vendorCategory';
export default {
  name: 'partyDetails',
  components: {
    Details,
    Address,
    Bank,
    Contact,
    Compliance,
    Documents,
    Additional,
    Evaluation,
    AccountingDetails,
    VendorCategory
  },
  props: [
    'partyRowDetails',
    'wfMenuId',
    'userId',
    'eventId',
    'dashboardDetails'
  ],
  data() {
    return {
      loader: false,
      activeTab: 'Details',
      urlSummaryData: null,
      menuId: null,
      workflowId: { menuId: null, userId: null, eventId: null },
      templateTypeProp: null
    };
  },
  created() {
    if (this.$route.params.summaryData) {
      this.urlSummaryData = JSON.parse(
        Buffer.from(this.$route.params.summaryData, 'base64').toString('ascii')
      );
      this.menuId = this.urlSummaryData.wf_menu_id;
    } else {
      this.menuId = this.wfMenuId;
    }
    this.workflowId.menuId = this.wfMenuId;
    this.workflowId.userId = this.userId;
    this.workflowId.eventId = this.eventId;
  },
  methods: {
    templateTypeRecieve(result) {
      this.templateTypeProp = result;
    },
    selectedTab(tab) {
      this.activeTab = tab;
    },
    updatedDetails() {
      this.$emit('gridDetailsUpdated');
    },
    updatedAddress() {
      this.$emit('gridAddressUpdated');
    },
    updatedContact() {
      this.$emit('gridContactUpdated');
    },
    updatedBank() {
      this.$emit('gridBankUpdated');
    },
    updatedAccount() {
      this.$emit('gridAccUpdated');
    }
  }
};
