import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'SecurityDeposit',
  components: {
    DatePicker
  },
  props: ['editMode', 'investorLeaseApprovalStatus'],
  data() {
    return {
      vsetCode: null,
      uomClassObj: null,
      specIndex: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      areaType: null,
      setTimeVsetCode: null,
      gridData: [
        {
          lease_termination_period: null,
          active: false,
          lease_tenant_agreement_hdr_id: null,
          lease_tenant_agreement_termination_id: 0,
          lease_termination_period_uom_vset: null,
          lease_termination_period_uom_vset_meaning: null,
          period_from: null,
          period_to: null
        }
      ],
      gridFields: [
        {
          key: 'active'
        },
        {
          key: 'lease_termination_period_uom_vset_meaning',
          label: 'UOM'
        },
        {
          key: 'lease_termination_period',
          label: 'Termination Period'
        },

        {
          key: 'period_from_to',
          label: 'Period (From - To)'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ]
    };
  },
  mounted() {
    this.eventBus.$on('tn', tabData => {
      if (tabData.leaseInvestorHdrId) {
        this.getDetails(tabData.leaseInvestorHdrId);
      }
      if (tabData.leaseMasterAgreementHdrId) {
        this.getMasterData(tabData.leaseMasterAgreementHdrId);
      }
    });
  },
  methods: {
    getDetails(leaseInvestorHdrId) {
      const payload = {
        tabName: 'termination-notice',
        leaseInvestorHdrId: leaseInvestorHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('leases/getLeaseInvestorDataById', payload)
        .then(resp => {
          this.loader = false;
          if (
            resp.status === 200 &&
            resp.data.data.length &&
            this.gridData[0].lease_tenant_agreement_termination_id === 0
          ) {
            this.gridData = resp.data.data.map(data => {
              data.period_from_to = [
                new Date(data.period_from),
                new Date(data.period_to)
              ];
              return data;
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getMasterData(leaseMasterAgreementHdrId) {
      const payload = {
        tabName: 'termination-notice',
        leaseMasterAgreementHdrId: leaseMasterAgreementHdrId
      };
      this.$store
        .dispatch('leases/getLeaseMasterAgreementDataById', payload)
        .then(resp => {
          if (resp.status === 200 && resp.data.data.length > 0) {
            const filterData = resp.data.data.filter(
              item => item.active === true
            );
            this.gridData = filterData.map(item => {
              return {
                lease_termination_period: item.lease_termination_period,
                active: item.active,
                lease_tenant_agreement_hdr_id: null,
                lease_tenant_agreement_termination_id: 0,
                lease_termination_period_uom_vset:
                  item.lease_termination_period_uom_vset,
                lease_termination_period_uom_vset_meaning:
                  item.lease_termination_period_uom_vset_meaning,
                period_from: item.period_from,
                period_to: item.period_to
              };
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addNewRow() {
      this.gridData.push({
        lease_termination_period: null,
        active: true,
        lease_tenant_agreement_hdr_id: null,
        lease_tenant_agreement_termination_id: 0,
        lease_termination_period_uom_vset: null,
        lease_termination_period_uom_vset_meaning: null,
        period_from: null,
        period_to: null
      });
    },
    removeRow(index) {
      this.gridData.splice(index, 1);
    },
    opendDependentUom(vsetCode, index) {
      const payload = {
        valueSetName: vsetCode,
        dependentVariable: {
          user_id: this.$store.state.auth.userId,
          res_id: this.$store.state.shared.responsibilityId
        }
      };
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          this.loader = false;
          this.uomClassObj = null;
          if (response.status === 200) {
            const findTimeObj = response.data.data.page.find(
              vsetData => vsetData.value_code === 'TIME'
            );
            if (findTimeObj) {
              this.uomClassObj = findTimeObj;
              this.openValueSetModal('UOM', index);
            }
          }
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'UOM') {
        this.gridData[this.specIndex].lease_termination_period_uom_vset =
          item.value_code;
        this.gridData[
          this.specIndex
        ].lease_termination_period_uom_vset_meaning = item.value_meaning;
      }
      this.showValueSetModal = false;
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.specIndex = index;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.uomClassObj) {
        this.parent_value_set_id = this.uomClassObj.value_set_dtl_id;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    checkUncheckRadio(specIndex) {
      this.gridData.forEach((element, index) => {
        return (element.active = index === specIndex ? true : false);
      });
    },
    setDate(dateRange, index) {
      this.gridData[index].period_from_to = commonHelper.setFutureDate(
        dateRange
      );
    }
  },
  beforeDestroy() {
    this.eventBus.$off('tn');
  }
};
