import appStrings from '@/app/utility/string.utility';
import responseModal from '../../../../shared/responseModal';

export default {
  name: 'Accounting',
  watch: {
    selectAllCheckBox: function() {
      this.checkUncheckAll();
    }
  },
  components: {
    responseModal
  },
  props: [
    'partyRowDetails',
    'urlSummaryData',
    'workflowId',
    'dashboardDetails',
    'templateTypeProp'
  ],
  data() {
    return {
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      editMode: false,
      legalEntity: { value: null, text: null },
      operatingUnit: { value: null, text: null },
      vendorType: { value: null, text: null },
      paymentTerm: { value: null, text: null, id: null },
      liabilityCcid: null,
      liabilityCcidCode: null,
      liabilityCcidMeaning: null,
      prepayCcid: null,
      prepayCcidCode: null,
      prepayCcidMeaning: null,
      accountData: [
        {
          acc_flag: false,
          ou_id: null,
          ou_name: null,
          address_id: null,
          address: null,
          state_id: null,
          state: null,
          gstn: null,
          liability_ccid: null,
          liability_ccid_code: null,
          liability_ccid_meaning: null,
          prepay_ccid: null,
          prepay_ccid_code: null,
          prepay_ccid_meaning: null,
          add_site_id: null,
          party_req_id: null,
          payment_term_id: null,
          payment_term: null,
          payment_term_meaning: null
        }
      ],
      accountFields: [
        {
          key: 'acc_flag',
          label: 'Select',
          variant: 'info',
          stickyColumn: true
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'address'
        },
        {
          key: 'state',
          class: 'state-fix'
        },
        {
          key: 'gstn',
          label: 'GSTN'
        }
        // {
        //   key: 'liability_ccid_code',
        //   label: 'Liability Account'
        // },
        // {
        //   key: 'prepay_ccid_code',
        //   label: 'Pre Payment Account'
        // },
        // {
        //   key: 'payment_term'
        // }
      ],
      showAccDetailsRejectModal: false,
      selectAllCheckBox: false,
      rejectedReason: null,
      partyReqId: null,
      wfDtlId: null,
      eventId: null,
      approvalStatus: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      specIndex: null,
      showAccountDetailsModal: false,
      isAccDetailsSaved: false,
      accountType: null,
      wfResId: null,
      indeterminate: false,
      defaultAccType: null,
      wfMenuId: null,
      accountDefaultFlag: false,
      approvalStatusKey: null,
      menuId: this.$store.state.shared.menuId,
      showAccDetailsApproveModal: false,
      approveRemarks: null,
      seqOrder: null,
      buyerRemarks: null,
      firstRemarks: null,
      secondRemarks: null,
      userId: null,
      gstHold: false,
      totalEvaluationRows: 0,
      showOpenResponseModal: false,
      paymentTermsData: [],
      paymentTermsFields: [
        {
          key: 'payment_term_name',
          label: 'Transaction Name'
        },
        {
          key: 'payment_term_desc'
        }
      ]
    };
  },
  mounted() {
    if (this.partyRowDetails) {
      this.partyReqId = this.partyRowDetails.req_id;
      this.wfDtlId = this.partyRowDetails.inprocess_dtl_id;
      this.eventId = this.workflowId.eventId;
      this.userId = this.workflowId.userId;
    }
    if (this.urlSummaryData) {
      this.partyReqId = this.urlSummaryData.PARTY_REQ_ID;
      this.wfDtlId = this.urlSummaryData.wf_inprocess_dtl_id;
      this.eventId = this.urlSummaryData.wf_event_id;
      this.userId = this.urlSummaryData.wf_user_id;
      this.wfResId = this.urlSummaryData.wf_res_id;
      this.workflowId.menuId = this.urlSummaryData.wf_menu_id;
      this.menuId = this.urlSummaryData.DY_MENU_ID;
      this.approvalStatusKey = this.urlSummaryData.APPROVAL_STATUS_KEY;
      this.seqOrder = this.urlSummaryData.SEQ_ORDER;
    }
    if (this.dashboardDetails) {
      this.partyReqId = this.dashboardDetails.party_req_id;
      this.wfDtlId = this.dashboardDetails.wf_inprocess_dtl_id;
      this.eventId = this.dashboardDetails.wf_event_id;
      this.userId = this.dashboardDetails.wf_user_id;
      // this.wfResId = this.dashboardDetails.wf_res_id;
      this.workflowId.menuId = this.dashboardDetails.wf_menu_id;
      this.menuId = this.dashboardDetails.dy_menu_id;
      this.approvalStatusKey = this.dashboardDetails.approval_status_key;
      this.seqOrder = this.dashboardDetails.seq_order;
    }
    if (this.menuId === this.workflowId.menuId) {
      this.accountFields.push(
        {
          key: 'vendor_type_lookup',
          label: 'Vendor Type'
        },
        {
          key: 'payment_term'
        },
        {
          key: 'liability_ccid_code',
          label: 'Liability Account'
        },
        {
          key: 'prepay_ccid_code',
          label: 'Pre Payment Account'
        }
      );
    } else if (this.menuId !== this.workflowId.menuId) {
      this.accountFields.push({
        key: 'payment_term'
      });
    }
    this.getPartyAccDetailsById();
    this.getPartyOnBoardingDetailsById();
    this.getUploadedDocDtl();
    this.getPaymentTerms();
  },
  methods: {
    checkSeqOrder() {
      if (this.seqOrder == 4) {
        this.addEditPartyAccDetails('finance');
      } else {
        this.approveRejectAccDetails('APPROVED');
      }
    },
    showHideAccDetailsApproveModal(flag) {
      this.showAccDetailsApproveModal = flag;
    },
    isPaymentTermExist() {
      const filterData = this.accountData.filter(
        elem => elem.acc_flag && !elem.payment_term_id
      );
      if (filterData.length) {
        alert('Please Enter Payment Term');
      } else {
        this.addEditPartyAccDetails('commercial');
      }
    },
    defaultPaymentCom() {
      if (this.paymentTerm.value) {
        this.accountData.map(elem => {
          if (elem.acc_flag) {
            elem.payment_term = this.paymentTerm.value;
            elem.payment_term_id = this.paymentTerm.id;
            elem.payment_term_meaning = this.paymentTerm.text;
          }
        });
        this.accountData = JSON.parse(JSON.stringify(this.accountData));
      } else {
        alert('Please Select Payment Term');
      }
    },
    getVendorEvaluationDetails() {
      if (
        this.totalEvaluationRows === 0 &&
        (this.templateTypeProp === 'TEMPLATE_1' ||
          this.templateTypeProp === 'TEMPLATE_2')
      ) {
        alert('Please upload Background Verification Document');
      } else {
        const payload = {
          party_req_id: this.partyReqId,
          type: this.templateTypeProp
        };
        this.loader = true;
        this.$store
          .dispatch('intWorkflow/getVendorEvaluationDetails', payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              if (resp.data.data.length) {
                this.showHideAccDetailsApproveModal(true);
                // this.approveRejectAccDetails('APPROVED');
              } else {
                alert('Please fill Evaluation Data');
              }
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    getAccountingDetailsCom() {
      if (this.legalEntity.value) {
        const payload = {
          party_req_id: this.partyReqId,
          le_id: this.legalEntity.value,
          ou_id: this.operatingUnit.value ? this.operatingUnit.value : ''
        };
        this.loader = true;
        this.$store
          .dispatch('intWorkflow/getAccountingDetails', payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              const result = resp.data.data;
              if (result) {
                this.accountData = result;
              }
            }
          })
          .catch(() => {
            this.loader = false;
          });
      } else {
        alert('Please Select Legal Entity');
      }
    },
    selectAccount(accType) {
      if (this.legalEntity.value) {
        this.defaultAccType = accType;
        this.openValueSetModal('PARTY_REQ_ACCOUNTING_VSET');
      } else {
        alert('Please Select Legal Entity');
      }
    },
    defaultLiability() {
      if (this.liabilityCcid) {
        this.accountData.map(elem => {
          elem.liability_ccid = this.liabilityCcid;
          elem.liability_ccid_code = this.liabilityCcidCode;
          elem.liability_ccid_meaning = this.liabilityCcidMeaning;
        });
      } else {
        alert('Please Select Liability Account');
      }
    },
    defaultPrepay() {
      if (this.prepayCcid) {
        this.accountData.map(elem => {
          elem.prepay_ccid = this.prepayCcid;
          elem.prepay_ccid_code = this.prepayCcidCode;
          elem.prepay_ccid_meaning = this.prepayCcidMeaning;
        });
      } else {
        alert('Please Select Pre Payment Account');
      }
    },
    defaultPayment() {
      if (this.paymentTerm.value) {
        this.accountData.map(elem => {
          elem.payment_term = this.paymentTerm.value;
          elem.payment_term_id = this.paymentTerm.id;
          elem.payment_term_meaning = this.paymentTerm.text;
        });
        this.accountData = JSON.parse(JSON.stringify(this.accountData));
      } else {
        alert('Please Select Payment Term');
      }
    },
    clearFields() {
      this.legalEntity = { text: null, value: null };
      this.operatingUnit = { text: null, value: null };
      this.vendorType = { text: null, value: null };
      this.accountDefaultFlag = false;
      this.getPartyAccDetailsById();
    },
    editModeChange() {
      this.editMode = true;
    },
    checkUncheckAll() {
      this.accountData = this.accountData.map(data => {
        data.acc_flag = this.selectAllCheckBox;
        return data;
      });
    },
    addEditPartyAccDetails(value) {
      const filterData = this.accountData.filter(data => data.acc_flag);
      if (filterData.length) {
        const partyDetails = filterData.map(elem => {
          return {
            add_site_id: elem.add_site_id ? elem.add_site_id : 0,
            acc_flag: elem.acc_flag,
            le_id: elem.le_id,
            vendor_type_lookup: elem.vendor_type_lookup
              ? elem.vendor_type_lookup
              : this.vendorType.value,
            ou_id: elem.ou_id,
            address_id: elem.address_id,
            state_id: elem.state_id,
            gstn: elem.gstn,
            liability_ccid: elem.liability_ccid,
            prepay_ccid: elem.prepay_ccid,
            payment_term_id: elem.payment_term_id,
            party_req_id: elem.party_req_id
              ? elem.party_req_id
              : this.partyReqId,
            payment_term_name: elem.payment_term,
            payment_term_desc: elem.payment_term_meaning
          };
        });
        const payload = {
          party_req_acc_detials: partyDetails ? partyDetails : null,
          gst_hold: this.gstHold
        };
        this.loader = true;
        this.$store
          .dispatch('intWorkflow/addEditPartyAccDetails', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 201) {
              this.editMode = false;
              this.showAlert = false;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
              this.isAccDetailsSaved = true;
              this.getPartyAccDetailsById(value);
              // if (value === 'finance') {
              //   this.validateAccountType();
              // }
              // this.clearFields();
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      } else {
        alert('Please Select Accounting Details');
      }
    },
    getPartyAccDetailsById(value) {
      const payload = {
        party_req_id: this.partyReqId
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getPartyAccDetailsById', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            if (result.length) {
              this.accountData = result;
              this.gstHold = this.accountData[0].gst_hold;
              this.editMode = false;
              this.isAccDetailsSaved = true;
              if (value === 'finance') {
                this.validateAccountType();
              } else if (value === 'clear') {
                this.clearFields();
              } else if (value === 'commercial') {
                this.legalEntity = { value: null, text: null };
                this.operatingUnit = { value: null, text: null };
                this.paymentTerm = { value: null, text: null, id: null };
              }
            } else {
              this.accountData = [];
              this.editMode = true;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    validateAccountType() {
      // if (this.vendorType.value) {
      const accType = this.accountData.filter(
        data =>
          !data.liability_ccid ||
          !data.prepay_ccid ||
          !data.payment_term_id ||
          !data.vendor_type_lookup
      );
      if (accType.length) {
        alert(
          'Vendor Type, Liability Account, Pre Payment Account and Payment Term are Mandatory'
        );
        this.legalEntity = { value: null, text: null };
        this.operatingUnit = { value: null, text: null };
        this.vendorType = { value: null, text: null };
        this.liabilityCcid = null;
        this.liabilityCcidCode = null;
        this.liabilityCcidMeaning = null;
        this.prepayCcid = null;
        this.prepayCcidCode = null;
        this.prepayCcidMeaning = null;
        this.paymentTerm = { value: null, text: null, id: null };
        this.accountDefaultFlag = false;
      } else {
        // this.approveRejectAccDetails('APPROVED');
        this.setTimeVset = setTimeout(() => {
          this.approveRejectAccDetails('APPROVED');
        }, 0);
      }
      // } else {
      //   alert('Please Select the Vendor Type First');
      // }
    },
    getAccountingDetailsFin() {
      if (
        this.legalEntity.value &&
        this.operatingUnit.value &&
        this.vendorType.value
      ) {
        const payload = {
          party_req_id: this.partyReqId,
          le_id: this.legalEntity.value,
          ou_id: this.operatingUnit.value ? this.operatingUnit.value : '',
          vendor_type: this.vendorType.value
        };
        this.loader = true;
        this.$store
          .dispatch('intWorkflow/getAccountingDetailsFin', payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              const result = resp.data.data;
              if (result.length) {
                this.accountDefaultFlag = true;
                this.editMode = true;
                this.accountData = result;
                // this.accountData = result.map(data => {
                //   data.payment_term = data.payment_term_name;
                //   data.payment_term_meaning = data.payment_term_description;
                //   return data;
                // });
                this.liabilityCcid = result[0].liability_ccid;
                this.liabilityCcidCode = result[0].liability_ccid_code;
                this.liabilityCcidMeaning = result[0].liability_ccid_meaning;
                this.prepayCcid = result[0].prepay_ccid;
                this.prepayCcidCode = result[0].prepay_ccid_code;
                this.prepayCcidMeaning = result[0].prepay_ccid_meaning;
                this.accountData.map(elem => (elem.acc_flag = true));
              } else {
                this.accountData = [];
              }
            }
          })
          .catch(() => {
            this.loader = false;
          });
      } else {
        alert('Please Select Legal Entity, Operating Unit and Vendor Type');
      }
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
        this.operatingUnit = { value: null, text: null };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.operatingUnit = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.PARTY_REQ_VENDOR_TYPE_VSET
      ) {
        this.vendorType = {
          text: item.value_meaning,
          value: item.value_code
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.VENDOR_PORTAL_PAYMENT_TERMS
      ) {
        if (this.accountType === 'payment_term') {
          this.accountData[this.specIndex].payment_term_id =
            item.value_set_dtl_id;
          this.accountData[this.specIndex].payment_term = item.value_code;
          this.accountData[this.specIndex].payment_term_meaning =
            item.description;
        } else {
          this.paymentTerm = {
            text: item.description,
            value: item.value_code,
            id: item.value_set_dtl_id
          };
        }
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.PARTY_REQ_ACCOUNTING_VSET
      ) {
        if (this.accountType === 'liability') {
          this.accountData[this.specIndex].liability_ccid = item.ccid;
          this.accountData[this.specIndex].liability_ccid_code = item.code;
          this.accountData[this.specIndex].liability_ccid_meaning =
            item.meaning;
        } else if (this.accountType === 'prepayment') {
          this.accountData[this.specIndex].prepay_ccid = item.ccid;
          this.accountData[this.specIndex].prepay_ccid_code = item.code;
          this.accountData[this.specIndex].prepay_ccid_meaning = item.meaning;
        } else if (this.defaultAccType === 'liability') {
          this.liabilityCcid = item.ccid;
          this.liabilityCcidCode = item.code;
          this.liabilityCcidMeaning = item.meaning;
        } else if (this.defaultAccType === 'prepay') {
          this.prepayCcid = item.ccid;
          this.prepayCcidCode = item.code;
          this.prepayCcidMeaning = item.meaning;
        }
      }
      this.accountData = JSON.parse(JSON.stringify(this.accountData));
      // else if (
      //   this.vsetCode === appStrings.VALUESETTYPE.PARTY_REQ_ACCOUNTING_VSET &&
      //   this.accountType === 'liability'
      // ) {
      //   this.accountData[this.specIndex].liability_ccid = item.ccid;
      //   this.accountData[this.specIndex].liability_ccid_code = item.code;
      //   this.accountData[this.specIndex].liability_ccid_meaning = item.meaning;
      // } else if (
      //   this.vsetCode === appStrings.VALUESETTYPE.PARTY_REQ_ACCOUNTING_VSET &&
      //   this.accountType === 'prepayment'
      // ) {
      //   this.accountData[this.specIndex].prepay_ccid = item.ccid;
      //   this.accountData[this.specIndex].prepay_ccid_code = item.code;
      //   this.accountData[this.specIndex].prepay_ccid_meaning = item.meaning;
      // }
    },
    openValueSetModal(vsetCode, index, accType) {
      this.vsetCode = vsetCode;
      this.specIndex = index;
      this.accountType = accType;
      this.parent_value_set_id = null;
      this.showValueSetModal = true;
      if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.legalEntity.value;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.PARTY_REQ_ACCOUNTING_VSET
      ) {
        if (accType) {
          this.parent_value_set_id = this.accountData[index].le_id;
        } else {
          this.parent_value_set_id = this.legalEntity.value;
        }
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode, index) {
      if (vsetCode === this.legalEntity.value) {
        this.legalEntity = {
          text: null,
          value: null
        };
        this.operatingUnit = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.operatingUnit.value) {
        this.operatingUnit = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.vendorType.value) {
        this.vendorType = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.liabilityCcidCode) {
        this.liabilityCcid = null;
        this.liabilityCcidCode = null;
        this.liabilityCcidMeaning = null;
      } else if (vsetCode === this.prepayCcidCode) {
        this.prepayCcid = null;
        this.prepayCcidCode = null;
        this.prepayCcidMeaning = null;
      } else if (vsetCode === this.paymentTerm.value) {
        this.paymentTerm = { value: null, text: null, id: null };
      } else if (vsetCode === 'liability') {
        this.accountData[index].liability_ccid = null;
        this.accountData[index].liability_ccid_code = null;
        this.accountData[index].liability_ccid_meaning = null;
      } else if (vsetCode === 'prepay') {
        this.accountData[index].prepay_ccid = null;
        this.accountData[index].prepay_ccid_code = null;
        this.accountData[index].prepay_ccid_meaning = null;
      } else if (vsetCode === 'payment_term') {
        this.accountData[index].payment_term_id = null;
        this.accountData[index].payment_term = null;
        this.accountData[index].payment_term_meaning = null;
      }
    },
    showHideAccDetailsRejectModal(flag) {
      this.showAccDetailsRejectModal = flag;
    },
    approveRejectAccDetails(status) {
      if (this.seqOrder == 4) {
        this.showHideAccDetailsApproveModal(false);
      }
      const payload = {
        event_id: this.eventId,
        wf_inprocess_dtl_id: this.wfDtlId,
        notification_status: status,
        notification_comments:
          status === 'APPROVED' ? this.approveRemarks : this.rejectedReason
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/approvalRejectSingle', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.getPartyOnBoardingDetailsById();
            this.$emit('updatedAccList');
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            if (status === 'REJECTED') {
              this.showHideAccDetailsRejectModal(false);
            } else if (status === 'APPROVED') {
              this.showHideAccDetailsApproveModal(false);
            }
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getPartyOnBoardingDetailsById() {
      const payload = {
        party_req_id: this.partyReqId,
        user_id: this.userId
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getPartyOnBoardingDetailsById', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.seqOrder = resp.data.data.seq_order;
            this.approvalStatus = resp.data.data.approval_status;
            this.approvalStatusKey = resp.data.data.approval_status_key;
            // this.approveRemarks = resp.data.data.comments;
            this.buyerRemarks = resp.data.data.buyers_remarks;
            this.firstRemarks = resp.data.data.first_remarks;
            this.secondRemarks = resp.data.data.second_remarks;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getUploadedDocDtl() {
      const payload = {
        _page: 0,
        _limit: 100,
        menu_id: 374,
        sub_menu_id: 87,
        record_id: this.partyReqId
      };
      this.documentData = [];
      this.$store
        .dispatch('intWorkflow/getOpenKmDocDtl', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.totalEvaluationRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    async setDefaultOperatingUnit() {
      const payload = {
        valueSetName: appStrings.VALUESETTYPE.OU_LIST,
        dependentVariable: {
          le_id: this.legalEntity.value
        }
      };
      const response = await this.$store.dispatch(
        'shared/getCommonVSetData',
        payload
      );
      const result = response.data.data;
      const valuesetData =
        result.data && result.data.length > 0 ? result.data : result.page;
      alert(valuesetData);
    },
    // tempFunc() {
    //   this.getMenuId();
    // },
    // getMenuId() {
    //   this.$store
    //     .dispatch('intWorkflow/getMenuIdForPartyOnBoarding')
    //     .then(response => {
    //       if (response.status === 200) {
    //         this.wfMenuId = response.data.data.menu_id;
    //         this.getEventId();
    //       }
    //     });
    // },
    // getEventId() {
    //   this.$store
    //     .dispatch('intWorkflow/getEventId', this.wfMenuId)
    //     .then(response => {
    //       if (response.status === 200) {
    //         this.eventId = response.data.data;
    //         this.approveRejectAccDetails('APPROVED');
    //       }
    //     });
    // },
    // tempFuncApprove() {
    // this.respFlag = true;
    // this.addEditPartyAccDetails('finance');
    // this.validateAccountType();
    // this.setTimeAprove = setTimeout(() => {
    //   this.validateAccountType();
    // }, 1000);
    // this.setTime = setTimeout(() => {
    //   this.getPartyAccDetailsById();
    //   this.setTimeAprove = setTimeout(() => {
    //     this.validateAccountType();
    //   }, 1000);
    // }, 1000);
    // }
    openCloseResponseModal() {
      this.showOpenResponseModal = true;
    },
    responseModal(flag) {
      this.showOpenResponseModal = flag;
    },
    selectedPaymentTerm(flag, paymentTerm) {
      this.showOpenResponseModal = flag;
      this.paymentTerm = {
        value: paymentTerm.payment_name,
        text: paymentTerm.description,
        id: paymentTerm.payment_term_id
      };
    },
    getPaymentTerms() {
      const payload = {
        payment_term_name: this.paymentTerm.value,
        payment_term_desc: this.paymentTerm.text
      };
      this.$store
        .dispatch('intWorkflow/getPaymentTerms', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 201) {
            this.paymentTermsData = response.data.data.data.page;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  },
  beforeDestroy() {
    clearTimeout(this.setTimeVset);
    clearTimeout(this.setTimeVsetCode);
    this.eventBus.$off('accountingPaymentTerms');
    // clearTimeout(this.setTime);
    // clearTimeout(this.setTimeAprove);
  }
};
