import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'tax',
  components: {
    DatePicker
  },
  props: [
    'editMode',
    'leaseMasterAgreementHdrId',
    'investorLeaseApprovalStatus'
  ],
  data() {
    return {
      vsetCode: null,
      uomClassObj: null,
      specIndex: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      areaType: null,
      setTimeVsetCode: null,
      gridData: [
        {
          active: false,
          category_name: null,
          lease_tenant_agreement_hdr_id: null,
          tax_group: null,
          tax_group_id: null,
          tax_cat_id: null,
          period_from: null,
          period_to: null,
          lease_tenant_agreement_tax_id: 0
        }
      ],
      gridFields: [
        {
          key: 'active'
        },
        {
          key: 'tax_group'
        },
        {
          key: 'category_name'
        },
        {
          key: 'period_from_to',
          label: 'Period (From - To)'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ]
    };
  },
  mounted() {
    this.eventBus.$on('tax', tabData => {
      if (tabData.leaseInvestorHdrId) {
        this.getDetails(tabData.leaseInvestorHdrId);
      }
      if (tabData.leaseMasterAgreementHdrId) {
        this.getMasterLease(tabData.leaseMasterAgreementHdrId);
      }
    });
  },
  methods: {
    getDetails(leaseInvestorHdrId) {
      const payload = {
        tabName: 'tax',
        leaseInvestorHdrId: leaseInvestorHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('leases/getLeaseInvestorDataById', payload)
        .then(resp => {
          this.loader = false;
          if (
            resp.status === 200 &&
            resp.data.data.length &&
            this.gridData[0].lease_tenant_agreement_tax_id === 0
          ) {
            this.gridData = resp.data.data.map(data => {
              data.period_from_to = [
                new Date(data.period_from),
                new Date(data.period_to)
              ];
              return data;
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addNewRow() {
      this.gridData.push({
        active: false,
        category_name: null,
        lease_tenant_agreement_hdr_id: null,
        tax_group: null,
        tax_group_id: null,
        tax_cat_id: null,
        period_from: null,
        period_to: null,
        lease_tenant_agreement_tax_id: 0
      });
    },
    getMasterLease(leaseMasterAgreementHdrId) {
      const payload = {
        tabName: 'tax',
        leaseMasterAgreementHdrId: leaseMasterAgreementHdrId
      };
      this.$store
        .dispatch('leases/getLeaseMasterAgreementDataById', payload)
        .then(resp => {
          if (resp.status === 200 && resp.data.data.length) {
            const filterData = resp.data.data.filter(
              item => item.active === true
            );
            this.gridData = filterData.map(item => {
              return {
                active: item.active,
                category_name: item.category_name,
                lease_tenant_agreement_hdr_id: 0,
                tax_group: item.tax_group,
                tax_group_id: item.tax_group_id,
                tax_cat_id: item.tax_cat_id,
                period_from: item.period_from,
                period_to: item.period_to,
                lease_tenant_agreement_tax_id: 0
              };
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    removeRow(index) {
      this.gridData.splice(index, 1);
    },
    opendDependentUom(vsetCode, index) {
      const payload = {
        valueSetName: vsetCode,
        dependentVariable: {
          user_id: this.$store.state.auth.userId,
          res_id: this.$store.state.shared.responsibilityId
        }
      };
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          this.loader = false;
          this.uomClassObj = null;
          if (response.status === 200) {
            const findTimeObj = response.data.data.page.find(
              vsetData => vsetData.value_code === 'TIME'
            );
            if (findTimeObj) {
              this.uomClassObj = findTimeObj;
              this.openValueSetModal('UOM', index);
            }
          }
        });
    },
    selectBoxChecked(flag, index) {
      if (flag) {
        const findActivatedRecords = this.gridData.filter(data => {
          return (
            data.active &&
            this.gridData[index].tax_group_id == data.tax_group_id
          );
        });
        if (findActivatedRecords.length > 1) {
          alert('Two groups can not be active');
          this.gridData[index].active = false;
        }
      }
    },
    validateDoubleGroup(item) {
      const findActivatedRecords = this.gridData.find(data => {
        return (
          data.active === this.gridData[this.specIndex].active &&
          item.tax_group_id == data.tax_group_id
        );
      });
      return findActivatedRecords ? true : false;
    },
    selectedvalueSet(item) {
      if (
        this.validateDoubleGroup(item) &&
        this.vsetCode === 'TAX_GROUP_NAME_VSET'
      ) {
        alert('You are not allowed to add two active group');
        return;
      }
      if (this.vsetCode === 'TAX_GROUP_NAME_VSET') {
        this.gridData[this.specIndex].tax_group_id = item.tax_group_id;
        this.gridData[this.specIndex].tax_group = item.tax_group;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.TAX_CATEGORY_NAME_VSET) {
        this.gridData[this.specIndex].category_name = item.category_name;
        this.gridData[this.specIndex].tax_cat_id = item.tax_cat_hdr_id;
      }
      this.showValueSetModal = false;
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.specIndex = index;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.gridData[this.specIndex].tax_group_id) {
        this.parent_value_set_id = this.gridData[this.specIndex].tax_group_id;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    setDate(dateRange, index) {
      this.gridData[index].period_from_to = commonHelper.setFutureDate(dateRange);
    },
    clearVsetValues(vsetCode, index){
      if(vsetCode === 'TAX_GROUP_NAME_VSET'){
        this.gridData[index].tax_group_id = null;
        this.gridData[index].tax_group = null;
      }else if (vsetCode === 'TAX_CATEGORY_NAME_VSET'){
        this.gridData[index].category_name = null;
        this.gridData[index].tax_cat_id = null;
      }

    }
  },
  beforeDestroy() {
    this.eventBus.$off('tax');
  }
};
