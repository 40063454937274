import commonHelper from '@/app/utility/common.helper.utility';

export default {
  name: 'responseModal',
  data() {
    return {
      loading: false,
      responseDetailsData: [],
      responseDetailsFields: [],
      showOpenModal: false,
      selectedResponse: {
        value: null,
        text: null
      },
      searchResponse: null,
      currentPage: 1,
      perPage: commonHelper.perPageRecordData,
      pageOptions: commonHelper.getPageOption()
    };
  },
  props: [
    'selectedNumber',
    'responseData',
    'responseFields',
    'selectedField',
    'allData',
    'arInvoiceFunc'
  ],
  mounted() {
    if (this.responseData && this.responseFields) {
      this.responseDetailsData = this.responseData;
      this.responseDetailsFields = this.responseFields;
    }
  },
  methods: {
    rowSelected(item) {
      this.showOpenModal = false;
      if (this.selectedField === 'transactionNumber') {
        this.selectedResponse = {
          value: item.trx_number,
          text: item.trx_number
        };
      } else if (this.selectedField === 'legalEntity') {
        this.selectedResponse = {
          value: item.legal_entity_id,
          text: item.name
        };
      } else if (this.selectedField === 'operatingUnit') {
        this.selectedResponse = {
          value: item.organization_id,
          text: item.name
        };
      } else if (this.selectedField === 'bankAccounts') {
        this.selectedResponse = {
          value: item.bank_account_id,
          text: item.bank_account_num
        };
      } else if (this.selectedField === 'customers') {
        this.selectedResponse = {
          value: item.customer_id,
          text: item.customer_name
        };
      } else if (this.selectedField === 'customerSite') {
        this.selectedResponse = {
          value: item.site_use_id,
          text: item.bill_to,
          meaning: item.party_site_number
        };
      } else if (this.selectedField === 'currency') {
        this.selectedResponse = {
          value: item.currency_code,
          text: item.name
        };
      } else if (this.selectedField === 'receiptMethod') {
        this.selectedResponse = {
          accountname: item.bank_account_name,
          accountnumber: item.bank_account_number,
          bankname: item.bank_name,
          bankbranchname: item.bank_branch_name,
          receiptmethod: item.receipt_method,
          receiptmethodid: item.receipt_method_id
        };
      } else if (this.selectedField === 'transactionNumbers') {
        this.selectedResponse = {
          amountDue: item.amount_due,
          amountOriginal: item.amount_original,
          customerTrxId: item.customer_trx_id,
          transactionNumber: item.trx_number,
          trxType: item.trx_type
        };
        this.eventBus.$emit('transactionNumbers', this.selectedResponse);
      } else if (this.selectedField === 'assetStatus') {
        this.selectedResponse = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      } else if (this.selectedField === 'LEGAL_ENTITY_LIST_FUSION') {
        this.selectedResponse = {
          legalEntityId: item.LEGAL_ENTITY_ID,
          name: item.NAME
        };
      } else if (this.selectedField === 'itemCode') {
        this.selectedResponse = {
          item_code: item.item_code,
          item_description: item.item_description,
          uom: item.uom,
          item_id: item.item_id
        };
        this.eventBus.$emit('itemCode', this.selectedResponse);
      } else if (this.selectedField === 'userDepartment') {
        this.selectedResponse = {
          concatenated_segment: item.concatenated_segment,
          concatenated_segment_meaning: item.concatenated_segment_meaning
        };
        this.eventBus.$emit('userDepartment', this.selectedResponse);
      } else if (this.selectedField === 'rfpStates') {
        this.selectedResponse = {
          region: item.region,
          state: item.state,
          state_code: item.state_code
        };
        this.eventBus.$emit('rfpSelectedState', this.selectedResponse);
      } else if (this.selectedField === 'costCentre') {
        this.selectedResponse = {
          cost_centre: item.cost_centre,
          state_code: item.state_code
        };
        this.eventBus.$emit('rfpSelectedCostCentre', this.selectedResponse);
      } else if (this.selectedField === 'paymentTerms') {
        this.selectedResponse = {
          payment_name: item.payment_term_name,
          description: item.payment_term_desc,
          payment_term_id: item.term_id
        };
        this.eventBus.$emit('accountingPaymentTerms', this.selectedResponse);
      }
      const functionVariable = this.selectedNumber;
      functionVariable(this.showOpenModal, this.selectedResponse);
    },
    searchResponseData() {
      if (this.selectedField == 'LEGAL_ENTITY_LIST_FUSION') {
        this.arInvoiceFunc('LEGAL_ENTITY_LIST_FUSION', this.searchResponse);
      } else {
        const search = this.searchResponse;
        if (this.selectedField === 'transactionNumber') {
          this.responseDetailsData = this.responseDetailsData.filter(item => {
            return item.trx_number === search;
          });
        } else if (this.selectedField === 'legalEntity') {
          this.responseDetailsData = this.responseDetailsData.filter(item => {
            return item.name === search;
          });
        } else if (this.selectedField === 'operatingUnit') {
          this.responseDetailsData = this.responseDetailsData.filter(item => {
            return item.name === search;
          });
        } else if (this.selectedField === 'bankAccounts') {
          this.responseDetailsData = this.responseDetailsData.filter(item => {
            return item.bank_name === search;
          });
        } else if (this.selectedField === 'customers') {
          this.responseDetailsData = this.responseDetailsData.filter(item => {
            return item.customer_name === search;
          });
        } else if (this.selectedField === 'transactionNumbers') {
          this.responseDetailsData = this.responseDetailsData.filter(item => {
            return item.trx_number === search;
          });
        } else if (this.selectedField === 'currency') {
          this.responseDetailsData = this.responseDetailsData.filter(item => {
            return item.currency_code === search;
          });
        } else if (
          this.selectedField === 'assetStatus' &&
          this.searchResponse !== null
        ) {
          if (this.searchResponse == '') {
            this.responseDetailsData = this.responseData;
          } else {
            this.responseDetailsData = this.responseDetailsData.filter(item => {
              return item.value_code === this.searchResponse;
            });
          }
        } else if (this.selectedField === 'itemCode') {
          // let payload = {
          //   _page: 0,
          //   _limit: 10000,
          //   item_code: search
          // };
          // this.loading = true;
          // this.$store
          //   .dispatch('rfp/getItemCodeData', payload)
          //   .then(response => {
          //     if (response.status === 200) {
          //       this.loading = false;
          //       if (response.data.data.page.length > 0) {
          //         this.responseDetailsData = response.data.data.page;
          //       }
          //     }
          //   })
          //   .catch(() => {
          //     this.loading = false;
          //   });
          let payload1 = {
            _page: 0,
            _limit: 10000,
            item_description: search
          };
          this.loading = true;
          this.$store
            .dispatch('rfp/getItemCodeData', payload1)
            .then(response => {
              if (response.status === 200) {
                this.loading = false;
                if (response.data.data.page.length > 0) {
                  this.responseDetailsData = response.data.data.page;
                }
              }
            })
            .catch(() => {
              this.loading = false;
            });
        } else if (this.selectedField === 'userDepartment') {
          if (search) {
            const payload = {
              le_id: 67,
              _page: 0,
              _limit: 1000,
              concatenated_segment_meaning: search
            };
            this.loading = true;
            this.$store
              .dispatch('rfp/getUserDepartmentData', payload)
              .then(response => {
                if (response.status === 200) {
                  this.responseDetailsData = response.data.data.page;
                }
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          } else {
            this.responseDetailsData = this.responseData;
          }
        } else if (this.selectedField === 'rfpStates') {
          if (search) {
            this.responseDetailsData = this.responseDetailsData.filter(item => {
              return item.region === search || item.state === search;
            });
          } else {
            this.responseDetailsData = this.responseData;
          }
        } else if (this.selectedField === 'costCentre') {
          if (search) {
            const payload = {
              _page: 0,
              _limit: 1000,
              cost_centre: search
            };
            this.loading = true;
            this.$store
              .dispatch('rfp/getCostCentreData', payload)
              .then(response => {
                if (response.status === 200) {
                  this.responseDetailsData = response.data.data.data.page;
                }
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          } else {
            this.responseDetailsData = this.responseData;
          }
        } else if (this.selectedField === 'paymentTerms') {
          if (search) {
            const payload = {
              _page: 0,
              _limit: 1000,
              payment_term_name: search,
              payment_term_desc: null
            };
            this.loading = true;
            this.$store
              .dispatch('intWorkflow/getPaymentTerms', payload)
              .then(response => {
                if (response.status === 201) {
                  if (response.data.data.data.page.length > 0) {
                    this.responseDetailsData = response.data.data.data.page;
                  }
                }
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });

              const payload1 = {
                _page: 0,
                _limit: 1000,
                payment_term_name: null,
                payment_term_desc: search
              };
              this.loading = true;
              this.$store
                .dispatch('intWorkflow/getPaymentTerms', payload1)
                .then(response => {
                  if (response.status === 201) {
                    if (response.data.data.data.page.length > 0) {
                      this.responseDetailsData = response.data.data.data.page;
                    }
                  }
                  this.loading = false;
                })
                .catch(() => {
                  this.loading = false;
                });


            
          } else {
            this.responseDetailsData = this.responseData;
          }
        }
      }
    },
    searchClear() {
      this.searchResponse = null;
    }
  }
};
